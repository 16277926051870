/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Customer} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  Customer: Customer
}

const UserInfoCell: FC<Props> = ({Customer}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-40px overflow-hidden me-3'>
      <a href='#'>
        {Customer.avatar ? (
          <div className='symbol-label'>
            <img
              src={process.env.REACT_APP_IMG_URL + '/customer/' + Customer?.avatar}
              alt={Customer.name}
              className='w-100'
            />
          </div>
        ) : (
          <div
            className={clsx(
              'symbol-label fs-4',
              'badge-light-success',
              `bg-light-${Customer?.isDeleted == true ? 'danger' : 'success'}`
            )}
          >
            <span style={{fontSize: '20x'}}>
              {(Customer?.name ?? '').charAt(0).toUpperCase() +
                (Customer?.name ?? '').charAt(1).toUpperCase()}
            </span>
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column'>
      <Link to={'/customers/' + Customer.code}>
        <a href='#' className='text-gray-800 text-hover-primary mb-1'>
          {Customer?.name}
        </a>
      </Link>

      {/*<span>
        {Customer?.isDeleted ? (
          <span className='badge badge-light-danger'>DISABLED </span>
        ) : (
          <span className='badge badge-light-success'>ACTIVE </span>
        )}{' '}
      </span>*/}
    </div>
  </div>
)

export {UserInfoCell}

import React from 'react'
import './styles.css'

type BadgeProps = {
  className: any
}

const UnderDevelopmentBadge: React.FC<BadgeProps> = (className) => {
  return (
    <div className={`d-flex align-items-center ms-2 ${className}`}>
      <span className='badge badge-warning new fs-9'>
        <div className='wave'></div>
        <div className='symbol symbol-20px me-2'>
          <img src='/media/other/underD1.gif' alt='' />
        </div>
        Under Development
      </span>
    </div>
    // <button type='button' className={`btn btn-${theme} btn-sm`} onClick={onClick}>
    //   {icon} {text}
    // </button>
  )
}

export default UnderDevelopmentBadge

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import AvarageLinechart from './AvarageLinechart'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title: string
  aggregateRateData?: any
  label ?: string
}

export const AvarageOverviewChart: React.FC<Props> = ({className, title,aggregateRateData,label}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-6 mb-1'>{title}</span>
        </h3>

        <div className='card-toolbar'>
          {/* <h3 className='card-title align-items-end flex-column '>
            <span className='text-dark fw-boldfs-6'> 9.564</span>
            <span className='text-muted fw-lite fs-7 text-end'>01:14</span>
          </h3> */}
        </div>
      </div>

      <div className='card-body pt-0'>
        <AvarageLinechart details={aggregateRateData} label={label}/>
      </div>
    </div>
  )
}


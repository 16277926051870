/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

const UsersListSearchComponent = () => {
  const {updateState} = useQueryRequest()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const inputRef = useRef<HTMLInputElement>(null)

  const handleSearch = () => {
    if (inputRef.current) {
      const newSearchTerm = inputRef.current.value
      setSearchTerm(newSearchTerm)
      updateState({search: newSearchTerm, ...initialQueryState})
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }
  useEffect(() => {
    if (searchTerm === '') {
      updateState({search: '', ...initialQueryState})
    }
  }, [searchTerm])

  return (
    <div className='d-flex justify-content-end align-items-center'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative flex-nowrap'>
        <input
          ref={inputRef}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-white form-control-sm w-250px custom-search-radius'
          placeholder='Search Customer'
          onKeyDown={handleKeyDown}
          onChange={() => setSearchTerm(inputRef.current?.value || '')}
        />
        <button
          className='btn btn-primary btn-sm btn-icon px-3 custom-search-btn-radius'
          onClick={handleSearch}
        >
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}

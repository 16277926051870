/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import Linechart from './Linechart'

type Props = {
  className: string
  chartColor?: string
  chartHeight?: string
  title: string
  onDemardData?: any
  label ?: string
}

export const OverviewChart: React.FC<Props> = ({className, title, onDemardData, label}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-6 mb-1'>{title}</span>
        </h3>

        <div className='card-toolbar'>
          {/* <h3 className='card-title align-items-end flex-column '>
            <span className='text-dark fw-bold fs-6'>956.4</span>
            <span className='text-muted fw-lite fs-7 text-end'>01:14</span>
          </h3> */}
        </div>
      </div>

      <div className='card-body pt-0'>
        <Linechart details={onDemardData} label={label} />
      </div>
    </div>
  )
}

import {useQuery} from 'react-query'
import {UserEditModalForm} from './UserEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getUserById} from '../core/_requests'

type Props = {
  userType: string
  customerCode: string
}

const UserEditModalFormWrapper: React.FC<Props> = ({userType, customerCode}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    // {
    //   cacheTime: 0,
    //   enabled: itemIdForUpdate !== undefined && enabledQuery,
    //   onError: (err) => {
    //     setItemIdForUpdate(undefined)
    //     console.error(err)
    //   },
    // }

    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  if (!itemIdForUpdate) {
    return (
      <UserEditModalForm
        customerCode={customerCode}
        userType={userType}
        isUserLoading={isLoading}
        user={{code: undefined}}
      />
    )
  }

  if (!isLoading && !error && user) {
    return (
      <UserEditModalForm
        userType={userType}
        isUserLoading={isLoading}
        user={user}
        customerCode={customerCode}
      />
    )
  }

  return null
}

export {UserEditModalFormWrapper}

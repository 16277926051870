import axios, {AxiosResponse} from 'axios'
import {ID, Response, ResponseObject} from '../../../../../_metronic/helpers'
import {Customer, CustomersQueryResponse, Deletemodel, DropdownResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const Customer_URL = `${API_URL}/customer/Insert`
const GET_CustomerS_URL = `${API_URL}/customer/filter`
const GET_RoleS_URL = `${API_URL}/roles/getall-shortlist`
const GET_CUSTOMERBY_URL = `${API_URL}/customer/GetAccountDetailByID`
const UPDATE_CUSTOMER_URL = `${API_URL}/customer/Update`
const Delete_CUSTOMERBY_URL = `${API_URL}/customer/DeleteCustomerAccount`
const GET_ALL_COUNTRY = `${API_URL}/country/getall`
const GET_ALL_MASTER = `${API_URL}/master/getall`

const POST_INV_EMAILS = `${API_URL}/customer/send-invitation`
const GET_ALL_CUSTOMER_LOCATION = `${API_URL}/customer`
const Customer_REINVITE_URL = `${API_URL}/customer/re-invite`
const Customer_Email_Check = `${API_URL}/customer/check-email`

const sendInvitation = (Customer: Customer): Promise<Customer | undefined> => {
  return axios
    .post(POST_INV_EMAILS, Customer)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

const getCustomers = (query: string,payload:any): Promise<CustomersQueryResponse> => {
  var dt = axios
    .post(`${GET_CustomerS_URL}/?${query}`,payload)
    .then((d: AxiosResponse<CustomersQueryResponse>) => d.data)
  return dt
}

const getCustomerLocations = (Code: number): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_CUSTOMER_LOCATION}/${Code}}/location/getall-shortlist`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}
const getCountries = (): Promise<DropdownResponse> => {
  var dt = axios.get(`${GET_ALL_COUNTRY}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getMaster = (code: string): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_MASTER}/${code}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const checkCustomerEmail = (email: string): Promise<ResponseObject | undefined> => {
  var dt = axios
    .get(`${Customer_Email_Check}/${email}`)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)

    return dt;
}


const getCustomerById = (id: ID): Promise<Customer | undefined> => {
  var dr = axios
    .get(`${GET_CUSTOMERBY_URL}/${id}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
  return dr
}

const createCustomer = (Customer: Customer): Promise<ResponseObject | undefined> => {
  return axios
    .post(Customer_URL, Customer)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const updateCustomer = (Customer: FormData): Promise<ResponseObject | undefined> => {
  return axios
    .post(UPDATE_CUSTOMER_URL, Customer, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const deleteCustomer = (Id: ID): Promise<ID | undefined> => {
  return axios
    .delete(`${Delete_CUSTOMERBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const deleteSelectedCustomers = (CustomerIds: Array<ID>): Promise<void> => {
  const requests = CustomerIds.map((id) => axios.delete(`${Delete_CUSTOMERBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const reinvite = (CustomerID: string): Promise<Customer | undefined> => {
  return axios
    .post(`${Customer_REINVITE_URL}/${CustomerID}`)
    .then((response: AxiosResponse<Response<Customer>>) => response.data)
    .then((response: Response<Customer>) => response.data)
}

export {
  getCustomers,
  deleteCustomer,
  deleteSelectedCustomers,
  getCustomerById,
  createCustomer,
  updateCustomer,
  getMaster,
  getCustomerLocations,
  sendInvitation,
  reinvite,
  getCountries,
  checkCustomerEmail
}

import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {useState} from 'react'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function InvoiceView() {
  const [selectedFormat, setSelectedFormat] = useState('0')

  const handleFormatChange = (event: any) => {
    setSelectedFormat(event.target.value)
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_edit_invoice'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Invoice Custom ID</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <span style={{fontSize: '12px'}}>
                      Specify number of invoice generated in format XXX-XX-XX-0000.<br></br> Prefix
                      and postfix are 0-5 alphanumeric characters, combination should be unique.
                      Starting number will be added to generated sequential number. Keep 0 to start
                      from 1. Add leading zeros with Min Digits option. <br />
                      <strong>
                        After rule creation, starting number can not be set lower than last used
                      </strong>
                    </span>
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Invoice Number For
                    </label>
                    <select
                      className='form-select form-select-sm form-select-white custom-input-height'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      onChange={handleFormatChange}
                    >
                      <option value='0'>Select</option>
                      <option value='1'> Interpreters (global)</option>
                      <option value='2'> Customer (global)</option>
                      {/* <option value='3'> Clients (pre-account) </option> */}
                    </select>
                  </div>
                  <div className='row g-4 g-xl-6'>
                    <div className='col-sm-12 col-md-12 mb-5'>
                      {/* <div className='d-flex justify-content-between align-items-center mb-3'>
                        <div> Prefix</div>
                        <div> Prefix</div>
                        <div> Prefix - Min Digits</div>
                      </div> */}
                      <div className='input-group input-group-sm'>
                        <input
                          type='text'
                          className='form-control form-control-white form-control-sm custom-input-height'
                          placeholder='INV'
                        />
                        <span className='input-group-text'>-</span>
                        <input
                          type='text'
                          className='form-control form-control-white form-control-sm custom-input-height'
                          placeholder='AC'
                        />
                        <span className='input-group-text'>-</span>
                        {selectedFormat === '0' && (
                          <input
                            type='text'
                            className='form-control form-control-white form-control-sm custom-input-height'
                            placeholder='None'
                          />
                        )}

                        {selectedFormat === '1' && (
                          <input
                            type='text'
                            className='form-control form-control-white form-control-sm custom-input-height'
                            placeholder='AP'
                          />
                        )}
                        {selectedFormat === '2' && (
                          <input
                            type='text'
                            className='form-control form-control-white form-control-sm custom-input-height'
                            placeholder='AR'
                          />
                        )}
                        <span className='input-group-text'>-</span>
                        <input
                          type='Number'
                          className='form-control form-control-white form-control-sm custom-input-height'
                          placeholder='None'
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>
                      Next invoice number preview: <strong>INV-AC-(AP/AR)-0000</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_delete_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Delete Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/delete.gif' alt='' />
                </div>
              </div>
              <h4 style={{textAlign: 'center'}}>Are you sure you want to delete this?</h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-danger btn-sm' data-bs-dismiss='modal'>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='row g-1'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex align-items-center me-4 mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <h5 className='text-black fs-4 fw-semibold mb-0'>Invoice Numbers</h5>
                </div>
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Add system default invoice format
                  </p>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center mb-2'>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'> Add Invoice Number</Tooltip>}
              >
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_add_edit_invoice'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <div className='py-0 pt-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                <thead>
                  <tr className='fw-semibold text-muted text-uppercase'>
                    <th className='min-w-100px '>Rule Scope </th>
                    <th className='min-w-100px '>Format Sample </th>

                    <th className='min-w-100px text-end'>Action</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <a className='text-gray-800 text-hover-primary fs-6'>Interpreters (global)</a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary fs-6'>INV-AC-AP-58994</a>
                    </td>

                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_add_edit_invoice'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-muted'
                          />
                        </a>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_delete_confirm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-muted '
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <a className='text-gray-800 text-hover-primary fs-6'>Clients (global)</a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary fs-6'>INV-AC-AR-66994</a>
                    </td>

                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_add_edit_invoice'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-muted'
                          />
                        </a>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_delete_confirm'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-muted '
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

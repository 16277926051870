/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

import {LogsSheduledViewTable} from './LogsSheduledViewTable'
import {LogsInPersonFilterDropdown} from './LogsInPersonFilterDropdown'
import {LogsInPersonViewTable} from './LogsInPersonViewTable'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

type Props = {
  className: string
}

const LogsInPerson: React.FC<Props> = () => {
  return (
    <>
      <div className='card-body p-0'>
        <div className='rounded'>
          <div className='mt-4 d-flex justify-content-between flex-wrap align-items-center'>
            <div className='d-flex'>
              <h4 className='text-center mb-0'>In Person Log</h4>
            </div>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Download CSV</Tooltip>}
              >
                <button type='button' className='btn btn-primary btn-sm me-3 btn-icon'>
                  <KTSVG path='/media/icons/duotune/files/fil017.svg' className='svg-icon-muted' />
                </button>
              </OverlayTrigger>
              <div className=''>
                <LogsInPersonFilterDropdown />
              </div>
            </div>
          </div>
          <div className='table-responsive mb-5 mt-3'>
            <LogsInPersonViewTable className={''} />
          </div>
        </div>

        <img
          className='w-100 card-rounded-bottom'
          alt=''
          src='assetsmedia/svg/illustrations/bg-4.svg'
        />
      </div>
    </>
  )
}

export {LogsInPerson}

import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu, Search, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {useState} from 'react'
import {updateNotificationStatus} from '../../../../app/UI/Notifications/core/_requests'
import './styles.css'
import {useOktaAuth} from '@okta/okta-react'

const itemClass = 'ms-3 ms-lg-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px w-md-40px h-md-40px'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'

const Navbar = () => {
  const {oktaAuth} = useOktaAuth()
  const {config} = useLayout()
  const {currentUser, logout} = useAuth()
  const [unviewedCount, setUnviewedCount] = useState<number>(0)
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const UpdateNotifications = () => {
    updateNotificationStatus()
    setUnviewedCount(0)
  }

  const handleLogOut = async () => {
    try {
      const result = await oktaAuth.signOut()
      if (result) {
        logout()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <div className='app-navbar flex-shrink-0'>
      {/* <div className={clsx('app-navbar-item align-items-stretch', itemClass)}>
        <Search />
      </div> */}
      {currentUser?.result.userType == 'INTERPRETER' && (
        <>
          <div className='d-flex align-items-center text-gray-500 fs-6 me-3 custom-hide-class'>
            Ad Astra Internal{' '}
          </div>
          <div className={clsx('app-navbar-item', itemClass)}>
            <a
              onClick={() => {
                openInNewTab(`${process.env.REACT_APP_AWS_CONNECT_URL}`)
              }}
              className='btn btn-success btn-sm fs-6'
            >
              <i className='fa-solid fa-phone me-2'></i>Connect
            </a>
          </div>
        </>
      )}

      <div className={clsx('app-navbar-item me-5 me-lg-10', itemClass)}>
        <div className='d-flex flex-column me-4 custom-hide-class'>
          <div className='fw-bold d-flex align-items-center fs-7'>
            <span className=''>
              {' '}
              {currentUser?.result.firstName} {currentUser?.result.lastName}{' '}
            </span>
          </div>
          <div className='text-end'>
            <span className='text-gray-600 fw-semibolder fs-8 py-1'>
              {currentUser?.result.role}
            </span>
          </div>
        </div>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div
            className={clsx(
              'cursor-pointer symbol symbol-circle d-flex align-items-center symbol-50px',
              userAvatarClass
            )}
          >
            {currentUser?.result?.profileImage !== null &&
              currentUser?.result?.profileImage !== undefined &&
              currentUser?.result?.profileImage !== '' &&
              currentUser?.result?.profileImage !== 'default.png' && (
                <>
                  <img
                    alt='Logo'
                    src={
                      process.env.REACT_APP_IMG_URL +
                      '/profile/' +
                      currentUser?.result?.profileImage
                    }
                  />
                </>
              )}
            {!(
              currentUser?.result?.profileImage !== null &&
              currentUser?.result?.profileImage !== undefined &&
              currentUser?.result?.profileImage !== '' &&
              currentUser?.result?.profileImage !== 'default.png'
            ) && (
              <>
                <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
              </>
            )}{' '}
            <a href='#' className='fs-2 fw-bold ms-4 cursor-pointer'>
              <i className='bi bi-chevron-down fa-2x' style={{fontSize: '1rem'}} />
            </a>
          </div>
        </div>

        <HeaderUserMenu />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          className={btnClass}
          style={{position: 'relative'}}
          onClick={UpdateNotifications} // Ensure relative positioning for parent div
        >
          {unviewedCount > 0 && (
            <span
              className='badge badge-circle bg-danger badge-position '
              style={{
                width: '18px',
                height: '18px',
                position: 'absolute',
                top: '6px',
                right: '6px',
                transform: 'translate(50%, -50%)',
              }}
            >
              {unviewedCount}
            </span>
          )}
          <div className='symbol-label fs-2 fw-bold'>
            <i className='bi bi-bell fa-3x' style={{fontSize: '1.5rem'}} />
          </div>
        </div>
        <HeaderNotificationsMenu setUnviewedCount={setUnviewedCount} unViewdCount={unviewedCount} />
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          // data-kt-menu-trigger="{default: 'click'}"
          // data-kt-menu-attach='parent'
          // data-kt-menu-placement='bottom-end'
          className={btnClass}
          // style={{position: 'relative'}}
          onClick={handleLogOut} // Ensure relative positioning for parent div
        >
          <div className='symbol-label fs-2 fw-bold'>
            <i className='bi bi-box-arrow-right fa-3x' style={{fontSize: '1.5rem'}} />
          </div>
        </div>
      </div>

      {/* <div className={clsx('app-navbar-item', itemClass)}>
        <div className={clsx('position-relative', btnClass)} id='kt_drawer_chat_toggle'>
          <KTSVG path='/media/icons/duotune/communication/com012.svg' className={btnIconClass} />
          <KTSVG
            path='media/icons/duotune/general/gen007.svg'
            className='svg-icon-muted svg-icon-2hx'
          />
          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink' />
        </div>
      </div>

      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div> */}

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          {/* <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div> */}
        </div>
      )}
    </div>
  )
}

export {Navbar}

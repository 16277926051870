import React, {FC, useState, useCallback} from 'react'
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty, ResponseObject} from '../../../../../_metronic/helpers'
import {initialUser, Location} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, updateUserInfo} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import toaster from '../../../../../Utils/toaster'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete'
import {DeleteConfirmationModal} from '../../../../common/Models/confirmation-modal/DeleteConfirmationModal'

type Props = {
  isUserLoading: boolean
  user: Location
  userType: string
  customerCode: number
}

interface RenderFuncProps {
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  getSuggestionItemProps: (suggestion: Suggestion) => React.HTMLAttributes<HTMLDivElement>
  suggestions: any
  loading?: boolean // Optional loading indicator
  error: any
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const containerStyle = {
  width: 'auto',
  height: '400px',
}

const center = {
  lat: -3.745,
  lng: -38.523,
}

const editUserSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  address: Yup.string().required('Required'),
  street1: Yup.string(),
  street2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  latitude: Yup.number(),
  longitude: Yup.number(),
  isActive: Yup.boolean(),
})

const UserEditModalForm: FC<Props> = ({user, isUserLoading, userType, customerCode}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<Location>({
    code: user.code ?? initialUser.code,
    name: user.name ?? initialUser.name,
    address: user.address ?? initialUser.address,
    city: user.city ?? initialUser.city,
    state: user.state ?? initialUser.state,
    country: user.country ?? initialUser.country,
    postalCode: user.postalCode ?? initialUser.postalCode,
    latitude: user.latitude ?? initialUser.latitude,
    longitude: user.longitude ?? initialUser.longitude,
    street1: user.street1 ?? initialUser.street1,
    street2: user.street2 ?? initialUser.street2,
    isActive: user.isActive ?? initialUser.isActive,
  })
  const [gpsLocation, setGpsLocation] = useState<any>({
    lat: user.latitude ?? initialUser.latitude,
    lng: user.longitude ?? initialUser.longitude,
  })
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyC0Nh39yAtFIg-x83gBbRCIfOOE_N8Qdl0',
  })
  const [map, setMap] = useState<google.maps.Map | null>(null)

  const [google, setGoogle] = useState<any>(window.google ? window.google : {})

  const onLoad = useCallback(
    (map: any) => {
      setGoogle(window.google)
      setMap(map)
      // eslint-disable-next-line
    },
    [map]
  )

  const onUnmount = useCallback(() => {
    setMap(null)
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      values.fK_CustomerCode = customerCode
      // if (values.isDeleted + '' === 'true' || values.isDeleted + '' == 'false') {
      //   values.isDeleted = JSON.parse(values.isDeleted + '')
      // }
      setSubmitting(true)
      try {
        let result: any
        if (isNotEmpty(values.code)) {
          result = await updateUserInfo(values)
          if (result?.result?.status == 'S') {
            toaster('success', result?.text ?? 'Successfully updated')
            cancel(true)
          } else if (result?.result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not Updated')
          }
        } else {
          result = await createUser(values)
          if (result?.status == 'S') {
            toaster('success', result?.text ?? 'Record Updated')
            cancel(true)
          } else if (result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not Updated')
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address)
      const placeDetails = await getLatLng(results[0])

      // Extract detailed address components
      let street1 = ''
      let street2 = ''
      let city = ''
      let state = ''
      let country = ''
      let postalCode = ''

      results[0].address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          street1 = component.long_name
        } else if (component.types.includes('route')) {
          street2 = component.long_name
        } else if (component.types.includes('locality')) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })

      formik.setFieldValue('address', address)
      formik.setFieldValue('street1', street1)
      formik.setFieldValue('street2', street2)
      formik.setFieldValue('city', city)
      formik.setFieldValue('state', state)
      formik.setFieldValue('country', country)
      formik.setFieldValue('postalCode', postalCode)
      formik.setFieldValue('latitude', placeDetails.lat)
      formik.setFieldValue('longitude', placeDetails.lng)
      setGpsLocation({
        lat: placeDetails.lat,
        lng: placeDetails.lng,
      })

      // Select the input element with id "city"
      const cityInputElement = document.querySelector<HTMLInputElement>('#city')

      // Ensure the cityInputElement exists before focusing on it
      if (cityInputElement) {
        cityInputElement.focus() // Focuses on the input field
      }
    } catch (error) {
      console.error('Error fetching place details', error)
    }
  }

  const renderFunc: React.FC<RenderFuncProps> = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
    error,
  }) => (
    <div className='autocomplete-root'>
      <input
        className={clsx('form-control form-control-sm form-control-light mb-3 custom-input-height')}
        disabled={formik.isSubmitting || isUserLoading}
        placeholder='Address'
        {...getInputProps()}
      />
      {error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
      <div className='autocomplete-dropdown-container'>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            className='bg-light p-2 border border-bottom overflow-auto'
            style={{cursor: 'pointer'}}
          >
            <option>{suggestion.description}</option>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className=''>
              <div className='col-lg-12'>
                <label className='form-label fs-7 mb-1 required  '>Location Name</label>
                <div>
                  <input
                    placeholder='Location Name'
                    {...formik.getFieldProps('name')}
                    type='text'
                    name='name'
                    className={clsx(
                      'form-control form-control-sm form-control-light mb-3 custom-input-height'
                    )}
                    autoComplete='off'
                    disabled={formik.isSubmitting || isUserLoading}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className=''>
              <label className='required form-label fs-7 mb-1'>Address</label>
              {typeof window.google !== 'undefined' && (
                <PlacesAutocomplete
                  onSelect={(suggestion: any) => {
                    handleSelect(suggestion)
                  }}
                  value={formik.values.address}
                  onChange={(e: any) => {
                    if (e === '') {
                      formik.setFieldValue('address', '')
                      formik.setFieldValue('street1', '')
                      formik.setFieldValue('street2', '')
                      formik.setFieldValue('city', '')
                      formik.setFieldValue('state', '')
                      formik.setFieldValue('country', '')
                      formik.setFieldValue('postalCode', '')
                      formik.setFieldValue('latitude', center.lat)
                      formik.setFieldValue('longitude', center.lng)

                      setGpsLocation({
                        lat: center.lat,
                        lng: center.lng,
                      })
                    }
                    formik.setFieldValue('address', e)
                  }}
                >
                  {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                    <div>
                      {renderFunc({
                        getInputProps,
                        getSuggestionItemProps,
                        suggestions,
                        loading,
                        error: formik.errors.address,
                      })}
                    </div>
                  )}
                </PlacesAutocomplete>
              )}
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-4 col-sm-12'>
              <label className='form-label fs-7 mb-1 required '>City</label>
              <div>
                <input
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                  type='text'
                  name='city'
                  id='city'
                  className={clsx(
                    'form-control form-control-sm form-control-light custom-input-height mb-3'
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.errors.city && formik.touched.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.city}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <label className='form-label fs-7 mb-1 required '>State</label>
              <div>
                <input
                  placeholder='State'
                  {...formik.getFieldProps('state')}
                  type='text'
                  name='state'
                  id='state'
                  className={clsx(
                    'form-control form-control-sm form-control-light custom-input-height mb-3'
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.errors.state && formik.touched.state &&(
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.state}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-4 col-sm-12'>
              <label className='form-label fs-7 mb-1 required '>Country</label>
              <div>
                <input
                  placeholder='Country'
                  {...formik.getFieldProps('country')}
                  type='text'
                  name='country'
                  id='country'
                  className={clsx(
                    'form-control form-control-sm form-control-light custom-input-height mb-3'
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.errors.country && formik.touched.country &&(
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.country}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-2'>
            <div className='col-lg-4'>
              <label className='form-label fs-7 mb-1 required '>Postal Code</label>
              <div className=''>
                <input
                  placeholder='Postal Code'
                  {...formik.getFieldProps('postalCode')}
                  type='text'
                  name='postalCode'
                  id='postalCode'
                  className={clsx(
                    'form-control form-control-sm form-control-light custom-input-height mb-3'
                  )}
                  autoComplete='off'
                  disabled={formik.isSubmitting || isUserLoading}
                />
                {formik.errors.postalCode && formik.touched.postalCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.postalCode}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='col-lg-8'>
              <div className=''>
                <label className='form-label fs-7 mb-1 required '>Status</label>
                <div className='row '>
                  <div className='col-lg-6 col-md-6'>
                    <label className='d-flex flex-start cursor-pointer'>
                      <span className='form-check form-check-custom form-check-success form-check-light form-check-sm me-3'>
                        <input
                          autoComplete='off'
                          disabled={formik.isSubmitting || isUserLoading}
                          className='form-check-input'
                          type='radio'
                          value='1'
                          checked={formik.values.isActive}
                          onChange={() => formik.setFieldValue('isActive', true)}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                          <span className='fw-semibold text-gray-800 text-hover-primary fs-6'>
                            Active
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                  <div className='col-lg-6 col-md-6'>
                    <label className='d-flex flex-start  cursor-pointer'>
                      <span className='form-check form-check-custom form-check-danger form-check-light form-check-sm me-3'>
                        <input
                          autoComplete='off'
                          disabled={formik.isSubmitting || isUserLoading}
                          className='form-check-input'
                          type='radio'
                          value='0'
                          checked={!formik.values.isActive}
                          onChange={() => formik.setFieldValue('isActive', false)}
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span className='d-flex flex-column'>
                          <span className='fw-semibold text-gray-800 text-hover-primary fs-6'>
                            Inactive
                          </span>
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoaded && (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={gpsLocation ? gpsLocation : center}
              zoom={10}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              {gpsLocation && <Marker position={gpsLocation}></Marker>}
            </GoogleMap>
          )}
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-end mt-5 px-2'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light btn-sm me-3'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
            disabled={isUserLoading || formik.isSubmitting }
            onClick={async (e) => {
              e.preventDefault()

              if (!formik.values.isActive) {
                setDeleteModalOpen(true)
              } else {
                await formik.handleSubmit()
              }
            }}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {isDeleteModalOpen && (
            <DeleteConfirmationModal
              title='Confirmation'
              content={'Are you sure you would like to deactivate ?'}
              onSubmit={()=>formik.handleSubmit()}
              setOpen={setDeleteModalOpen}
            />
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}

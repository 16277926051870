import axios, {AxiosResponse} from 'axios'
import {ID, Response,ResponseObject} from '../../../../../_metronic/helpers'
import {
  LanguageModel,
  UsersQueryResponse,
  UserMasterInfoResponse,
  Deletemodel,
  DropdownResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const USER_URL = `${API_URL}/accounts/language/Insert`
const GET_USERS_URL = `${API_URL}/accounts/`
const GET_RoleS_URL = `${API_URL}/roles/getall-shortlist`
const GET_UERSBY_URL = `${API_URL}/accounts/language`
const Update_UERSBY_URL = `${API_URL}/accounts/language/Update`
const Delete_UERSBY_URL = `${API_URL}/accounts/language/delete`
const Approve_UERSBY_URL = `${API_URL}/accounts/language/approve`

const GET_ALL_COUNTRY = `${API_URL}/country/getall`
const GET_ALL_MASTER = `${API_URL}/master/getall`
const GET_ALL_CUSTOMER_SHORTLIST = `${API_URL}/accounts/getall-shortlist`
const POST_INV_EMAILS = `${API_URL}/accounts/send-invitation`
const GET_ALL_CUSTOMER_LOCATION = `${API_URL}/accounts`
const USER_REINVITE_URL = `${API_URL}/accounts/re-invite`
const GET_USER_MORE_DETAILS_MASTER_URL = `${API_URL}/master/user-additional`

const getadditionalMaster = (): Promise<UserMasterInfoResponse> => {
  var dt = axios
    .get(`${GET_USER_MORE_DETAILS_MASTER_URL}`)
    .then((d: AxiosResponse<UserMasterInfoResponse>) => d.data)
  return dt
}

const getUsers = (
  query: string,
  userType: string,
  customerCode?: string
): Promise<UsersQueryResponse> => {
  var dt = axios
    .get(`${GET_USERS_URL}${customerCode ?? '0'}/language/${userType}/getall/?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return dt
}

const getUserById = (id: ID): Promise<LanguageModel | undefined> => {
  var dr = axios
    .get(`${GET_UERSBY_URL}/${id ?? 0}`)
    .then((response: AxiosResponse<Response<LanguageModel>>) => response.data)
    .then((response: Response<LanguageModel>) => response.data)
  return dr
}

const createUser = (user: LanguageModel): Promise<LanguageModel | undefined> => {
  return axios
    .post(USER_URL, user)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const updateUserInfo = (UserModel: LanguageModel): Promise<LanguageModel | undefined> => {
  return axios
    .post(Update_UERSBY_URL, UserModel)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
  }

const deleteUser = (Id: ID): Promise<ID | undefined> => {
  return axios
    .post(`${Delete_UERSBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.post(`${Delete_UERSBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const approveSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.post(`${Approve_UERSBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getUsers,
  getadditionalMaster,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUserInfo,
  approveSelectedUsers,
}

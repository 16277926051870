import React, {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'

export default function ImageComponent(props) {
  const [state, setState] = useState({isOpen: false, photoIndex: 0})
  const handleShowDialog = () => {
    setState({isOpen: !state.isOpen})
    console.log('cliked')
  }
  console.log(props.profileImage)
  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null)

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0]
    props.onSelectFile(event)
  }

  return (
    <div className='col-sm-4 col-md-4 col-lg-4'>
      <div className='d-flex flex-center flex-column'>
        <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
          {props.profileImage && props.profileImage !== '' ? (
            props.preview && props.preview !== '' ? (
              <img src={props.preview} alt='user' className='rounded-circle cvf' />
            ) : (
              <img
                src={`${process.env.REACT_APP_IMG_URL}/${props.type}/${props.profileImage}`}
                alt='user'
                className='rounded-circle bbfg'
              />
            )
          ) : props.preview && props.preview !== '' ? (
            <img src={props.preview} alt='user' className='rounded-circle nnn' />
          ) : (
            <img
              src={toAbsoluteUrl('../media/avatars/blank.png')}
              alt='user'
              className='rounded-circle'
            />
          )}
        </div>
        <div className='d-flex flex-center mt-5'>
          <input
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            type='file'
            ref={hiddenFileInput}
            style={{display: 'none'}}
            onChange={(e) => props.onSelectFile(e)}
          />
          <div className='d-flex flex-center'>
            <a
              className='btn btn-color-primary btn-active-primary btn-sm'
              onClick={(e) => handleClick(e)}
            >
              <i className='bi bi-cloud-arrow-up-fill'></i>
              Upload
            </a>
          </div>
          {/* <button
            type='button'
            className='btn btn-outline btn-outline-dashed btn-outline-default btn-sm text-active-primary active py-1'
          >
            Upload
          </button> */}
        </div>
      </div>
    </div>
  )
  return (
    <div>
      <div className='row'>
        <div className='col-lg-3'>
          {props.selectedFile && (
            <>
              <img onClick={(r) => handleShowDialog()} width='150px' src={props.preview} />
              {state.isOpen && <></>}
            </>
          )}
          {!props.selectedFile && (
            <>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                }}
              >
                {props.profileImage !== undefined && props.profileImage !== '' && (
                  <>
                    <img
                      width='150px'
                      className='imagex'
                      onClick={(r) => handleShowDialog()}
                      src={process.env.REACT_APP_INSEE_KIOSK_BACKEND_API + props.profileImage}
                    />
                    {state.isOpen && <></>}
                  </>
                )}

                {props.profileImage === undefined ||
                  (props.profileImage === '' && (
                    <>
                      <div
                        className='image-input-wrapper w-125px h-125px'
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                        }}
                      ></div>
                    </>
                  ))}
              </div>
            </>
          )}
        </div>

        {props.profileImage !== undefined && props.profileImage !== '' && (
          <div className='col-lg-3'>
            <button
              style={{width: '100%'}}
              type='button'
              onClick={(e) =>
                window.confirm('Are you sure you wish to delete this item?')
                  ? props.deleteImage(e)
                  : false
              }
              className='btn  btn-danger'
            >
              <span className='indicator-progress' style={{display: 'block'}}>
                Delete Image
              </span>
            </button>
          </div>
        )}

        <div className='col-lg-6'>
          <input
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            type='file'
            onChange={(e) => props.onSelectFile(e)}
          />
        </div>
      </div>
    </div>
  )


//   <div className='col-sm-4 col-md-4 col-lg-4'>
//   <div className='d-flex flex-center flex-column'>
//     <div className='symbol symbol-100px symbol-lg-150px symbol-fixed position-relative'>
//       {props.profileImage !== undefined && props.profileImage !== '' && (
//         <>
//           {props.preview !== undefined && props.preview !== '' && (
//             <>
//               <img src={props.preview} alt='user' className='rounded-circle' />
//             </>
//           )}

//           {(props.preview == null || props.preview == '') &&
//             props?.profileImage !== null &&
//             props.profileImage !== '' && (
//               <>
//                 <img
//                   src={
//                     process.env.REACT_APP_IMG_URL + '/' + props.type + '/' + props.profileImage
//                   }
//                   alt='user'
//                   className='rounded-circle'
//                 />
//               </>
//             )}
//         </>
//       )}
//       {(props.profileImage == undefined || props.profileImage == '') && (
//         <>
//           {props.preview !== undefined && props.preview !== '' && (
//             <>
//               <img src={props.preview} alt='user' className='rounded-circle' />
//             </>
//           )}

//           {(props.preview == undefined || props.preview == '') && (
//             <>
//               <img
//                 src={toAbsoluteUrl('../media/avatars/blank.png')}
//                 alt='user'
//                 className='rounded-circle'
//               />
//             </>
//           )}
//         </>
//       )}

//       {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
//     </div>
//     <div className='d-flex flex-center mt-5'>
//       <input
//         className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
//         type='file'
//         ref={hiddenFileInput}
//         style={{display: 'none'}}
//         onChange={(e) => props.onSelectFile(e)}
//       />
//       <button
//         onClick={(e) => handleClick(e)}
//         type='button'
//         className='btn btn-outline btn-outline-dashed btn-outline-default btn-sm text-active-primary active py-1'
//       >
//         <i className='bi bi-cloud-arrow-up-fill'></i>
//         Upload
//       </button>
//     </div>
//   </div>
// </div>
}

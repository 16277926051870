import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {BillingMyFilterDropdown} from './BillingMyFilterDropdown'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Select from 'react-select'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function MyInvoice() {
  const [todayDate, setTodayDate] = useState('')
  const [oneMonthAgoDate, setOneMonthAgoDate] = useState('')

  useEffect(() => {
    // Function to format date as YYYY-MM-DD
    const formatDate = (date: any) => {
      const year = date.getFullYear()
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0')
      return `${year}-${month}-${day}`
    }

    // Get today's date
    const today = new Date()
    setTodayDate(formatDate(today))

    // Get the date one month ago
    const oneMonthAgo = new Date()
    oneMonthAgo.setMonth(today.getMonth() - 1)
    setOneMonthAgoDate(formatDate(oneMonthAgo))
  }, [])
  const customer = [
    {value: 'option 1', label: 'Adastra 1'},
    {value: 'option 2', label: 'Adastra 2'},
    {value: 'option 3', label: 'Adastra 3'},
  ]
  const terms = [
    {value: 'option 1', label: 'None'},
    {value: 'option 2', label: 'Custome'},
    {value: 'option 3', label: 'Net 7'},
    {value: 'option 3', label: 'Net 10'},
    {value: 'option 3', label: 'Net 15'},
    {value: 'option 3', label: 'Net 30'},
    {value: 'option 3', label: 'Net 45'},
    {value: 'option 3', label: 'Net 60'},
    {value: 'option 3', label: 'Net 90'},
  ]
  const tags = [
    {value: 'option 1', label: 'tag 1'},
    {value: 'option 2', label: 'tag 2'},
    {value: 'option 3', label: 'tag 3'},
  ]
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_my-invoice'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Create Invoices</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Date Range
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter Subject'
                        value={oneMonthAgoDate}
                      />
                      -
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Start Time'
                        value={todayDate}
                      />
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div className='row d-flex mb-3'>
                      <div className='col-md-6'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexRadioLg1'
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg1'>
                            Include Calls
                          </label>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexRadioLg1'
                          />
                          <label className='form-check-label' htmlFor='flexRadioLg1'>
                            Include Appointments
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='separator my-3'></div>
                  <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                    <div className='mb-5'>
                      <div
                        className='accordion-header py-3 d-flex'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_2_item_1'
                      >
                        <span className='accordion-icon'>
                          <KTSVG
                            className='svg-icon svg-icon-4'
                            path='media/icons/duotune/arrows/arr064.svg'
                          />
                        </span>
                        <h3 className='fs-7 text-gray-800 fw-semibold mb-0 ms-4'>Advanced</h3>
                      </div>
                      <div
                        id='kt_accordion_2_item_1'
                        className='fs-6 collapse ps-8'
                        data-bs-parent='#kt_accordion_2'
                      >
                        <div className='mb-3'>
                          <div className='row d-flex mb-3'>
                            <div className='col-md-1 d-flex justify-content-start align-items-start'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='flexRadioLg1'
                                />
                              </div>
                            </div>
                            <div className='col-md-11'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Appointment Tags
                              </label>
                              <Select
                                className='react-select-styled react-select-solid react-select-sm'
                                classNamePrefix='react-select'
                                options={tags}
                                placeholder='Select some options'
                                isDisabled
                                isMulti
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    border: '1px solid #e4e6ef',
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='mb-3'>
                          <div className='row d-flex mb-3'>
                            <div className='col-md-1 d-flex justify-content-start align-items-start'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id='flexRadioLg1'
                                />
                              </div>
                            </div>
                            <div className='col-md-11'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required  form-label fs-7 mb-1'
                              >
                                Include Cancelled Appointments with $0 amounts
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='separator my-3'></div>
              <span className='fs-8 fst-italic'>
                Note: appointments with signatures still pending will not be included.
              </span>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Create Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='d-flex justify-content-between mb-3 align-items-center flex-wrap'>
          <div className='card-title d-flex align-items-center me-4 mb-3'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-4 fw-semibold mb-0'>Invoices List</h5>
              </div>
              <div className='d-flex flex-wrap fs-6 '>
                <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                  Invoice List Overview
                </p>
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <div className='my-0 me-3' style={{display: 'flex', justifyContent: 'end'}}>
                <BillingMyFilterDropdown />
              </div>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Create Invoice</Tooltip>}
              >
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_add_my-invoice'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                <th className='min-w-150px '>Invoice#</th>
                <th className='min-w-100px '>Status</th>
                <th className='min-w-100px '>PO#</th>
                <th className='min-w-100px '>Created</th>
                <th className='min-w-100px '>Invoice Date</th>
                <th className='min-w-100px '>Due Date</th>
                <th className='min-w-100px '>Call/Appts</th>

                <th className='min-w-100px text-end'>Total</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    <a className='text-gray-800 text-hover-primary fs-6'>INV-000339-A</a>
                  </div>
                </td>
                <td>
                  <span className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'>
                    Approved
                  </span>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'></a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>11/20/23 5:47pm</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>05/20/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>12/13/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>0 / 0</a>
                </td>
                <td className='text-end'>
                  <a className='text-gray-800 text-hover-primary fs-6 '>$20.00</a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    <a className='text-gray-800 text-hover-primary fs-6'>INV-000229-A</a>
                  </div>
                </td>
                <td>
                  <span className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'>
                    Rejected
                  </span>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'></a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>01/20/23 3:47pm</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>03/20/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>10/13/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>1 / 0</a>
                </td>
                <td className='text-end'>
                  <a className='text-gray-800 text-hover-primary fs-6 '>$20.00</a>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
}

const LogsOperatorViewTable: React.FC<Props> = ({className}) => {
  const [activeModalTab, setActiveModalTab] = useState(1)

  const handleModalTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveModalTab(tabNumber)
  }
  return (
    <div className={`card ${className}`}>
      <div className='modal fade' tabIndex={-1} id='kt_click_details'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Details</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='d-flex align-items-center justify-content-start'>
                <h5 className='d-flex text-gray-600 fw-normal fs-2 mb-0'>
                  Call ID : 70610776623026
                </h5>{' '}
                <span className='d-flex ms-3'>
                  <KTSVG
                    path='media/icons/duotune/general/gen054.svg'
                    className='svg-icon-muted svg-icon-2'
                  />
                </span>
              </div>
              <div className='row g-4'>
                <div className='mb-5'>
                  <div
                    className='py-3'
                    style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
                  >
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                      <li className='nav-item'>
                        <a
                          className={`nav-link fs-7 text-gray-400   ${
                            activeModalTab === 1 ? 'text-active-dark fw-bold active show' : ''
                          }`}
                          data-bs-toggle='tab'
                          href='#kt_modaltab_pane_1'
                          onClick={() => handleModalTabClick(1)}
                        >
                          Call Details
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className={`nav-link fs-7 text-gray-400 ${
                            activeModalTab === 2 ? 'text-active-dark fw-bold active show' : ''
                          }`}
                          data-bs-toggle='tab'
                          href='#kt_modaltab_pane_2'
                          onClick={() => handleModalTabClick(2)}
                        >
                          Routing Details
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className=''>
                    <div className='my-3'>
                      <div className='tab-content' id='myTabContent'>
                        {activeModalTab === 1 && (
                          <div className=''>
                            <div
                              className='tab-pane fade show active'
                              id='kt_modaltab_pane_1'
                              role='tabpanel'
                            >
                              <>
                                <div className=''>
                                  <div className='d-flex justify-content-between align-items-center py-5 flex-wrap'>
                                    <div className='d-flex flex-wrap'>
                                      <div className='d-flex align-items-center '>
                                        <div className='symbol-hover me-2'>
                                          <div className='symbol symbol-circle symbol-50px'>
                                            <img
                                              src={toAbsoluteUrl('/media/avatars/300-2.jpg')}
                                              alt=''
                                            />
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column flex-1 w-auto'>
                                          <div className='d-flex align-items-center fw-light'>
                                            <span className='fw-semibold d-flex align-items-center fs-6 me-1'>
                                              Faren Jam Sector called on the following device
                                            </span>
                                          </div>
                                          <span className='fs-6 fw-normal'>
                                            <i className='bi bi-telephone-forward-fill me-2'></i>
                                            GSM/+17603522407
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='d-flex flex-wrap'>
                                      <div className='d-flex align-items-center '>
                                        <div className='symbol-hover me-2'>
                                          <div className='symbol symbol-circle symbol-50px'>
                                            <img
                                              src={toAbsoluteUrl('/media/avatars/300-7.jpg')}
                                              alt=''
                                            />
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column flex-1 w-auto'>
                                          <div className='d-flex align-items-center fw-light'>
                                            <span className='fw-semibold d-flex align-items-center fs-6 me-1'>
                                              Navom ser answered on the following device
                                            </span>
                                          </div>
                                          <span className='fs-6 fw-normal'>
                                            <i className='bi bi-phone-fill me-2'></i>iOS/17.5.1
                                            Adastra for Interpreters
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='separator my-3'></div>
                                  <div className='row'>
                                    <h6 className='fs-6 fw-normal py-5'>
                                      You can edit any field that was answered on this call. New
                                      fields added to a pre-call policy will not be included below.
                                    </h6>
                                    <div className='col-sm-12 col-md-4'>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='form-label mb-1 fs-7 required'
                                        >
                                          Name of Requestor
                                        </label>

                                        <input
                                          type='text'
                                          className='form-control form-control-white form-select-sm custom-input-height'
                                          placeholder='Enter Name of Requestor'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='form-label mb-1 fs-7 mb-1 fs-7 required'
                                        >
                                          Please clearly state your department
                                        </label>

                                        <input
                                          type='text'
                                          className='form-control form-control-white form-select-sm custom-input-height'
                                          placeholder='Enter Department'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='form-label mb-1 fs-7 mb-1 fs-7 required'
                                        >
                                          Are you on site with the participant? 1=yes, 2=no
                                        </label>

                                        <input
                                          type='number'
                                          className='form-control form-control-white form-select-sm custom-input-height'
                                          placeholder='Enter Number'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='text-end pt-3'>
                                  <button
                                    type='reset'
                                    className='btn btn-sm btn-light me-2'
                                    data-kt-menu-dismiss='true'
                                  >
                                    Cancel
                                  </button>

                                  <button
                                    type='submit'
                                    className='btn btn-sm btn-primary'
                                    data-kt-menu-dismiss='true'
                                  >
                                    Save
                                  </button>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        {activeModalTab === 2 && (
                          <div className=''>
                            <div
                              className='tab-pane fade show active'
                              id='kt_modaltab_pane_2'
                              role='tabpanel'
                            >
                              <>
                                <div className='separator my-3'></div>
                                <div className='row'>
                                  <h6 className='fs-6 fw-normal py-5'>
                                    The list below shows all interpreters in your roster that were
                                    dialed before this call was picked up or abandoned.
                                  </h6>
                                </div>
                                <div className='d-flex flex-wrap flex-end'>
                                  <div className='d-flex align-items-center me-3 flex-nowrap'>
                                    <input
                                      type='text'
                                      data-kt-user-table-filter='search'
                                      className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                      placeholder='Search'
                                    />

                                    <button
                                      type='button'
                                      className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen021.svg'
                                        className=''
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-100px'>Interpreter Name</th>
                                        <th className='min-w-150px'>ID</th>
                                        <th className='min-w-150px'>Time Dialed</th>
                                        <th className='min-w-150px'>Ring Duration</th>
                                        <th className='min-w-150px'>Device</th>
                                        <th className='min-w-150px text-end'>Refused</th>
                                      </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            Amila silva
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            3453454
                                          </a>
                                        </td>

                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            7/13/24 1:06:54 am
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            12 sec
                                          </a>
                                        </td>

                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            Web
                                          </a>
                                        </td>

                                        <td className='text-end'>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                                        </td>
                                      </tr>
                                    </tbody>
                                    {/* end::Table body */}
                                  </table>
                                  {/* end::Table */}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 '></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className='py-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                <th className='min-w-100px'>Call ID</th>
                <th className='min-w-150px'>Call Status</th>
                <th className='min-w-150px'>Operator</th>
                <th className='min-w-150px'>Time</th>
                <th className='min-w-150px'>Requestor</th>
                <th className='min-w-150px'>Service</th>

                <th className='min-w-150px'>Type</th>
                <th className='min-w-150px'>Queue/Hold Time</th>
                <th className='min-w-150px'>Intake Duration</th>
                <th className='min-w-150px text-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <a
                    className='text-gray-800 text-hover-primary d-block fs-6'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_click_details'
                  >
                    2453453454
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Connected</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Operator 1234</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>
                    7/13/24 1:06:54 am
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>CFC Department</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>
                    English to Vietnamese / M.
                  </a>
                </td>

                <td>
                  <a type='button' className='text-gray-800 text-hover-primary d-block fs-6'>
                    Audio
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>6s</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>00:01:01</a>
                </td>
                <td>
                  <div className='d-flex flex-end'>
                    <a
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_click_details'
                    >
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {LogsOperatorViewTable}

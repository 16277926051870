// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => (
      <UserSelectionCell
        id={props.data[props.row.index].code}
        accountStatus={props.data[props.row.index].accountStatus}
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Role' className='min-w-125px' />,
    accessor: 'role',
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Customer' className='min-w-125px' />
    ),
    accessor: 'customerName',
  },

  ,
  /*{
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    accessor: 'accountStatus',
  }*/ {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' />,
    id: 'status',
    Cell: ({...props}) => (
      <>
        {(props.data[props.row.index].email && props.data[props.row.index].isdeleted) ||
        props.data[props.row.index].accountStatus === 'Not Active' ? (
          <span
            className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            Inactive{' '}
          </span>
        ) : props.data[props.row.index].isConfirmed ? (
          <span
            className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            Active
          </span>
        ) : (
          <span
            className='badge badge-light-warning px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            Pending{' '}
          </span>
        )}
      </>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Last login' className='min-w-125px' />
    ),
    id: 'lastLoginDate',
    Cell: ({...props}) => (
      <UserLastLoginCell last_login={props.data[props.row.index].lastLoginDate} />
    ),
  },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Joined Date' className='min-w-125px' />
    ),
    accessor: 'join_date',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        isConfirmed={props.data[props.row.index].isConfirmed}
        code={props.data[props.row.index].code}
        email={props.data[props.row.index].email}
        accountStatus={props.data[props.row.index].accountStatus}
      />
    ),
  },
]

export {usersColumns}

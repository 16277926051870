import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../../UI/members-management/users-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setAlloperationDetails: any
  setIsLoading: any
  fetchOperationDetails: any
  setSearchQuery : any
}
export function DashboardOperationFilter({
  setAlloperationDetails,
  setIsLoading,
  fetchOperationDetails,
  setSearchQuery,
}: Props) {
  const [selectedCustomer, setSelectedCustomer] = useState<any>({})
  const [selectedLanguages, setSelectedLanguages] = useState<any>({})
  const [selectedCommunicationTypes, setSelectedCommunicationTypes] = useState<any>({})
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getcommunicationtypes = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_COMMUNICATIONTYPES}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
    `Query.communicationTypeList`,
    getcommunicationtypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
    communicationTypeList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = () => {
    setIsLoading(true)
    const SUBMIT_FILTERS_URL = `${API_URL}/Dashboard/operations`
    const data = {
      customer: selectedCustomer?.value,
      lang: selectedLanguages?.value,
      communicationType: selectedCommunicationTypes?.value,
    }
    setSearchQuery(data)
    axios.post(SUBMIT_FILTERS_URL, data).then((response) => {
      const {data: appointments, payload} = response.data
      setAlloperationDetails(appointments)
      setShowDropdownMenu(false)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const handleReset = async () => {
    setSearchQuery({})
    setSelectedCustomer({})
    setSelectedLanguages({})
    setSelectedCommunicationTypes({})
    setShowDropdownMenu(false)
    await fetchOperationDetails({})
  }
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className='btn btn-icon btn-sm no-caret'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-400px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>

            <div className='separator border-gray-200'></div>
            <div className='card-scroll px-4 mt-4'>
              <div className='row flex-column'>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                      Customer Name
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingCustomer ? customerOptions : []}
                            placeholder='Select Customer'
                            value={{
                              value: selectedCustomer?.value,
                              label: selectedCustomer?.label ?? 'Select Customer',
                            }}
                            onChange={(e: any) => setSelectedCustomer(e)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='languages' className='form-label fs-7 mb-1'>
                      Languages
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingNativeLanguage ? languageOptions : []}
                            placeholder='Select Language'
                            value={{
                              value: selectedLanguages?.value,
                              label: selectedLanguages?.label ?? 'Select Language',
                            }}
                            onChange={(e: any) => setSelectedLanguages(e)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                      Communication Type
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingcommunication ? communicationTypeOptions : []}
                            placeholder='Select : All'
                            value={{
                              value: selectedCommunicationTypes?.value,
                              label:
                                selectedCommunicationTypes?.label ?? 'Select Communication Type',
                            }}
                            onChange={(e: any) => setSelectedCommunicationTypes(e)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-end pt-5 px-4'>
              <button
                type='reset'
                className='btn btn-sm btn-light me-2'
                data-kt-menu-dismiss='true'
                onClick={handleReset}
              >
                Reset
              </button>

              <button
                type='submit'
                className='btn btn-sm btn-primary'
                data-kt-menu-dismiss='true'
                onClick={handleSubmit}
              >
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}

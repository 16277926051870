import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, ModelQUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser} from '../../core/_requests'
import toaster from '../../../../../../Utils/toaster'
import {DeleteConfirmationModal} from '../../../../../common/Models/confirmation-modal/DeleteConfirmationModal'

type Props = {
  code: string
  value: string
}

const ActionsCell: FC<Props> = ({code, value}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(code)
  }

  // const statusData: StatusChangemodel = {
  //   value: value,
  //   code: code,
  // };
  const deleteItem = useMutation(() => deleteUser(code), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${ModelQUERIES.DATA_LIST}-${query}`])
      toaster('success', 'Succesfully Deleted')
    },
  })

  // const statusChangeItem = useMutation(() => activeStatusChangeDataRecord(code), {
  //   // 💡 response of the mutation is passed to onSuccess
  //   onSuccess: () => {
  //     // ✅ update detail view directly
  //     queryClient.invalidateQueries([`${ModelQUERIES.DATA_LIST}-${query}`])
  //   },
  // })

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
          type='button'
          onClick={() => setDeleteModalOpen(true)}
        >
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
        {isDeleteModalOpen && (
          <DeleteConfirmationModal
            title='Confirmation'
            content={'Are you sure you would like to deactivate ?'}
            onSubmit={async () => await deleteItem.mutateAsync()}
            setOpen={setDeleteModalOpen}
          />
        )}
      </div>
    </>
  )
}

export {ActionsCell}

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {CustomDictionaryModel, DropdownResponse, DropdownSelectResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const GET_ACTIVE_NATIVE_LANGUAGE = `${API_URL}/master/languages/active-shortlist`
const GET_ACTIVE_MASTER_SHORTLIST = `${API_URL}/master/data`
const GET_TIME_ZONE_SHORTLIST = `${API_URL}/master/time-zone/active-shortlist`
const GET_ALL_COUNTRY = `${API_URL}/country/getall`
const GET_ALL_PreCallQuestion=`${API_URL}/precalls/ddlist`

const GET_ALL_CUSTOMER_SHORTLIST = `${API_URL}/customer/getall-shortlist`
const GET_OFFICE_LOCATIONS_URL = `${API_URL}/customer`

const getOfficeLocations = (CustomerCode: number): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_OFFICE_LOCATIONS_URL}/${CustomerCode}/location/getall-shortlist`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getNativeLanguage = (): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ACTIVE_NATIVE_LANGUAGE}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getMasterData = (code: string): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ACTIVE_MASTER_SHORTLIST}/${code}/active-shortlist`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getTimeZone = (): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_TIME_ZONE_SHORTLIST}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getCountries = (): Promise<DropdownResponse> => {
  var dt = axios.get(`${GET_ALL_COUNTRY}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}


const getAllPreCallQuestionByCustomer = async (customerId: string): Promise<DropdownSelectResponse> => {
  var dt = await axios
    .get(`${GET_ALL_PreCallQuestion}/${customerId}`)
    .then((d: AxiosResponse<DropdownSelectResponse>) => d.data)
  return dt
}
const getCustomers = (userType: string, customerCode: number): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_CUSTOMER_SHORTLIST}/${userType}/${customerCode}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

export {
  getNativeLanguage,
  getMasterData,
  getTimeZone,
  getCountries,
  getAllPreCallQuestionByCustomer,
  getCustomers,
  getOfficeLocations,
}

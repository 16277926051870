/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'

const HeaderUserMenu: FC = () => {
  const {currentUser} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-300px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3 w-auto'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            {currentUser?.result?.profileImage !== null &&
              currentUser?.result?.profileImage !== undefined &&
              currentUser?.result?.profileImage !== '' &&
              currentUser?.result?.profileImage !== 'default.png' && (
                <>
                  <img
                    alt='Logo'
                    src={
                      process.env.REACT_APP_IMG_URL +
                      '/profile/' +
                      currentUser?.result?.profileImage
                    }
                  />
                </>
              )}

            {!(
              currentUser?.result?.profileImage !== null &&
              currentUser?.result?.profileImage !== undefined &&
              currentUser?.result?.profileImage !== '' &&
              currentUser?.result?.profileImage !== 'default.png'
            ) && (
              <>
                <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
              </>
            )}
          </div>

          <div className='d-flex flex-column flex-1 w-auto'>
            <div className='fw-bolder d-flex align-items-center fs-6 flex-wrap'>
              <span className='me-2'>
                {' '}
                {currentUser?.result.firstName} {currentUser?.result.lastName}{' '}
              </span>

              <span className='badge badge-light-success fw-semibolder fs-8 py-1 custom-show-class'>
                {currentUser?.result.role}
              </span>
            </div>
            <a href='#' className='text-muted mt-1 fw-normal' style={{fontSize: '10px'}}>
              {currentUser?.result.email}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>

      <div className='menu-item px-3'>
        <a href={`/profile/${currentUser?.result.code}`}>
          <a className='menu-link px-3 fw-semibold'>
            {' '}
            <i className='bi bi-sliders me-2'></i>
            Account Settings
          </a>
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

import {Table} from './table/Table'
import {KTCard} from '../../../../../../_metronic/helpers'
import {ListHeader} from './components/header/ListHeader'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {CreateModal} from './modals/CreateModal'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'

const List = () => {
  const {itemIdForUpdate} = useListView()

  return (
    <>
      <KTCard>
        <ListHeader />
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined && <CreateModal />}
    </>
  )
}

const ListWrapper = () => (
  <>
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <List />
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
)

export {ListWrapper}

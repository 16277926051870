import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListHeader} from './components/header/ListHeader'
import {Table} from './table/Table'
import {EditModal} from './edit-modal/EditModal'
import {KTCard} from '../../../../_metronic/helpers'

type Props = {
  userID: string
  userType?: string
}

const List: React.FC<Props> = ({userID, userType}) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <ListHeader userType={userType}/>
        <Table />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditModal userID={userID} />}
    </>
  )
}

type Props2 = {
  userID: string
  userType: string
}

const ListWrapper: React.FC<Props2> = ({userID, userType}) => (
  <QueryRequestProvider>
    <QueryResponseProvider userID={userID} userType={userType}>
      <ListViewProvider>
        <List userID={userID} userType={userType} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ListWrapper}

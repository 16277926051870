import {PayloadAction, createSlice} from '@reduxjs/toolkit'
import {QueryState} from '../../../_metronic/helpers'

export type PrecallState = {
  isLoading: boolean
  queryString: QueryState
  searchTerm: string // query update only when search is clicked
  policyIdToUpdate: string | null
}

const initialState: PrecallState = {
  isLoading: false,
  queryString: {
    page: 1,
    items_per_page: 10,
    total: 0,
    to: 0,
    from: 0,
    last_page: 1,
  },
  searchTerm: '',
  policyIdToUpdate: null,
}

export const PrecallSlice = createSlice({
  name: 'precall',
  initialState: initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    updateQueryString: (state, action: PayloadAction<QueryState>) => {
      state.queryString = action.payload
    },
    setPolicyIdToUpdate: (state, action: PayloadAction<string | null>) => {
      state.policyIdToUpdate = action.payload
    },
  },
})

export const {setSearchTerm, updateQueryString, setPolicyIdToUpdate} = PrecallSlice.actions

export default PrecallSlice.reducer

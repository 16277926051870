import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

type Props = {
  userType?: string
}

const UsersListToolbar: React.FC<Props> = ({userType}) => {
  const {setItemIdForUpdate, setAdminModalOpen} = useListView()

  const openAddUserModal = (userType: string | undefined) => {
    if (userType == 'ADMIN' && setAdminModalOpen) {
      setAdminModalOpen(null)
    } else if (setItemIdForUpdate) {
      setItemIdForUpdate(null)
    }
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id='tooltip-filter'>Add Language & Rate</Tooltip>}
      >
        <div>
          <button
            type='button'
            className='btn btn-sm btn-primary btn-icon'
            onClick={() => openAddUserModal(userType)}
          >
            <i className='bi bi-plus fs-2'></i>
          </button>
        </div>
      </OverlayTrigger>
    </div>
  )
}

export {UsersListToolbar}

import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {UsersListFilter} from './UsersListFilter'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
 

type Props = {
  userType: string
}

const UsersListToolbar: React.FC<Props> = ({userType}) => {
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <UsersListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {userType == 'INTERPRETER' && (
        <>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip-filter'> Add Interpreter</Tooltip>}
          >
            <div>
              <button
                type='button'
                className='btn btn-sm btn-primary btn-icon ms-3'
                onClick={openAddUserModal}
              >
                <i className='bi bi-plus fs-2'></i>
              </button>
            </div>
          </OverlayTrigger>
          {/* <button type='button' className='btn btn-sm btn-primary' onClick={openAddUserModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='' />
            Add Interpreter
          </button> */}
        </>
      )}

      {userType == 'CONSUMER' && (
        <>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip-filter'> Add Requester</Tooltip>}
          >
            <div>
              <button
                type='button'
                className='btn btn-sm btn-primary btn-icon ms-3'
                onClick={openAddUserModal}
              >
                <i className='bi bi-plus fs-2'></i>
              </button>
            </div>
          </OverlayTrigger>
          {/* <button type='button' className='btn btn-sm btn-primary' onClick={openAddUserModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='' />
            Add Requester
          </button> */}
        </>
      )}

      {userType == 'SYSTEM' && (
        <>
          <OverlayTrigger
            placement='top'
            overlay={<Tooltip id='tooltip-filter'> Add Administrator</Tooltip>}
          >
            <div>
              <button
                type='button'
                className='btn btn-sm btn-primary btn-icon ms-3'
                onClick={openAddUserModal}
              >
                <i className='bi bi-plus fs-2'></i>
              </button>
            </div>
          </OverlayTrigger>
          {/* <button type='button' className='btn btn-sm btn-primary' onClick={openAddUserModal}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='' />
            Add Administrator
          </button> */}
        </>
      )}

      {/* end::Add user */}
    </div>
  )
}

export {UsersListToolbar}

import React, {useState} from 'react'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'

type TableColumnTemplate = {}
const CSVTemplate: React.FC<TableColumnTemplate> = ({}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [isTableVisible, setTableVisibility] = useState(false)
  const [title, setTitle] = useState('INV-0001-A-template')

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  const handleChange = (e: any) => {
    setTitle(e.target.value)
  }

  const toggleTableVisibility = () => {
    setTableVisibility(!isTableVisible)
  }
  const [isTableVisible2, setTableVisibility2] = useState(false)

  const toggleTableVisibility2 = () => {
    setTableVisibility2(!isTableVisible2)
  }

  return (
    <>
      <div className='grid-item shadow-sm rounded-sm flex-column mb-2'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          <div className='d-flex'>
            <label className='d-flex align-items-center  mb-0 cursor-pointer me-3'>
              <span className='form-check form-check-custom form-check-white'>
                <input name='accountPlan11' className='form-check-input' type='radio' value='1' />
              </span>
            </label>
            <span className='d-flex align-items-center me-2'>
              <span className='text-gray-800 text-hover-primary fw-semibold'>
                <div className='editable-title-container'>
                  {isEditing ? (
                    <input
                      type='text'
                      value={title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoFocus
                      className='edit-input'
                    />
                  ) : (
                    <div className='d-flex align-items-center '>
                      <div className='d-flex'>
                        {' '}
                        <h1 className='fs-6 fw-semibold me-3 mb-0'>{title}</h1>
                      </div>
                      <div className='d-flex'>
                        <i className='d-flex bi bi-pencil-square' onClick={handleEditClick}></i>
                      </div>
                    </div>
                  )}
                </div>
              </span>
            </span>
          </div>
          <div className='d-flex text-end'>
            <a
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
              onClick={toggleTableVisibility}
            >
              <i className='bi bi-eye-fill'></i>
            </a>
            <a className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'>
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-muted ' />
            </a>
          </div>
        </div>

        {isTableVisible && (
          <div className='py-2 table-responsive w-100'>
            <table className='table table-row-dashed table-row-gray-300 gy-3 gx-2'>
              <thead>
                <tr className='fs-7 text-gray-600 text-uppercase'>
                  <th>System</th>
                  <th>Pre-Call Custom Fields</th>
                  <th>Interpreter Profile Custom Fields</th>
                  <th>Requestor Profile Custom Fields</th>
                  <th>Account Profile Custom Fields</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className='badge badge-secondary me-1 fs-8'>Call Id</span>
                    <span className='badge badge-secondary me-1 fs-8'>Account Name</span>
                    <span className='badge badge-secondary me-1 fs-8'>Finished By</span>
                  </td>
                  <td>
                    <span className='badge badge-secondary me-1'>Service Center</span>
                  </td>
                  <td>
                    <span className='badge badge-secondary me-1'>Additional Expiration</span>
                  </td>
                  <td>
                    <span className='badge badge-secondary me-1'>Department Name</span>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  )
}

export default CSVTemplate

// PolarAreaChart.js
import React from 'react'
import {PolarArea} from 'react-chartjs-2'
import {Chart as ChartJS, Title, Tooltip, Legend, CategoryScale, LinearScale} from 'chart.js'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'

// Register Chart.js components
ChartJS.register(Title, Tooltip, Legend, CategoryScale, LinearScale)

type Props = {
  details: any
}
const ServiceTypePolarChart = ({details}: Props) => {
  const data = {
    labels: details?.x,
    datasets: [
      {
        label: 'My Dataset',
        data: details?.y,
        backgroundColor: [
          'rgba(7, 20, 55, 0.5)', // #58595B at 80% opacity
          'rgba(163, 162, 66, 0.6)',
          'rgba(227, 117, 155, 0.6)',
          'rgba(201, 203, 207, 0.6)',
          'rgba(255, 199, 0, 0.6)',
          'rgba(96, 187, 26, 0.5)', // #C82128 at 80% opacity
        ],
        borderColor: [
          '#ffffff', // Darker red
          '#ffffff', // Darker blue
          '#ffffff', // Darker yellow
          '#ffffff', // Darker green
          '#ffffff', // Darker blue
          '#ffffff', // Darker yellow
          '#ffffff',
        ],
        // backgroundColor: [
        //   'rgba(255, 99, 132, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        // ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: 1,
      },
    ],
  }

  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const, // Use a string literal or 'as const' to ensure type compatibility
        align: 'center' as const, // Use 'start' as it is valid for align
        labels: {
          usePointStyle: true, // Use point style to look more like a list
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.label}: ${tooltipItem.raw}`
          },
        },
      },
    },
  }

  return (
    <div className='card card-lg-stretch shadow-custom'>
      <div style={{position: 'absolute', top: -12, right: -5, zIndex: '9'}}>
        <UnderDevelopmentBadge className={undefined} />
      </div>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark fs-6'>Service Type Distribution</h3>
      </div>

      <div className='card-body pt-0 d-flex align-items-center justify-content-center'>
        <div style={{maxWidth: '300px', maxHeight: '300px'}}>
          <PolarArea data={data} options={options} />
        </div>
      </div>
    </div>
  )
}

export default ServiceTypePolarChart

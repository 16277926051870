import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {UserEditModal} from './user-edit-modal/UserEditModal'
import {KTCard} from '../../../../_metronic/helpers'

const UsersList: React.FC<Props> = ({userType}) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard >
        <UsersListHeader userType={userType} />
        <UsersTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <UserEditModal userType={userType} />}
    </>
  )
}

type Props = {
  userType: string
}

const UsersListWrapper: React.FC<Props> = ({userType}) => (
  <QueryRequestProvider>
    <QueryResponseProvider userType={userType}>
      <ListViewProvider>
        <UsersList userType={userType} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}

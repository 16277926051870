import {Column} from 'react-table'
import {CustomHeader} from './CustomHeader'
import {PreCallPolicy} from '../../core/_models'
import {InfoCellName, InfoCellNumberOfMembers} from './InfoCells'
import {ActionsCell} from './ActionsCell'

const objsColumns: ReadonlyArray<Column<PreCallPolicy>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
    Cell: ({...props}) => <InfoCellName dbObj={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Description' className='min-w-125px' />
    ),
    accessor: 'description',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='# OF QUESTIONS' className='min-w-125px' />
    ),
    accessor: 'fieldCount',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='# of Members' className='min-w-125px' />
    ),
    accessor: 'numOfMembers',
    Cell: ({...props}) => <InfoCellNumberOfMembers dbObj={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Action' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} dbObj={props.data[props.row.index]} />,
  },
]

export {objsColumns}

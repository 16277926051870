import {ReactNode} from 'react'
import {MenuComponent} from '../assets/ts/components'

type WithChildren = {
  children?: ReactNode
}

const reInitMenu = () => {
  setTimeout(() => {
    MenuComponent.reinitialization()
  }, 500)
}

type WithChildren2 = {
  children?: ReactNode
  userType: string
  customerCode?: number
}

type WithChildren4 = {
  children?: ReactNode
  userType: string
  customerCode?: string
}

type WithChildren3 = {
  children?: ReactNode
  userID: string
  userType: string
}

export {type WithChildren, type WithChildren2, type WithChildren3, type WithChildren4, reInitMenu}

import React, {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../../UI/members-management/users-list/core/_models'
import moment from 'moment'
import toaster from '../../../../Utils/toaster'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`

type Props = {
  setAnalyticsDetails: any
  fetchAnalytics: any
  setIsLoading: any
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
}

export function DashboardAnalyticsFilter({
  setAnalyticsDetails,
  fetchAnalytics,
  setIsLoading,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) {
  const [selectedCustomer, setSelectedCustomer] = useState<any>([])
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [selectedCommunicationType, setSelectedCommunicationType] = useState<string[]>([])
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  useEffect(() => {
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD')
    const defaultEndDate = moment().format('YYYY-MM-DD')
    setStartDate(defaultStartDate)
    setEndDate(defaultEndDate)
  }, [])

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  // Communication type options
  const CommunicationTypeOptions = [
    {value: 'Voice', label: 'OPI'},
    {value: 'Vedio', label: 'VRI'},
    {value: 'OSI', label: 'OSI'},
  ]

  const handleSubmit = () => {
    setIsLoading(true)
    const SUBMIT_FILTERS_URL = `${API_URL}/Dashboard/analytics`
    const data = {
      customers:
        selectedCustomer.length > 0 ? selectedCustomer.map((item: any) => item.value) : undefined,
      startDate: startDate,
      endDate: endDate,
      communicationTypes:
        selectedCommunicationType.length > 0 ? selectedCommunicationType : undefined,
    }

    axios
      .post(SUBMIT_FILTERS_URL, data)
      .then((response) => {
        const {data: appointments} = response.data
        if (appointments) {
          setAnalyticsDetails(appointments)
          setShowDropdownMenu(false)
          setIsFilterApplied(true)
        } else toaster('error', 'Loading failed!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleReset = async () => {
    setSelectedCustomer([])
    setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
    setSelectedCommunicationType([])
    setIsFilterApplied(false)
    setShowDropdownMenu(false)
    await fetchAnalytics()
  }

  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className={`btn btn-icon btn-sm no-caret ${isFilterApplied ? 'btn-danger' : ''}`}
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-400px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>

            <div className='separator border-gray-200'></div>
            <div className='card-scroll px-4 mt-4'>
              <div className='row flex-column'>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                      Customer
                    </label>
                    <div className='w-100'>
                      <Select
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={!isFetchingCustomer ? customerOptions : []}
                        placeholder='Select Customer'
                        isMulti
                        value={selectedCustomer}
                        onChange={(e: any) => setSelectedCustomer(e)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                      Communication Type
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={CommunicationTypeOptions}
                      placeholder='Select Communication Type'
                      isMulti
                      value={CommunicationTypeOptions.filter((option) =>
                        selectedCommunicationType.includes(option.value)
                      )}
                      onChange={(e: any) =>
                        setSelectedCommunicationType(e.map((option: any) => option.value))
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                        Start Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                        End Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='text-end pt-5 px-4'>
              <button
                type='reset'
                className='btn btn-sm btn-light me-2'
                data-kt-menu-dismiss='true'
                onClick={handleReset}
              >
                Reset
              </button>

              <button
                type='submit'
                className='btn btn-sm btn-primary'
                data-kt-menu-dismiss='true'
                onClick={handleSubmit}
              >
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}

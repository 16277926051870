import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {
  getNotifications,
  updateNotificationStatus,
} from '../../../../app/UI/Notifications/core/_requests'
import {useMutation, useQuery} from 'react-query'
import {Notification} from '../../../../app/UI/Notifications/core/_models'
import '@fortawesome/fontawesome-free/css/all.min.css' // Import FontAwesome CSS
import {useNavigate, useParams} from 'react-router-dom'

type HeaderNotificationsMenuProps = {
  setUnviewedCount: React.Dispatch<React.SetStateAction<number>>
  unViewdCount: number
}

const HeaderNotificationsMenu: FC<HeaderNotificationsMenuProps> = ({
  setUnviewedCount,
  unViewdCount,
}) => {
  const [notificationList, setNotificationList] = useState<Notification[]>([])

  const {
    isFetching: isFetchingNotificationType,
    data: Notificationtypelist,
    isSuccess: isSuccessNotifications,
    refetch: refetchNotifications,
  } = useQuery('Query.NotificationTypeList', getNotifications, {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  const {mutate: updateNotification} = useMutation(updateNotificationStatus)

  useEffect(() => {
    const intervalId = setInterval(refetchNotifications, 60000)
    return () => clearInterval(intervalId)
  }, [refetchNotifications])

  useEffect(() => {
    if (isSuccessNotifications && Notificationtypelist !== undefined) {
      const notifications = Notificationtypelist.notifications
      const updatedNotifications = notifications.map((notif) => {
        if (notif.status === false) {
          return {...notif, recent: true}
        }
        return notif
      })
      setNotificationList(updatedNotifications)
      setUnviewedCount(Notificationtypelist.remainingCount)
    }
  }, [Notificationtypelist, isSuccessNotifications])

  const navigate = useNavigate()
  const handleNavigation = (url: any) => {
    navigate(url)
  }

  const markAsViewed = (notification: Notification, index: number) => {
    try {
      const updatedNotifications = notificationList.map((notif, i) => {
        if (i === index) {
          return {...notif, recent: false}
        }
        return notif
      })

      setNotificationList(updatedNotifications)
    } catch (error) {
      console.error('Failed to update notification status:', error)
    }
  }

  const formatNotificationTime = (createdDateTime: string) => {
    const createdDate = new Date(createdDateTime)
    const currentDate = new Date()

    const isSameDate = (date1: Date, date2: Date): boolean => {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      )
    }

    return isSameDate(createdDate, currentDate)
      ? createdDate.toLocaleTimeString()
      : createdDate.toLocaleDateString()
  }

  const {mutate: markAllAsRead} = useMutation({
    mutationFn: async () => {
      await updateNotificationStatus()
    },
    onSuccess: () => {
      const updatedNotifications = notificationList.map((notif) => ({
        ...notif,
        status: true,
      }))
      setNotificationList(updatedNotifications)
      setUnviewedCount(0)
    },
    onError: (error) => {
      console.error('Failed to mark all notifications as read:', error)
    },
  })

  const handleMarkAllAsRead = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    markAllAsRead()
  }

  return (
    <>
      <div hidden> Not Viewed</div>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
        data-kt-menu='true'
      >
        <div
          className='d-flex flex-column bgi-no-repeat rounded-top'
          // style={{backgroundColor: '#A3A242'}}
        >
          <div className='d-flex flex-column px-6 mb-2'>
            <h3 className='text-dark fw-bold  mt-5 mb-2'>Notifications </h3>
            <div className='d-flex justify-content-between'>
              <div className='d-flex'>
                <span className='fs-7 text-info fw-bold'>
                  All ({Notificationtypelist?.notifications.length})
                </span>
              </div>
              <div className='d-flex '>
                <a
                  href='#'
                  className='fs-8 text-muted fe-auto text-hover-primary'
                  onClick={handleMarkAllAsRead}
                >
                  Mark all as read
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='separator'></div>
        <div className='overflow-auto' style={{maxHeight: '400px'}}>
          {notificationList.map((notification, index) => (
            <>
              <div
                onClick={() => markAsViewed(notification, index)}
                key={`notification${index}`}
                className='d-flex py-2 px-6 border-bottom justify-content-between'
                style={{backgroundColor: notification.status === false ? '#f5f8fa' : '#ffffff'}}
              >
                <div className='d-flex align-items-center'>
                  <div className='symbol symbol-35px me-3'>
                    <span
                      className={clsx('symbol-label', '')}
                      style={{backgroundColor: notification.status ? '#f5f8fa' : '#ffffff'}}
                    >
                      <i
                        className='fas fa-bell svg-icon-2 svg-icon-success'
                        style={{color: notification.status ? '#a1a5b7' : '#a3a242'}}
                      ></i>
                    </span>
                  </div>

                  <div className='mb-0 me-2'>
                    {notification.notificationType === 0 ? (
                      <a
                        href={notification.actionUrl}
                        className='fs-7 text-gray-700 text-hover-primary fw-semibold'
                        onClick={(e) => {
                          e.preventDefault()
                          markAsViewed(notification, index)
                          handleNavigation(notification.actionUrl)
                        }}
                      >
                        {notification.title}
                      </a>
                    ) : (
                      <p
                        className='fs-7 mb-0 text-gray-700 text-primary fw-semibold'
                        style={{fontWeight: '900px'}}
                      >
                        {notification.title}
                      </p>
                    )}

                    <div className='text-gray-500 fs-8'>{notification.message}</div>
                  </div>
                </div>

                <div className='d-flex align-items-end justify-content-end'>
                  <span className='badge badge-light fs-9 font-base'>
                    {formatNotificationTime(notification.createdDateTime)}
                  </span>
                  {/* <button
                  className='btn btn-icon btn-light btn-sm'
                  onClick={() => markAsViewed(notification, index)}
                  hidden={notification.status}
                >
                  <i className='fas fa-envelope-open-text'></i>
                </button> */}
                </div>
              </div>
            </>
          ))}
          <div className='text-center h-10px'>
            {/* <a className='text-primary fs-8 h-30px'></a> */}
          </div>
        </div>
      </div>
    </>
  )
}

export {HeaderNotificationsMenu}

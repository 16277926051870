import React, { useEffect } from 'react'
// import 'react-quill/dist/quill.snow.css'
import {useQuill} from 'react-quilljs'
import 'quill/dist/quill.snow.css'


type QuillInputProps = {
  defaultMessage?: string // Optional prop for default message
}
const QuillInput: React.FC<QuillInputProps> = ({defaultMessage = ''}) => {
  const {quill, quillRef} = useQuill()

  useEffect(() => {
    if (quill && defaultMessage) {
      // Set the HTML content
      quill.root.innerHTML = defaultMessage
    }
  }, [quill, defaultMessage])

  return (
    <>
      <div className='w-100 '>
        <div ref={quillRef} />
      </div>
    </>
  )
}

export default QuillInput

import {ID, Response} from '../../../../../../../_metronic/helpers'

export type PreCallPolicy = {
  id?: ID
  name: string
  description: string
  fieldCount: number
  customers: number
  requesters: number
  numOfMembers?: number
  fK_InsertedBy: string
  fK_InsertedByName: string
  insertedDateTime: string
  fK_LastModifiedBy: string
  fK_LastModifiedByName: string
  lastModifiedDateTime: string
  customer: number | undefined
}

export const initial: PreCallPolicy = {
  id: '',
  name: '',
  description: '',
  fieldCount: 0,
  customers: 0,
  requesters: 0,
  numOfMembers: 0,
  fK_InsertedBy: '',
  fK_InsertedByName: '',
  insertedDateTime: '',
  fK_LastModifiedBy: '',
  fK_LastModifiedByName: '',
  lastModifiedDateTime: '',
  customer: undefined,
}

export type ResponseObject = {
  status?: ID
  text?: string
}

export type ModelQueryResponse = Response<Array<PreCallPolicy>>

import React, {FC, useState, useEffect} from 'react'
import {Button, Modal} from 'react-bootstrap'

const TimezoneConfirmationModal = ({...props}) => {
  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      tabIndex={-2}
      dialogClassName='modal-90w'
    >
      <Modal.Header closeButton>
        <h4 className=''>You're on the move!</h4>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <div className='symbol symbol-100px '>
            <img src='/media/other/clock.gif' alt='' />
          </div>
        </div>
        <h4 className='fw-normal mt-2' style={{textAlign: 'center'}}>
          {props.title}
        </h4>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            props.onClose()
            props.onHide()
          }}
          className='btn btn-sm'
        >
          Not now
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            props.onHide()
            props.onConfirm()
          }}
          className='btn btn-primary btn-sm'
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {TimezoneConfirmationModal}

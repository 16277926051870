import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import toaster from '../../../../Utils/toaster'
import axios from 'axios'
import {Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {CommonLoading} from '../../../../Utils/commonLoading'
import {Modal} from 'bootstrap'

const API_URL = process.env.REACT_APP_API_URL
const itemsPerPage = Number(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE) || 10

const createTermsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  timePeriod: Yup.number()
    .positive('Must be greater than 0')
    .integer('Must be an integer')
    .required('Required'),
  description: Yup.string(),
})

/* eslint-disable jsx-a11y/anchor-is-valid */
export function Terms() {
  const [initialValues, setInitialValues] = useState({
    code: '',
    name: '',
    timePeriod: '',
    description: '',
  })
  const [allTerms, setAllAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [deleteTermID, setDeleteTermID] = useState('')

  // pagination
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(itemsPerPage)
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    fetchAllTerms(currentPage)
  }, [currentPage, rowsPerPage])

  const fetchAllTerms = async (currentPage: number) => {
    setIsLoading(true)
    try {
      let response = await axios.get(`${API_URL}/invoice/term`, {
        params: {
          page: currentPage,
          items_per_page: 10,
        },
      })
      const {data, payload} = response.data
      setCurrentPage(1)
      setAllAccounts(data)
      setTotalPages(payload.pagination.last_page)
      setTotalItems(payload.pagination.total)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDelete = async (code: string) => {
    try {
      const response = await axios.delete(`${API_URL}/invoice/term/${code}`)
      if (response.data.status === 'S') {
        toaster('success', response.data.text ?? 'Successfully Deleted')
        await fetchAllTerms(1)
        hideModal('kt_delete_confirm')
      } else if (response.data.status === 'E') {
        toaster('error', response.data.text)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const hideModal = (elementID: string) => {
    const modal = document.getElementById(elementID)
    if (modal) {
      const modalInstance = Modal.getInstance(modal)
      if (modalInstance) modalInstance.hide()
    }
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add-terms'>
        <div className='modal-dialog'>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={createTermsSchema}
            onSubmit={async (values, {setSubmitting, resetForm}) => {
              setSubmitting(true)
              try {
                let response: any = null
                if (values?.code === '') {
                  response = await axios.post(`${API_URL}/invoice/term`, values)
                } else {
                  response = await axios.put(`${API_URL}/invoice/term`, values)
                }

                if (response.data.status === 'S') {
                  toaster('success', response.data.text ?? 'Successfully Updated')
                  resetForm()
                  hideModal('kt_add-terms')
                  await fetchAllTerms(1)
                } else if (response.data.status === 'E') {
                  toaster('error', response.data.text ?? 'Updated Error')
                }
              } catch (ex) {
                toaster('error', 'Server Error')
                console.error(ex)
              } finally {
                setSubmitting(true)
              }
            }}
          >
            {({
              isSubmitting,
              handleChange,
              handleBlur,
              setFieldTouched,
              setFieldValue,
              handleSubmit,
              resetForm,
              values,
              errors,
              touched,
              isValid,
              dirty,
              ...formik
            }) => (
              <Form>
                <div className='modal-content'>
                  <div className='modal-header py-2'>
                    <h4 className='modal-title'>Terms</h4>
                    <div
                      className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                      data-bs-dismiss='modal'
                      aria-label='Close'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr061.svg'
                        className='svg-icon svg-icon-2x'
                      />
                    </div>
                  </div>
                  <div className='modal-body'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1'
                          >
                            Term Name
                          </label>
                          <input
                            type='text'
                            className='form-control form-control-white form-control-sm'
                            placeholder='Enter Name'
                            id='name'
                            name='name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched?.name && (
                            <div className='fv-plugins-message-container mx-1'>
                              <div className='fv-help-block'>{errors.name}</div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1'
                          >
                            Time Period (Days)
                          </label>
                          <input
                            type='number'
                            className='form-control form-control-white form-control-sm'
                            placeholder='Enter Time Period'
                            id='timePeriod'
                            name='timePeriod'
                            value={values.timePeriod}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.timePeriod && touched?.timePeriod && (
                            <div className='fv-plugins-message-container mx-1'>
                              <div className='fv-help-block'>{errors.timePeriod}</div>
                            </div>
                          )}
                        </div>
                        <div className='mb-3'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1'
                          >
                            Description
                          </label>
                          <textarea
                            {...formik.getFieldProps('description')}
                            id='description'
                            placeholder='Enter Description'
                            name='description'
                            className='form-control form-control-sm form-select-white mb-lg-0'
                            rows={2}
                            value={values?.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          ></textarea>
                          {errors.description && touched?.description && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.description}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer py-3'>
                    <button
                      type='reset'
                      className='btn btn-light btn-sm'
                      data-bs-dismiss='modal'
                      onClick={() => {
                        resetForm()
                      }}
                    >
                      Close
                    </button>

                    <button
                      type='submit'
                      className='btn btn-primary btn-sm'
                      disabled={isSubmitting || !isValid || !dirty}
                    >
                      {values?.code === '' ? 'Save' : 'Update'}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_delete_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Delete Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/delete.gif' alt='' />
                </div>
              </div>
              <h4 style={{textAlign: 'center'}}>Are you sure you want to delete this?</h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button
                type='button'
                className='btn btn-danger btn-sm'
                onClick={() => handleDelete(deleteTermID)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='row g-1'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex align-items-center me-4 mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <h5 className='text-black fs-4 fw-semibold mb-0'>Terms</h5>
                </div>
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Add and Edit Terms
                  </p>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center mb-2'>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'> Add Term</Tooltip>}
              >
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_add-terms'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </OverlayTrigger>
            </div>
          </div>

          <div className='py-0 pt-3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
                <thead>
                  <tr className='fw-semibold text-muted text-uppercase'>
                    <th className='min-w-100px '>Term Name </th>
                    <th className='min-w-100px '>Time period </th>
                    <th className='min-w-100px '>Description </th>

                    <th className='min-w-100px text-end'>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {allTerms?.length > 0 ? (
                    allTerms.map((item: any, index: number) => (
                      <tr>
                        <td>
                          <a className='text-gray-800 text-hover-primary fs-6'>{item.name}</a>
                        </td>
                        <td>
                          <a className='text-gray-800 text-hover-primary fs-6'>{item.timePeriod}</a>
                        </td>
                        <td>
                          <a className='text-gray-800 text-hover-primary fs-6'>
                            {item.description === '' ? '-' : item.description}
                          </a>
                        </td>

                        <td>
                          <div className='d-flex justify-content-end flex-shrink-0'>
                            <a
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_add-terms'
                              onClick={() =>
                                setInitialValues({
                                  code: item?.code,
                                  name: item?.name,
                                  timePeriod: item?.timePeriod,
                                  description: item?.description,
                                })
                              }
                            >
                              <KTSVG
                                path='/media/icons/duotune/art/art005.svg'
                                className='svg-icon-muted'
                              />
                            </a>
                            <a
                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_delete_confirm'
                              onClick={() => setDeleteTermID(item?.code)}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-muted '
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                          <div className='text-center'>
                            <div className='symbol symbol-200px '>
                              <img src='/media/other/nodata.png' alt='' />
                            </div>
                          </div>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                            No matching records found
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <CommonPaginationModel
            currentPage={currentPage}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            total={totalItems}
            onPageChange={setCurrentPage}
            onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
          />
        </div>
        {isLoading && <CommonLoading />}
      </div>
    </>
  )
}

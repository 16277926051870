/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {LanguageModel} from '../../core/_models'

type Props = {
  user: LanguageModel
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'></a>
    </div>
    <div className='d-flex flex-column'>
      <a className='text-gray-800 text-hover-primary mb-1'>
        {user?.specializedTypes?.map((val) => (
          <>
            <span className='badge badge-dark me-2'>{val?.label}</span>
          </>
        ))}
      </a>
      {/* <span>{user.name}  </span> */}
    </div>
  </div>
)

export {UserInfoCell}

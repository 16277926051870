import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
/* eslint-disable jsx-a11y/anchor-is-valid */
export function CurrencyView() {
  const currency = [
    {value: 'option 1', label: 'United States Dollar - USD, $'},
    {value: 'option 2', label: 'Argentine Peso - ARS, $'},
    {value: 'option 3', label: 'Australian Dollar - AUD, $'},
    {value: 'option 4', label: 'Baht - THB, ฿'},
  ]
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_currency'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Add Currency</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Currency
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={currency}
                      placeholder='Select'
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Show Currency Symbol
                    </label>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='form-label fs-7 mb-1 ms-5'
                      >
                        Interpreter
                      </label>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan1'
                              className='form-check-input'
                              type='radio'
                              value='1'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan1'
                              className='form-check-input'
                              type='radio'
                              value='2'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='form-label fs-7 mb-1 ms-5'
                      >
                        Customer
                      </label>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='1'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='2'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_currency'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Edit Currency</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Currency
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={currency}
                      placeholder='Select'
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Show Currency Symbol
                    </label>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='form-label fs-7 mb-1 ms-5'
                      >
                        Interpreter
                      </label>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan1'
                              className='form-check-input'
                              type='radio'
                              value='1'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan1'
                              className='form-check-input'
                              type='radio'
                              value='2'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3 d-flex justify-content-between align-items-center'>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='form-label fs-7 mb-1 ms-5'
                      >
                        Customer
                      </label>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='1'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan'
                              className='form-check-input'
                              type='radio'
                              value='2'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='row g-1'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex align-items-center me-4 mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <h5 className='text-black fs-4 fw-semibold mb-0'>Currency Manage</h5>
                </div>
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Add system defaults and turn on/off currency related features
                  </p>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center mb-2'>
              {/* <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Add Currency</Tooltip>}
              >
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_add_currency'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </OverlayTrigger> */}
            </div>
          </div>

          <div className=' p-5 border border-gray-500 border-dotted rounded mb-3'>
            <div className='d-flex align-items-start flex-column'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>United States Dollar</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_currency'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                Select a currency for your entire LSC.
                <strong>United States Dollar (USD, $)</strong>
                <span className='text-gray-600 fw-semibold mt-2'>
                  Interpreters: <strong className='text-dark fw-bold fs-5'>On</strong>
                </span>
                <span className='text-gray-600 fw-semibold'>
                  Customer: <strong className='text-dark fw-bold fs-5'>On</strong>
                </span>
                <span style={{fontStyle: 'italic'}}>
                  {' '}
                  When the setting is Off, the currency symbol will be hidden from users.
                </span>
              </div>
            </div>
          </div>
          {/* <div className=' p-5 border border-gray-500 border-dotted rounded mb-3'>
            <div className='d-flex align-items-start flex-column'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>Argentine Peso</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_currency'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                Select a currency for your entire LSC.
                <strong>Argentine Peso (ARS, $)</strong>
                <span className='text-gray-600 fw-semibold mt-2'>
                  Interpreters: <strong className='text-dark fw-bold fs-5'>On</strong>
                </span>
                <span className='text-gray-600 fw-semibold'>
                  Customers: <strong className='text-dark fw-bold fs-5'>Off</strong>
                </span>
                <span style={{fontStyle: 'italic'}}>
                  {' '}
                  When the setting is Off, the currency symbol will be hidden from users.
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {BillingMyFilterDropdown} from './BillingMyFilterDropdown'

/* eslint-disable jsx-a11y/anchor-is-valid */
export function Overview() {
  return (
    <>
      <div className='card-body p-0'>
        <div className='d-flex justify-content-between mb-3 align-items-center flex-wrap'>
          <div className='card-title d-flex align-items-center me-4 mb-3'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-4 fw-semibold mb-0'>Invoices List</h5>
              </div>
              <div className='d-flex flex-wrap fs-6 '>
                <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                  Invoice List Overview
                </p>
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <div className='my-0' style={{display: 'flex', justifyContent: 'end'}}>
                <BillingMyFilterDropdown />
              </div>
            </div>
          </div>
        </div>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                <th className='min-w-150px '>Invoice#</th>
                <th className='min-w-100px '>Status</th>
                <th className='min-w-100px '>PO#</th>
                <th className='min-w-100px '>Created</th>
                <th className='min-w-100px '>Invoice Date</th>
                <th className='min-w-100px '>Due Date</th>
                <th className='min-w-100px '>Call/Appts</th>

                <th className='min-w-100px text-end'>Total</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    <a className='text-gray-800 text-hover-primary fs-6'>INV-000339-A</a>
                  </div>
                </td>
                <td>
                  <span
                    className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                    
                  >
                    Approved
                  </span>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'></a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>11/20/23 5:47pm</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>05/20/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>12/13/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>0 / 0</a>
                </td>
                <td className='text-end'>
                  <a className='text-gray-800 text-hover-primary fs-6 '>$20.00</a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex justify-content-start flex-column'>
                    <a className='text-gray-800 text-hover-primary fs-6'>INV-000229-A</a>
                  </div>
                </td>
                <td>
                  <span
                    className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
               
                  >
                    Rejected
                  </span>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'></a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>01/20/23 3:47pm</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>03/20/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>10/13/23</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary fs-6'>1 / 0</a>
                </td>
                <td className='text-end'>
                  <a className='text-gray-800 text-hover-primary fs-6 '>$20.00</a>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </>
  )
}

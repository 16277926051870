import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteUser, reinvite} from '../../core/_requests'
import {Deletemodel} from '../../core/_models'

type Props = {
  code: string
  email: string
  isConfirmed?: boolean
}

const UserActionsCell: FC<Props> = ({isConfirmed, code, email}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(code)
  }

  // const deleteData: Deletemodel = {
  //   email: email,
  //   code: code,
  // };

  const deleteItem = useMutation(() => deleteUser(code), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })

  const func_reinvite = (param: string) => {
    reinvite(code)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2'
        onClick={openEditModal}
      >
        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
      </a>
      {/* begin::Menu */}

      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}

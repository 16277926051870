import React, {useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import UnderDevelopmentBadge from '../../common/componenets/underDevelopment/underDevelopmentBadge'

export function QuickStart() {
  const [showOnStartup, setShowOnStartup] = useState(true)

  // Toggle function to switch between showing and hiding the page on startup
  const toggleShowOnStartup = () => {
    setShowOnStartup((prevState) => !prevState)
  }
  return (
    <>
      <div className='mb-3 d-flex flex-column'>
        {/* <div className='d-flex flex-column'>
          <h1 className='mb-0 fw-semibold fs-2'>Billing</h1>
          <span className='text-gray-500'>Manage Invoices</span>
        </div> */}
        <div className='d-flex'>
          <h1 className='mb-0 fw-bold mb-2' style={{fontSize: '25px', lineHeight: '32px'}}>
            Quick Start
          </h1>
          <div className='d-flex align-items-start'>
            <UnderDevelopmentBadge className='' />
          </div>
        </div>
        <div className='d-flex'>
          <p className='text-gray-500 fs-5 mb-2'>Welcome to Adastra Guide</p>
        </div>
      </div>
      <div className='card mb-5'>
        {/* <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
        ></div> */}
        <div className='card-body'>
          <div className='row'>
            <div className='col-lg-2 col-md-3 col-sm-12'>
              <div className='d-flex justify-content-between flex-wrap align-items-center mb-3'>
                <div className='d-flex flex-column'>
                  <h4 className='text-gray-700 fs-5 fw-semibold mb-1'>Quick Links</h4>
                  <span className='fs-8 text-gray-400'>More coming soon...</span>
                </div>
              </div>
              <div className='separator my-3'></div>
              <div
                className='fs-7 text-primary'
                onClick={toggleShowOnStartup}
                style={{cursor: 'pointer'}}
              >
                <i
                  className={`bi ${showOnStartup ? 'bi-eye' : 'bi-eye-slash'} me-1 text-primary`}
                ></i>
                <span>
                  {showOnStartup ? 'Show this page on startup' : 'Do not show this page on startup'}
                </span>
              </div>
            </div>
            <div className='col-lg-10 col-md-9 col-sm-12'>
              <div className='row'>
                <div className='d-flex justify-content-between flex-wrap align-items-center'>
                  <div className='d-flex flex-column flex-start'>
                    <h4 className='text-center mb-1 text-gray-700 fs-5 fw-semibold'>Community</h4>
                    <span className='fs-8 text-gray-400'>Discribe community service...</span>
                  </div>
                </div>
                <div className='separator my-3'></div>
                <div className='col-sm-12 col-md-4 mt-2'>
                  <a href='#'>
                    <div className='card card-md-stretch mb-5 light-hover'>
                      <div className='card-body shadow-custom px-4 py-4 rounded'>
                        <div className='d-flex justify-content-start align-items-center'>
                          <div className='d-flex me-4'>
                            {' '}
                            <div className='symbol symbol-60px symbol-lg-85px symbol-fixed position-relative'>
                              <img
                                src={toAbsoluteUrl('../media/avatars/q-and-a.gif')}
                                alt='appointment'
                                className='rounded-circle'
                              />
                              {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                            </div>
                          </div>
                          <div className='d-flex flex-column'>
                            <span className='text-gray-800 pt-1 fw-semibold fs-4'>FAQs</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='col-sm-12 col-md-4 mt-2'>
                  {' '}
                  <a href='#'>
                    <div className='card card-md-stretch mb-5 light-hover'>
                      <div className='card-body shadow-custom px-4 py-4 rounded'>
                        <div className='d-flex justify-content-start align-items-center'>
                          <div className='d-flex me-4'>
                            {' '}
                            <div className='symbol symbol-60px symbol-lg-85px symbol-fixed position-relative'>
                              <img
                                src={toAbsoluteUrl('../media/avatars/notebook.gif')}
                                alt='appointment'
                                className='rounded-circle'
                              />
                              {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                            </div>
                          </div>
                          <div className='d-flex flex-column'>
                            <span className='text-gray-800 pt-1 fw-semibold fs-4'>
                              Release Notes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className='col-sm-12 col-md-4 mt-2'>
                  {' '}
                  <a href='#'>
                    <div className='card card-md-stretch mb-5 light-hover'>
                      <div className='card-body shadow-custom px-4 py-4 rounded'>
                        <div className='d-flex justify-content-start align-items-center'>
                          <div className='d-flex me-4'>
                            {' '}
                            <div className='symbol symbol-60px symbol-lg-85px symbol-fixed position-relative'>
                              <img
                                src={toAbsoluteUrl('../media/avatars/video.gif')}
                                alt='appointment'
                                className='rounded-circle'
                              />
                              {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                            </div>
                          </div>
                          <div className='d-flex flex-column'>
                            <span className='text-gray-800 pt-1 fw-semibold fs-4'>Videos</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className='row g-4 mt-1'>
                <div className='d-flex justify-content-between flex-wrap align-items-center'>
                  <div className='d-flex flex-column flex-start'>
                    <h4 className='text-center mb-0 text-gray-700 fs-5 fw-semibold'>Documents</h4>
                    <span className='fs-8 text-gray-400'>Articles and videos...</span>
                  </div>
                </div>
                <div className='separator my-3'></div>
                <div className='col-sm-12 col-md-6 mt-2'>
                  <div className='card shadow-custom'>
                    <div className='card-header px-5' style={{minHeight: '60px'}}>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='text-gray-800 pt-1 fw-semibold fs-4'>Articles</span>
                      </h3>

                      <div className='card-toolbar'>
                        <a href='#' className='btn btn-primary btn-sm'>
                          See All
                        </a>
                      </div>
                    </div>

                    <div className='card-body pt-0 px-5'>
                      <div className='d-flex flex-column'>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Ad Astra Browser Support
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Requestor Permissions
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Interpreter Permissions
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Microphone and Camera Access
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Interpreter Call Flows
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Registering as a Language Agency
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Ensuring Apps are Up to Date
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Bandwidth Requirements and Speed Testing
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-6 mt-2'>
                  <div className='card shadow-custom'>
                    <div className='card-header px-5' style={{minHeight: '60px'}}>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='text-gray-800 pt-1 fw-semibold fs-4'>Videos</span>
                      </h3>

                      <div className='card-toolbar'>
                        <a href='#' className='btn btn-primary btn-sm'>
                          See All
                        </a>
                      </div>
                    </div>

                    <div className='card-body pt-0 px-5'>
                      <div className='d-flex flex-column'>
                        <a
                          href=''
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Ad Astra - Our new look
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Ad Astra - Visual - see what's new
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Ad Astra - See the Apps
                        </a>
                        <div className='separator separator-dashed'></div>
                        <a
                          href='#'
                          className='text-gray-800 text-info fs-7 text-hover-info fw-semibold text-decoration-underline py-3'
                        >
                          Ad Astra + ProZ - Coming Soon
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

'use client'

import {useCallback, useState} from 'react'
import {useResizeObserver} from '@wojtekmaj/react-hooks'
import {pdfjs, Document, Page} from 'react-pdf'
import {Button} from 'react-bootstrap'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

import './Sample.css'

type Props = {
  file: string
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
}

const resizeObserverOptions = {}

const maxWidth = 800

const PdfReader: React.FC<Props> = ({file}) => {
  const [containerRef, setContainerRef] = useState<any>(null)
  const [containerWidth, setContainerWidth] = useState()
  const [numPages, setNumPages] = useState<any>(null)
  const [zoomValue, setZoomValue] = useState([
    {label: '75%', value: 0.75},
    {label: '100%', value: 1.0},
    {label: '125%', value: 1.25},
    {label: '150%', value: 1.5},
    {label: '175%', value: 1.75},
    {label: '200%', value: 2.0},
  ])
  const [currentZoomValueIndex, setCurrentZoomValueIndex] = useState(1)
  let [pageNumber, setPageNumber] = useState(1)

  const onResize = useCallback((entries: any) => {
    const [entry] = entries

    if (entry) {
      setContainerWidth(entry.contentRect.width)
    }
  }, [])

  useResizeObserver(containerRef, resizeObserverOptions, onResize)

  function onDocumentLoadSuccess(nextNumPages: any) {
    setNumPages(nextNumPages)
  }

  const nextPage = () => {
    if (numPages?._pdfInfo?.numPages > pageNumber) {
      setPageNumber(++pageNumber)
    }
  }

  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(--pageNumber)
    }
  }

  const zoomUp = () => {
    if (currentZoomValueIndex < 5) {
      const newValue = currentZoomValueIndex + 1
      setCurrentZoomValueIndex(newValue)
    }
  }

  const zoomDown = () => {
    if (currentZoomValueIndex > 0) {
      const newValue = currentZoomValueIndex - 1
      setCurrentZoomValueIndex(newValue)
    }
  }

  return (
    <div className='pdf_container_layout_container'>
      <div className='pdf_container_layout_main'>
        <div className='pdf_container_layout_body'>
          <div className='pdf_container_layout_toolbar'>
            <div className='pdf-toolbar'>
              <div className='pdf-toolbar-item-left'>
                <Button className='toolbar-btn' onClick={previousPage} disabled={pageNumber == 1}>
                  <i className='bi bi-caret-left-fill text-white'></i>
               
                </Button>
                <div>
                  <span className='text-white fs-5'>
                    <b>{pageNumber}</b>
                  </span>
                  <span className='text-white fs-5'>/</span>
                  <span className='text-white fs-5'>{numPages?._pdfInfo?.numPages}</span>
                </div>
                <Button
                  className='toolbar-btn'
                  onClick={nextPage}
                  disabled={pageNumber == numPages?._pdfInfo?.numPages}
                >
                  <i className='bi bi-caret-right-fill text-white'></i>
                
                </Button>
              </div>
              <div className='pdf-toolbar-item-right'>
                <Button className='toolbar-btn pe-1' onClick={zoomDown}>
                  <i className='bi bi-zoom-out text-white px-1'></i>
                </Button>
                <span className='text-white fs-5'>{zoomValue[currentZoomValueIndex].label}</span>
                <Button className='toolbar-btn ps-1' onClick={zoomUp}>
                  <i className='bi bi-zoom-in text-white px-1'></i>
                </Button>
              </div>
            </div>
          </div>
          <div className='pdf_container_document' ref={setContainerRef}>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
              className='document-container'
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${pageNumber}`}
                  pageNumber={pageNumber}
                  width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                  scale={zoomValue[currentZoomValueIndex].value}
                />
              ))}
            </Document>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PdfReader

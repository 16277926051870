import {FC} from 'react'
import {PreCallPolicy} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  dbObj: PreCallPolicy
}

const isRecentlyUpdated = (modifiedDateTime: string | undefined) => {
  if (!modifiedDateTime) {
    return false
  }

  const today = new Date()
  const modifiedDate = new Date(modifiedDateTime)

  return (
    today.getDate() === modifiedDate.getDate() &&
    today.getMonth() === modifiedDate.getMonth() &&
    today.getFullYear() === modifiedDate.getFullYear()
  )
}

export const InfoCellNumberOfMembers: FC<Props> = ({dbObj}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>{`${dbObj.requesters}`}</div>
  </div>
)

export const InfoCellName: FC<Props> = ({dbObj}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex'>
      {/* <a href={`questions/${dbObj.id}/all`} className='text-gray-800 text-hover-primary mb-1'>
        {dbObj.name}
      </a> */}

      <Link to={`precalls/${dbObj.id}?name=${dbObj.name}`}>
        <a className='text-gray-800 text-hover-primary mb-1 fw-semibold fs-6'>{dbObj.name}</a>
      </Link>
      <span>
        {isRecentlyUpdated(dbObj.lastModifiedDateTime) && (
          <span className='badge badge-light-warning fs-8 ms-2'>Recently Updated</span>
        )}
      </span>
    </div>
  </div>
)

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {LogsOnDemand} from './LogsOnDemand'
import {LogsSheduled} from './LogsScheduled'
import {LogsInPerson} from './LogsInPerson'
import {LogsOperator} from './LogsOperator'
import './style.css'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  className: string
}

const DashboardLogs: React.FC<Props> = () => {
  const [showOndemandUI, setShowOndemandUI] = useState(true)
  const [showSheduledUI, setShowSheduledUI] = useState(false)
  const [showInPersonUI, setShowInPersonUI] = useState(false)
  const [showOperatorUI, setShowOperatorUI] = useState(false)
  const [totalCallLength, setTotalCallLength] = useState<string>('00:00:00')

  function handleOndemandClick() {
    setShowOndemandUI(true)
    setShowSheduledUI(false)
    setShowInPersonUI(false)
    setShowOperatorUI(false) // Hide Info UI
  }

  function handleSheduledClick() {
    setShowOndemandUI(false)
    setShowSheduledUI(true)
    setShowInPersonUI(false)
    setShowOperatorUI(false) // Hide Authentication UI
  }
  function handleInPersonClick() {
    setShowOndemandUI(false)
    setShowSheduledUI(false)
    setShowInPersonUI(true)
    setShowOperatorUI(false) // Hide Authentication UI
  }
  function handleOperatorClick() {
    setShowOndemandUI(false)
    setShowSheduledUI(false)
    setShowInPersonUI(false)
    setShowOperatorUI(true) // Hide Authentication UI
  }

  const fetchTatalCallLength = async () => {
    try {
      let response = await axios.get(`${API_URL}/aws-connect/call-duration`)
      setTotalCallLength(`${response.data.hh}:${response.data.mm}:${response.data.ss}`)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchTatalCallLength()
  }, [])
  
  return (
    <>
      <div className='card '>
        <div className='card-header px-0 '>
          <div className='card-title d-flex align-items-center me-4'>
            <div className='p-4 d-flex justify-content-center align-items-center rounded'>
              <div className='d-flex text-center me-2'>
                <div className='symbol symbol-85px '>
                  <img src='/media/other/phone.gif' alt='' />
                </div>
              </div>
              {/* <div className='d-flex'>
                <i className='fa-solid fa-phone me-4 text-info fs-1'></i>
              </div> */}
              <div className='d-flex flex-column justify-content-start'>
                <span className='fs-5 text-gray-400'>
                  Total Call Length{' '}
                  <span className='fs-7 fw-semibold text-gray-400'>(HH:MM:SS)</span>
                </span>
                <span className='fs-1 fw-bold d-flex align-items-center'>
                  {totalCallLength}{' '}
                </span>
              </div>
            </div>
          </div>

          <div className='card-toolbar'>
            <button
              type='button'
              onClick={handleOndemandClick}
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white ${
                showOndemandUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              On Demand
            </button>
            <button
              type='button'
              disabled
              onClick={handleSheduledClick}
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white me-3 ${
                showSheduledUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              Scheduled
            </button>
            <button
              onClick={handleInPersonClick}
              disabled
              type='button'
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm me-3 text-active-white me-3 ${
                showInPersonUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              In Person
            </button>
            <button
              onClick={handleOperatorClick}
              disabled
              type='button'
              className={`btn btn-outline text-muted btn-outline-dotted btn-sm text-active-white ${
                showOperatorUI ? 'active fw-bold border border-2 bg-primary' : ''
              }`}
            >
              Operator
            </button>
          </div>
        </div>
        {showOndemandUI && <LogsOnDemand className={''} />}
        {showSheduledUI && <LogsSheduled className={''} />}
        {showInPersonUI && <LogsInPerson className={''} />}
        {showOperatorUI && <LogsOperator className={''} />}
      </div>
    </>
  )
}

export {DashboardLogs}

import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register, getUserByToken, sendLoginDetails} from '../core/_requests'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import toaster from '../../../../Utils/toaster'
import {useDeviceInfo} from './DeviceInfo'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  oldPassword: '',
  newPassword: '',
  acceptTerms: false,
  token: '',
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  oldPassword: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(16, 'Maximum 16 symbols')
    .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*_]).{8,16}$/, 'Invalid password')
    .required('Password is required'),
  newPassword: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('oldPassword')], 'The passwords entered do not match'),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const urlParams = new URLSearchParams(window.location.search)
  const Passwordaccesstoken = String(urlParams.get('token'))
  const firstName = String(urlParams.get('firstName'))
  const lastName = String(urlParams.get('lastName'))
  const email = String(urlParams.get('email'))
  const deviceInfo = useDeviceInfo()
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        values.token = Passwordaccesstoken
        const {data: auth} = await register(
          values.token,
          values.email,
          values.firstName,
          values.lastName,
          values.oldPassword,
          values.newPassword
        )

        if (auth.isAuthenticated === false) {
          setLoading(false)
          await sendLoginDetails(
            deviceInfo.deviceId,
            deviceInfo.deviceType,
            values.email,
            deviceInfo.ipAddress,
            deviceInfo.browser,
            deviceInfo.longitude,
            deviceInfo.latitude,
            deviceInfo.loginTimestamp,
            auth.isAuthenticated
          )
          setStatus(auth.message)
          toaster('error', auth?.message ?? 'AWS Error please contact Admin')
        } else {
          saveAuth(auth)
          await sendLoginDetails(
            deviceInfo.deviceId,
            deviceInfo.deviceType,
            values.email,
            deviceInfo.ipAddress,
            deviceInfo.browser,
            deviceInfo.longitude,
            deviceInfo.latitude,
            deviceInfo.loginTimestamp,
            auth.isAuthenticated
          )
          const {data: user} = await getUserByToken()
          setCurrentUser({...user, notNowTimeZoneChanging: false})
        }

        setStatus(auth.text)
        setLoading(false)
        setSubmitting(false)

        values.acceptTerms = false
        values.newPassword = ''
        values.email = ''
        values.firstName = ''
        values.lastName = ''
        values.oldPassword = ''
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    formik.setFieldValue('firstName', firstName)
    formik.setFieldValue('lastName', lastName)
    formik.setFieldValue('email', email)
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group firstName */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>First Name</label>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            },
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      <div className='fv-row mb-8'>
        {/* begin::Form group lastName */}
        <label className='form-label fw-bolder text-dark fs-6'>Last Name</label>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            },
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          </div>
        )}
        {/* end::Form group */}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          disabled
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('oldPassword')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword,
                },
                {
                  'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                }
              )}
            />
            {formik.touched.oldPassword && formik.errors.oldPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.oldPassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-gray-800 fs-8 '>
          (Password must be 8-16 characters long, include at least one lowercase letter, one
          uppercase letter, one digit, and one special character, and must not contain spaces.)
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
            },
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I accept the
            <a href='#' className='ms-1 link-primary'>
              Terms and Conditions
            </a>
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* {isAuthenticated === false && (
          <>
            <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Error!</h4>
                  <div className='fs-6 text-gray-600'>{result?.text}</div>
                </div>
              </div>
            </div>
          </>
        )} */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}

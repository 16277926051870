import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
/* eslint-disable jsx-a11y/anchor-is-valid */
export function ExpensesView() {
  const metric = [
    {value: 'option 1', label: 'Per Mile'},
    {value: 'option 2', label: 'Per Hour'},
  ]
  const [isRatePerMile, setIsRatePerMile] = useState(true)

  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isTravelTime, setIsTravelTime] = useState(true)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  const [selectedExpence, setSelectedExpence] = useState('1')

  const handleExpenceChange = (event: any) => {
    setSelectedExpence(event.target.value)
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_new_expense'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Add Expenses</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'auto'}}>
              <div className='row'>
                <div className='mb-3'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1 '
                  >
                    Expense Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    placeholder='Enter Name'
                  />
                </div>
                <div className='mb-3'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1'
                  >
                    Metric
                  </label>
                  <Select
                    className='react-select-styled react-select-solid react-select-sm'
                    classNamePrefix='react-select'
                    options={metric}
                    placeholder='Select Metric'
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: '100%',
                        border: '1px solid #e4e6ef',
                      }),
                    }}
                  />
                </div>
                <div className='mb-3'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1 '
                  >
                    Price
                  </label>
                  <div className='input-group input-group-sm'>
                    {/* <span className='input-group-text'>Hr</span> */}
                    <span className='input-group-text'>$</span>
                    <input
                      type='number'
                      className='form-control form-control-white form-control-sm custom-input-height'
                      placeholder='Enter Price'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_add_newtest_expense'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Add Expenses</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'auto'}}>
              <div className='row'>
                <div className='mb-3'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1 '
                  >
                    Expense Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    placeholder='Enter Name'
                  />
                </div>
                <div className=''>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1'
                  >
                    Metric
                  </label>
                  <select
                    className='form-select form-select-sm form-select-white custom-input-height'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    onChange={handleExpenceChange}
                  >
                    <option value='1'>Per Mile</option>
                    <option value='2'>Per Hour</option>
                  </select>
                </div>
                {selectedExpence === '1' && (
                  <div className='row g-4 '>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan1'
                            className='form-check-input'
                            type='radio'
                            value='1'
                            onChange={() => setIsRatePerMile(true)}
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary '>Rate per Mile</span>
                        </span>
                      </label>
                      <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan1'
                            className='form-check-input'
                            type='radio'
                            value='2'
                            onChange={() => setIsRatePerMile(false)}
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary'>Based on Rate</span>
                        </span>
                      </label>
                    </div>
                    {isRatePerMile ? (
                      <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                          Rate per Mile
                        </label>
                        <div className='input-group input-group-sm'>
                          {/* <span className='input-group-text'>Hr</span> */}
                          <span className='input-group-text'>$</span>
                          <input
                            type='number'
                            className='form-control form-control-white form-control-sm'
                            placeholder='None'
                          />
                        </div>
                      </div>
                    ) : (
                      <div className='col-sm-12 col-md-12 col-lg-12 '>
                        <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                          Base on Rate
                        </label>
                        <div className='input-group input-group-sm'>
                          {/* <span className='input-group-text'>Hr</span> */}

                          <input
                            type='number'
                            className='form-control form-control-white form-control-sm'
                            placeholder='None'
                          />
                          <span className='input-group-text'>miles equals to</span>
                          <input
                            type='number'
                            className='form-control form-control-white form-control-sm'
                            placeholder='None'
                          />
                          <span className='input-group-text'>hr of the rate</span>
                        </div>
                      </div>
                    )}
                    <div className='col-sm-12 col-md-6 col-lg-6 '>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg'
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg'>
                          Min Miles
                        </label>
                      </div>

                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm'
                        placeholder='None'
                        disabled={!isChecked}
                      />
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg-6 '>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg'
                          checked={isChecked2}
                          onChange={handleCheckboxChange2}
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg'>
                          Max Miles
                        </label>
                      </div>
                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm'
                        placeholder='None'
                        disabled={!isChecked2}
                      />
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg1'
                          checked
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg1'>
                          Automatically add to appointments for Interpreters
                        </label>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg2'
                          checked
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg2'>
                          Automatically add to appointments for Customer
                        </label>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg3'
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg'>
                          Round-trip{' '}
                          <i
                            className='bi bi-info-circle text-info'
                            data-bs-toggle='popover'
                            data-bs-custom-class='popover-inverse'
                            data-bs-placement='top'
                            title='When selected, distance from interpreter address to appointment location will be automatically doubled.'
                          ></i>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                {selectedExpence === '2' && (
                  <div className='row g-4 '>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan2'
                            className='form-check-input'
                            type='radio'
                            value='3'
                            checked={isTravelTime}
                            disabled={isTravelTime}
                            onChange={() => setIsTravelTime(true)}
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary '>
                            Based on Interpreter/Account Rate{' '}
                            <i
                              className='bi bi-info-circle text-info'
                              data-bs-toggle='popover'
                              data-bs-custom-class='popover-inverse'
                              data-bs-placement='top'
                              title='1 hour/min of travel time equals to 1 hour/min of Account and Interpreter rate'
                            ></i>
                          </span>
                        </span>
                      </label>
                      <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                        <span className='form-check form-check-custom form-check-white me-3'>
                          <input
                            name='accountPlan2'
                            className='form-check-input'
                            type='radio'
                            value='4'
                            checked={!isTravelTime}
                            disabled={!isTravelTime}
                            onChange={() => setIsTravelTime(false)}
                          />
                        </span>
                        <span className='d-flex align-items-center me-2'>
                          <span className='text-gray-800 text-hover-primary'>New Rate</span>
                        </span>
                      </label>
                    </div>
                    {isTravelTime ? (
                      <></>
                    ) : (
                      <>
                        <div className='col-sm-12 col-md-12 col-lg-12 '>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1 '
                          >
                            New Rate
                          </label>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>$</span>

                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm'
                              placeholder='None'
                            />
                            <span className='input-group-text'>Per</span>
                            <select
                              className='form-select form-select-sm form-select-white rounded-0'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                            >
                              <option value='1'>Hour</option>
                              <option value='2'>Minute</option>
                            </select>
                            <span className='input-group-text'>for interpreterse</span>
                          </div>
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 '>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>$</span>

                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm'
                              placeholder='None'
                            />
                            <span className='input-group-text'>Per</span>
                            <select
                              className='form-select form-select-sm form-select-white rounded-0'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                            >
                              <option value='1'>Hour</option>
                              <option value='2'>Minute</option>
                            </select>
                            <span className='input-group-text'>for Customer</span>
                          </div>
                        </div>
                      </>
                    )}
                    <div className='col-sm-12 col-md-6 col-lg-6 '>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg'
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg'>
                          Min Time
                        </label>
                      </div>
                      <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                        <button type='button' className='btn btn-light btn-sm me-2'>
                          -
                        </button>
                        <div className='d-flex'>
                          <input
                            type='text'
                            className='form-control form-control-white form-select-sm'
                            pattern='[0-9]{2}:[0-9]{2}'
                            title='Enter Duration in HH:MM format'
                            placeholder='(HH:MM)'
                            disabled={!isChecked}
                          />
                        </div>
                        <button type='button' className='btn btn-light btn-sm ms-2'>
                          +
                        </button>
                      </div>
                    </div>

                    <div className='col-sm-12 col-md-6 col-lg-6 '>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg'
                          checked={isChecked2}
                          onChange={handleCheckboxChange2}
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg'>
                          Max Time
                        </label>
                      </div>
                      <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                        <button type='button' className='btn btn-light btn-sm me-2'>
                          -
                        </button>
                        <div className='d-flex'>
                          <input
                            type='text'
                            className='form-control form-control-white form-select-sm'
                            pattern='[0-9]{2}:[0-9]{2}'
                            title='Enter Duration in HH:MM format'
                            placeholder='(HH:MM)'
                            disabled={!isChecked2}
                          />
                        </div>
                        <button type='button' className='btn btn-light btn-sm ms-2'>
                          +
                        </button>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexRadioLg11'
                        />
                        <label className='form-check-label' htmlFor='flexRadioLg11'>
                          Automatically add to Account expenses
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_mileage_expense'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Distance Rule</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan1'
                        className='form-check-input'
                        type='radio'
                        value='1'
                        checked={isRatePerMile}
                        disabled={isRatePerMile}
                        onChange={() => setIsRatePerMile(true)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary '>Rate per Mile</span>
                    </span>
                  </label>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan1'
                        className='form-check-input'
                        type='radio'
                        value='2'
                        checked={!isRatePerMile}
                        disabled={!isRatePerMile}
                        onChange={() => setIsRatePerMile(false)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary'>Based on Rate</span>
                    </span>
                  </label>
                </div>
                {isRatePerMile ? (
                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Rate per Mile
                    </label>
                    <div className='input-group input-group-sm'>
                      {/* <span className='input-group-text'>Hr</span> */}
                      <span className='input-group-text'>$</span>
                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm'
                        placeholder='None'
                      />
                    </div>
                  </div>
                ) : (
                  <div className='col-sm-12 col-md-12 col-lg-12 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                      Base on Rate
                    </label>
                    <div className='input-group input-group-sm'>
                      {/* <span className='input-group-text'>Hr</span> */}

                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm'
                        placeholder='None'
                      />
                      <span className='input-group-text'>miles equals to</span>
                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm'
                        placeholder='None'
                      />
                      <span className='input-group-text'>hr of the rate</span>
                    </div>
                  </div>
                )}
                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Min Miles
                    </label>
                  </div>

                  <input
                    type='number'
                    className='form-control form-control-white form-control-sm'
                    placeholder='None'
                    disabled={!isChecked}
                  />
                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Max Miles
                    </label>
                  </div>
                  <input
                    type='number'
                    className='form-control form-control-white form-control-sm'
                    placeholder='None'
                    disabled={!isChecked2}
                  />
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg1'
                      checked
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg1'>
                      Automatically add to appointments for Interpreters
                    </label>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg2'
                      checked
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg2'>
                      Automatically add to appointments for Customer
                    </label>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg3'
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Round-trip{' '}
                      <i
                        className='bi bi-info-circle text-info'
                        data-bs-toggle='popover'
                        data-bs-custom-class='popover-inverse'
                        data-bs-placement='top'
                        title='When selected, distance from interpreter address to appointment location will be automatically doubled.'
                      ></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit_travel_expense'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Travel Time Rule</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row g-4 '>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan2'
                        className='form-check-input'
                        type='radio'
                        value='3'
                        checked={isTravelTime}
                        disabled={isTravelTime}
                        onChange={() => setIsTravelTime(true)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary '>
                        Based on Interpreter/Account Rate{' '}
                        <i
                          className='bi bi-info-circle text-info'
                          data-bs-toggle='popover'
                          data-bs-custom-class='popover-inverse'
                          data-bs-placement='top'
                          title='1 hour/min of travel time equals to 1 hour/min of Account and Interpreter rate'
                        ></i>
                      </span>
                    </span>
                  </label>
                  <label className='d-flex flex-start mb-0 cursor-pointer mb-2'>
                    <span className='form-check form-check-custom form-check-white me-3'>
                      <input
                        name='accountPlan2'
                        className='form-check-input'
                        type='radio'
                        value='4'
                        checked={!isTravelTime}
                        disabled={!isTravelTime}
                        onChange={() => setIsTravelTime(false)}
                      />
                    </span>
                    <span className='d-flex align-items-center me-2'>
                      <span className='text-gray-800 text-hover-primary'>New Rate</span>
                    </span>
                  </label>
                </div>
                {isTravelTime ? (
                  <></>
                ) : (
                  <>
                    <div className='col-sm-12 col-md-12 col-lg-12 '>
                      <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1 '>
                        New Rate
                      </label>
                      <div className='input-group input-group-sm'>
                        <span className='input-group-text'>$</span>

                        <input
                          type='number'
                          className='form-control form-control-white form-control-sm'
                          placeholder='None'
                        />
                        <span className='input-group-text'>Per</span>
                        <select
                          className='form-select form-select-sm form-select-white rounded-0'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                        >
                          <option value='1'>Hour</option>
                          <option value='2'>Minute</option>
                        </select>
                        <span className='input-group-text'>for interpreterse</span>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-12 col-lg-12 '>
                      <div className='input-group input-group-sm'>
                        <span className='input-group-text'>$</span>

                        <input
                          type='number'
                          className='form-control form-control-white form-control-sm'
                          placeholder='None'
                        />
                        <span className='input-group-text'>Per</span>
                        <select
                          className='form-select form-select-sm form-select-white rounded-0'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                        >
                          <option value='1'>Hour</option>
                          <option value='2'>Minute</option>
                        </select>
                        <span className='input-group-text'>for Customer</span>
                      </div>
                    </div>
                  </>
                )}
                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Min Time
                    </label>
                  </div>
                  <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                    <button type='button' className='btn btn-light btn-sm me-2'>
                      -
                    </button>
                    <div className='d-flex'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm'
                        pattern='[0-9]{2}:[0-9]{2}'
                        title='Enter Duration in HH:MM format'
                        placeholder='(HH:MM)'
                        disabled={!isChecked}
                      />
                    </div>
                    <button type='button' className='btn btn-light btn-sm ms-2'>
                      +
                    </button>
                  </div>
                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 '>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked2}
                      onChange={handleCheckboxChange2}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Max Time
                    </label>
                  </div>
                  <div className='d-flex justify-content-amount align-items-center flex-nowrap'>
                    <button type='button' className='btn btn-light btn-sm me-2'>
                      -
                    </button>
                    <div className='d-flex'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm'
                        pattern='[0-9]{2}:[0-9]{2}'
                        title='Enter Duration in HH:MM format'
                        placeholder='(HH:MM)'
                        disabled={!isChecked2}
                      />
                    </div>
                    <button type='button' className='btn btn-light btn-sm ms-2'>
                      +
                    </button>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg11'
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg11'>
                      Automatically add to Account expenses
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='card-body p-0'>
        <div className='row'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='card-title d-flex align-items-center me-4 mb-3'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center'>
                  <h5 className='text-black fs-4 fw-semibold mb-0'> Expense Manage</h5>
                </div>
                <div className='d-flex flex-wrap fs-6 '>
                  <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                    Add system defaults and turn on/off expense related features
                  </p>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center mb-2'>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Add Expenses</Tooltip>}
              >
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_add_new_expense'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>

        <div className='row mx-1'>
          <div className='col-md-12 col-sm-12 border border-gray-500 border-dotted rounded p-5 mb-3'>
            <div className='d-flex align-items-start flex-column'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>Fuel/Mileage</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_mileage_expense'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Rate per Mile:</strong> $1/mi{' '}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Min Miles:</strong>Not configured{' '}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Max Miles:</strong>Not configured{' '}
                </span>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Interpreters -</strong>
                    Automatically add mileage expense for interpreters.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='1'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan11'
                          className='form-check-input'
                          type='radio'
                          value='2'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Customers -</strong>
                    Automatically add mileage expense for client accounts.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan12'
                          className='form-check-input'
                          type='radio'
                          value='1'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan12'
                          className='form-check-input'
                          type='radio'
                          value='2'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Round-trip -</strong>Automatically double distance from
                    interpreter address to appointment location.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan13'
                          className='form-check-input'
                          type='radio'
                          value='1'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan13'
                          className='form-check-input'
                          type='radio'
                          value='2'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-12 col-sm-12 border border-gray-500 border-dotted rounded p-5'>
            <div className='d-flex align-items-start flex-column'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex fs-3 fw-semibold mb-3'>Travel Time</div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_travel_expense'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='d-flex flex-column mb-2'>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Based on Interpreter/Account Rate</strong>
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Min Time:</strong>Not configured{' '}
                </span>
                <span className='d-flex mb-2'>
                  <strong className='me-2'>Max Time:</strong>Not configured{' '}
                </span>

                <div className='mb-3'>
                  <span className='d-flex mb-2'>
                    <strong className='me-2'>Auto-Expense Appointments for Customers -</strong>
                    Automatically add travel time expense for client accounts.
                  </span>
                  <div className='d-flex'>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan15'
                          className='form-check-input'
                          type='radio'
                          value='1'
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-600 text-hover-primary '
                          style={{fontSize: '12px'}}
                        >
                          On
                        </span>
                      </span>
                    </label>
                    <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                      <span className='form-check form-check-custom form-check-white me-3'>
                        <input
                          name='accountPlan15'
                          className='form-check-input'
                          type='radio'
                          value='2'
                          checked
                        />
                      </span>
                      <span className='d-flex align-items-center me-2'>
                        <span
                          className='text-gray-800 text-hover-primary'
                          style={{fontSize: '12px'}}
                        >
                          Off
                        </span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

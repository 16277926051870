/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

type Props = {
  className: string
}

const LogsInPersonViewTable: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='py-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                <th className='min-w-150px'>Appointment ID</th>
                <th className='min-w-150px'>Requestor</th>
                <th className='min-w-150px '>Interpreter</th>
                <th className='min-w-150px '>Language</th>
                <th className='min-w-150px '>Status</th>
                <th className='min-w-150px '>Type</th>
                <th className='min-w-150px '>Subject</th>
                <th className='min-w-150px '>Date Time</th>
                <th className='min-w-150px '>Requestor Joined</th>
                <th className='min-w-150px '>Interpreter Joined</th>

                <th className='min-w-200px '>Scheduled Duration</th>
                <th className='min-w-250px '>
                  <span className='d-flex align-items-center'>
                    Total Interpretation Duration
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Total duration of all overlapping times when both interpreter and requestor were in a meeting together. This duration that is being used for charging accounts and paying interpreters when settings is Charge/Pay by actual duration'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-muted text-info'
                      />
                    </a>
                  </span>
                </th>
                <th className='min-w-200px '>
                  <span className='d-flex align-items-center'>
                    Total Call Duration
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Total duration of all calls made within Scheduled OPI/VRI appointment. Starts when requestor has joined the room and ends when meeting is explicitly ended by one of participants, or ended by the system due to other participant not being in the room at the scheduled appointment end time. Includes the time when only 1 participant is in the room during the meeting.'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-muted text-info'
                      />
                    </a>
                  </span>
                </th>
                <th className='min-w-150px '>Other Participants</th>
                <th className='min-w-200px '>Conference Duration</th>
                <th className='min-w-150px '>Account Charge</th>
                <th className='min-w-150px '>Interpreter Pay</th>
                <th className='min-w-100px text-end'>Action</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>2453453454</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Cen 23</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Syeda Gilan</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>English -English</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Scheduled</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>
                    Scheduled Telephonic
                  </a>
                </td>
                <td>
                  <a type='button' className='text-gray-800 text-hover-primary d-block fs-6'>
                    Sub123
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>3/5/24 1:30:25</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>00:00:14</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>00:00:00</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>1.64</a>
                </td>

                <td className=''>
                  <a className='text-gray-800 text-hover-primary d-block fs-6 '>5.90</a>
                </td>
                <td>
                  <div className='d-flex flex-end'>
                    <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>2533523568</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Cen 23</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Syeda Gilan</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>English -English</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>Scheduled</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>
                    Scheduled Telephonic
                  </a>
                </td>
                <td>
                  <a type='button' className='text-gray-800 text-hover-primary d-block fs-6'>
                    Sub123
                  </a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>3/5/24 1:30:25</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>00:00:14</a>
                </td>

                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>00:00:00</a>
                </td>
                <td>
                  <a className='text-gray-800 text-hover-primary d-block fs-6'>1.64</a>
                </td>

                <td className=''>
                  <a className='text-gray-800 text-hover-primary d-block fs-6 '>5.90</a>
                </td>
                <td>
                  <div className='d-flex flex-end'>
                    <a className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {LogsInPersonViewTable}

import React, {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const appointment = [
  {value: 'option 1', label: 'New/Initial'},
  {value: 'option 2', label: 'Follow-Up'},
]
const designation = [
  {value: 'option 1', label: 'Standed'},
  {value: 'option 2', label: 'Internal'},
  {value: 'option 3', label: 'External'},
]
const requester = [
  {value: 'option 1', label: 'Aron Chen'},
  {value: 'option 2', label: 'AASA-Flodia'},
  {value: 'option 3', label: 'AASA-Rocio'},
]
const callstatus = [
  {value: 'option 1', label: 'Connected'},
  {value: 'option 2', label: 'Abandoned'},
  {value: 'option 3', label: 'Unfulfilled'},
  {value: 'option 4', label: 'Rollover'},
]
const languages = [
  {value: 'option 1', label: 'English-English'},
  {value: 'option 2', label: 'Achi-Achi'},
  {value: 'option 3', label: 'Acoli-Acoli'},
]

export function LogsOperatorFilterDropdown() {
  return (
    <Dropdown>
      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
        <Dropdown.Toggle
          variant='primary'
          id='dropdown-basic'
          className='btn btn-icon btn-sm  no-caret'
        >
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu className='p-4 w-400px'>
        <div className='px-4 pb-4'>
          <div className='fs-4 text-dark fw-semibolder'>Filter</div>
        </div>

        <div className='separator border-gray-200'></div>
        <div className='card-scroll min-h-200px px-4 mt-4'>
          <div className='row flex-column'>
            <div className='col-sm-12 col-md-12'>
              <div className='mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label mb-1 fs-7'>
                  Requestor
                </label>
                <div className='row g-4 g-xl-4'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='w-100'>
                      <Select
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={requester}
                        placeholder='Select : All'
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-12'>
              <div className='mb-3'>
                <label
                  htmlFor='exampleFormControlInput1'
                  className='form-label mb-1 fs-7 mb-1 fs-7'
                >
                  Call Status
                </label>
                <div className='row g-4 g-xl-4'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='w-100'>
                      <Select
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={callstatus}
                        placeholder='Select : All'
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='fv-row mb-3'>
              <div className='row '>
                <div className='col-sm-6'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    Start Date
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    placeholder='Enter Subject'
                  />
                </div>
                <div className='col-sm-6'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    End Date
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    placeholder='Start Time'
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-12'>
              <div className='mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label mb-1 fs-7'>
                  Languages
                </label>
                <div className='row g-4 g-xl-4'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='w-100'>
                      <Select
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={languages}
                        placeholder='Select : All'
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-end pt-3 px-4'>
          <button
            type='reset'
            className='btn btn-sm btn-light me-2'
            data-kt-menu-dismiss='true'
          >
            Reset
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

import {ListSearch} from './ListSearch'
import {ListToolbar} from './ListToolbar'

const ListHeader = () => {
  return (
    <div className='card-header'>
      <div className='card-title d-flex align-items-center position-relative me-4 '>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <h5 className='text-black fs-4 fw-semibold '>Pre-Call Policies</h5>
          </div>
          <div className='d-flex flex-wrap fs-6'>
            <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
              Manage Pre-Call Policies
            </p>
          </div>
        </div>
      </div>

      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        <ListSearch />
        {/* begin::Group actions */}
        <ListToolbar />
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ListHeader}

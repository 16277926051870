import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Select from 'react-select'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'
/* eslint-disable jsx-a11y/anchor-is-valid */
export function FeatureView() {
  const call = [
    {value: 'option 1', label: 'Eff 1'},
    {value: 'option 2', label: 'Eff 2'},
    {value: 'option 3', label: 'Eff 3'},
  ]
  const interpreters = [
    {value: 'option 1', label: 'AASA 1'},
    {value: 'option 2', label: 'AASA 2'},
    {value: 'option 3', label: 'AASA 3'},
  ]
  const mode = [
    {value: 'option 1', label: 'Prioritize'},
    {value: 'option 2', label: 'Call Only'},
  ]
  const [isRatePerMile, setIsRatePerMile] = useState(true)

  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)
  const [isTravelTime, setIsTravelTime] = useState(true)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_client_acc_rount'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content '>
            <div className='modal-header py-2'>
              <div className='d-flex flex-column mb-3'>
                <h4 className='modal-title'>Edit Access</h4>
                <span>
                  Updating access will force Account users to re-login to application as settings
                  are applied.
                </span>
              </div>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
              <div className='row'>
                <div className='col-md-6 col-sm-12'>
                  <div className='d-flex flex-column mb-2'>
                    <div className='mb-3'>
                      <span className='d-flex mb-2'>
                        <strong className='me-2'>In Person</strong>
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan11'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan11'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan11'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Scheduled Video Remote</strong>
                        {/* <span>
                      Allow interpreters in your pool to dial out to the external numbers and share
                      the link to join the conference
                    </span> */}
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan12'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan12'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan12'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Scheduled Over the Phone</strong>
                        {/* <span>
                      Selection Allow requestor to specify interpreter gender when dialing though
                      IVR
                    </span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan13'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan13'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan13'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Video Remote</strong>
                        {/* <span>Choose who can share their screen.</span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan14'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan14'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan14'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Over the Phone</strong>
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan15'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan15'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan15'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                  <div className='d-flex flex-column mb-2'>
                    <div className='mb-3'>
                      <span className='d-flex mb-2'>
                        <strong className='me-2'>Chat</strong>
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan9'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan9'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan9'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Add Conference Participants - App or Web</strong>
                        {/* <span>
                      Allow interpreters in your pool to dial out to the external numbers and share
                      the link to join the conference
                    </span> */}
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan8'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan8'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan8'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Add Conference Participants - IVR</strong>
                        {/* <span>
                      Selection Allow requestor to specify interpreter gender when dialing though
                      IVR
                    </span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan6'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan6'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan6'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Third Party Platform</strong>
                        {/* <span>Choose who can share their screen.</span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan5'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan5'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan5'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Invoice Management</strong>
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan55'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan55'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan55'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Cancel
              </button>
              <Link to='#'>
                <button type='button' className='btn btn-primary btn-sm'>
                  Save
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='card-body p-0'>
        <div className='card-title d-flex align-items-center me-4 mb-3'>
          <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
              <h5 className='text-black fs-4 fw-semibold mb-0'>Features Setting</h5>
            </div>
            <div className='d-flex flex-wrap fs-6 '>
              <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                Manage Features
              </p>
            </div>
          </div>
          <UnderDevelopmentBadge className='' />
        </div>
        <div className='row g-1 g-xl-1'>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column mb-3'>
                  <div className='d-flex fs-3 fw-semibold'> Account Feature Access</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    Decide what services the account have access to. It overrides system-level
                    settings.
                  </span>
                  <span></span>
                </div>

                <div className='d-flex align-items-center'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_client_acc_rount'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='d-flex flex-column mb-2'>
                    <div className='mb-3'>
                      <span className='d-flex mb-2'>
                        <strong className='me-2'>In Person</strong>
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan11'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan11'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan11'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Scheduled Video Remote</strong>
                        {/* <span>
                      Allow interpreters in your pool to dial out to the external numbers and share
                      the link to join the conference
                    </span> */}
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan12'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan12'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan12'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Scheduled Over the Phone</strong>
                        {/* <span>
                      Selection Allow requestor to specify interpreter gender when dialing though
                      IVR
                    </span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan13'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan13'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan13'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Video Remote</strong>
                        {/* <span>Choose who can share their screen.</span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan14'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan14'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan14'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Over the Phone</strong>
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan15'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan15'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan15'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-md-6'>
                  <div className='d-flex flex-column mb-2'>
                    <div className='mb-3'>
                      <span className='d-flex mb-2'>
                        <strong className='me-2'>Chat</strong>
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan9'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan9'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan9'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Add Conference Participants - App or Web</strong>
                        {/* <span>
                      Allow interpreters in your pool to dial out to the external numbers and share
                      the link to join the conference
                    </span> */}
                      </span>
                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan8'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan8'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan8'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Add Conference Participants - IVR</strong>
                        {/* <span>
                      Selection Allow requestor to specify interpreter gender when dialing though
                      IVR
                    </span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan6'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan6'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan6'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Third Party Platform</strong>
                        {/* <span>Choose who can share their screen.</span> */}
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan5'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan5'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan5'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <span className='d-flex mb-2 d-flex flex-wrap'>
                        <strong className='me-2'>Invoice Management</strong>
                      </span>

                      <div className='d-flex'>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan55'
                              className='form-check-input'
                              type='radio'
                              value='1'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Follow system rule (Currently On)
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2 me-3'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan55'
                              className='form-check-input'
                              type='radio'
                              value='2'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-600 text-hover-primary '
                              style={{fontSize: '12px'}}
                            >
                              On
                            </span>
                          </span>
                        </label>
                        <label className='d-flex align-items-center  mb-0 cursor-pointer mb-2'>
                          <span className='form-check form-check-custom form-check-white me-3'>
                            <input
                              name='accountPlan55'
                              className='form-check-input'
                              type='radio'
                              value='3'
                              checked
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span
                              className='text-gray-800 text-hover-primary'
                              style={{fontSize: '12px'}}
                            >
                              Off
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        <div className='row g-1 g-xl-4'>
          <div className='col-md-12 col-sm-12 '>
            <div className='border border-gray-500 border-dotted rounded p-5 mb-3'>
              <div className='d-flex justify-content-between align-items-center w-100'>
                <div className='d-flex flex-column'>
                  <div className='d-flex fs-3 fw-semibold'>Policies</div>
                  <span className='text-gray-500' style={{fontSize: '12px'}}>
                    This account is a member of following policies.
                  </span>
                </div>
              </div>
              <div className='row g-4 '>
                <div className=''>
                  <div className='table-responsive'>
                    <table className='table table-row-dashed  table-rounded table-row-gray-300 align-middle gs-0 gy-2'>
                      <thead>
                        <tr className='fw-semibold text-muted text-uppercase'>
                          <th className='min-w-100px'>Policies</th>
                          <th className='min-w-150px'>Change Setting</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className=''>
                            <a className='text-gray-800 fs-6'>Language Policies</a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              <strong>OPI</strong> : USCIS All Language List 11/28/22
                            </a>
                            <br></br>
                            <a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              <strong>VRI</strong> : Default
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className=''>
                            <a className='text-gray-800 fs-6 '>Communication Type Policy</a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              Communication Type Custom Policy
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className=''>
                            <a className='text-gray-800 fs-6 '>Service Type Policy</a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              Business
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className=''>
                            <a className='text-gray-800 fs-6 '>Pre-call Policy</a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              Adventist Medical Group (1/16)
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className=''>
                            <a className='text-gray-800 fs-6 '>Appointment Policy</a>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              Conference Platform
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className=''>
                            <a className='text-gray-800 fs-6 '>Zoom Routing Policy</a>
                          </td>
                          <td>
                            {/*<a
                              href='#'
                              className='text-gray-800 text-info fs-6 text-hover-info fw-semibold text-decoration-underline'
                            >
                              Testing with Greg (16/16)
                            </a>*/}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {Outlet, Link, useLocation} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useOktaAuth} from '@okta/okta-react'
import axios from 'axios'
import {useAuth} from './core/Auth'
import {useDeviceInfo} from './components/DeviceInfo'
import {getUserByToken} from './core/_requests'
import toaster from '../../../Utils/toaster'
import {LoadingComponent} from './Loading'

const API_URL = process.env.REACT_APP_API_URL

const AuthLayout = () => {
  const {authState, oktaAuth} = useOktaAuth()
  const [loadingMessage, setLoadingMessage] = useState('Processing')
  const [loadingType, setLoadingType] = useState('R')
  const {saveAuth, setCurrentUser, currentUser, logout} = useAuth()
  const location = useLocation()
  const deviceInfo = useDeviceInfo()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  useEffect(() => {
    if (location && authState) {
      if (!authState?.isAuthenticated) {
        setLoadingMessage('Autherization redirecting')
        setLoadingType('R')

        const timer = setTimeout(() => {
          oktaAuth.signInWithRedirect()
        }, 2000) // 02 seconds delay

        // Cleanup function to clear the timer if the component unmounts or dependencies change
        return () => clearTimeout(timer)
      } else {
        setLoadingType('V')
        setLoadingMessage('Autherization Verifying')

        const timer = setTimeout(() => {
          handleOktaLogin(authState?.accessToken?.accessToken)
        }, 2000) // 02 seconds delay

        // Cleanup function to clear the timer if the component unmounts or dependencies change
        return () => clearTimeout(timer)
      }
    }
  }, [authState, location])

  const handleOktaLogin = async (accessToken: any) => {
    try {
      const {data: auth} = await axios.post(`${API_URL}/accounts/token-okta`, {
        token: accessToken,
      })
      if (auth.isAuthenticated === false) {
        toaster('error', 'Unauthorized!')
        saveAuth(undefined)
        try {
          const result = await oktaAuth.signOut()
          if (result) {
            logout()
          }
        } catch (error) {
          console.log('error', error)
        }
      } else {
        saveAuth(auth)
        try {
          const {data: user} = await getUserByToken()
          setCurrentUser({...user, notNowTimeZoneChanging: false})
        } catch (error) {
          try {
            const result = await oktaAuth.signOut()
            if (result) {
              logout()
            }
          } catch (error) {
            console.log('error', error)
          }
        }
      }
    } catch (error) {
      console.error(error)
      saveAuth(undefined)
      try {
        const result = await oktaAuth.signOut()
        if (result) {
          logout()
        }
      } catch (error) {
        console.log('error', error)
      }
      // setStatus('The login detail is incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-20 bgi-size-contain bgi-position-center no-repeat'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.jpg')})`}}
      >
        <div className='overlay' style={{position: 'absolute'}}></div>
        {/* begin::Content */}
        <div className='d-flex flex-column flex-start align-items-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/loginlogo.png')} className='h-200px' />
          </Link>
          {/* end::Logo */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Body */}
      <LoadingComponent title={loadingMessage} loadingType={loadingType} />
    </div>
  )
}

export {AuthLayout}

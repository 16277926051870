import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../../_metronic/assets/ts/components'
import {KTSVG, ModelQUERIES} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {activeStatusChangeDataRecord} from '../../core/_requests'
import {PreCallPolicy} from '../../core/_models'
import {useNavigate} from 'react-router-dom'

type Props = {
  id: any
  value?: any
  dbObj: PreCallPolicy
}

const ActionsCell: FC<Props> = ({id, value, dbObj}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }
  const handleClick = () => {
    navigate(`precalls/${dbObj.id}?name=${dbObj.name}`)
  }

  // const statusData: StatusChangemodel = {
  //   value: value,
  //   id: code,
  // };

  const statusChangeItem = useMutation(() => activeStatusChangeDataRecord(id), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${ModelQUERIES.DATA_LIST}-${query}`])
    },
  })

  return (
    <>
      <div className='d-flex justify-content-end flex-shrink-0'>
        <a
          href='#'
          type='button'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={openEditModal}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>

        <a
          href='#'
          type='button'
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          id='addicon'
          onClick={handleClick}
        >
          <KTSVG path='/media/icons/duotune/general/gen041.svg' className='svg-icon-2' />
        </a>
      </div>
    </>
  )
}

export {ActionsCell}

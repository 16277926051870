import {type} from 'os'
import {ID, Response} from '../../../../../_metronic/helpers'

export type Location = {
  code?: ID
  name?: string
  address?: string
  isActive?: boolean
  fK_CustomerCode?: number
  city ?: string
  state ?: string
  country ?: string
  postalCode ?: string
  latitude ?: number
  longitude ?: number
  street1 ?: string
  street2 ?: string
}

export type CustomDictionaryModel = {
  key?: string
  value?: string
}

export type UserRole = {
  id?: string
  name?: string
  normalizedName?: string
  status: string
  userId?: string
  userName?: string
}

export type Deletemodel = {
  email?: string
  code?: string
}

export type UsersQueryResponse = Response<Array<Location>>
export type RolesQueryResponse = Response<Array<UserRole>>
export type DropdownResponse = Response<Array<CustomDictionaryModel>>

export const initialUser: Location = {
  address: '',
  city: '',
  code: undefined,
  country: '',
  fK_CustomerCode: 0,
  latitude: -3.745,
  longitude: -38.523,
  name: '',
  postalCode: '',
  state: '',
  street1: '',
  street2: '',
  isActive: true,
}

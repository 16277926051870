import {FC, useState, useEffect} from 'react'
import {Location} from '../../core/_models'
import {useListView} from '../../core/ListViewProvider'
import {UsersListToolbar} from './UserListToolbar'
import {UsersListGrouping} from './UsersListGrouping'
import {UsersListSearchComponent} from './UsersListSearchComponent'
import {KTSVG, toAbsoluteUrl, roleQUERIES} from '../../../../../../_metronic/helpers'
import {sendInvitation} from '../../core/_requests'
import {getCustomers} from '../../../../Common/components/core/_requests'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useQuery} from 'react-query'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import clsx from 'clsx'

type Props = {
  isUserLoading?: boolean
  userType: string
  customerCode?: number
}

const UsersListHeader: React.FC<Props> = ({userType, isUserLoading, customerCode}) => {
  const [data, setData] = useState<string[]>([])
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const {selected} = useListView()
  const editUserSchema = Yup.object().shape({
    email: Yup.string().email().min(2, 'Too Short!').max(500, 'Too Long!').required('Required'),
    fK_Customer: Yup.string().required('Required'),
  })

  const [userForEdit] = useState<Location>({})

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    () => {
      return getCustomers(userType, customerCode ?? 0)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  // const func_addemail = (params: string) => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     if (!data.includes(params)) {
  //       data.push(params)
  //       setData(data);
  //     }
  //     setEmail('');
  //     setLoading(false);
  //   }, 10);
  // }

  // const func_removeemail = (params: string) => {
  //   setLoading(true)
  //   setTimeout(() => {
  //     var restData = data.filter(function (item) {
  //       return item !== params
  //     })
  //     setData(restData);
  //     setEmail('');
  //     setLoading(false);
  //   }, 10);
  // }

  return (
    <div className='card-header align-items-center'>
      {/* style={{backgroundColor: '#FAF7F5'}} */}
      <div className='card-title d-flex align-items-center position-relative me-4 '>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <h5 className='text-black fs-4 fw-semibold '>Locations</h5>
          </div>
          <div className='d-flex flex-wrap fs-6'>
            <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
              Management office Location
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className='card-toolbar'>
          <div className='d-flex align-items-center position-relative me-1'>
            <UsersListSearchComponent />
          </div>
          <div>
            {selected.length > 0 ? <UsersListGrouping /> : <UsersListToolbar />}
          </div>
        </div>
      </div>
    </div>
  )
}

export {UsersListHeader}

import React, {useEffect, useState} from 'react'
import TableRow from './TableRow'
import axios from 'axios'
import {CommonPaginationModel} from '../../../../Utils/commonPagination'
import {useAuth} from '../../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL

// Define the interface for the row data
interface RowData {
  id: number
  fK_LanguageFrom: string
  languageFrom: string
  fK_LanguageTo: any[]
  fK_ServiceType: any[]
  fK_CommunicationType: any[]
  startTime: string
  endTime: string
  minimumBillableHours: number
  minimumBillableMinutes: number
  incrementsHours: number
  incrementsMinutes: number
  days: any[]
  regularRate: string
  rushRate: string
  emergencyRate: string
  conferenceCharges: string
  isActive: boolean
}

// Define the props for the TableRow component
interface TableRowProps {
  data: RowData
  onSave: (updatedRow: RowData) => void
}

// Define the props for the EditableTable component
interface EditableTableProps {
  initialRows: RowData[]
  fetchAllCustomerRate: any
  rateCategory: string
  currentPage?: any
  totalPages?: any
  rowsPerPage?: any
  totalItems?: any
  setRowsPerPage?: any
  setCurrentPage?: any
}

const EditableTable: React.FC<EditableTableProps> = ({
  initialRows,
  fetchAllCustomerRate,
  rateCategory,
  currentPage,
  totalPages,
  rowsPerPage,
  totalItems,
  setRowsPerPage,
  setCurrentPage,
}) => {
  const {currentUser} = useAuth()
  const [languages, setLanguages] = useState([])
  const [serviceTypes, setServiceTypes] = useState([])
  const [communicationTypes, setCommunicationTypes] = useState([])

  const fetchLanguages = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/languages/active-shortlist`)
      setLanguages(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllServices = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/SERVICE_TYPE`)
      setServiceTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllCommunicationType = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/COMMUNICATION_TYPE`)
      setCommunicationTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchLanguages()
    fetchAllServices()
    fetchAllCommunicationType()
  }, [])

  return (
    <>
      <div className='table-responsive' style={{minHeight: '28vh'}}>
        <table className='table table-row-dashed table-row-gray-300 table-hover align-middle gs-2 gy-2'>
          <thead>
            <tr className='fw-semibold text-muted text-uppercase'>
              <th className='min-w-150px'>From Language</th>
              <th className='min-w-250px'>To Language</th>
              <th className='min-w-300px'>Communication Type</th>
              <th className='min-w-300px'>Service Type</th>
              <th className='min-w-100px'>Start Time</th>
              <th className='min-w-100px'>End Time</th>
              <th className='min-w-350px'>Minimum Billable</th>
              <th className='min-w-350px'>Increments</th>
              <th className='min-w-300px'>Days</th>
              <th className='min-w-100px'>Regular</th>
              <th className='min-w-100px'>Rush</th>
              <th className='min-w-100px'>Emergency</th>
              {rateCategory === 'C' && <th className='min-w-200px'>Conference Charge</th>}
              <th className='min-w-100px'>Status</th>
              {currentUser?.result.userType == 'SYSTEM' && (
                <th
                  className='min-w-100px text-end'
                  style={{position: 'sticky', right: 0, background: 'white'}}
                >
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {initialRows?.length > 0 ? (
              initialRows.map((row) => (
                <TableRow
                  key={row.id}
                  data={row}
                  languages={languages}
                  serviceTypes={serviceTypes}
                  communicationTypes={communicationTypes}
                  fetchAllCustomerRate={fetchAllCustomerRate}
                  rateCategory={rateCategory}
                />
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                    <div className='text-center'>
                      <div className='symbol symbol-200px '>
                        <img src='/media/other/nodata.png' alt='' />
                      </div>
                    </div>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                      No matching records found
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CommonPaginationModel
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        total={totalItems}
        onPageChange={setCurrentPage}
        onRowsPerPageChange={(newRowsPerPage: number) => setRowsPerPage(newRowsPerPage)}
      />
    </>
  )
}

export default EditableTable

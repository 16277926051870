// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {LanguageModel} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<LanguageModel>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => (
  //     <UserSelectionCell
  //       userType='USER'
  //       status={props.data[props.row.index].status}
  //       id={props.data[props.row.index].code}
  //     />
  //   ),
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='From Language' className='min-w-125px' />
    ),
    accessor: 'languageFromName',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='To Language' className='min-w-125px' />
    ),
    accessor: 'languageToName',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Service Type' />,
    id: 'specializedTypes',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Exp' className='min-w-125px' />,
    accessor: 'yearOfExp',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' />,
    id: 'status',
    Cell: ({...props}) => (
      <>
        {props.data[props.row.index].status == 'PENDING' ? (
          <span
            className='badge badge-light-warning px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            {props.data[props.row.index].status}
          </span>
        ) : (
          <span
            className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            {props.data[props.row.index].status}
          </span>
        )}
      </>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        code={props.data[props.row.index].code}
        userType='USER'
        status={props.data[props.row.index].status}
      />
    ),
  },
]

const usersColumns_admin: ReadonlyArray<Column<LanguageModel>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => (
  //     <UserSelectionCell
  //       userType='ADMIN'
  //       status={props.data[props.row.index].status}
  //       id={props.data[props.row.index].code}
  //     />
  //   ),
  // },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='From Language' className='min-w-125px' />
    ),
    accessor: 'languageFromName',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='To Language' className='min-w-125px' />
    ),
    accessor: 'languageToName',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='service type' />,
    id: 'specializedTypes',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Exp' className='min-w-125px' />,
    accessor: 'yearOfExp',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' />,
    id: 'status',
    Cell: ({...props}) => (
      <>
        {props.data[props.row.index].status == 'PENDING' ? (
          <span
            className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            {props.data[props.row.index].status}
          </span>
        ) : (
          <span
            className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            {props.data[props.row.index].status}
          </span>
        )}
      </>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Action' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        code={props.data[props.row.index].code}
        userType='ADMIN'
        status={props.data[props.row.index].status}
      />
    ),
  },
]

const usersColumns_visitor: ReadonlyArray<Column<LanguageModel>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='From Language' className='min-w-125px' />
    ),
    accessor: 'languageFromName',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='To Language' className='min-w-125px' />
    ),
    accessor: 'languageToName',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Service Type' />,
    id: 'specializedTypes',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },

  // {
  //   Header: (props) => <UserCustomHeader tableProps={props} title='Exp' className='min-w-125px' />,
  //   accessor: 'yearOfExp',
  // },
]

export {usersColumns, usersColumns_admin, usersColumns_visitor}

import axios, {AxiosResponse} from 'axios'
import {ID, Response, ResponseObject} from '../../../../../_metronic/helpers'
import {
  User,
  CustomersQueryResponse,
  ChangePasswordModel,
  Deletemodel,
  DropdownResponse,
  UserMasterInfoResponse,
  UserAdditionalInfo,
  userMasterInfoModel,
  ChangeEmailModel,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const Customer_URL = `${API_URL}/customer/Insert`
const GET_CustomerS_URL = `${API_URL}/customer/Getall`
const GET_RoleS_URL = `${API_URL}/roles/getall-shortlist`
const GET_USER_DETAILS_URL = `${API_URL}/accounts/GetAccountDetailByID`
const GET_USER_MORE_DETAILS_URL = `${API_URL}/accounts/additional`
const GET_USER_MORE_DETAILS_MASTER_URL = `${API_URL}/master/user-additional`

const UPDATE_CUSTOMER_URL = `${API_URL}/accounts/update-user-profile`
const UPDATE_USER_ADDITIONAL_URL = `${API_URL}/accounts/additional-update`
const Delete_CUSTOMERBY_URL = `${API_URL}/customer/DeleteCustomerAccount`
const GET_ALL_COUNTRY = `${API_URL}/country/getall`
const GET_ALL_MASTER = `${API_URL}/master/getall`
const CHANGE_PASSWORD_URL = `${API_URL}/accounts/password-change`
const CHANGE_EMAIL_URL = `${API_URL}/accounts/email-change`

const POST_INV_EMAILS = `${API_URL}/customer/send-invitation`
const GET_ALL_CUSTOMER_LOCATION = `${API_URL}/customer`
const Customer_REINVITE_URL = `${API_URL}/customer/re-invite`
const Update_UERSBY_URL = `${API_URL}/accounts/Update`

const sendInvitation = (Customer: User): Promise<User | undefined> => {
  return axios
    .post(POST_INV_EMAILS, Customer)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const changePassword = (model: ChangePasswordModel): Promise<ChangePasswordModel | undefined> => {
  return axios
    .post(CHANGE_PASSWORD_URL, model)
    .then((response: AxiosResponse<Response<ChangePasswordModel>>) => response.data)
    .then((response: Response<ChangePasswordModel>) => response.data)
}

const changeEmailRequest = (model: ChangeEmailModel): Promise<ChangeEmailModel | undefined> => {
  return axios
    .post(CHANGE_EMAIL_URL, model)
    .then((response: AxiosResponse<Response<ChangeEmailModel>>) => response.data)
    .then((response: Response<ChangeEmailModel>) => response.data)
}

const getCustomers = (query: string): Promise<CustomersQueryResponse> => {
  var dt = axios
    .get(`${GET_CustomerS_URL}/?${query}`)
    .then((d: AxiosResponse<CustomersQueryResponse>) => d.data)
  return dt
}

const getCustomerLocations = (Code: number): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_CUSTOMER_LOCATION}/${Code}}/location/getall-shortlist`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getMaster = (code: string): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_MASTER}/${code}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}
const getadditionalMaster = (): Promise<UserMasterInfoResponse> => {
  var dt = axios
    .get(`${GET_USER_MORE_DETAILS_MASTER_URL}`)
    .then((d: AxiosResponse<UserMasterInfoResponse>) => d.data)
  return dt
}

const getUserByID = (id: ID): Promise<User | undefined> => {
  var dr = axios
    .get(`${GET_USER_DETAILS_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
  return dr
}

const getUserAdditionalInfo = (id: ID): Promise<UserAdditionalInfo | undefined> => {
  var dr = axios
    .get(`${GET_USER_MORE_DETAILS_URL}/${id}`)
    .then((response: AxiosResponse<Response<UserAdditionalInfo>>) => response.data)
    .then((response: Response<UserAdditionalInfo>) => response.data)
  return dr
}

const createCustomer = (UserModel: User): Promise<User | undefined> => {
  return axios
    .post(Customer_URL, UserModel)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUserInfo = (UserModel: FormData): Promise<ResponseObject | undefined> => {
  //  axios.defaults.headers.Accept = 'application/json'
  // axios.interceptors.request.use(
  //   (config: {headers: {Authorization: string}}) => {
  //     const auth = getAuth()
  //     if (auth && auth.token) {
  //       config.headers.Authorization = `Bearer ${auth.token}`
  //     }

  //     return config
  //   },
  //   (err: any) => Promise.reject(err)
  // )

  return axios
    .put(UPDATE_CUSTOMER_URL, UserModel, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}
const updateUser = (user: FormData): Promise<ResponseObject | undefined> => {
  return axios
    .post(Update_UERSBY_URL, user, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}
const updateadditionalInfo = (
  UserModel: UserAdditionalInfo
): Promise<UserAdditionalInfo | undefined> => {
  return axios
    .put(UPDATE_USER_ADDITIONAL_URL, UserModel)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteCustomer = (Id: ID): Promise<ID | undefined> => {
  return axios
    .delete(`${Delete_CUSTOMERBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const deleteSelectedCustomers = (CustomerIds: Array<ID>): Promise<void> => {
  const requests = CustomerIds.map((id) => axios.delete(`${Delete_CUSTOMERBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const reinvite = (CustomerID: string): Promise<User | undefined> => {
  return axios
    .post(`${Customer_REINVITE_URL}/${CustomerID}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

export {
  getCustomers,
  deleteCustomer,
  deleteSelectedCustomers,
  getUserByID,
  createCustomer,
  updateUserInfo,
  getMaster,
  getCustomerLocations,
  sendInvitation,
  reinvite,
  changePassword,
  getUserAdditionalInfo,
  getadditionalMaster,
  updateadditionalInfo,
  updateUser
}

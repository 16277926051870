import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../../_metronic/helpers'
import {ModelQueryResponse, PreCallPolicy, ResponseObject} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const POST_Obj_URL = `${API_URL}/precalls`
const GET_Objs_URL = `${API_URL}/precalls`
const GET_ObjBY_URL = `${API_URL}/PreCalls`
const Update_ObjBY_URL = `${API_URL}/PreCalls`
const ActiveStatusChange_ObjBY_URL = `${API_URL}/precalls/isactive-update-languages`
const GET_PreCallPoliciesByCustomer = `${API_URL}/precalls/pre-calls-policies`

const getAllData = (query: string): Promise<ModelQueryResponse> => {
  var dt = axios
    .get(`${GET_Objs_URL}?${query}`)
    .then((d: AxiosResponse<ModelQueryResponse>) => d.data)
  return dt
}

const getAllPoliciesByCustomerId = (
  query: string,
  customerId: string
): Promise<ModelQueryResponse> => {
  var dt = axios
    .get(`${GET_PreCallPoliciesByCustomer}/${customerId}?${query}`)
    .then((d: AxiosResponse<ModelQueryResponse>) => d.data)
  return dt
}

const getDataRecordById = (id: ID): Promise<PreCallPolicy | undefined> => {
  var dr = axios
    .get(`${GET_ObjBY_URL}/${id ?? 'null'}`)
    .then((response: AxiosResponse<Response<PreCallPolicy>>) => response.data)
    .then((response: Response<PreCallPolicy>) => response.data)
  return dr
}

const createDataRecord = (dbObj: PreCallPolicy): Promise<ResponseObject | undefined> => {
  return axios
    .post(POST_Obj_URL, dbObj)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const updateDataRecord = (dbObj: PreCallPolicy): Promise<ResponseObject | undefined> => {
  return axios
    .put(Update_ObjBY_URL, dbObj)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const activeStatusChangeDataRecord = (Id: ID): Promise<ID | undefined> => {
  return axios
    .put(`${ActiveStatusChange_ObjBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}
const activeStatusChangeSelectedDataRecords = (Ids: Array<ID>): Promise<void> => {
  const requests = Ids.map((id) => axios.put(`${ActiveStatusChange_ObjBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getAllData,
  activeStatusChangeDataRecord,
  activeStatusChangeSelectedDataRecords,
  getDataRecordById,
  createDataRecord,
  updateDataRecord,
  getAllPoliciesByCustomerId,
}

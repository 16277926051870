import axios, {AxiosResponse} from 'axios'
import {ID, Response, ResponseObject} from '../../../../../_metronic/helpers'
import {
  RolesQueryResponse,
  Location,
  UsersQueryResponse,
  Deletemodel,
  DropdownResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const CREATE_LOCATION_URL = `${API_URL}/customer/location/Insert`
const GET_USERS_URL = `${API_URL}/customer/`
const GET_RoleS_URL = `${API_URL}/roles/getall-shortlist`
const GET_UERSBY_URL = `${API_URL}/customer/location`
const Update_UERSBY_URL = `${API_URL}/customer/location/Update`
const Delete_UERSBY_URL = `${API_URL}/Accounts/DeleteUserAccount`
const DELETE_USER_LOCATIONS = `${API_URL}/customer/DeleteCustomerLocation`
const GET_ALL_COUNTRY = `${API_URL}/country/getall`
const GET_ALL_MASTER = `${API_URL}/master/getall`
const GET_ALL_CUSTOMER_SHORTLIST = `${API_URL}/customer/getall-shortlist`
const POST_INV_EMAILS = `${API_URL}/accounts/send-invitation`
const GET_ALL_CUSTOMER_LOCATION = `${API_URL}/customer`
const USER_REINVITE_URL = `${API_URL}/accounts/re-invite`

const sendInvitation = (user: Location): Promise<Location | undefined> => {
  return axios
    .post(POST_INV_EMAILS, user)
    .then((response: AxiosResponse<Response<Location>>) => response.data)
    .then((response: Response<Location>) => response.data)
}

const getUsers = (
  query: string,
  userType: string,
  customerCode?: number
): Promise<UsersQueryResponse> => {
  var dt = axios
    .get(
      `${GET_USERS_URL}${isNaN(customerCode ?? 0) ? '1' : customerCode}/location/getall/?${query}`
    )
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return dt
}

const getCountries = (): Promise<DropdownResponse> => {
  var dt = axios.get(`${GET_ALL_COUNTRY}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getCustomerLocations = (Code: number): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_CUSTOMER_LOCATION}/${Code}}/location/getall-shortlist`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getMaster = (code: string): Promise<DropdownResponse> => {
  var dt = axios
    .get(`${GET_ALL_MASTER}/${code}`)
    .then((d: AxiosResponse<DropdownResponse>) => d.data)
  return dt
}

const getUserById = (id: ID): Promise<Location | undefined> => {
  var dr = axios
    .get(`${GET_UERSBY_URL}/${id ?? 0}`)
    .then((response: AxiosResponse<Response<Location>>) => response.data)
    .then((response: Response<Location>) => response.data)
  return dr
}

const getUserroles = (usertype: string): Promise<RolesQueryResponse> => {
  var dt = axios
    .get(`${GET_RoleS_URL}/${usertype}`)
    .then((d: AxiosResponse<RolesQueryResponse>) => d.data)
  return dt
}

const createUser = (user: Location): Promise<ResponseObject | undefined> => {
  return axios
    .post(CREATE_LOCATION_URL, user)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const updateUserInfo = (UserModel: Location): Promise<ResponseObject | undefined> => {
  return axios
    .put(Update_UERSBY_URL, UserModel)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const deleteUser = (Id: ID): Promise<ID | undefined> => {
  return axios
    .delete(`${Delete_UERSBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  return axios.delete(`${DELETE_USER_LOCATIONS}`, {data: userIds}).then(() => {})
}

const reinvite = (userID: string): Promise<Location | undefined> => {
  return axios
    .post(`${USER_REINVITE_URL}/${userID}`)
    .then((response: AxiosResponse<Response<Location>>) => response.data)
    .then((response: Response<Location>) => response.data)
}

export {
  getUsers,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  getUserroles,
  getCountries,
  getMaster,
  getCustomerLocations,
  sendInvitation,
  reinvite,
  updateUserInfo,
}

import React, {useState, useEffect} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {KTSVG} from '../../../../../../_metronic/helpers/components/KTSVG'
import axios, {AxiosResponse} from 'axios'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../core/_models'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {QueryResponseProvider, useQueryResponse} from '../../core/QueryResponseProvider'
import {createContext} from 'react'
import context from 'react-bootstrap/esm/AccordionContext'

const statusOptions = [
  {value: 'ACTIVE', label: 'Active'},
  {value: 'DESABLE', label: 'Inactive'},
]

export const mycontext = createContext({})

export function UserListFilterDropdown() {
  const [selectedCountry, setSelectedCountry] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedStatus, setSelectedStatus] = useState<MultiValue<{value: string; label: string}>>(
    []
  )
  const {refetch} = useQueryResponse()
  const API_URL = process.env.REACT_APP_API_URL
  const GET_ALL_COUNTRY_NAME = `${API_URL}/country/getall`
  const [contextValue, setContextValue] = useState({})
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)

  const getCountry = (): Promise<DropdownResponse> => {
    return axios.get(GET_ALL_COUNTRY_NAME).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCountry, data: responseCountry} = useQuery(
    `Country_LIST`,
    getCountry,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const countryOptions =
    responseCountry?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleReset = () => {
    setSelectedStatus([])
    setSelectedCountry([])
    setShowDropdownMenu(false)
    setContextValue({})
    refetch()
  }

  const handleSubmit = () => {
    const payload = {
      status: selectedStatus.map((option) => option.value),
      countries: selectedCountry.map((option) => option.value),
    }
    setContextValue(payload)
    setShowDropdownMenu(false)
  }

  return (
    <mycontext.Provider value={contextValue}>
      <QueryResponseProvider userType={''} />
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className='btn btn-icon btn-sm no-caret'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 max-w-300px w-300px' style={{minHeight: '100%'}}>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>

            <div className='separator border-gray-200'></div>
            <div className='px-4 mt-4'>
              <div className='row flex-column'>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='statusSelect' className='form-label fs-7 mb-0'>
                      Status
                    </label>

                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={statusOptions}
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                      isMulti
                      placeholder='Select : All'
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                        menu: (provided) => ({
                          ...provided,
                          zIndex: 9999,
                        }),
                      }}
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='countryInput' className='form-label fs-7 mb-0'>
                      Country
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={!isFetchingCountry ? countryOptions : []}
                      placeholder='Select : All'
                      isMulti
                      value={selectedCountry}
                      onChange={setSelectedCountry}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='text-end mt-3 px-4'>
              <button type='button' className='btn btn-sm btn-light me-2' onClick={handleReset}>
                Reset
              </button>
              <button type='button' className='btn btn-sm btn-primary' onClick={handleSubmit}>
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </mycontext.Provider>
  )
}

import React, {FC, useState} from 'react'
import * as Yup from 'yup'
import Select from 'react-select'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import {LanguageModel} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createUser, getadditionalMaster, updateUserInfo} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useQuery} from 'react-query'
import {getNativeLanguage} from '../../../Common/components/core/_requests'
import toaster from '../../../../../Utils/toaster'

type Props = {
  isUserLoading: boolean
  user: LanguageModel
  userType: string
  customerCode: string
}

const UserEditModalForm: FC<Props> = ({user, isUserLoading, userType, customerCode}) => {
  const {setItemIdForUpdate, itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [userForEdit] = useState<LanguageModel>({
    ...user,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const editUserSchema = Yup.object().shape({
    languageFrom: Yup.string().required('required').nullable(),
    languageTo: Yup.string()
      .required('required')
      .nullable()
      .test('not-same', 'Language From and Language To must be different', function (value) {
        const {languageFrom} = this.parent
        return value !== languageFrom
      }),
    specializedTypes: Yup.array().min(1, 'required').required('required'),
    yearOfExp: Yup.number().required('required').min(0, 'Must be a positive number'),
  })

  const {
    isLoading: isLoadingMasterInfo,
    data: userMasterIfo,
    error: errorMasterinfo,
    refetch: refetchMasterInfo,
  } = useQuery(
    `customer-additional-master`,
    () => {
      return getadditionalMaster()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    () => {
      return getNativeLanguage()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const formik = useFormik({
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      values.fK_User = customerCode
      values.userType = userType
      if (values.isDeleted + '' === 'true' || values.isDeleted + '' == 'false') {
        values.isDeleted = JSON.parse(values.isDeleted + '')
      }
      setSubmitting(true)
      try {
        let result: any
        if (isNotEmpty(values.code)) {
          result = await updateUserInfo(values)
          if (result?.status == 'S') {
            toaster('success', result?.text ?? 'Successfully updated')
            cancel(true)
          } else if (result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not Updated')
          }
        } else {
          result = await createUser(values)
          if (result?.status == 'S') {
            toaster('success', result?.text ?? 'Successfully created')
            cancel(true)
          } else if (result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not created')
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const languageOptions = nativeLanguageList?.data?.map((d) => ({
    value: d.key,
    label: d.value,
  }))

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        {/**---------- user languge add modal content ------------------ */}
        {itemIdForUpdate == undefined && userType == 'USER' && (
          <div
            className='d-flex flex-column '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='mb-0'>
              <div className='row mb-0'>
                <div className='col-lg-12 '>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Language From
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <Select
                        {...formik.getFieldProps('languageFrom')}
                        name='languageFrom'
                        isDisabled={formik.isSubmitting || isUserLoading}
                        placeholder='Select a Language'
                        value={
                          languageOptions?.find(
                            (option) => option.value === formik.values.languageFrom
                          ) || null
                        }
                        onChange={(option) =>
                          formik.setFieldValue('languageFrom', option?.value || '')
                        }
                        options={!isFetchingNativeLanguage ? languageOptions : []}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                        onBlur={() => formik.setFieldTouched('languageFrom', true)}
                      />
                      {formik.touched.languageFrom && formik.errors.languageFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.languageFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-0'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Language To
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <Select
                        {...formik.getFieldProps('languageTo')}
                        name='languageTo'
                        isDisabled={formik.isSubmitting || isUserLoading}
                        placeholder='Select a Language'
                        value={
                          languageOptions?.find(
                            (option) => option.value === formik.values.languageTo
                          ) || null
                        }
                        onChange={(option) =>
                          formik.setFieldValue('languageTo', option?.value || '')
                        }
                        options={!isFetchingNativeLanguage ? languageOptions : []}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                        onBlur={() => formik.setFieldTouched('languageTo', true)}
                      />

                      {formik.touched.languageTo && formik.errors.languageTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.languageTo}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-0'>
              <div className='row mb-0'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Service Type
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <Select
                        {...formik.getFieldProps('specializedTypes')}
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={userMasterIfo?.data?.specializedTypes}
                        onBlur={() => formik.setFieldTouched('specializedTypes', true)}
                        onChange={(e) => formik.setFieldValue('specializedTypes', e)}
                        placeholder='Service Type'
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: '1px solid #e4e6ef',
                            color: '#5e6278',
                            minHeight: '33px',
                          }),
                        }}
                      />
                      {formik.touched.specializedTypes && formik.errors.specializedTypes && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.specializedTypes}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='fv-row mb-0'>
              <div className='row mb-4'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Years of Exp
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        {...formik.getFieldProps('yearOfExp')}
                        type='number'
                        className='form-control form-control-white form-select-sm'
                        placeholder='Years of Exp'
                      />
                      {formik.touched.yearOfExp && formik.errors.yearOfExp && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.yearOfExp}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/**---------- languge edit modal content ------------------ */}
        {itemIdForUpdate != undefined && (
          <div
            className='d-flex flex-column '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='mb-0'>
              <div className='row mb-0'>
                <div className='col-lg-12 '>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Language From
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <Select
                        {...formik.getFieldProps('languageFrom')}
                        name='languageFrom'
                        isDisabled={formik.isSubmitting || isUserLoading || userType == 'ADMIN'}
                        placeholder='Select a Language'
                        value={
                          languageOptions?.find(
                            (option) => option.value === formik.values.languageFrom
                          ) || null
                        }
                        onChange={(option) =>
                          formik.setFieldValue('languageFrom', option?.value || '')
                        }
                        options={!isFetchingNativeLanguage ? languageOptions : []}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                        onBlur={() => formik.setFieldTouched('languageFrom', true)}
                      />
                      {formik.touched.languageFrom && formik.errors.languageFrom && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.languageFrom}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-0'>
              <div className='row'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Language To
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <Select
                        {...formik.getFieldProps('languageTo')}
                        name='languageTo'
                        isDisabled={formik.isSubmitting || isUserLoading || userType == 'ADMIN'}
                        placeholder='Select a Language'
                        value={
                          languageOptions?.find(
                            (option) => option.value === formik.values.languageTo
                          ) || null
                        }
                        onChange={(option) =>
                          formik.setFieldValue('languageTo', option?.value || '')
                        }
                        options={!isFetchingNativeLanguage ? languageOptions : []}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                        onBlur={() => formik.setFieldTouched('languageTo', true)}
                      />

                      {formik.touched.languageTo && formik.errors.languageTo && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.languageTo}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-0'>
              <div className='row mb-0'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Service Type
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <Select
                        {...formik.getFieldProps('specializedTypes')}
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={userMasterIfo?.data?.specializedTypes}
                        onBlur={() => formik.setFieldTouched('specializedTypes', true)}
                        onChange={(e) => formik.setFieldValue('specializedTypes', e)}
                        placeholder='Service Type'
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: '1px solid #e4e6ef',
                            color: '#5e6278',
                            minHeight: '33px',
                          }),
                        }}
                      />
                      {formik.touched.specializedTypes && formik.errors.specializedTypes && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.specializedTypes}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='fv-row mb-0'>
              <div className='row mb-4'>
                <div className='col-lg-12 fv-row'>
                  <label className='col-lg-6 col-form-label required fw-semibold'>
                    Years of Exp
                  </label>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        {...formik.getFieldProps('yearOfExp')}
                        type='number'
                        className='form-control form-control-white form-select-sm'
                        placeholder='Years of Exp'
                      />
                      {formik.touched.yearOfExp && formik.errors.yearOfExp && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.yearOfExp}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/**---------- user languge add modal content ------------------ */}

        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='text-end mt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3 btn-sm'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Discard
          </button>

          {formik.values.isDeleted && (
            <button
              type='submit'
              className='btn btn-danger btn-sm'
              data-kt-users-modal-action='submit'
              disabled={isUserLoading || formik.isSubmitting}
            >
              <span className='indicator-label'>Delete</span>
            </button>
          )}

          {!formik.values.isDeleted && userType == 'ADMIN' && itemIdForUpdate == undefined && (
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              data-kt-users-modal-action='submit'
              disabled={isUserLoading || formik.isSubmitting}
            >
              <span className='indicator-label'>Approve & Save New </span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {!formik.values.isDeleted && userType == 'ADMIN' && itemIdForUpdate != undefined && (
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              data-kt-users-modal-action='submit'
            >
              <span className='indicator-label'> Save </span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}

          {!formik.values.isDeleted && userType == 'USER' && (
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              data-kt-users-modal-action='submit'
              disabled={isUserLoading || formik.isSubmitting}
            >
              <span className='indicator-label'>Save</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}

import React, {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'

const scope = [
  {value: 1, label: 'Today'},
  {value: 2, label: 'Yesterday'},
  {value: 3, label: 'Current Week'},
  {value: 4, label: 'Last Week'},
  {value: 5, label: 'Current Month'},
  {value: 6, label: 'Last Month'},
  {value: 7, label: 'Current Year'},
  {value: 8, label: 'Last Year'},
]
const communication = [
  {value: 'Voice', label: 'OPI'},
  {value: 'Video', label: 'VRI'},
  {value: 'OSI', label: 'OSI'},
]

export function OverviewFilterDropdown(props: any) {
  const {
    filterBy,
    setFilterBy,
    fetchDashBoardData,
    filterCommunicationTypes,
    setFilterCommunicationTypes,
  } = props

  return (
    <>
      <Dropdown>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className='btn btn-icon btn-sm no-caret'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted' />
          </Dropdown.Toggle>
        </OverlayTrigger>

        <Dropdown.Menu className='p-4 max-w-300px w-300px' style={{minHeight: '100%'}}>
          <div className='px-4 pb-4'>
            <div className='fs-4 text-dark fw-semibolder'>Filter</div>
          </div>

          <div className='separator border-gray-200'></div>
          <div className='px-4 mt-4'>
            <div className='row flex-column'>
              <div className='col-sm-12 col-md-12'>
                <div className='mb-3'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    Chart Scope
                  </label>
                  <div className='row g-4 g-xl-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={scope}
                          value={scope.find((c) => c.value === filterBy)}
                          onChange={(e) => setFilterBy(e?.value)}
                          placeholder='Select : All'
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-sm-12 col-md-12'>
                <div className='mb-3'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    Communication Type
                  </label>
                  <div className='row g-4 g-xl-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={communication}
                          isMulti
                          value={filterCommunicationTypes}
                          onChange={(e) => setFilterCommunicationTypes(e)}
                          placeholder='Select : All'
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className='separator border-gray-200'></div> */}
          <div className='text-end mt-3 px-4'>
            <button
              type='reset'
              className='btn btn-sm btn-light me-2'
              data-kt-menu-dismiss='true'
              onClick={async () => {
                setFilterBy(5)
                setFilterCommunicationTypes(null)
                await fetchDashBoardData(5, null)
              }}
            >
              Reset
            </button>

            <button
              type='submit'
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
              onClick={async () => await fetchDashBoardData(filterBy, filterCommunicationTypes)}
            >
              Apply
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

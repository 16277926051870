// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Location} from '../../core/_models'

const usersColumns: ReadonlyArray<Column<Location>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => (
      <UserSelectionCell
        id={props.data[props.row.index].code}
        isActive={props.data[props.row.index].isActive}
      />
    ),
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    id: 'name',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Address' className='min-w-125px' />
    ),
    accessor: 'address',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='City' className='min-w-125px' />,
    accessor: 'city',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='State' className='min-w-125px' />
    ),
    accessor: 'state',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Country' className='min-w-125px' />
    ),
    accessor: 'country',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Postal Code' className='min-w-125px' />
    ),
    accessor: 'postalCode',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Status' />,
    id: 'isActive',
    Cell: ({...props}) => (
      <>
        {props.data[props.row.index].isActive ? (
          <span
            className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
            style={{width: '80px'}}
          >
            Active
          </span>
        ) : (
          <span className='badge badge-danger px-3 py-2 fx-9'>Inactive</span>
        )}
      </>
    ),
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <UserActionsCell
        isConfirmed={props.data[props.row.index].isConfirmed}
        code={props.data[props.row.index].code}
        email={props.data[props.row.index].email}
      />
    ),
  },
]

export {usersColumns}

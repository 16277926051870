import axios from 'axios'
import {AuthModel, NewUserRegistrationModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/accounts/loggeduser`
export const LOGIN_URL = `${API_URL}/accounts/token`
export const REGISTER_URL = `${API_URL}/accounts/ResetPasswordConfirm`
export const REQUEST_PASSWORD_URL = `${API_URL}/accounts/forgetPassword`
export const RESET_PASSWORD_URL = `${API_URL}/accounts/resetPassword`
export const LOGIN_DETAILS_URL = `${API_URL}/accounts/login-log-details`
//export const EMAIL_CONFIRMATION = `${API_URL}/accounts/confirm-account`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Function to send login details
export function sendLoginDetails(
  deviceId:string,
  deviceType: string,
  email: string,
  ipAddress: string,
  browserName: string,
  lat: any,
  lon: any,
  timeStamp: any,
  isSuccess: boolean
) {
  return axios.post(LOGIN_DETAILS_URL, {
    deviceId,
    deviceType,
    email,
    ipAddress,
    browserName,
    lat,
    lon,
    isSuccess,
    timeStamp,
  })
}

// Server should return AuthModel
export function register(
  token: string,
  email: string,
  firstName: string,
  lastName: string,
  oldPassword: string,
  newPassword: string
) {
  return axios.post(REGISTER_URL, {
    token,
    email,
    firstName: firstName,
    lastName: lastName,
    oldPassword,
    newPassword,
  })
}

export function resetPassword2(
  token: string,
  email: string,
  oldPassword: string,
  newPassword: string
) {
  return axios.post(RESET_PASSWORD_URL, {
    token,
    email,
    oldPassword,
    newPassword,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

//Registration
export function NewUserRegistration() {
  return axios.get<NewUserRegistrationModel>(REGISTER_URL)
}

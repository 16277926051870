import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'

type Props = {
  className: string
  description: string
  number: string
  url: string
}

const DashboardSmallCards = ({className, description, number, url}: Props) => (
  <div className='card card-md-stretch mb-5'>
    <div className='card-body shadow-custom px-4 py-4 rounded'>
      <div className='d-flex justify-content-start align-items-center flex-wrap'>
        <div className='d-flex me-4 '>
          <div className='symbol symbol-60px symbol-circle'>
            <div className='symbol-label'>
              {' '}
              <i className={`${url} ${className}`} style={{fontSize: '28px'}}></i>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column'>
          <span className='text-gray-600 pt-1 fw-semibold fs-7'>{description}</span>
          <span className='fw-bold text-dark me-2 fs-1'>{number}</span>
        </div>
      </div>
    </div>
  </div>
)
export {DashboardSmallCards}

import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {EmailFilterDropdown} from './EmailFilterDropdown'
export function MainConfig() {
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_add_server'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Create Server</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      SMTP Server Address
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter SMTP Server Address'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      SMTP Port
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter SMTP port'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Protocol
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter Protocol'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Username
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter Username'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Password
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='password'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter Password'
                      />
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-4 col-lg-4'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Gateway IP
                    </label>
                    <div className='d-flex w-100 flex-1 align-items-center'>
                      <input
                        type='text'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        placeholder='Enter Gateway IP'
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-wrap'>
                  <div className='mb-3'>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1'
                    >
                      Status
                    </label>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label className='d-flex flex-start mb-5 cursor-pointer'>
                          <span className='form-check form-check-custom form-check-success form-check-light form-check-sm me-3'>
                            <input
                              name='isDelete'
                              autoComplete='off'
                              className='form-check-input'
                              type='radio'
                              value='1'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span className='d-flex flex-column'>
                              <span className='fw-semibold text-gray-800 text-hover-primary fs-6'>
                                Active
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>

                      <div className='col-lg-6 col-md-6 col-sm-12'>
                        <label className='d-flex flex-start mb-5 cursor-pointer'>
                          <span className='form-check form-check-custom form-check-danger form-check-light form-check-sm me-3'>
                            <input
                              name='isDelete'
                              autoComplete='off'
                              className='form-check-input'
                              type='radio'
                              value='0'
                            />
                          </span>
                          <span className='d-flex align-items-center me-2'>
                            <span className='d-flex flex-column'>
                              <span className='fw-semibold text-gray-800 text-hover-primary fs-6'>
                                Inactive
                              </span>
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div className='d-flex justify-content-between mb-3 align-items-center flex-wrap'>
          <div className='card-title d-flex align-items-center me-4 mb-3'>
            <div className='d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <h5 className='text-black fs-4 fw-semibold mb-0'>Main Configuration</h5>
              </div>
              <div className='d-flex flex-wrap fs-6 '>
                <p className='text-gray-500 mb-0 fw-normal' style={{fontSize: '12px'}}>
                  Manage Email Server Configuration
                </p>
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <div className='d-flex flex-wrap'>
              <div className='d-flex align-items-center position-relative me-3 flex-nowrap'>
                <input
                  type='text'
                  data-kt-user-table-filter='search'
                  className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                  placeholder='Search'
                />
                <button
                  type='button'
                  className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                >
                  <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
                </button>
              </div>
              <div className='my-0 me-3' style={{display: 'flex', justifyContent: 'end'}}>
                <EmailFilterDropdown />
              </div>

              <OverlayTrigger
                placement='top'
                overlay={<Tooltip id='tooltip-filter'>Create Email Server</Tooltip>}
              >
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-primary btn-icon'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_add_server'
                  >
                    <i className='bi bi-plus fs-2'></i>
                  </button>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        {/* begin::Body */}
        <div className='py-0 pt-3' style={{minHeight: '44vh'}}>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-2 gy-2 table-hover'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-semibold text-muted text-uppercase'>
                  <th className='min-w-250px '>SMTP server address</th>
                  <th className='min-w-300px '>SMTP port</th>
                  <th className='min-w-150px '>Protocol</th>
                  <th className='min-w-200px '>Username</th>
                  <th className='min-w-150px '>Password</th>
                  <th className='min-w-200px'>Gateway IP</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px text-end'>Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>smtp.example.com</td>
                  <td>465</td>
                  <td>SMTP</td>
                  <td>user@example.com</td>
                  <td>password123</td>
                  <td>89.207.132.170</td>
                  <td>
                    <span
                      className='badge badge-light-success px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                      style={{width: '80px'}}
                    >
                      Active
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_add_server'
                      >
                        <KTSVG
                          path='/media/icons/duotune/art/art005.svg'
                          className='svg-icon-muted'
                        />
                      </a>
                      {/* <a
                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_delete_confirm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-muted '
                              />
                            </a> */}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>smtp.mailserver.net</td>
                  <td>25</td>
                  <td>SMTP over SSL</td>
                  <td>usupport@yourdomain.com</td>
                  <td>YourP@ssw0rd</td>
                  <td>203.0.113.5</td>
                  <td>
                    <span
                      className='badge badge-light-danger px-3 py-2 fs-9 d-flex align-items-center justify-content-center'
                      style={{width: '80px'}}
                    >
                      Inactive
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                      <a
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_add_server'
                      >
                        <KTSVG
                          path='/media/icons/duotune/art/art005.svg'
                          className='svg-icon-muted'
                        />
                      </a>
                      {/* <a
                              className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_delete_confirm'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen027.svg'
                                className='svg-icon-muted '
                              />
                            </a> */}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

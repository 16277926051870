import React, {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import Select, {SingleValue} from 'react-select'
import {Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import CSVTemplate from '../../../common/componenets/tableColumnTemplate/tableColumnTemplate'

export function CustomerInvoiceViewer() {
  const template = [
    {id: 'option1', label: 'INV-0001-A-template'},
    {id: 'option2', label: 'INV-0002-A-template'},
    {id: 'option3', label: 'INV-0003-A-template'},
    // Add more options if needed
  ]

  const rounding = [
    {value: 'option 1', label: 'Pro-rata'},
    {value: 'option 2', label: '1 min'},
    {value: 'option 3', label: '5 min'},
    {value: 'option 4', label: '15 min'},
    {value: 'option 5', label: '30 min'},
    {value: 'option 6', label: '1 Hr'},
  ]
  const direction = [
    {value: 'option 1', label: 'Up'},
    {value: 'option 2', label: 'Down'},
    {value: 'option 3', label: 'Up / Down'},
  ]
  const terms = [
    {value: 'none', label: 'None'},
    {value: 'custom', label: 'Custom'},
    {value: 'net7', label: 'Net 7'},
    {value: 'net10', label: 'Net 10'},
    {value: 'net15', label: 'Net 15'},
    {value: 'net30', label: 'Net 30'},
    {value: 'net45', label: 'Net 45'},
    {value: 'net60', label: 'Net 60'},
    {value: 'net90', label: 'Net 90'},
  ]
  const timeZone = [
    {value: 'option 1', label: '(GMT+05:30) Sri Jayawardenepura'},
    {value: 'option 2', label: '(GMT+05:30) Mumbai'},
    {value: 'option 3', label: '(GMT-05:00) Mexico City'},
  ]
  const [activeTab, setActiveTab] = useState(1)
  const [activeColumnTab, setActiveColumnTab] = useState(1)

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber)
  }
  const handleTabColumnClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveColumnTab(tabNumber)
  }
  const [selectedRecurrence, setSelectedRecurrence] = useState('1')

  const handleRecurrenceChange = (event: any) => {
    setSelectedRecurrence(event.target.value)
  }
  const [rows, setRows] = useState([{}])

  const addRow = () => {
    if (rows.length < 2) {
      setRows([...rows, {}])
    }
  }
  const removeRow = (index: number) => {
    const newRows = rows.filter((_, i) => i !== index)
    setRows(newRows)
  }
  const [isChecked, setIsChecked] = useState(false)
  const [isChecked2, setIsChecked2] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
  }
  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2)
  }
  interface OptionType {
    value: string
    label: string
  }
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null)

  // Type the argument of handleSelectChange to be an OptionType or null
  const handleSelectChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option)
  }
  const [selectedOptionInvoice, setSelectedOptionInvoice] = useState('1')

  // Type the argument of handleSelectChange to be an OptionType or null
  const handleSelectChangeInvoice = (event: any) => {
    setSelectedOptionInvoice(event.target.value)
  }
  const [selectedOptionRadio, setSelectedOptionRadio] = useState('')

  const handleChangeRadio = (event: any) => {
    setSelectedOptionRadio(event.target.value)
  }
  const [isTableVisible, setTableVisibility] = useState(false)

  const toggleTableVisibility = () => {
    setTableVisibility(!isTableVisible)
  }
  const [isTableVisible2, setTableVisibility2] = useState(false)

  const toggleTableVisibility2 = () => {
    setTableVisibility2(!isTableVisible2)
  }

  const [isEditing, setIsEditing] = useState(false)

  const [title, setTitle] = useState('INV-0001-A-template')

  const handleEditClick = () => {
    setIsEditing(true)
  }

  const handleBlur = () => {
    setIsEditing(false)
  }

  const handleChange = (e: any) => {
    setTitle(e.target.value)
  }

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_edit_inter-invoice'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Edit Invoice</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      PO Number
                    </label>
                    <input
                      type='text'
                      className='form-control form-control-sm custom-input-height'
                      name='po number'
                      placeholder='Enter PO Number'
                    />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Terms
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={terms}
                      placeholder='Select'
                      onChange={handleSelectChange}
                      value={selectedOption}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  {selectedOption && selectedOption.value === 'custom' && (
                    <div className='mb-3'>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='required form-label fs-7 mb-1'
                      >
                        Due Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-sm custom-input-height'
                        name='po number'
                        placeholder='Enter PO Number'
                      />
                    </div>
                  )}
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Write a Note
                    </label>
                    <textarea
                      placeholder='write Note..'
                      name='Note'
                      className='form-control form-select-white mb-lg-0'
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_note-invoice'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Notes</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='mb-3'>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Note
                    </label>
                    <textarea
                      placeholder=''
                      name='Note'
                      className='form-control form-select-white mb-lg-0'
                    ></textarea>
                  </div>
                  <div className='form-check form-check-custom form-check-light form-check-sm mb-2'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value=''
                      id='flexRadioLg'
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                    <label className='form-check-label' htmlFor='flexRadioLg'>
                      Show to Customer
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_edit-call-rate'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Edit Call Rate</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 mb-0'>
                <div className='col-sm-12 col-md-12'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    Rate
                  </label>
                  <div className='input-group input-group-sm'>
                    {/* <span className='input-group-text'>Minute</span>
                    <span className='input-group-text'>$</span> */}
                    <input
                      type='number'
                      className='form-control form-control-white form-control-sm custom-input-height'
                      placeholder='None'
                      step='0.01'
                      min='0'
                    />
                  </div>
                </div>
                <div className='row mt-5'>
                  {rows.map((_, index) => (
                    <div className='' key={index}>
                      <div className='row g-4 bg-light p-4 rounded'>
                        <div className='col-sm-12 col-md-6'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1'
                          >
                            For
                          </label>
                          <div className='input-group input-group-sm'>
                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm custom-input-height'
                              placeholder='No Threshold '
                              step='0.01'
                              min='0'
                            />
                            <span className='input-group-text'>Min(s)</span>
                          </div>
                        </div>
                        <div className='col-sm-12 col-md-5'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='form-label fs-7 mb-1'
                          >
                            Then
                          </label>
                          <div className='input-group input-group-sm'>
                            <span className='input-group-text'>Minute</span>
                            <span className='input-group-text'>$</span>
                            <input
                              type='number'
                              className='form-control form-control-white form-control-sm custom-input-height'
                              placeholder='None '
                              step='0.01'
                              min='0'
                            />
                          </div>
                        </div>
                        <div className='col-sm-12 col-md-1 d-flex align-items-end flex-end'>
                          <a className='' onClick={() => removeRow(index)}>
                            <KTSVG
                              path='/media/icons/duotune/general/gen040.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className='col-sm-12 col-md-12'>
                    <div>
                      <a
                        type='button'
                        className='btn btn-link btn-color-info btn-active-color-primary me-5 fs-6'
                        onClick={addRow}
                      >
                        <i className='bi bi-plus fs-2'></i>
                        <span style={{fontSize: '12px'}}>Add Rate Threshold ({rows.length}/2)</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mb-3 mt-0'>
                <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                  Per
                </label>
                {/* <div className='w-100'>
                            <Select
                              className='react-select-styled react-select-solid react-select-sm'
                              classNamePrefix='react-select'
                              options={pre}
                              placeholder='Select Recurrence'
                              value={{
                                value: selectedRecurrence,
                                label:
                                  pre?.find((c: any) => c.value === selectedRecurrence)
                                    ?.label || 'Select Recurrence',
                              }}
                              onChange={handleRecurrenceChange}
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                  border: '1px solid #e4e6ef',
                                }),
                              }}
                            />
                          </div> */}

                <select
                  className='form-select form-select-sm form-select-white custom-input-height'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  onChange={handleRecurrenceChange}
                >
                  <option value='1'>Minute</option>
                  <option value='2'>Hour</option>
                  <option value='3'>Fixed</option>
                </select>
              </div>
              {selectedRecurrence === '1' && (
                <div className='row g-4 mb-3 '>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Rounding
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={rounding}
                      placeholder='Select'
                      defaultValue={rounding[0]}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Direction
                      <i
                        className='bi bi-info-circle text-info ms-2'
                        data-bs-toggle='popover'
                        data-bs-custom-class='popover-inverse'
                        data-bs-placement='top'
                        title='Rounding can be configured to round up, down or in both directions based on duration. 15 min up/down rounding follows the 7-minute rule where the cutoff point for rounding down is 7 full minutes. All other options simply round to the nearest selected interval.'
                      ></i>
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={direction}
                      placeholder='Select'
                      defaultValue={direction[0]}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Minimum
                    </label>
                    <div className='input-group input-group-sm'>
                      <span className='input-group-text'>Minute</span>
                      <input
                        type='number'
                        className='form-control form-control-white form-control-sm custom-input-height'
                        placeholder='None'
                        step='0.01'
                        min='0'
                      />
                    </div>
                  </div>
                </div>
              )}
              {selectedRecurrence === '2' && (
                <div className='row g-4 mb-3 '>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Rounding
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={rounding}
                      placeholder='Select'
                      defaultValue={rounding[1]}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Direction
                      <i
                        className='bi bi-info-circle text-info ms-2'
                        data-bs-toggle='popover'
                        data-bs-custom-class='popover-inverse'
                        data-bs-placement='top'
                        title='Rounding can be configured to round up, down or in both directions based on duration. 15 min up/down rounding follows the 7-minute rule where the cutoff point for rounding down is 7 full minutes. All other options simply round to the nearest selected interval.'
                      ></i>
                    </label>
                    <Select
                      className='react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={direction}
                      placeholder='Select'
                      defaultValue={direction[0]}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                      }}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Minimum
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-white form-control-sm custom-input-height'
                      placeholder='Enter Minimum'
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 d-flex justify-content-start align-items-end'>
                    <a className='form-label fs-7 mb-1'>Hour</a>
                  </div>
                </div>
              )}
              <div className='mb-3'>
                <div className='row g-4 mb-3 '>
                  <div className='col-sm-12 col-md-6 '>
                    <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                      Conference Rate{' '}
                      <i
                        className='bi bi-info-circle text-info'
                        data-bs-toggle='popover'
                        data-bs-custom-class='popover-inverse'
                        data-bs-placement='top'
                        title='Additional rate for having 3+ participants during audio or video call.'
                      ></i>
                    </label>
                    <select
                      className='form-select form-select-sm  custom-input-height form-select-white'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-allow-clear='true'
                      value={selectedOptionInvoice}
                      onChange={handleSelectChangeInvoice}
                    >
                      <option value='1'>Add fixed amount</option>
                      <option value='2'>Add to a base rate</option>
                    </select>
                  </div>
                  <div className='col-sm-12 col-md-6 d-flex align-items-end'>
                    {selectedOptionInvoice === '1' && (
                      <div className='input-group input-group-sm'>
                        <span className='input-group-text'>$</span>
                        <input
                          type='number'
                          className='form-control form-control-white form-control-sm custom-input-height'
                          placeholder='None'
                        />
                      </div>
                    )}
                    {selectedOptionInvoice === '2' && (
                      <div className='input-group input-group-sm'>
                        <span className='input-group-text'>Minute</span>
                        <span className='input-group-text'>$</span>
                        <input
                          type='number'
                          className='form-control form-control-white form-control-sm custom-input-height'
                          placeholder='None'
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm'>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_detach_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/broken-chain.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Are you sure you would like to detach this call from the invoice?
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-danger btn-sm' data-bs-dismiss='modal'>
                Detach
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_void_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Confirmation</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center mb-5'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/warning.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Are you sure you would like to void this invoice? This will detach all appointment
                and calls currently part of this invoice
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-danger btn-sm' data-bs-dismiss='modal'>
                Void
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_pdf_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Download</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/download.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Save as PDF
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-success btn-sm' data-bs-dismiss='modal'>
                PDF Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_CSV_confirm'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Download</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/download.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                Save as CSV
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-success btn-sm' data-bs-dismiss='modal'>
                CSV Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' tabIndex={-1} id='kt_CSV_appointment'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Download</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='text-center'>
                <div className='symbol symbol-100px '>
                  <img src='/media/other/info.gif' alt='' />
                </div>
              </div>
              <h4 className='fw-normal fs-5' style={{textAlign: 'center'}}>
                This document does not contain any records and can’t be downloaded.
              </h4>
            </div>
            <div className='modal-footer py-3'>
              <button type='button' className='btn btn-light btn-sm' data-bs-dismiss='modal'>
                Close
              </button>

              <button type='button' className='btn btn-primary btn-sm' data-bs-dismiss='modal'>
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' tabIndex={-1} id='kt_select-column'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'> Select Column</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4 g-xl-6'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                  <div className='card mb-5'>
                    <div
                      className='card-header py-0 px-0'
                      style={{minWidth: '300px', overflowX: 'auto', minHeight: '40px'}}
                    >
                      <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400   ${
                              activeColumnTab === 1 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_1'
                            onClick={() => handleTabColumnClick(1)}
                          >
                            System
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 2 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_2'
                            onClick={() => handleTabColumnClick(2)}
                          >
                            Pre-Call Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 3 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_3'
                            onClick={() => handleTabColumnClick(3)}
                          >
                            Interpreter Profile Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 4 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_4'
                            onClick={() => handleTabColumnClick(4)}
                          >
                            Requestor Profile Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 5 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_5'
                            onClick={() => handleTabColumnClick(5)}
                          >
                            Account Profile Custom Fields
                          </a>
                        </li>
                        <li className='nav-item'>
                          <a
                            className={`nav-link fs-7 text-gray-400 ${
                              activeColumnTab === 6 ? 'text-active-dark fw-bold active show' : ''
                            }`}
                            data-bs-toggle='tab'
                            href='#kt_tab_column_6'
                            onClick={() => handleTabColumnClick(6)}
                          >
                            Saved Templates
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='card-body pt-0 px-0'>
                      <div className=''>
                        <div className='tab-content' id='myTabContent'>
                          {activeColumnTab === 1 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_1'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                    <input
                                      type='text'
                                      data-kt-user-table-filter='search'
                                      className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                      placeholder='Search'
                                    />
                                    <button
                                      type='button'
                                      className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen021.svg'
                                        className=''
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div
                                  className='row g-4 py-2'
                                  style={{maxHeight: '300px', overflowY: 'auto'}}
                                >
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg0'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg0'>
                                        Call Id
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg1'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg1'>
                                        Interpreter
                                      </label>
                                    </div>
                                  </div>

                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg2'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg2'>
                                        Interpreter ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg3'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg3'>
                                        Requestor
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg4'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg4'>
                                        Account Name
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg5'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg5'>
                                        Date/Time
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg6'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg6'>
                                        Language 1
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg7'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg7'>
                                        Language 2
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg8'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg8'>
                                        Type
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg9'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg9'>
                                        Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg10'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg10'>
                                        Client Billable Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg11'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg11'>
                                        Interpreter Billable Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg12'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg12'>
                                        Finished By
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg13'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg13'>
                                        Requestor Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg14'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg14'>
                                        Requestor Call Quality Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg15'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg15'>
                                        Interpreter Peer Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg16'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg16'>
                                        Interpreter Call Quality Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg17'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg17'>
                                        Account Charge
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg18'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg18'>
                                        Account Minimum
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg19'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg19'>
                                        Interpreter Minimum
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg20'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg20'>
                                        Account Rounding
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg21'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg21'>
                                        Interpreter Rounding
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg22'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg22'>
                                        Account Thresholds
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg23'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg23'>
                                        Interpreter Thresholds
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg24'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg24'>
                                        Account Special Rate Amount
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg25'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg25'>
                                        Interpreter Special Rate Amount
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg26'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg26'>
                                        Account Conference Charge
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg27'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg27'>
                                        Interpreter Conference Pay
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg28'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg28'>
                                        Account Invoice ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg29'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg29'>
                                        Account Invoice Status
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg30'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg30'>
                                        Interpreter Invoice ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg31'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg31'>
                                        Interpreter Peer Rating
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg32'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg32'>
                                        Queue Time (s)
                                      </label>
                                    </div>
                                  </div>

                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg33'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg33'>
                                        Call Center Queue/Hold Time (s)
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg34'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg34'>
                                        Gender Requested
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg35'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg35'>
                                        Client Device Type
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg36'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg36'>
                                        From Number
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg37'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg37'>
                                        Requestor Device Info
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg38'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg38'>
                                        Other Participants
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg39'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg39'>
                                        Conference Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg40'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg40'>
                                        Operator Name
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg41'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg41'>
                                        Operator Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg42'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg42'>
                                        Intake Duration
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg43'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg43'>
                                        Toll-Free Dialed
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg44'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg44'>
                                        Total Interpreter Pay
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg45'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg45'>
                                        Crowd
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg46'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg46'>
                                        Account ID
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg47'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg47'>
                                        Pending Duration Updates
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg48'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg48'>
                                        Duration Adjusted to 0
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg49'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg49'>
                                        Call Status
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg50'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg50'>
                                        Call Status (Operator)
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg51'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg51'>
                                        Client Company UniqueId
                                      </label>
                                    </div>
                                  </div>
                                  <div className='col-sm-12 col-md-4 col-lg-3'>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                      <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexRadioLg52'
                                      />
                                      <label className='form-check-label' htmlFor='flexRadioLg52'>
                                        PRequestor Pin
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 2 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_2'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg0'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg0'
                                          >
                                            Name of Requestor
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg1'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg1'
                                          >
                                            Department
                                          </label>
                                        </div>
                                      </div>

                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg2'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg2'
                                          >
                                            Call Center
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg3'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg3'
                                          >
                                            School Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg4'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg4'
                                          >
                                            Division
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg5'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg5'
                                          >
                                            Service Center
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg6'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg6'
                                          >
                                            Client name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg7'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg7'
                                          >
                                            Maryland Auto
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg8'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg8'
                                          >
                                            Department or Service Area
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg9'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg9'
                                          >
                                            Email Address
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg10'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg10'
                                          >
                                            DE CDW
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg11'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg11'
                                          >
                                            Using the key pad, please enter the last five digits of
                                            your Endeavors phone number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg12'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg12'
                                          >
                                            Caller's Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg13'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg13'
                                          >
                                            Employee Identification Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg14'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg14'
                                          >
                                            Please clearly state your department
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg15'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg15'
                                          >
                                            Officer''s Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg16'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg16'
                                          >
                                            Form Type
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg17'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg17'
                                          >
                                            Requester's first and last name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg18'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg18'
                                          >
                                            Region Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg19'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg19'
                                          >
                                            Medical Record Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg20'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg20'
                                          >
                                            Patient's Name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg21'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg21'
                                          >
                                            State Dept - Assigned Attorney
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg22'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg22'
                                          >
                                            State Dept Case ID
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg23'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg23'
                                          >
                                            Adventist Medical Group
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg24'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg24'
                                          >
                                            Clinic or department
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg25'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg25'
                                          >
                                            Location Code
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg26'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg26'
                                          >
                                            Program Code
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg27'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg27'
                                          >
                                            Are you on site with the participant? 1=yes, 2=no
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg28'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg28'
                                          >
                                            Onsite with participant?
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg29'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg29'
                                          >
                                            CBP - Are you a member of US Border patrol
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg30'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg30'
                                          >
                                            Claim or Policy Number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg31'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg31'
                                          >
                                            Name of Adjuster
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg32'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg32'
                                          >
                                            Claim number as requested by Florida Peninsula Insurance
                                            compliance department
                                          </label>
                                        </div>
                                      </div>

                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg33'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg33'
                                          >
                                            Requester store name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg34'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg34'
                                          >
                                            Employee's name
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg35'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg35'
                                          >
                                            Provide the Claim ID, using no spaces or dashes
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg36'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg36'
                                          >
                                            Two-digit local agency number
                                          </label>
                                        </div>
                                      </div>
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg37'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg37'
                                          >
                                            Three digit clinic number
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 3 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_3'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg0'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg0'
                                          >
                                            Additional Expiration
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 4 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_4'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            value=''
                                            id='flexRadioLg0'
                                          />
                                          <label
                                            className='form-check-label'
                                            htmlFor='flexRadioLg0'
                                          >
                                            Department Name
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 5 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_5'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-4 col-lg-3'>
                                        <p>No Custome Field</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {activeColumnTab === 6 && (
                            <div
                              className='tab-pane fade show active'
                              id='kt_tab_column_6'
                              role='tabpanel'
                            >
                              <div className='row g-4 py-2'>
                                <div className='col-md-12'>
                                  <div className='row g-4 py-2'>
                                    <div className='col-md-12'>
                                      <div className='d-flex align-items-center position-relative flex-nowrap mt-3'>
                                        <input
                                          type='text'
                                          data-kt-user-table-filter='search'
                                          className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                          placeholder='Search'
                                        />
                                        <button
                                          type='button'
                                          className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                        >
                                          <KTSVG
                                            path='/media/icons/duotune/general/gen021.svg'
                                            className=''
                                          />
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      className='row g-4 py-2'
                                      style={{maxHeight: '300px', overflowY: 'auto'}}
                                    >
                                      <div className='col-sm-12 col-md-12 col-lg-12'>
                                        <CSVTemplate />
                                        <CSVTemplate />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      {activeColumnTab !== 6 && (
                        <div className='row '>
                          <div className='separator my-2'></div>
                          <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                            <div className='mb-2'>
                              <div
                                className='accordion-header py-3 d-flex'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_accordion_2_item_1'
                              >
                                <span className='accordion-icon'>
                                  <KTSVG
                                    className='svg-icon svg-icon-4'
                                    path='media/icons/duotune/arrows/arr064.svg'
                                  />
                                </span>
                                <h3 className='fs-7 text-gray-800 fw-semibold mb-0 ms-4'>
                                  Advanced
                                </h3>
                              </div>
                              <div
                                id='kt_accordion_2_item_1'
                                className='fs-6 collapse ps-8'
                                data-bs-parent='#kt_accordion_2'
                              >
                                <div className='mb-3'>
                                  <div className='row d-flex mb-3'>
                                    <div className='col-md-12'>
                                      <label
                                        htmlFor='exampleFormControlInput1'
                                        className='form-label fs-7 mb-1'
                                      >
                                        Date/time fields will be displayed in your local time zone.
                                        You can change this in the drop-down below
                                      </label>
                                      <Select
                                        className='react-select-styled react-select-solid react-select-sm'
                                        classNamePrefix='react-select'
                                        options={timeZone}
                                        defaultValue={timeZone[0]}
                                        placeholder='Select'
                                        styles={{
                                          control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            border: '1px solid #e4e6ef',
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer py-3'>
              <div className='d-flex justify-content-between align-items-center w-100 flex-wrap'>
                <div className='d-flex'>
                  {activeColumnTab !== 6 && (
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value=''
                        id='flexRadioLg111'
                      />
                      <label className='form-check-label' htmlFor='flexRadioLg111'>
                        Save selected columns as CSV template
                      </label>
                    </div>
                  )}
                </div>
                <div className='d-flex'>
                  <button
                    type='button'
                    className='btn btn-light btn-sm me-3'
                    data-bs-dismiss='modal'
                  >
                    Close
                  </button>

                  <button type='button' className='btn btn-success btn-sm'>
                    Download CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-3'>
        <div className='d-flex align-items-center '>
          <Link
            to='/billing'
            className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'
          >
            <i className='bi bi-arrow-left-circle' style={{fontSize: '18px'}}></i>
            {/* <i className='bi bi-arrow-left-circle-fill' s></i> */}
          </Link>
          <div>
            <h1 className='mb-0 fw-semibold fs-2'>Customer Invoice Viewer</h1>
            <span className='text-gray-500'>Customer History check and Print Invoice</span>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between align-items-center  px-3 py-2 mb-2 rounded shadow-sm bg-success text-white'>
        <span className='fs-8 d-flex align-items-center justify-content-center fw-semibold'>
          Sent To QuickBooks
        </span>
        <div>
          <span className='text-white fs-8 fw-semibold'>Invoice has been added to QuickBooks.</span>
        </div>
      </div>
      <div className='card mb-5 mb-xl-5 shadow-sm' style={{borderTop: '3px solid #a3a242'}}>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='symbol symbol-100px symbol-lg-120px symbol-fixed position-relative border border-primary border-3 rounded-circle'>
                <img
                  src={toAbsoluteUrl('../media/logos/Untitled-1.png')}
                  alt='interpreter'
                  className='rounded-circle'
                />
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
              </div>
              {/* {activeTab === 1 && (
                <div className='d-flex align-items-center justify-content-center mt-2'>
                  <a
                    className='btn btn-color-primary btn-active-primary btn-sm'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_edit_inter-invoice'
                    style={{fontSize: '12px'}}
                  >
                    <i className='fa-solid fa-pen me-1' style={{fontSize: '12px'}}></i>
                    Edit
                  </a>
                </div>
              )} */}
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      Ad Astra 1
                    </a>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 '>
                    <a className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'>
                      <i className='bi bi-receipt me-2'></i>
                      INV-000339-A
                    </a>
                  </div>
                </div>
                <div className='d-flex '>
                  <div className='d-flex flex-wrap'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-filter'>Send to Quick Books</Tooltip>}
                    >
                      <div>
                        <button type='button' className='btn btn-sm btn-success btn-icon me-3'>
                          qb
                        </button>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div className='d-flex flex-wrap'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='primary'
                        id='dropdown-basic'
                        className='btn btn-sm btn-primary btn-icon me-3 no-caret'
                      >
                        <i className='bi bi-three-dots fs-4'></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='dropdown-menu-left-1'>
                        <Dropdown.Item>Archive</Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_void_confirm'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Void
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item>Mark Paid</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className='d-flex flex-wrap'>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant='primary'
                        id='dropdown-basic'
                        className='btn btn-sm btn-primary btn-icon me-3 no-caret'
                      >
                        <i className='bi bi-download fs-4'></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='dropdown-menu-left'>
                        <Dropdown.Item>Print</Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_pdf_confirm'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download to PDF
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_CSV_confirm'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download All Columns to CSV Calls
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_select-column'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download Select Columns to CSV Calls
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_CSV_appointment'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download All Columns to CSV Appointments
                          </a>
                        </Dropdown.Item>
                        <Dropdown.Item data-bs-toggle='modal' data-bs-target='#kt_CSV_appointment'>
                          <a href='#' className='text-gray-800 text-hover-primary'>
                            Download Select Columns to CSV Appointments
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {/* <div className='d-flex flex-wrap'>
                    <OverlayTrigger
                      placement='top'
                      overlay={<Tooltip id='tooltip-filter'>Download</Tooltip>}
                    >
                      <div>
                        <button type='button' className='btn btn-sm btn-primary btn-icon'>
                          <i className='bi bi-download fs-4'></i>
                        </button>
                      </div>
                    </OverlayTrigger>
                  </div> */}
                </div>
              </div>
              {activeTab === 1 && (
                <>
                  <div className='d-flex flex-wrap flex-stack'>
                    <div className='d-flex flex-column flex-grow-1 pe-4'>
                      <div className='d-flex flex-wrap'>
                        <div className='card-body p-0'>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>PO#</label>
                            <div className='col-lg-8'>
                              <span className='fs-6 text-gray-900'>: </span>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>Created</label>
                            <div className='col-lg-8 fv-row'>
                              <span className='fs-6'>: 8/20/24 5:41 am</span>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>
                              Invoice Date{' '}
                              {/* <i
                            className='fas fa-exclamation-circle ms-1 fs-7'
                            data-bs-toggle='tooltip'
                            title='Phone number must be active'
                          ></i> */}
                            </label>
                            <div className='col-lg-8 d-flex align-items-center'>
                              <span className=' fs-6 me-2'>: </span>
                              {/* <span className='badge badge-success'>Verified</span> */}
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>Due Date</label>
                            <div className='col-lg-8 d-flex align-items-center'>
                              <span className=' fs-6 me-2'>: None</span>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-4 fw-bold text-black fs-6'>Bill To</label>
                            <div className='col-lg-8 d-flex align-items-center'>
                              <span className=' fs-6 me-2'>:</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-grow-1 pe-4'>
                      <div className='d-flex flex-wrap'>
                        <div className='card-body p-0'>
                          <div className='row mb-3'>
                            <label className='col-lg-6 fw-bold text-black fs-6'>Date Range</label>
                            <div className='col-lg-6 fv-row'>
                              <span className='fs-6'>: 7/19/24 to 8/20/24</span>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-6 fw-bold text-black fs-6'>
                              # of revisions
                              {/* <i
                            className='fas fa-exclamation-circle ms-1 fs-7'
                            data-bs-toggle='tooltip'
                            title='Phone number must be active'
                          ></i> */}
                            </label>
                            <div className='col-lg-6 d-flex align-items-center'>
                              <span className=' fs-6 me-2'>: 0</span>
                              {/* <span className='badge badge-success'>Verified</span> */}
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-6 fw-bold text-black fs-6 '>
                              # of Appointments
                            </label>
                            <div className='col-lg-6'>
                              <span className='fs-6 text-gray-900'>: 0</span>
                            </div>
                          </div>
                          <div className='row mb-3'>
                            <label className='col-lg-6 fw-bold text-black fs-6 '># of Calls</label>
                            <div className='col-lg-6 fv-row'>
                              <span className='fs-6'>: 1</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-grow-1 pe-4'>
                      <div className='d-flex flex-wrap'>
                        <div className='card-body p-0'>
                          <div className='row mb-5'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='form-label fw-bold text-black fs-6 ps-0'
                            >
                              Notes
                            </label>
                            <textarea
                              placeholder=''
                              name='Note'
                              className='form-control form-select-white form-control-custom'
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5'>
        <div
          className='card-header py-0'
          style={{minWidth: '300px', overflowX: 'auto', minHeight: '40px'}}
        >
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400   ${
                  activeTab === 1 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_1'
                onClick={() => handleTabClick(1)}
              >
                Invoice
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 2 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_2'
                onClick={() => handleTabClick(2)}
              >
                History
              </a>
            </li>
            <li className='nav-item'>
              <a
                className={`nav-link fs-7 text-gray-400 ${
                  activeTab === 3 ? 'text-active-dark fw-bold active show' : ''
                }`}
                data-bs-toggle='tab'
                href='#kt_tab_pane_3'
                onClick={() => handleTabClick(3)}
              >
                Print View
              </a>
            </li>
          </ul>
        </div>
        <div className='card-body pt-0'>
          <div className=''>
            <div className='tab-content' id='myTabContent'>
              {activeTab === 1 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_1' role='tabpanel'>
                  <div className='mt-5'>
                    <span className='badge badge-warning'>Taxes Not Included</span>
                  </div>
                  <div className='mt-2 px-2 py-2 bg-light border border-gray-300 rounded'>
                    <span className='text-dark fw-bold'>Calls: $3.00</span>
                  </div>
                  <div className='py-0 pt-3 mb-xl-5 mt-3'>
                    {/* <h4 className='mb-3'>Overview</h4> */}
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                      {/* begin::Table */}
                      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'>
                        {/* begin::Table head */}
                        <thead>
                          <tr className='fw-bold text-muted text-uppercase'>
                            <th className='min-w-150px '>Call</th>
                            <th className='min-w-100px '>Requestor</th>
                            <th className='min-w-100px '>Type</th>
                            <th className='min-w-100px '>Date/Time</th>
                            <th className='min-w-100px '>Duration</th>
                            <th className='min-w-100px '>Rate</th>
                            <th className='min-w-100px '>Extras</th>
                            <th className='min-w-100px '>Total</th>
                            <th className='min-w-100px text-end'>Action</th>
                          </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                          <tr>
                            <td>
                              <div className='d-flex flex-column'>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  75613828859593
                                </a>
                                <span className='text-gray-500 fs-9'>English to Spanish</span>
                              </div>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                Shared Account
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>Audio</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                8/20/24, Tuesday, 1:49 am
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                21 minutes, 30 seconds
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00min</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                            </td>

                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_note-invoice'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen005.svg'
                                    className='svg-icon-muted'
                                  />
                                </a>
                                {/* <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_edit-call-rate'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-muted'
                                  />
                                </a> */}

                                {/* <a
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_detach_confirm'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/coding/cod008.svg'
                                    className='svg-icon-muted '
                                  />
                                </a> */}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='d-flex flex-column'>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  75613828859593
                                </a>
                                <span className='text-gray-500 fs-9'>English to Spanish</span>
                              </div>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                Shared Account
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>Audio</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                8/20/24, Tuesday, 1:49 am
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                21 minutes, 30 seconds
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00min</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                            </td>

                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_note-invoice'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen005.svg'
                                    className='svg-icon-muted'
                                  />
                                </a>
                                {/* <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_edit-call-rate'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-muted'
                                  />
                                </a> */}

                                {/* <a
                                  className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_detach_confirm'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/coding/cod008.svg'
                                    className='svg-icon-muted '
                                  />
                                </a> */}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className='d-flex flex-column'>
                                <a className='text-gray-800 text-hover-primary  fs-7'>
                                  75613828859593
                                </a>
                                <span className='text-gray-500 fs-9'>English to Spanish</span>
                              </div>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                Shared Account
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>Audio</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                8/20/24, Tuesday, 1:49 am
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>
                                21 minutes, 30 seconds
                              </a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00min</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                            </td>
                            <td>
                              <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                            </td>

                            <td>
                              <div className='d-flex justify-content-end flex-shrink-0'>
                                <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_note-invoice'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen005.svg'
                                    className='svg-icon-muted'
                                  />
                                </a>
                                {/* <a
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_edit-call-rate'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-muted'
                                  />
                                </a> */}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        {/* end::Table body */}
                      </table>
                      {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                  </div>
                  <div className='separator my-5'></div>
                  <div className='mt-5'>
                    <span className='badge badge-warning'>Taxes Not Included</span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-2'>
                    <div className='d-flex flex-grow-1  px-2 py-2 bg-light border border-gray-300 rounded'>
                      <span className='text-dark fw-bold'>Additional Items: $0.00</span>
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 2 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_2' role='tabpanel'>
                  <div className='row g-4'>
                    <div className='col-md-6 col-sm-12 pt-5 '>
                      <h3 className='fs-6 fw-normal text-gray-600 mb-5'>Invoice History</h3>
                      <div className='card mb-2 shadow-sm'>
                        <div className='rounded-sm'>
                          {/* <div className='card-header py-2' style={{minHeight: '50px'}}>
                            <div className='card-title'>
                              <span className='fw-semibold d-flex align-items-center fs-5 me-1'>
                                wcw
                              </span>
                            </div>
                          </div> */}
                          <div className='card-body py-2'>
                            <div className='w-100'>
                              <div className='d-flex justify-content-between flex-nowrap w-100'>
                                <div className='d-flex align-items-center '>
                                  <div className='symbol-group symbol-hover me-2'>
                                    <div className='symbol symbol-circle symbol-50px'>
                                      <img
                                        src={toAbsoluteUrl('../media/logos/Untitled-1.png')}
                                        alt=''
                                      />
                                    </div>
                                  </div>

                                  <div className='d-flex flex-column flex-1 w-auto'>
                                    <span className='fs-7'>Invoice created.</span>
                                  </div>
                                </div>
                                <div className='text-gray-600 fw-semibold d-flex fs-8'>Admin </div>
                              </div>
                            </div>
                          </div>
                          <div className='py-2 card-footer'>
                            <div className='row g-1'>
                              <div className='col-sm-12 col-md-12 col-lg-12 text-end text-gray-400 fs-8'>
                                04/01/24 10:35 pm
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12 pt-5'>
                      <h3 className='fs-6 fw-normal text-gray-600 mb-5'>Notes</h3>
                      <div className='card mb-2 shadow-sm'>
                        <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                          <div className='text-center'>
                            <div className='symbol symbol-200px '>
                              <img src='/media/other/nodata.png' alt='' />
                            </div>
                          </div>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                            No matching records found
                          </div>
                        </div>
                      </div>
                      {/* <div className='card mb-2 shadow-sm'>
                        <div className='rounded-sm'>
                          <div className='card-title py-3 px-10'>
                            <span className='fw-bolder d-flex align-items-center fs-4 me-1 mb-2'>
                              Note from LSC Administrator to All
                            </span>
                          </div>
                          <div className='card-body py-2'>
                            <div className='w-100'>
                              <div className='d-flex justify-content-between flex-nowrap w-100'>
                                <div className='d-flex align-items-center '>
                                  <div className='symbol-group symbol-hover me-2'>
                                    <div className='symbol symbol-circle symbol-50px'>
                                      <img src={toAbsoluteUrl('media/avatars/300-6.jpg')} alt='' />
                                    </div>
                                  </div>

                                  <div className='d-flex flex-column flex-1 w-auto'>
                                    <span className='fs-6'>
                                      This would be a video call with client and case manager
                                      through Whatsapp.
                                    </span>
                                  </div>
                                </div>
                                <div className='text-info fw-semibold d-flex'>Admin</div>
                              </div>
                            </div>
                          </div>
                          <div className='py-2 card-footer'>
                            <div className='row g-1'>
                              <div className='col-sm-12 col-md-12 col-lg-12 text-end text-primary'>
                                04/01/24 10:35 pm
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  */}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className='tab-pane fade show active' id='kt_tab_pane_3' role='tabpanel'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between align-items-center flex-wrap mt-5'>
                      <div className='d-flex flex-column'>
                        <span className='fw-bold me-2 fs-2'>Ad Astra 1</span>
                        <span className='text-gray-800 fs-6'>
                          8701 Georgia Ave, Suit # 800 Silver Spring, Maryland, 20910 US
                        </span>
                        {/* <span className='text-gray-800 fs-7 fw-bold'>P: +18157081344</span> */}
                      </div>
                      <div className='symbol-group symbol-hover me-2'>
                        <div className='symbol symbol-circle symbol-100px'>
                          <img src={toAbsoluteUrl('../media/logos/Untitled-1.png')} alt='' />
                        </div>
                      </div>
                    </div>
                    <div className='separator my-3 border-3 border-gray-600'></div>
                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                      <div className='d-flex flex-column'>
                        <span className='pt-1 fw-semibold fs-7 ' style={{minWidth: '160px'}}>
                          To :
                        </span>
                        <span className='fw-bold me-2 fs-6'>Ad Astra Internal</span>
                        <span className='text-gray-700 fs-7'>8701 Georgia Ave, Suit # 800</span>
                        <span className='text-gray-700 fs-7'>Silver Spring, Maryland, 20910</span>
                        <span className='text-gray-700 fs-7'>US</span>
                      </div>
                      <div className='d-flex flex-column'>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Invoice# : <span className='text-primary fw-bold'> INV-000339-A</span>
                        </span>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Invoice Date :
                          <span className='text-dark fw-bold fs-7'> 8/20/24 5:41 am</span>
                        </span>
                        <span className='fw-semibold me-2 fs-7 text-gray-700'>
                          Due Date:
                          <span className='text-dark fw-bold fs-7'> (Net 30)</span>
                        </span>
                      </div>
                    </div>

                    <div className='separator my-3'></div>
                    <div className=''>
                      <div className='px-2 py-2 bg-light border border-gray-300 rounded'>
                        <span className='text-dark fw-bold'>Calls: $3.00</span>
                      </div>
                      <div className='py-0 pt-3 mb-xl-5 mt-3'>
                        {/* <h4 className='mb-3'>Overview</h4> */}
                        {/* begin::Table container */}
                        <div className='table-responsive'>
                          {/* begin::Table */}
                          <table
                            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2'
                            id='sumtable'
                          >
                            {/* begin::Table head */}
                            <thead>
                              <tr className='fw-bold text-muted text-uppercase'>
                                <th className='min-w-150px '>Call</th>
                                <th className='min-w-100px '>Requestor</th>
                                <th className='min-w-100px '>Type</th>
                                <th className='min-w-100px '>Date/Time</th>
                                <th className='min-w-100px '>Duration</th>
                                <th className='min-w-100px '>Rate</th>
                                <th className='min-w-100px '>Extras</th>
                                <th className='min-w-100px text-end'>Total</th>
                                {/* <th className='min-w-100px text-end'>Action</th> */}
                              </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                              <tr>
                                <td>
                                  <div className='d-flex flex-column'>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      7561382888790
                                    </a>
                                    <span className='text-gray-500 fs-9'>English to Spanish</span>
                                  </div>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    Shared Account
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>Audio</a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    8/20/24, Tuesday, 1:49 am
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    21 minutes, 30 seconds
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$0.00min</a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                                </td>
                                <td className='text-end'>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$1.00</a>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='d-flex flex-column'>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      75613828853425
                                    </a>
                                    <span className='text-gray-500 fs-9'>English to Spanish</span>
                                  </div>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    Shared Account
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>Audio</a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    8/20/24, Tuesday, 1:49 am
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    30 minutes, 30 seconds
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$1.00min</a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                                </td>
                                <td className='text-end'>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$1.00</a>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='d-flex flex-column'>
                                    <a className='text-gray-800 text-hover-primary  fs-7'>
                                      75613828859593
                                    </a>
                                    <span className='text-gray-500 fs-9'>English to Spanish</span>
                                  </div>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    Shared Account
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>Audio</a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    8/20/24, Tuesday, 1:49 am
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>
                                    21 minutes, 30 seconds
                                  </a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$1.00min</a>
                                </td>
                                <td>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$0.00</a>
                                </td>
                                <td className='text-end'>
                                  <a className='text-gray-800 text-hover-primary  fs-7'>$1.00</a>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={7} className='text-end'>
                                  <a className='text-dark fs-6'>Calls :</a>
                                </td>
                                <td className='text-end border-bottom border-bottom-1 border-gray-600'>
                                  <a className='text-dark fs-6'>$3.00</a>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={7} className='text-end'>
                                  <a className='text-dark fs-6 fw-bold'>
                                    <span className='text-danger fs-7 me-2 fw-normal'>
                                      (*Taxes Not Included)
                                    </span>{' '}
                                    TOTAL :
                                  </a>
                                </td>
                                <td className='text-end '>
                                  <a className='text-dark fs-6 fw-bold'>$3.00</a>
                                </td>
                              </tr>
                            </tbody>

                            {/* end::Table body */}
                          </table>

                          {/* end::Table */}
                        </div>
                        {/* <div className='d-flex text-end justify-content-end w-100'>
                          <div className='flex-column'>
                            <div className='d-flex align-items-end justify-content-end'>
                              <a className='text-dark fs-6 fw-bold'>Call :</a>

                              <a className='text-dark fs-6 fw-bold'>$3.00</a>
                            </div>
                            <div className='d-flex border-bottom border-bottom-2 border-dark'>
                              <a className='text-dark fs-6 fw-bold'>
                                <span className='text-danger fs-6'>(*Taxes Not Included)</span>{' '}
                                TOTAL :
                              </a>
                              <div className='text-end'>
                                <a className='text-dark fs-6 fw-bold'> $3.00</a>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* end::Table container */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

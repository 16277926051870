import React, {useState} from 'react'
import {KTSVG} from '../_metronic/helpers'

/**
  pageName for Scheduled :-Scheduled_InPerson
  pageName for Inperson  :- Scheduled_InPerson
  PageName for Operator  :- Operator
  onDemnad is already set
*/

type Props = {
  title: string
  pageName: string
}

const DownloadModal: React.FC<Props> = ({title, pageName}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([])
  const [selectAll, setSelectAll] = useState(false)
  const [fileType, setFileType] = useState<string>('CSV')

  const OnDemand = [
    {label: 'Call Id', value: 'callId'},
    {label: 'Interpreter', value: 'interpreter'},
    {label: 'Interpreter ID', value: 'interpreterID'},
    {label: 'Requestor', value: 'Requestor'},
    {label: 'Account Name', value: 'accountName'},
    {label: 'Date/Time', value: 'dateTime'},
    {label: 'Downloaded Time zone', value: 'downloadedTimeZone'},
    {label: 'Language 1', value: 'language1'},
    {label: 'Language 2', value: 'language2'},
    {label: 'Service Type', value: 'serviceType'},
    {label: 'Type', value: 'type'},
    {label: 'Duration', value: 'duration'},
    {label: 'Client Billable Duration', value: 'clientBillableDuration'},
    {label: 'Interpreter Billable Duration', value: 'interpreterBillableDuration'},
    {label: 'Finished By', value: 'finishedBy'},
    {label: 'Requestor Rating', value: 'requestorRating'},
    {label: 'Requestor Call Quality Rating', value: 'requestorCallQualityRating'},
    {label: 'Interpreter Peer Rating', value: 'interpreterPeerRating'},
    {label: 'Interpreter Call Quality Rating', value: 'interpreterCallQualityRating'},
    {label: 'Account Charge', value: 'accountCharge'},
    {label: 'Account Minimum', value: 'accountMinimum'},
    {label: 'Interpreter Minimum', value: 'interpreterMinimum'},
    {label: 'Account Rounding', value: 'accountRounding'},
    {label: 'Interpreter Rounding', value: 'interpreterRounding'},
    {label: 'Account Thresholds', value: 'accountThresholds'},
    {label: 'Interpreter Thresholds', value: 'interpreterThresholds'},
    {label: 'Account Special Rate Amount', value: 'accountSpecialRateAmount'},
    {label: 'Interpreter Special Rate Amount', value: 'interpreterSpecialRateAmount'},
    {label: 'Account Conference Charge', value: 'accountConferenceCharge'},
    {label: 'Interpreter Conference Pay', value: 'interpreterConferencePay'},
    {label: 'Account Invoice ID', value: 'accountInvoiceID'},
    {label: 'Account Invoice Status', value: 'accountInvoiceStatus'},
    {label: 'Interpreter Invoice ID', value: 'interpreterInvoiceID'},
    {label: 'Interpreter Invoice Status', value: 'interpreterInvoiceStatus'},
    {label: 'Queue Time (s)', value: 'queueTime(s)'},
    {label: 'Call Center Queue/Hold Time (s)', value: 'callCenterQueueHoldTime(s)'},
    {label: 'Gender Requested', value: 'genderRequested'},
    {label: 'Client Device Type', value: 'clientDeviceType'},
    {label: 'From Number', value: 'fromNumber'},
    {label: 'Requestor Device Info', value: 'requestorDeviceInfo'},
    {label: 'Interpreter Device Info', value: 'interpreterDeviceInfo'},
    {label: 'Other Participants', value: 'otherParticipants'},
    {label: 'Conference Duration', value: 'conferenceDuration'},
    {label: 'Operator Name', value: 'operatorName'},
    {label: 'Operator Duration', value: 'operatorDuration'},
    {label: 'Intake Duration', value: 'intakeDuration'},
    {label: 'Toll-Free Dialed', value: 'toll-FreeDialed'},
    {label: 'Total Interpreter Pay', value: 'totalInterpreterPay'},
    {label: 'Crowd', value: 'Crowd'},
    {label: 'Account ID', value: 'accountID'},
    {label: 'Pending Duration Updates', value: 'pendingDurationUpdates'},
    {label: 'Duration Adjusted to 0', value: 'durationAdjustedto0'},
    {label: 'Call Status', value: 'callStatus'},
    {label: 'Call Status (Operator)', value: 'callStatus(Operator)'},
    {label: 'Client Company UniqueId', value: 'clientCompanyUniqueId'},
    {label: 'Requestor Pin', value: 'requestorPin'},
    {label: 'Name of Requestor', value: 'nameofRequestor'},
    {label: 'Department', value: 'department'},
    {label: 'Call Center', value: 'callCenter'},
    {label: 'School Name', value: 'schoolName'},
    {label: 'Division', value: 'Division'},
    {label: 'Service Center', value: 'Service Center'},
    {
      label: 'DE DSCYF PID, Case, or authorization number',
      value: 'dEDSCYFPID,Case,orAuthorizationNumber',
    },
    {label: 'Call back number', value: 'callBackNumber'},
    {label: 'Conference Duration', value: 'conferenceDuration'},
    {label: 'Title', value: 'title'},
    {label: 'Location', value: 'location'},
    {label: 'Program Name', value: 'programName'},
    {label: 'Phone Extension', value: 'phoneExtension'},
    {label: 'Requestor Name', value: 'requestorName'},
    {label: 'Team Name', value: 'teamName'},
    {label: 'Client Name', value: 'clientName'},
    {
      label: 'Briefly describe the purpose of your call',
      value: 'brieflyDescribeThePurposeOfYouCall',
    },
    {label: 'Maryland Auto', value: 'marylandAuto'},
    {label: 'Department or Service Area', value: 'departmentOrServiceArea'},
    {label: 'Email Address', value: 'emailAddress'},
    {label: 'DE CDW', value: 'DeCDW'},
    {label: 'Endeavors phone number', value: 'endeavorsPhoneNumber'},
    {label: "Caller's Name", value: 'CallersName'},
    {label: 'Employee Identification Number', value: 'employeeIdentificationNumber'},
    {label: 'Your Department', value: 'yourDepartment'},
    {label: "Officer's Name", value: 'officersName'},
    {label: 'Form Type', value: 'formType'},
    {label: "Requester's first and last name", value: 'requestersFirstAndLastName'},
    {label: 'Region Number', value: 'regionNumber'},
    {label: 'Medical Record Number', value: 'medicalRecordNumber'},
    {label: "Patient's Name", value: 'patientsName'},
    {label: 'State Dept-Assigned Attorney', value: 'stateDeptAssignedAttorney'},
    {label: 'State Dept Case ID', value: 'stateDeptCaseID'},
    {label: 'Adventist Medical Group', value: 'adventistMedicalGroup'},
    {label: 'Clinic or Department', value: 'clinicOrDepartment'},
    {label: 'Location Code', value: 'locationCode'},
    {label: 'Program Code', value: 'programCode'},
    {label: 'Insure', value: 'insure'},
    {label: 'Onsite Participant', value: 'onsiteWithParticipant'},
    {label: 'CBP', value: 'CBP'},
    {label: 'Claim or Policy Number', value: 'claimOrPolicyNumber'},
    {label: 'Name of Adjuster', value: 'NameOfAdjuster'},
    {label: 'Your first name', value: 'yourFirstName'},
    {label: 'Requester store name', value: 'RequesterStoreName'},
    {label: "Employee's name", value: 'employeesName'},
    {label: 'FULL Claim ID', value: 'fullClaimID'},
    {label: 'Two-digit local agency number', value: 'twoDigitLocalAgencyNumber'},
    {label: 'Three Digit Clinic Number', value: 'threeDigitClinicNumber'},
    {label: 'CustomKey', value: 'customKey'},
    {label: 'Gender', value: 'gender'},
    {label: 'ADDITIONAL EXPIRATION (Interpreter Profile)', value: 'additionalExpiration'},
    {label: 'Backstop Partner', value: 'backstopPartner'},
    {label: 'Department Name (Requestor Profile)', value: 'departmentName'},
    {label: 'Claim numbe', value: 'claimNumbe'},
  ]

  const Scheduled_InPerson = [
    {label: 'Appointment Id', value: 'appointmentId'},
    {label: 'Type', value: 'type'},
    {label: 'Requestor', value: 'requestor'},
    {label: 'Invited Interpreters', value: 'invitedInterpreters'},
    {label: 'Filtered Invited Interpreters', value: 'filteredInvitedInterpreters'},
    {label: 'Invited Interpreters ID', value: 'invitedInterpretersID'},
    {label: 'Scheduled Interpreters', value: 'scheduledInterpreters'},
    {label: 'Scheduled Interpreters ID', value: 'scheduledInterpretersID'},
    {label: 'Status', value: 'status'},
    {label: 'Downloaded Time zone', value: 'downloadedTimeZone'},
    {label: 'Scheduled Start Time', value: 'scheduledStartTime'},
    {label: 'Scheduled End Time', value: 'scheduledEndTime'},
    {label: 'Confirmed Start Time', value: 'confirmedStartTime'},
    {label: 'Confirmed End Time', value: 'confirmedEndTime'},
    {label: 'Subject', value: 'subject'},
    {label: 'Client Priority', value: 'clientPriority'},
    {label: 'Interpreter Priority', value: 'interpreterPriority'},
    {label: 'Communication Type', value: 'communicationType'},
    {label: 'Record ID', value: 'recordID'},
    {label: 'Language 1', value: 'language1'},
    {label: 'Language 2', value: 'language2'},
    {label: 'Service Type', value: 'serviceType'},
    {label: 'Location', value: 'location'},
    {label: 'Miles To Appointment', value: 'milesToAppointment'},
    {label: 'Scheduled Duration', value: 'scheduledDuration'},
    {label: 'Client Billable Duration', value: 'clientBillableDuration'},
    {label: 'Interpreter Billable Duration', value: 'interpreterBillableDuration'},
    {label: 'Interpreter Special Rate Amount', value: 'interpreterSpecialRateAmount'},
    {label: 'Total Account Charge', value: 'totalAccountCharge'},
    {label: 'Account Expenses', value: 'accountExpenses'},
    {label: 'Total Interpreter Pay', value: 'totalInterpreterPay'},
    {label: 'Account Invoice Status', value: 'accountInvoiceStatus'},
    {label: 'Interpreter Expenses', value: 'interpreterExpenses'},
    {label: 'Account Name', value: 'accountName'},
    {label: 'Account ID', value: 'accountID'},
    {label: 'Canceled By', value: 'canceledBy'},
    {label: 'Cancellation Reason', value: 'cancellationReason'},
    {label: 'Cancellation Other Reason', value: 'cancellationOtherReason'},
    {label: 'Signature', value: 'signature'},
    {label: 'Sign Time', value: 'signTime'},
    {label: 'Signed By', value: 'signedBy'},
    {label: 'Total Interpretation Duration', value: 'totalInterpretatiOnDuration'},
    {label: 'Total Call Duration', value: 'totalCallDuration'},
    {label: 'Appointment Created Date', value: 'appointmentCreatedDate'},
    {label: 'Account Invoice ID', value: 'accountInvoiceID'},
    {label: 'Account Invoice Status', value: 'accountInvoiceStatus'},
    {label: 'Interpreter Invoice ID', value: 'interpreterInvoiceID'},
    {label: 'Interpreter Invoice Status', value: 'interpreterInvoiceStatus'},
    {label: 'Requestor Joined', value: 'requestorJoined'},
    {label: 'Interpreter Joined', value: 'interpreterJoined'},
    {label: 'Requestor Device Info', value: 'requestorDeviceInfo'},
    {label: 'Interpreter Device Info', value: 'interpreterDeviceInfo'},
    {label: 'Other Participants', value: 'otherParticipants'},
    {label: 'Conference Duration', value: 'conferenceDuration'},
    {label: 'Account Cancellation Rate', value: 'accountCancellationRate'},
    {label: 'Account Priority Rate', value: 'accountPriorityRate'},
    {label: 'Account Minimum', value: 'accountMinimum'},
    {label: 'Account Rounding Rate', value: 'accountRoundingRate'},
    {label: 'Account Thresholds', value: 'accountThresholds'},
    {label: 'Account Special Rate Amount', value: 'accountSpecialRateAmount'},
    {label: 'Interpreter Cancellation Rate', value: 'interpreterCancellationRate'},
    {label: 'Interpreter Priority Rate', value: 'interpreterPriorityRate'},
    {label: 'Interpreter Minimum', value: 'interpreterMinimum'},
    {label: 'Interpreter Rounding Rate', value: 'interpreterRoundingRate'},
    {label: 'Interpreter Thresholds', value: 'interpreterThresholds'},
    {label: 'Interpreter Special Rate Amount', value: 'interpreterSpecialRateAmount'},
    {label: 'Service Classification', value: 'serviceClassification'},
    {label: 'Gender Preference', value: 'genderPreference'},
    {label: 'Recurrence', value: 'Recurrence'},
    {label: 'Consumer Type', value: 'consumerType'},
    {label: 'Consumer Name', value: 'consumerName'},
    {label: 'Consumer ID', value: 'consumerID'},
    {label: 'Consumer Date of Birth', value: 'consumerDateOfBirth'},
    {label: 'Consumer Primary Number', value: 'consumerPrimaryNumber'},
    {label: 'Consumer Gender', value: 'consumerGender'},
    {label: 'Consumer Mobile Number', value: 'consumerMobileNumber'},
    {label: 'Consumer MRN', value: 'consumerMRN'},
    {label: 'Consumer PMI', value: 'consumerPMI'},
    {label: 'Consumer Employer', value: 'consumerEmployer'},
    {label: 'Consumer Can Contact', value: 'consumerCanContact'},
    {label: 'Consumer Location', value: 'consumerLocation'},
    {label: 'Consumer Incident Date', value: 'consumerIncidentDate'},
    {label: 'Consumer Legal Guardian', value: 'consumerLegalGuardian'},
    {label: 'Consumer Contact Number', value: 'consumerContactNumber'},
    {label: 'Consumer Email', value: 'consumerEmail'},
    {label: 'Requestor Pin', value: 'requestorPin'},
    {label: 'Client Company UniqueId', value: 'clientCompanyUniqueId'},
    {label: 'Appointment Tags', value: 'appointmentTags'},
    {label: 'Travel Time', value: 'travelTime'},
    {label: 'LAS Number', value: 'LASNumber'},
    {label: 'Patient First Name', value: 'patientFirstName'},
    {label: 'Patient Last Name', value: 'patientLastName'},
    {label: 'Conference Platform', value: 'conferencePlatform'},
    {
      label: 'ADDITIONAL EXPIRATION (Interpreter)',
      value: 'AdditionExpiration_Interpreter',
    },
    {label: 'Backstop Partner(Interpreter)', value: 'backstopPartner_Interpreter'},
    {label: 'Department Name (Requestor)', value: 'departmentName_Requestor'},
  ]

  const Operator = [
    {label: 'Call Id', value: 'callId'},
    {label: 'Requestor', value: 'requestor'},
    {label: 'Date/Time', value: 'dateTime'},
    {label: 'Downloaded Time zone', value: 'downloadedTimeZone'},
    {label: 'Language 1', value: 'language1'},
    {label: 'Language 2', value: 'language2'},
    {label: 'Service Type', value: 'serviceType'},
    {label: 'Type', value: 'type'},
    {label: 'Queue Time (s)', value: 'queueTime(s)'},
    {label: 'Call Center Queue/Hold Time (s)', value: 'callCenterQueueHoldTime(s)'},
    {label: 'Operator Name', value: 'operatorName'},
    {label: 'Operator Duration', value: 'operatorDuration'},
    {label: 'Intake Duration', value: 'intakeDuration'},
    {label: 'Call Status (Operator)', value: 'callStatus_Operator'},
    {label: 'CustomKey', value: 'customKey'},
    {label: 'Gender', value: 'gender'},
  ]

  const handleCheckboxChange = (columnValue: string) => {
    let updatedSelectedColumns
    if (selectedColumns.includes(columnValue)) {
      updatedSelectedColumns = selectedColumns.filter((col) => col !== columnValue)
      setSelectAll(false)
    } else {
      updatedSelectedColumns = [...selectedColumns, columnValue]
      const isAllSelected = updatedSelectedColumns.length === OnDemand.length
      setSelectAll(isAllSelected)
    }
    setSelectedColumns(updatedSelectedColumns)
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSelectAll(isChecked)

    if (isChecked) {
      switch (pageName) {
        case 'OnDemand':
          setSelectedColumns(OnDemand.map((col) => col.value))
          break
        case 'Scheduled_InPerson':
          setSelectedColumns(Scheduled_InPerson.map((col) => col.value))
          break
        case 'Operator':
          setSelectedColumns(Operator.map((col) => col.value))
          break
      }
    } else {
      setSelectedColumns([])
    }
  }

  const submitData = () => {
    console.log('Selected columns:', selectedColumns)
    console.log('file Type', fileType)
  }

  const resetSelection = () => {
    setSelectedColumns([])
    setSelectAll(false)
    setFileType('CSV')
  }

  return (
    <div className='modal fade' tabIndex={-1} id='kt_download_list_modal'>
      <div className='modal-dialog modal-xl'>
        <div className='modal-content'>
          <div className='modal-header py-2'>
            <h4 className='modal-title'>{title}</h4>
            <div
              className='btn btn-icon btn-sm btn-active-light-primary ms-2'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={resetSelection}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon svg-icon-2x'
              />
            </div>
          </div>

          <div className='modal-body' style={{maxHeight: '85vh', overflowY: 'scroll'}}>
            <div>
              <div style={{display: 'flex'}}>
                <p className='col-md-3'>Select file type</p>

                <label className='col-md-3'>
                  <input
                    type='checkbox'
                    value='CSV'
                    className='form-check-input mb-2'
                    style={{marginRight: '5px'}}
                    checked={fileType === 'CSV'}
                    onChange={() => {
                      setFileType('CSV')
                    }}
                  />
                  CSV
                </label>
                <label className='col-md-3'>
                  <input
                    type='checkbox'
                    value='XLSX'
                    className='form-check-input mb-2'
                    style={{marginRight: '5px'}}
                    checked={fileType === 'XLSX'}
                    onChange={() => {
                      setFileType('XLSX')
                    }}
                  />
                  XLSX
                </label>
              </div>

              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                  <p>Select columns to download</p>
                </div>
                <div>
                  <label className='form-label'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={selectAll}
                      style={{marginRight: '5px'}}
                      onChange={handleSelectAllChange}
                    />
                    Select All
                  </label>
                </div>
              </div>

              <hr className='m-0 p-2' />

              {pageName === 'OnDemand' && (
                <div className='row'>
                  {OnDemand.map((column, index) => (
                    <div key={index} className='col-md-3'>
                      <div className='form-check'>
                        <input
                          className='form-check-input mb-2'
                          type='checkbox'
                          id={column.value}
                          checked={selectedColumns.includes(column.value)}
                          onChange={() => handleCheckboxChange(column.value)}
                        />
                        <label className='form-check-label' htmlFor={column.value}>
                          {column.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {pageName === 'Scheduled_InPerson' && (
                <div className='row'>
                  {Scheduled_InPerson.map((column, index) => (
                    <div key={index} className='col-md-3'>
                      <div className='form-check'>
                        <input
                          className='form-check-input mb-2'
                          type='checkbox'
                          id={column.value}
                          checked={selectedColumns.includes(column.value)}
                          onChange={() => handleCheckboxChange(column.value)}
                        />
                        <label className='form-check-label' htmlFor={column.value}>
                          {column.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {pageName === 'Operator' && (
                <div className='row'>
                  {Operator.map((column, index) => (
                    <div key={index} className='col-md-3'>
                      <div className='form-check'>
                        <input
                          className='form-check-input mb-2'
                          type='checkbox'
                          id={column.value}
                          checked={selectedColumns.includes(column.value)}
                          onChange={() => handleCheckboxChange(column.value)}
                        />
                        <label className='form-check-label' htmlFor={column.value}>
                          {column.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className='modal-footer py-3'>
            <button
              type='reset'
              className='btn btn-light btn-sm'
              data-bs-dismiss='modal'
              onClick={resetSelection}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              onClick={submitData}
              disabled={selectedColumns.length === 0}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {DownloadModal}

import React from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from 'chart.js'
import 'chart.js/auto'

// Register necessary chart elements
ChartJS.register(Tooltip, Legend, CategoryScale, LinearScale, BarElement)

type Props = {
  details?: {
    x: string[]
    opi: number[]
    vri: number[]
    osi: number[]
  }
}

const TopAccountBarChart = ({ details }: Props) => {
  const data = {
    labels: details?.x,
    datasets: [
      {
        label: 'OPI',
        data: details?.opi, 
        backgroundColor: 'rgba(163, 162, 66, 0.6)',
        barPercentage: 0.6, 
        categoryPercentage: 0.8, 
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 2,
      },
      {
        label: 'VRI',
        data: details?.vri, 
        backgroundColor: 'rgba(227, 117, 155, 0.6)',
        barPercentage: 0.6, 
        categoryPercentage: 1, 
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 2,
      },
      {
        label: 'OSI',
        data: details?.osi, 
        backgroundColor: 'rgba(201, 203, 207, 0.6)',
        barPercentage: 0.6, 
        categoryPercentage: 1, 
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 2,
      },
    ],
  }

  const options = {
    indexAxis: 'y' as const, 
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem: any) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw} minutes`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        title: {
          display: true,
          text: 'Minutes',
          font: {
            size: 12,
          },
        },
        beginAtZero: true,
        ticks: {
          font: {
            size: 12,
          },
        },
        barPercentage: 0.5, 
        categoryPercentage: 0.6, 
      },
      y: {
        stacked: false, 
        title: {
          display: true,
          text: '',
          font: {
            size: 12,
          },
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
  }

  return (
    <div className='card card-lg-stretch mb-5 mb-xl-8 shadow-custom'>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark fs-6'>Top Accounts by Minutes</h3>
      </div>
      <div className='card-body pt-0 d-flex align-items-end' style={{ height: '500px' }}>
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

export default TopAccountBarChart

import {useQuery} from 'react-query'
import {useListView} from '../core/ListViewProvider'
import {CreateModalForm} from './CreateModalForm'
import {isNotEmpty, ModelQUERIES} from '../../../../../../../_metronic/helpers'
import {getDataRecordById} from '../core/_requests'

const CreateModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()

  //const {isLoading, data: dbObj, error} = useQuery('Create_PreCallPolicy', () => {})

  const {
    isLoading,
    data: dbObj,
    error,
  } = useQuery(
    `${ModelQUERIES.DATA_LIST}-dbObj-${itemIdForUpdate}`,
    () => {
      return getDataRecordById(itemIdForUpdate)
    },
    // {
    //   cacheTime: 0,
    //   enabled: itemIdForUpdate !== undefined && enabledQuery,
    //   onError: (err) => {
    //     setItemIdForUpdate(undefined)
    //     console.error(err)
    //   },
    // }

    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  if (!itemIdForUpdate) {
    return <CreateModalForm isObjLoading={false} dbObj={null} />
  }

  if (!isLoading && !error && dbObj) {
    return (
      <CreateModalForm
        isObjLoading={false}
        dbObj={dbObj}
      />
    )
  }

  return null
}

export {CreateModalFormWrapper}

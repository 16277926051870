import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getAuth} from '../modules/auth/core/AuthHelpers'

const AppApi = createApi({
  reducerPath: 'AppApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth?.isAuthenticated) {
        headers.set('Authorization', `Bearer ${auth.token}`)
        headers.set('Access-Control-Allow-Origin', '*')
      }
      return headers
    },
  }),
  tagTypes: ['precall-policy'],
  endpoints: () => ({}),
})

export default AppApi

import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {UserEditModal} from './user-edit-modal/UserEditModal'

import {KTCard} from '../../../../_metronic/helpers'
import {AdminModal} from './admin-edit-add-modal/AdminModal'

const UsersList: React.FC<Props> = ({userType, customerCode}) => {
  const {itemIdForUpdate, adminModalOpen} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader customerCode={customerCode} userType={userType} />
        <UsersTable userType={userType} />
      </KTCard>
      {itemIdForUpdate !== undefined && (
        <UserEditModal userType={userType} customerCode={customerCode} />
      )}
      {adminModalOpen !== undefined && <AdminModal rateCategory={'I'} />}
    </>
  )
}

type Props = {
  userType: string
  customerCode: string
}

const UsersListWrapper: React.FC<Props> = ({userType, customerCode}) => (
  <QueryRequestProvider>
    <QueryResponseProvider userType={userType} customerCode={customerCode}>
      <ListViewProvider>
        <UsersList userType={userType} customerCode={customerCode} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}

import {useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

type UserEditModalHeaderProps = {
  userType: string
}

const UserEditModalHeader: React.FC<UserEditModalHeaderProps> = ({userType}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header py-2'>
      {/* begin::Modal title */}
      <h2 className='fw-semibolder fs-3'>
        {itemIdForUpdate ? 'Edit' : 'Add'} {userType === 'CONSUMER' && 'Requester'}
        {userType === 'INTERPRETER' && 'Interpreter'}
        {userType === 'SYSTEM' && 'Administrators'}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {UserEditModalHeader}

import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedCustomers} from '../../core/_requests'
import toaster from '../../../../../../Utils/toaster'
import { useState } from 'react'
import { DeleteConfirmationModal } from '../../../../../common/Models/confirmation-modal/DeleteConfirmationModal'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const UsersListGrouping = () => {
  const {selected, clearSelected} = useListView()
  const queryClient = useQueryClient()
  const {query} = useQueryResponse()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteSelectedItems = useMutation(() => deleteSelectedCustomers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
      clearSelected()
      toaster('success', 'Succesfully Deactivated')
    },
  })

  return (
    <div className='d-flex justify-content-end align-items-center'>
      <div className='fw-bolder me-5 ms-5 '>
        <span className='badge badge-light-danger'>{selected.length} Selected</span>
      </div>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id='tooltip-filter'> Deactivate</Tooltip>}
      >
        <div>
          <button
            type='button'
            className='btn btn-sm btn-danger btn-icon px-4'
            //onClick={async () => await deleteSelectedItems.mutateAsync()}
            onClick={() => setDeleteModalOpen(true)}
          >
            <i className='bi bi-person-dash-fill'></i>
          </button>
        </div>
      </OverlayTrigger>

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          title='Confirmation'
          content={'Are you sure you would like to deactivate ?'}
          onSubmit={async () => await deleteSelectedItems.mutateAsync()}
          setOpen={setDeleteModalOpen}
        />
      )}
    </div>
  )
}

export {UsersListGrouping}

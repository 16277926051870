import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../../UI/members-management/users-list/core/_models'
import { useParams } from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_REQUESTERS = `${API_URL}/accounts/ddlist-awsuserid`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setcall_log: any
  setCurrentPage: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  setFilterData: any
  fetchcall_log: any
  searchQuery : any
}
export function LogsOnDemandFilterDropdown({
  setcall_log,
  setCurrentPage,
  setTotalPages,
  setFilterData,
  fetchcall_log,
  searchQuery
}: Props) {
  const [selectedRequestor, setSelectedRequestor] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedLanguages, setSelectedLanguages] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [callStatus, setCallStatus] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [currentPage, setpage] = useState(1)
  const {id} = useParams()

  const getRequesters = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_REQUESTERS}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseRequesters} = useQuery(
    `${roleQUERIES.requesters_LIST}`,
    getRequesters,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const requesterOptions =
    responseRequesters?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = async () => {
    const passingData = {
      awsUserId_REQList: selectedRequestor.map((option) => option.value),
      languageList: selectedLanguages.map((option) => option.label),
      callStateList: callStatus.map((option) => option.value),
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    }
    setFilterData(passingData)

    try {
      const response = await axios.post(`${API_URL}/AWSConnect/company-call-log-filter/${id}`, passingData, {
        params: {
          page: currentPage,
          items_per_page: 10,
          ...searchQuery,
        },
      })
      const {data, payload} = response.data
      setCurrentPage(1)
      setcall_log(data)
      setTotalPages(payload.pagination.last_page)
    } catch (error) {
      console.log(error)
    } finally {
      setShowDropdownMenu(false)
    }
  }

  const handleReset = async () => {
    setCurrentPage(1)
    setSelectedRequestor([])
    setSelectedLanguages([])
    setCallStatus([])
    setStartDate('')
    setEndDate('')
    setShowDropdownMenu(false)
    setFilterData({})
    await fetchcall_log(
      {},
      {
        search: '',
      }
    )
  }
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className='btn btn-icon btn-sm no-caret'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-400px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>

            <div className='separator border-gray-200'></div>
            <div className='card-scroll px-4 mt-4'>
              <div className='row flex-column'>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                      Requestor
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingCustomer ? requesterOptions : []}
                            placeholder='Select : All'
                            isMulti
                            value={selectedRequestor}
                            onChange={setSelectedRequestor}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='languages' className='form-label fs-7 mb-1'>
                      Languages
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingNativeLanguage ? languageOptions : []}
                            placeholder='Select : All'
                            isMulti
                            value={selectedLanguages}
                            onChange={setSelectedLanguages}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                      Call Status
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={[
                              {value: 'CNT', label: 'Connected'},
                              {value: 'ABD', label: 'Abandoned'},
                            ]}
                            placeholder='Select : All'
                            isMulti
                            value={callStatus}
                            onChange={setCallStatus}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                        Start Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder='Enter Subject'
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                        End Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-end pt-5 px-4'>
              <button
                type='reset'
                className='btn btn-sm btn-light me-2'
                data-kt-menu-dismiss='true'
                onClick={handleReset}
              >
                Reset
              </button>

              <button
                type='submit'
                className='btn btn-sm btn-primary'
                data-kt-menu-dismiss='true'
                onClick={handleSubmit}
              >
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}

import axios from 'axios'
import clsx from 'clsx'
import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import toaster from '../../../Utils/toaster'
import {updateUser} from './components/core/_requests'
import {useListView} from '../members-management/users-list/core/ListViewProvider'

interface PermissionsModalProps {
  show: boolean
  handleClose: () => void
  formik: any
  id: any
  refetchCsutomer: any
}

const PermissionsModal: React.FC<PermissionsModalProps> = ({
  show,
  handleClose,
  formik,
  id,
  refetchCsutomer,
}) => {
  const [loading, setLoading] = React.useState(false)
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const cancel = async (withRefresh?: boolean) => {
    if (withRefresh) {
      await refetchCsutomer()
    }
    setItemIdForUpdate(undefined)
    formik.resetForm()
  }

  const handleSaveChanges = async () => {
    try {
      setLoading(true)
      const API_URL = process.env.REACT_APP_API_URL

      const formData = new FormData()
      Object.keys(formik.values).forEach((key) => {
        const value = formik.values[key]
        formData.append(key, value === null ? '' : value)
      })

      formData.append('delete_Date', formik.values.delete_Date || '')
      formData.append('fK_PreCallPolicy', formik.values.fK_PreCallPolicy || '')

      try {
        const result = await updateUser(formData)
        if (result?.status === 'S') {
          toaster('success', result?.text ?? 'Record Updated')
          formik.resetForm()
          handleClose()
          await cancel(true)
        } else if (result?.status === 'E') {
          toaster('error', result?.text ?? 'Record Not Updated')
        }
      } catch (ex) {
        console.error(ex)
      }
    } catch (error) {
      console.error('Error updating permissions:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleModalClose = () => {
    formik.resetForm()
    handleClose()
  }

  return (
    <Modal show={show} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Permissions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-3'>
          <div className='row g-4 g-xl-6'>
            <div className='col-sm-6 col-md-6 col-lg-6 '>
              <label className='form-label fs-7 mb-1'>OPI</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('opI_ShdTelephonic')}
                    type='checkbox'
                    name='opI_ShdTelephonic'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.opI_ShdTelephonic && formik.errors.opI_ShdTelephonic,
                      },
                      {
                        'is-valid':
                          formik.touched.opI_ShdTelephonic && !formik.errors.opI_ShdTelephonic,
                      }
                    )}
                    checked={formik.values.opI_ShdTelephonic}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Scheduled Telephonic</label>
                </div>
              </div>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('opI_OndemandTelephonic')}
                    type='checkbox'
                    name='opI_OndemandTelephonic'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.opI_OndemandTelephonic &&
                          formik.errors.opI_OndemandTelephonic,
                      },
                      {
                        'is-valid':
                          formik.touched.opI_OndemandTelephonic &&
                          !formik.errors.opI_OndemandTelephonic,
                      }
                    )}
                    checked={formik.values.opI_OndemandTelephonic}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>On-Demand Telephonic</label>
                </div>
              </div>
              <label className='form-label fs-7 mb-1'>VRI</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('vrI_ShdVideoInteroreting')}
                    type='checkbox'
                    name='vrI_ShdVideoInteroreting'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.vrI_ShdVideoInteroreting &&
                          formik.errors.vrI_ShdVideoInteroreting,
                      },
                      {
                        'is-valid':
                          formik.touched.vrI_ShdVideoInteroreting &&
                          !formik.errors.vrI_ShdVideoInteroreting,
                      }
                    )}
                    checked={formik.values.vrI_ShdVideoInteroreting}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>
                    Scheduled Video Interpreting
                  </label>
                </div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('vrI_OndemandVideoInteroreting')}
                    type='checkbox'
                    name='vrI_OndemandVideoInteroreting'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.vrI_OndemandVideoInteroreting &&
                          formik.errors.vrI_OndemandVideoInteroreting,
                      },
                      {
                        'is-valid':
                          formik.touched.vrI_OndemandVideoInteroreting &&
                          !formik.errors.vrI_OndemandVideoInteroreting,
                      }
                    )}
                    checked={formik.values.vrI_OndemandVideoInteroreting}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>
                    On-Demand Video Interpreting
                  </label>
                </div>
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6 '>
              <label className='form-label fs-7 mb-1'>OSI</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('osI_OnsiteConsecutive')}
                    type='checkbox'
                    name='osI_OnsiteConsecutive'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.osI_OnsiteConsecutive &&
                          formik.errors.osI_OnsiteConsecutive,
                      },
                      {
                        'is-valid':
                          formik.touched.osI_OnsiteConsecutive &&
                          !formik.errors.osI_OnsiteConsecutive,
                      }
                    )}
                    checked={formik.values.osI_OnsiteConsecutive}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Onsite Consecutive</label>
                </div>
              </div>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('osI_OnsiteSimultaneous')}
                    type='checkbox'
                    name='osI_OnsiteSimultaneous'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.osI_OnsiteSimultaneous &&
                          formik.errors.osI_OnsiteSimultaneous,
                      },
                      {
                        'is-valid':
                          formik.touched.osI_OnsiteSimultaneous &&
                          !formik.errors.osI_OnsiteSimultaneous,
                      }
                    )}
                    checked={formik.values.osI_OnsiteSimultaneous}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Onsite Simultaneous</label>
                </div>
              </div>
              <label className='form-label fs-7 mb-1'>Other</label>
              <div className='d-flex align-items-center mb-2'>
                <div className='form-check-custom form-check-white form-check-sm-12'>
                  <input
                    placeholder=''
                    {...formik.getFieldProps('other_3rdPartyPlatform')}
                    type='checkbox'
                    name='other_3rdPartyPlatform'
                    className={clsx(
                      'form-check-input',
                      {
                        'is-invalid':
                          formik.touched.other_3rdPartyPlatform &&
                          formik.errors.other_3rdPartyPlatform,
                      },
                      {
                        'is-valid':
                          formik.touched.other_3rdPartyPlatform &&
                          !formik.errors.other_3rdPartyPlatform,
                      }
                    )}
                    checked={formik.values.other_3rdPartyPlatform}
                    disabled={formik.isSubmitting}
                  />
                  <label className='form-check-label form-check-sm'>Other</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleModalClose}>
          Close
        </Button>
        <Button variant='primary' onClick={handleSaveChanges} disabled={loading}>
          {loading ? 'Saving…' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PermissionsModal

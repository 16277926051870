/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type UsersListSearchComponentProps = {
  userType: string
}

const UsersListSearchComponent: React.FC<UsersListSearchComponentProps> = ({userType}) => {
  const {updateState} = useQueryRequest()
  const inputRef = useRef<HTMLInputElement>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const handleSearch = () => {
    if (inputRef.current) {
      const newSearchTerm = inputRef.current.value
      updateState({search: newSearchTerm, ...initialQueryState})
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  useEffect(() => {
    if (searchTerm === '') {
      updateState({search: '', ...initialQueryState})
    }
  }, [searchTerm])

  return (
    <div className='card-title m-0'>
      {/* begin::Search */}
      <div className='d-flex align-items-center position-relative'>
        <input
          ref={inputRef}
          type='text'
          data-kt-user-table-filter='search'
          className='form-control form-control-white form-control-sm w-250px custom-search-radius'
          placeholder={`Search ${userType === 'SYSTEM' ? 'Administrator' : ''}${
            userType === 'INTERPRETER' ? 'Intrepreter' : ''
          }${userType === 'CONSUMER' ? 'Requester' : ''}`}
          onKeyDown={handleKeyDown}
          onChange={() => setSearchTerm(inputRef.current?.value || '')}
        />
        <button
          type='button'
          className='btn btn-primary btn-sm custom-search-btn-radius px-4 btn-icon'
          onClick={handleSearch}
        >
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='' />
        </button>
      </div>
      {/* end::Search */}
    </div>
  )
}

export {UsersListSearchComponent}

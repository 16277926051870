import {useEffect} from 'react'
import {UserEditModalHeader} from './UserEditModalHeader'
import {UserEditModalFormWrapper} from './UserEditModalFormWrapper'
import {CustomerEditModal} from './CustomerEditModal'
import {KTSVG, roleQUERIES, isNotEmpty, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
type Props = {
  userType: string
}

const UserEditModal: React.FC<Props> = ({userType}) => {
  const {setItemIdForUpdate} = useListView()
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <>
      <div className='modal bg-white fade show d-block'>
        <div className='modal-dialog modal-fullscreen'>
          <div className='modal-content shadow-none'>
            <div className='modal-header'>
              <div className='d-flex flex-column'>
                <h4 className='modal-title'>Account Viewer</h4>
              </div>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                onClick={() => setItemIdForUpdate(undefined)}
              >
                <KTSVG
                  path='media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='card mb-5 mb-xl-5' style={{backgroundColor: '#FAF7F5'}}>
                <div className='card-body pt-9 pb-0'>
                  <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <img alt='Metornic' className='rounded-circle' />
                        {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div> */}
                      </div>
                      <div className='d-flex flex-center mt-2'>
                        <button
                          type='button'
                          className='btn btn-sm btn-primary'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_4'
                        >
                          <i className='fa-solid fa-pen '></i>
                          Edit
                        </button>
                      </div>
                    </div>

                    <div className='flex-grow-1'>
                      <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <a
                              href='#'
                              className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                            >
                              ABC Support Center
                            </a>
                            <span className='badge badge-light-success fw-bolder me-auto px-4 py-3 ms-3'>
                              Enabled
                            </span>
                          </div>

                          <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2 '>
                            <a
                              href='#'
                              className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'
                            >
                              <i className='bi bi-geo-alt-fill me-2'></i>
                              8701 Georgia Ave, Suite 800, Silver Spring, Maryland, 20910 , US
                            </a>
                            {/* <a
                                  href='#'
                                  className='d-flex align-items-center text-gray-600 text-hover-primary me-5 mb-2'
                                >
                                  <i className='fa-solid fa-phone me-2'></i>
                                  +94 77 345 6789 / +94 41 123 4556
                                </a> */}
                          </div>
                        </div>
                        <div className='d-flex flex-column'>
                          <div className='d-flex align-items-center mb-2'>
                            <button
                              type='button'
                              className='btn btn-sm btn-danger'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_3'
                            >
                              Deactivate
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='d-flex flex-column flex-grow-1 pe-8'>
                          <div className='d-flex flex-wrap'>
                            <div className='card-body p-0'>
                              <div className='row mb-5'>
                                <label className='col-lg-3 fw-bold text-black fs-6'>
                                  Default Service Type{' '}
                                </label>
                                <div className='col-lg-9'>
                                  <span className='fs-6 text-gray-900'> Business</span>
                                </div>
                              </div>
                              <div className='row mb-5'>
                                <label className='col-lg-3 fw-bold text-black fs-6'>IVR</label>
                                <div className='col-lg-9 fv-row'>
                                  <span className='fs-6'>: +1 222-833-5555</span>
                                </div>
                              </div>
                              <div className='row mb-5'>
                                <label className='col-lg-3 fw-bold text-black fs-6'>
                                  Timezone
                                  {/* <i
                            className='fas fa-exclamation-circle ms-1 fs-7'
                            data-bs-toggle='tooltip'
                            title='Phone number must be active'
                          ></i> */}
                                </label>
                                <div className='col-lg-9 d-flex align-items-center'>
                                  <span className=' fs-6 me-2'>
                                    (UTC-05:00) Eastern Time (US & Canada)
                                  </span>
                                  {/* <span className='badge badge-success'>Verified</span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='card mb-5'>
                <div
                  className='card-header py-0'
                  style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
                >
                  <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                    <li className='nav-item'>
                      <a data-bs-toggle='tab' href='#kt_tab_pane_3'>
                        Overview
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a data-bs-toggle='tab' href='#kt_tab_pane_4'>
                        Info
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='card-body'>
                  <div className=''>
                    <div className='tab-content' id='myTabContent'></div>
                  </div>
                  <img
                    className='w-100 card-rounded-bottom'
                    alt=''
                    src='assetsmedia/svg/illustrations/bg-4.svg'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )

  return (
    <>
      <div
        className='modal fade bg-white show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-fullscreen'>
          <CustomerEditModal />
        </div>
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {UserEditModal}

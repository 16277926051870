import {configureStore} from '@reduxjs/toolkit'
import precallReducer from '../modules/precall-management/precallSlice'
import AppApi from './api'

export const store = configureStore({
  reducer: {
    precall: precallReducer,
    [AppApi.reducerPath]: AppApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(AppApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

import {useQuery} from 'react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, ModelQUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getDataRecordById} from '../core/_requests'

type Props = {
  userID: string
}

const EditModalFormWrapper: React.FC<Props> = ({userID}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: dbObj,
    error,
  } = useQuery(
    `${ModelQUERIES.DATA_LIST}-dbObj-${itemIdForUpdate}`,
    () => {
      return getDataRecordById(itemIdForUpdate)
    },
    // {
    //   cacheTime: 0,
    //   enabled: itemIdForUpdate !== undefined && enabledQuery,
    //   onError: (err) => {
    //     setItemIdForUpdate(undefined)
    //     console.error(err)
    //   },
    // }

    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  if (!itemIdForUpdate) {
    return <EditModalForm userID={userID} isObjLoading={isLoading} dbObj={{code: undefined}} />
  }

  if (!isLoading && !error && dbObj) {
    return <EditModalForm userID={userID} isObjLoading={isLoading} dbObj={dbObj} />
  }

  return null
}

export {EditModalFormWrapper}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {KTSVG} from '../../../_metronic/helpers'
import {Customer} from './users-list/core/_models'
import {AccountDashboardOverview} from './AccountDashboardOverview'

type Props = {
  CustomerModel?: Customer
  isLoading: Boolean
}

const AccountOverview: React.FC<Props> = ({CustomerModel, isLoading}) => {
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_click_total'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Lifetime Usage</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='row g-4'>
                <div className='col-md-6 col-sm-12 '>
                  <div className='d-flex flex-column border border-2 bg-light-dark border-dark p-3 rounded min-h-100px'>
                    <h5 className='text-dark fw-semibold'>Total Earnings</h5>
                    <span className='fw-bold fs-1'>$0.00</span>
                    <span className='text-gray-800'>$0.00 + $0.00</span>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 '>
                  <div className='d-flex flex-column border border-2 bg-light-info border-info p-3 rounded min-h-100px'>
                    <h5 className='text-dark fw-semibold'>On Demand Calls</h5>
                    <span className='fw-bold fs-1'>0 Hours </span>
                    <span className='text-gray-800'>0 minutes</span>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 '>
                  <div className='d-flex flex-column border  border-2 bg-light-success border-success p-3 rounded min-h-100px'>
                    <h5 className='text-dark fw-semibold'>Completed Appointments</h5>
                    <span className='fw-bold fs-1'>0 Hours </span>
                    <span className='text-gray-800'>0 minutes</span>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 '>
                  <div className='d-flex flex-column border  border-2 bg-light-warning border-warning p-3 rounded min-h-100px'>
                    <h5 className='text-dark fw-semibold'>Aggregate Rating </h5>
                    <span className='fw-bold fs-1'>4.0</span>
                    <div className='rating'>
                      <div className='rating-label checked'>
                        <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon' />
                      </div>
                      <div className='rating-label checked'>
                        <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon' />
                      </div>
                      <div className='rating-label checked'>
                        <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon' />
                      </div>
                      <div className='rating-label checked'>
                        <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon' />
                      </div>
                      <div className='rating-label'>
                        <KTSVG path='media/icons/duotune/general/gen029.svg' className='svg-icon' />
                      </div>
                    </div>
                    {/* <span className='text-gray-500'>$0.00 + $0.00</span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='card-title d-flex align-items-center mb-3 mt-8'>
        <div className='d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <h5 className='text-black fs-4 fw-semibold mb-0'>Address and Contact</h5>
          </div>
          <div className='d-flex flex-wrap fs-6 '>
            <p
              className='text-gray-500 mb-0 fw-normal'
              style={{fontSize: '12px', maxWidth: '900px'}}
            >
              Manage Customer Details
            </p>
          </div>
        </div>
        <UnderDevelopmentBadge className='' />
      </div> */}
      {/* <div className='row g-4 mt-3'>
        <div className='col-sm-12 col-md-12'>
          <div className='d-flex flex-column '>
            {isLoading ? (
              <span
                className='indicator-progress p-5'
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
              >
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <>
                <h6 className='mb-3 '>{CustomerModel?.name}</h6>
                {CustomerModel?.street1 &&
                  CustomerModel?.city &&
                  CustomerModel?.postalCode &&
                  CustomerModel?.contryName && (
                    <div className='d-flex align-items-center text-gray-700 fw-semibold me-5 mb-3'>
                      <KTSVG
                        path='/media/icons/duotune/maps/map008.svg'
                        className='svg-icon-success svg-icon-2hx me-2'
                      />
                      {CustomerModel?.street1} {CustomerModel?.street2} {CustomerModel?.city}
                      {CustomerModel?.postalCode} , {CustomerModel?.contryName}
                    </div>
                  )}
                {CustomerModel?.defaultTimeZone && (
                  <div className='text-gray-700 fw-semibold'>
                    <KTSVG
                      path='/media/icons/duotune/maps/map004.svg'
                      className='svg-icon-info svg-icon-1 ms-1 me-3'
                    />
                    Timezone:{CustomerModel?.defaultTimeZone}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div> */}
      <div className='row g-4 mt-3'>
        {/* <div className='col-sm-12 col-md-12'>
          <div className='col-sm-12 col-md-12 col-lg-4'>
            <h5 className='text-black fs-4 fw-semibold mb-0'>Statistics</h5>

            <p className='fs-6 text-gray-400'>No appointments require immediate action</p>
          </div>
        </div> */}
        <AccountDashboardOverview />
      </div>
    </>
  )
}

export {AccountOverview}

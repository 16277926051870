import {FC} from 'react'

type Props = {
  last_login?: string
}

const UserLastLoginCell: FC<Props> = ({last_login}) => {
  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-CA');
    const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    return `${formattedDate} ${formattedTime}`;
  };
  const formattedDate = formatDate(last_login);
  return <div className=''>{formattedDate}</div>;
};
export { UserLastLoginCell };

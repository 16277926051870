// PieChart.tsx
import React from 'react'
import {Doughnut} from 'react-chartjs-2'
import 'chart.js/auto'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import UnderDevelopmentBadge from '../../../common/componenets/underDevelopment/underDevelopmentBadge'

// Register the necessary components with Chart.js
ChartJS.register(ArcElement, Tooltip, Legend)

type Props = {
  details: any
}
const CallDistributionDonutChart = ({details}: Props) => {
  const data = {
    labels: details?.x,
    datasets: [
      {
        label: '# Job status',
        data: details?.y,
        backgroundColor: [
          'rgba(163, 162, 66, 0.6)',
          'rgba(227, 117, 155, 0.6)',
          // 'rgba(201, 203, 207, 0.6)',
        ],
        borderColor: ['#ffffff', '#ffffff', '#ffffff'],
        // backgroundColor: [
        //   'rgba(88, 89, 91, 0.8)', // #58595B at 80% opacity
        //   'rgba(114, 57, 234, 0.8)', // #7239EA at 80% opacity
        //   'rgba(0, 158, 247, 0.8)', // #009ef7 at 80% opacity
        //   'rgba(255, 199, 0, 0.8)', // #ffc700 at 80% opacity
        //   'rgba(234, 124, 57, 0.8)', // #ea7c39 at 80% opacity
        //   'rgba(200, 33, 40, 0.8)', // #C82128 at 80% opacity
        //   'rgba(71, 175, 8, 0.8)', // #47af08 at 80% opacity
        // ],
        // borderColor: [
        //   '#ffffff', // Darker red
        //   '#ffffff', // Darker blue
        //   '#ffffff', // Darker yellow
        //   '#ffffff', // Darker green
        //   '#ffffff', // Darker blue
        //   '#ffffff', // Darker yellow
        //   '#ffffff',
        // ],
        borderWidth: 2,
      },
    ],
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const, // Use a string literal or 'as const' to ensure type compatibility
        align: 'center' as const, // Use 'start' as it is valid for align
        labels: {
          usePointStyle: true, // Use point style to look more like a list
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: '#000',
        formatter: (value: number, context: any) => {
          const label = context.chart.data.labels[context.dataIndex]
          return `${label}: ${value}`
        },
        font: {
          weight: 'bold',
        },
      },
    },
  }

  return (
    <div className='card card-lg-stretch shadow-custom '>
      <div style={{position: 'absolute', top: -12, right: -5, zIndex: '9'}}>
        <UnderDevelopmentBadge className={undefined} />
      </div>
      <div className='card-header border-0'>
        <h3 className='card-title fw-semibold text-dark fs-6'>
          Call Distribution by Requestor Device (OPI/VRI)
        </h3>
      </div>

      <div className='card-body pt-0 d-flex align-items-center justify-content-center'>
        <div style={{maxWidth: '300px', maxHeight: '300px'}}>
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </div>
  )
}

export default CallDistributionDonutChart

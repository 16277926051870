// DataContext.js
import {createContext, useContext, useState} from 'react'

const DataContext = createContext()

export function DataProvider({children}) {
  const [data3, setData] = useState('Initial Data')

  const [userData, setUserData] = useState({
    username: '',
    email: '',
  })

  const updateUser = (newUserData) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      ...newUserData,
    }))
  }

  return (
    <DataContext.Provider value={{data3, setData, userData, updateUser}}>
      {children}
    </DataContext.Provider>
  )
}

export function useData() {
  return useContext(DataContext)
}

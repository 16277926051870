/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {DashboardFilter} from './DashboardFilter'
import {DashboardSmallCards} from './DashboardSmallCards'
import '../Logs/style.css'
import TopAccountBarChart from './TopAccountBarChart'
import CallDistributionDonutChart from './CallDistributionDonutChart'
import CallDurationBarChart from './CallDurationBarChart'
import ServiceTypePolarChart from './ServiceTypePolarChart'
import TopLanguageBarChart from './TopLanguageBarChart'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {DashboardAnalyticsFilter} from './DashboardAnalyticsFilter'
import axios from 'axios'
import {CommonLoading} from '../../../../Utils/commonLoading'
import moment from 'moment'
import toaster from '../../../../Utils/toaster'

const API_URL = process.env.REACT_APP_API_URL

const DashboardAnalytics: FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [analyticsDetails, setAnalyticsDetails] = useState<any>([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const fetchAnalytics = async () => {
    setIsLoading(true)
    try {
      let response = await axios.post(`${API_URL}/Dashboard/analytics`, {})
      const {data} = response.data
      if (data) {
        setAnalyticsDetails(data)
        setStartDate(moment(data?.startDate).format('YYYY-MM-DD'))
        setEndDate(moment(data?.endDate).format('YYYY-MM-DD'))
      } else toaster('error', 'Loading failed!')
    } catch (error) {
      toaster('error', 'Loading failed!')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAnalytics()
  }, [])

  return (
    <>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <div className='bg-light px-3 py-2' style={{borderRadius: '6px'}}>
          <h5 className='text-gray-700 fs-7 mb-0 fw-semibold'>
            <KTSVG
              path='/media/icons/duotune/files/fil002.svg'
              className='svg-icon-muted svg-icon-3 me-2'
            />
            {moment(analyticsDetails?.startDate).format('DD MMM, YYYY')} -{' '}
            {moment(analyticsDetails?.endDate).format('DD MMM, YYYY')}
          </h5>
        </div>
        <div>
          {' '}
          <DashboardAnalyticsFilter
            setAnalyticsDetails={setAnalyticsDetails}
            fetchAnalytics={fetchAnalytics}
            setIsLoading={setIsLoading}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </div>
      </div>

      <>
        <div className='row gy-4 mb-2'>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={analyticsDetails?.totalCalls}
              className='text-success zoom'
              description='Total Jobs'
              url='bi bi-telephone-fill'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6  col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={analyticsDetails?.totalMinutes}
              className='text-info'
              description='Total Minutes '
              url='bi bi-clock-fill'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6  col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={analyticsDetails?.uniqueLanguages}
              className='text-primary zoom'
              description='Unique Languages'
              url='bi bi-translate'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={`${analyticsDetails?.opiMinutes ?? 0}`}
              className='text-warning'
              description='OPI Minutes'
              url='bi bi-clock-history'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={`${analyticsDetails?.vriMinutes ?? 0}`}
              className='text-info'
              description='VRI Minutes'
              url='bi bi-clock-history'
            />
          </div>
          <div className='col-sm-12 col-md-6 col-lg-6 col-xl-2 col-xxl-2 '>
            <DashboardSmallCards
              number={`${analyticsDetails?.osiMinutes ?? 0}`}
              className='text-success zoom'
              description='OSI Minutes'
              url='bi bi-clock-history'
            />
          </div>
        </div>
        <div className='row gy-4 gx-6 mb-2'>
          <div className='col-sm-12 col-xl-4'>
            <CallDurationBarChart details={analyticsDetails?.callDurationBarChart} />
          </div>
          <div className='col-sm-12 col-xl-4'>
            <CallDistributionDonutChart details={analyticsDetails?.callDistributionDonutChart} />
          </div>
          <div className='col-sm-12 col-xl-4'>
            <ServiceTypePolarChart details={analyticsDetails?.serviceTypePolarChart} />
          </div>
          <div className='col-sm-12 col-xl-6 mt-2'>
            <TopAccountBarChart details={analyticsDetails?.topAccountBarChart} />
          </div>
          <div className='col-sm-12 col-xl-6 mt-2'>
            <TopLanguageBarChart details={analyticsDetails?.topLanguageBarChart} />
          </div>
        </div>
        {isLoading && <CommonLoading />}
      </>
    </>
  )
}

export {DashboardAnalytics}

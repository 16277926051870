import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedUsers, deleteUser} from '../../core/_requests'
import {DeleteConfirmationModal} from '../../../../../common/Models/confirmation-modal/DeleteConfirmationModal'

type Props = {
  code: string
  userType?: string
  status?: string
}

const UserActionsCell: FC<Props> = ({code, userType, status}) => {
  const {setItemIdForUpdate, setAdminModalOpen} = useListView()
  const {selected, onSelect, clearSelected} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
    if (!isDeleteModalOpen) {
      clearSelected()
    }
  }, [isDeleteModalOpen])

  const openEditModal = () => {
    setItemIdForUpdate(code)
  }

  const openRateModal = () => {
    setAdminModalOpen?.(code)
  }

  // const deleteData: Deletemodel = {
  //   email: email,
  //   code: code,
  // };

  const deleteItem = useMutation(() => deleteUser(code), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })

  const deleteSelectedItems = useMutation(() => deleteSelectedUsers(selected), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      setDeleteModalOpen(false)
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}-ADMIN`])
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}-USER`])
      clearSelected()
    },
  })

  // const func_reinvite = (param: string) => {
  //   reinvite(code)
  // }

  return (
    <>
      {userType == 'USER' && (
        <a
          href='#'
          className={`btn btn-active-light-primary btn-sm me-1 ${
            status != 'PENDING' ? 'disabled' : ''
          }`}
          onClick={() => {
            if (status == 'PENDING') {
              openEditModal()
            }
          }}
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
      )}

      {userType === 'USER' && (
        <a
          className={`btn btn-icon btn-active-color-danger btn-sm me-1 ${
            status != 'PENDING' ? 'disabled' : ''
          }`}
          onClick={() => {
            if (status == 'PENDING') {
              onSelect(code)
              setDeleteModalOpen(true)
            }
          }}
        >
          {/* <i className='bi bi-person-dash-fill'></i> */}
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      )}

      {userType == 'ADMIN' && (
        <a href='#' className='btn btn-active-light-primary btn-sm' onClick={openEditModal}>
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </a>
      )}

      {userType === 'ADMIN' && (
        <a
          className={'btn btn-icon btn-active-color-danger btn-sm me-1'}
          onClick={() => {
            onSelect(code)
            setDeleteModalOpen(true)
          }}
        >
          {/* <i className='bi bi-person-dash-fill'></i> */}
          <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
        </a>
      )}

      {userType === 'ADMIN' && (
        <a
          className={`btn btn-icon btn-active-color-success btn-sm me-1 ${
            status != 'PENDING' ? 'disabled' : ''
          }`}
          onClick={() => {
            if (status == 'PENDING') {
              onSelect(code)
              openRateModal()
            }
          }}
        >
          <i className='bi bi-check-circle-fill me-2'></i>
        </a>
      )}

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          title='Confirmation'
          content={'Are you sure you would like to delete ?'}
          onSubmit={async () => await deleteSelectedItems.mutateAsync()}
          setOpen={setDeleteModalOpen}
        />
      )}
    </>
  )
}

export {UserActionsCell}

import axios, { AxiosResponse } from 'axios';
import { ID, Response } from '../../../../_metronic/helpers';
import { Notification, NotificationsQueryResponse } from './_models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_NOTIFICATIONS_URL = `${API_URL}/Notification`;
const UPDATE_NOTIFICATION_STATUS_URL= `${API_URL}/Notification`;


const getNotifications = (): Promise<NotificationsQueryResponse> => {
  return axios
    .get(`${GET_NOTIFICATIONS_URL}`)
    .then((response: AxiosResponse<NotificationsQueryResponse>) => response.data);
};
const updateNotificationStatus = (): Promise<void> => {
  return axios
    .put(`${UPDATE_NOTIFICATION_STATUS_URL}`)
    .then((response: AxiosResponse<void>) => response.data);


}
export {
  getNotifications,
  updateNotificationStatus
 
};

import React from 'react'
import GoogleMapReact from 'google-map-react'

interface AnyReactComponentProps {
  text: string
  lat: number
  lng: number
}

const AnyReactComponent: React.FC<AnyReactComponentProps> = ({text}) => <div>{text}</div>

const LocationMap: React.FC = () => {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  }

  // Replace 'YOUR_GOOGLE_MAPS_API_KEY' with your actual API key
  const apiKey = 'AIzaSyC0Nh39yAtFIg-x83gBbRCIfOOE_N8Qdl0'

  return (
    // Important! Always set the container height explicitly
    <div style={{height: '40vh', width: '100%'}}>
      <GoogleMapReact
        bootstrapURLKeys={{key: apiKey}}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent lat={59.955413} lng={30.337844} text='My Marker' />
      </GoogleMapReact>
    </div>
  )
}

export default LocationMap

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {ModelQueryResponse, Model, ResponseObject} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const POST_Obj_URL = `${API_URL}/documents`
const GET_Objs_URL = `${API_URL}/user-documents`
const GET_ObjBY_URL = `${API_URL}/master/data/SERVICE_TYPE`
const Update_ObjBY_URL = `${API_URL}/master/data/SERVICE_TYPE/update`
const ActiveStatusChange_ObjBY_URL = `${API_URL}/master/isactive-update-languages`
const Delete_UERSBY_URL = `${API_URL}/documents`

const getAllData = (query: string, userID: string): Promise<ModelQueryResponse> => {
  var dt = axios
    .get(`${GET_Objs_URL}/${userID}/?${query}`)
    .then((d: AxiosResponse<ModelQueryResponse>) => d.data)
  return dt
}

const deleteUser = (Id: ID): Promise<ID | undefined> => {
  return axios
    .delete(`${Delete_UERSBY_URL}/${Id}/delete`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}

const getDataRecordById = (id: ID): Promise<Model | undefined> => {
  var dr = axios
    .get(`${GET_ObjBY_URL}/${id ?? 'null'}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
  return dr
}

const createDataRecord = (dbObj: FormData, userID: string): Promise<ResponseObject | undefined> => {
  return axios
    .post(`${POST_Obj_URL}/${userID ?? 'null'}/insert`, dbObj, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    })
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const updateDataRecord = (dbObj: Model): Promise<ResponseObject | undefined> => {
  return axios
    .put(Update_ObjBY_URL, dbObj)
    .then((response: AxiosResponse<ResponseObject>) => response.data)
    .then((response: ResponseObject) => response)
}

const activeStatusChangeDataRecord = (Id: ID): Promise<ID | undefined> => {
  return axios
    .put(`${ActiveStatusChange_ObjBY_URL}/${Id}`)
    .then((response: AxiosResponse<Response<string>>) => response.data)
    .then((response: Response<string>) => response.data)
}
const activeStatusChangeSelectedDataRecords = (Ids: Array<ID>): Promise<void> => {
  const requests = Ids.map((id) => axios.put(`${ActiveStatusChange_ObjBY_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getAllData,
  activeStatusChangeDataRecord,
  activeStatusChangeSelectedDataRecords,
  getDataRecordById,
  createDataRecord,
  updateDataRecord,
  deleteUser,
}

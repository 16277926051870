/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {User} from '../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <Link to={'/profile/' + user.code}>
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-40px overflow-hidden me-3'>
        <a href='#'>
          {user.avatar ? (
            <div className='symbol-label'>
              <img
                src={process.env.REACT_APP_IMG_URL + '/profile/' + user?.avatar}
                alt={
                  (user?.firstName ?? '').charAt(0).toUpperCase() +
                  '' +
                  (user?.lastName ?? '').charAt(0).toUpperCase()
                }
                className='w-100'
              />
            </div>
          ) : (
            <div
              className={clsx(
                'symbol-label fs-4',
                'badge-light-success',
                `bg-light-${
                  user.accountStatus == 'Not Active'
                    ? 'danger'
                    : user.lastLoginDate == 'new'
                    ? 'back'
                    : 'success'
                }`
              )}
            >
              <span style={{fontSize: '20x'}} className='fs-7 text-gray-800'>
                {(user?.firstName ?? '').charAt(0).toUpperCase() +
                  '' +
                  (user?.lastName ?? '').charAt(0).toUpperCase()}
              </span>
            </div>
          )}
        </a>
      </div>
      <div className='d-flex flex-column'>
        <a href={'/profile/' + user.code} className='text-dark fw-semibold text-hover-primary mb-1'>
          {user.firstName + ' ' + user.lastName}
        </a>

        <span className='fs-8 text-gray-600'>
          {user.email}{' '}
          {/*{user.isdeleted || user.accountStatus === 'Not Active' ? (
          <span className='badge badge-light-danger'>DISABLED </span>
        ) : user.isConfirmed ? (
          <span className='badge badge-light-success'>ACTIVE</span>
        ) : (
          <span className='badge badge-light-warning'>PENDING </span>
        )}{' '}*/}
        </span>
      </div>
    </div>
  </Link>
)

export {UserInfoCell}

import {type} from 'os'
import {ID, Response} from '../../../../../_metronic/helpers'

export type Model = {
  code?: number
  name?: string
  acquisition_date?:string
  expiration_date?:string
  description?: string
  file?: File
  fK_ModifiedBy?: string
  modifiedDateTime?: string
  fileName?: string
}

export type ResponseObject = {
  status?: ID
  text?: string
}

export type ModelQueryResponse = Response<Array<Model>>

export const initial: Model = {
  code: 0,
  name: '',
  description: '',
}

export const initialResponseObject: ResponseObject = {
  status: '',
  text: '',
}

import {useState, useEffect} from 'react'
import axios from 'axios'
import {browserName, osName, isMobile, isTablet} from 'react-device-detect'

type DeviceInfo = {
  deviceId:string
  ipAddress: string
  latitude: number | null
  longitude: number | null
  deviceType: string
  browser: string
  loginTimestamp: string
}

export const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>({
    deviceId:'',
    ipAddress: '',
    latitude: 0,
    longitude: 0,
    deviceType: '',
    browser: '',
    loginTimestamp: '',
  })

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json')
        const device = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop'
        const location = await getLocation()
         const loginTimestamp = new Date().toISOString()

        setDeviceInfo({
          deviceId: response.data.ip,
          ipAddress: response.data.ip,
          latitude: location?.latitude ?? null,
          longitude: location?.longitude ?? null,
          deviceType: `${device}-${osName}`,
          browser: browserName,
          loginTimestamp: loginTimestamp,
        })
      } catch (error) {
        console.error('Error fetching IP address:', error)
      }
    }

    fetchIpAddress()
  }, [])

  const getLocation = async (): Promise<{latitude: number; longitude: number} | null> => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const {latitude, longitude} = position.coords
            resolve({latitude, longitude})
          },
          (error) => {
            console.error('Error getting location:', error)
            resolve(null)
          }
        )
      } else {
        console.error('Geolocation is not supported by this browser.')
        resolve(null)
      }
    })
  }

  return deviceInfo
}

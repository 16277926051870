import React, {useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import './style.css'


const status = [
  {value: 'option 1', label: 'Approved'},
  {value: 'option 2', label: 'Rejected'},
  {value: 'option 3', label: 'Viewed'},
  {value: 'option 4', label: 'Sent'},
  {value: 'option 5', label: 'Voided'},
  {value: 'option 6', label: 'Revided'},
  {value: 'option 7', label: 'Viewed'},
  {value: 'option 8', label: 'Paid'},
  {value: 'option 9', label: 'Draft'},
  {value: 'option 10', label: 'Archived'},
  {value: 'option 11', label: 'Sent To QuickBooks'},
  {value: 'option 12', label: 'Sent To QuickBooks Failed'},
]
const interpreter = [
  {value: 'option 1', label: 'HSB 0'},
  {value: 'option 2', label: 'HSB 1'},
  {value: 'option 3', label: 'HSB 2'},
  {value: 'option 4', label: 'HSB 3'},
  {value: 'option 5', label: 'HSB 4'},
  {value: 'option 6', label: 'HSB 5'},
]

export function BillingMyFilterDropdown() {
  return (
    <Dropdown>
      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
        <Dropdown.Toggle
          variant='primary'
          id='dropdown-basic'
          className='btn btn-icon btn-sm no-caret'
        >
          <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
        </Dropdown.Toggle>
      </OverlayTrigger>

      <Dropdown.Menu className='p-4 w-400px' style={{minHeight: '100px'}}>
        <div className='px-4 pb-4'>
          <div className='fs-4 text-dark fw-semibolder'>Filter</div>
        </div>

        <div className='separator border-gray-200'></div>
        <div className='px-4 mt-4'>
          <div className='row flex-column'>
            <div className='col-sm-12 col-md-12'>
              <div className='mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                  Status
                </label>
                <div className='row g-4 g-xl-4'>
                  <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='w-100'>
                      <Select
                        className='react-select-styled react-select-solid react-select-sm'
                        classNamePrefix='react-select'
                        options={status}
                        placeholder='Select : All'
                        isMulti
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            
            </div>

            <div className='fv-row mb-3'>
              <div className='row '>
                <div className='col-sm-12'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    Date Range
                  </label>
                  <div className='d-flex w-100 flex-1 align-items-center'>
                    <input
                      type='date'
                      className='form-control form-control-white form-select-sm custom-input-height'
                      placeholder='Enter Subject'
                    />
                    -
                    <input
                      type='date'
                      className='form-control form-control-white form-select-sm custom-input-height'
                      placeholder='Start Time'
                    />
                  </div>
                </div>
                {/* <div className='col-sm-6'>
                  <label htmlFor='exampleFormControlInput1' className='form-label fs-7 mb-1'>
                    End Date
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-white form-select-sm custom-input-height'
                    placeholder='Start Time'
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className='text-end pt-3 px-4'>
          <button type='reset' className='btn btn-sm btn-light me-2' data-kt-menu-dismiss='true'>
            Reset
          </button>

          <button type='submit' className='btn btn-sm btn-primary' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

import {useEffect} from 'react'
import {MyProfileView} from '../../configuration-management/components/MyProfileView'
import {
  ListViewProvider,
  useListView,
} from '../../members-management/users-list/core/ListViewProvider'
import {UserEditModal} from '../../members-management/users-list/user-edit-modal/UserEditModal'
import {QueryRequestProvider} from '../../members-management/users-list/core/QueryRequestProvider'
import {QueryResponseProvider} from '../../members-management/users-list/core/QueryResponseProvider'
import {useQuery} from 'react-query'
import {getUserByID} from './core/_requests'

const UsersList: React.FC<Props> = ({userType, customerCode}) => {
  const {itemIdForUpdate} = useListView()

  const {data: user} = useQuery(
    [`customer-detail`, itemIdForUpdate ?? itemIdForUpdate],
    () => {
      return getUserByID(itemIdForUpdate ?? itemIdForUpdate)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <>
      <MyProfileView className={''} />
      {itemIdForUpdate !== undefined && (
        <UserEditModal userType={user?.userType ?? 'CONSUMER'} customerCode={user?.code ?? 0} />
      )}
    </>
  )
}

type Props = {
  userType: string
  customerCode: number
}

const UserListEditInProfile: React.FC<Props> = ({userType, customerCode}) => (
  <QueryRequestProvider>
    <QueryResponseProvider userType={userType} customerCode={customerCode}>
      <ListViewProvider>
        <UsersList userType={userType} customerCode={customerCode} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UserListEditInProfile}

import {KTSVG, QUERIES} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {FC, useEffect, useState} from 'react'
import {Form, Formik} from 'formik'
import Select from 'react-select'
import * as Yup from 'yup'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import toaster from '../../../../../Utils/toaster'
import {Modal} from 'bootstrap'
import {useQuery, useQueryClient} from 'react-query'
import {getUserById} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

const API_URL = process.env.REACT_APP_API_URL
const Approve_UERSBY_URL = `${API_URL}/accounts/language/approve`

const HoursOptions: any = Array.from({length: 23}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

interface ModalProps {
  rateCategory: string
}

const MinutesOptions: any = Array.from({length: 60}, (_, i) => ({
  value: i,
  label: String(i).padStart(2, '0'),
}))

const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

const AdminModal: FC<ModalProps> = ({rateCategory}) => {
  const {adminModalOpen, setAdminModalOpen, clearSelected} = useListView()
  const [languages, setLanguages] = useState<any>([])
  const [serviceTypes, setServiceTypes] = useState<any>([])
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [communicationTypes, setCommunicationTypes] = useState<any>([])
  const [saveNewBtn, setSaveNewBtn] = useState(false)

  const {data: user} = useQuery(
    `${QUERIES.USERS_LIST}-user-${adminModalOpen}`,
    () => {
      return getUserById(adminModalOpen)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const fetchLanguages = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/languages/active-shortlist`)
      setLanguages(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllServices = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/COMMUNICATION_TYPE`)
      setCommunicationTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllCommunicationType = async () => {
    try {
      let response = await axios.get(`${API_URL}/master/getall/SERVICE_TYPE`)
      setServiceTypes(response.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (adminModalOpen == null) {
      document.body.classList.add('modal-open')
      return () => {
        document.body.classList.remove('modal-open')
      }
    }
  }, [adminModalOpen])

  useEffect(() => {
    fetchLanguages()
    fetchAllServices()
    fetchAllCommunicationType()
  }, [adminModalOpen])

  const daysOptions: any = [
    {value: '1', label: 'Sunday'},
    {value: '2', label: 'Monday'},
    {value: '3', label: 'Tuesday'},
    {value: '4', label: 'Wednesday'},
    {value: '5', label: 'Thursday'},
    {value: '6', label: 'Friday'},
    {value: '7', label: 'Saturday'},
  ]

  let {id} = useParams()

  const handleCancel = () => {
    clearSelected()
    if (setAdminModalOpen !== undefined) {
      setAdminModalOpen(undefined)
    }
    queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}-ADMIN`])
  }

  const createRateSchema = Yup.object().shape({
    fK_LanguageFrom: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_LanguageTo: Yup.string().required('Required').notOneOf(['0'], 'Required'),
    fK_ServiceType: Yup.array().required('Required').min(1, 'Required'),
    fK_CommunicationType: Yup.array().required('Required').min(1, 'Required'),
    startTime: Yup.string().required('Required'),
    endTime: Yup.string()
      .test('is-after-start', 'End time cannot be before start time', function (value) {
        const {startTime} = this.parent
        if (startTime && value) {
          return timeToMinutes(value) > timeToMinutes(startTime)
        }
        return true
      })
      .required('Required'),
    minimumBillableHours: Yup.number().required('Required'),
    minimumBillableMinutes: Yup.number().required('Required'),
    incrementsHours: Yup.number().required('Required'),
    incrementsMinutes: Yup.number().required('Required'),
    days: Yup.array().required('Required').min(1, 'Required'),
    regularRate: Yup.number().required('Required').min(0, 'Rate cannot be negative'),
    rushRate: Yup.number().required('Required').min(0, 'Rate cannot be negative'),
    yearOfExp: Yup.number().required('required').min(0, 'Must be a positive number'),
    emergencyRate: Yup.number().required('Required').min(0, 'Rate cannot be negative'),
    conferenceCharges: Yup.number().required('Required').min(0, 'Charges cannot be negative'),
  })

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_add_acc_rate'
        tabIndex={-1}
        aria-modal='true'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-xl'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            {/* begin::Modal body */}
            <div className='modal-body scroll-y  modal-xl'>
              <Formik
                enableReinitialize
                initialValues={{
                  fK_LanguageFrom: user?.languageFrom || 'EN',
                  fK_LanguageTo: user?.languageTo || '',
                  fK_ServiceType: user?.specializedTypes
                    ? user.specializedTypes.map((item) => ({
                        value: item.value,
                        label:
                          serviceTypes.find(
                            (lang: {key: string | undefined}) => lang.key === item.value
                          )?.value || item.value,
                      }))
                    : [],
                  yearOfExp: user?.yearOfExp || '',
                  fK_CommunicationType: [],
                  startTime: '',
                  endTime: '',
                  minimumBillableHours: 0,
                  minimumBillableMinutes: 0,
                  incrementsHours: 0,
                  incrementsMinutes: 0,
                  days: [],
                  regularRate: '',
                  rushRate: '',
                  emergencyRate: '',
                  conferenceCharges: '0',
                  isActive: true,
                }}
                validationSchema={createRateSchema}
                onSubmit={async (values, {setSubmitting, resetForm, setFieldValue}) => {
                  setSubmitting(true)
                  if (adminModalOpen === null) {
                    try {
                      let response = await axios.post(`${API_URL}/accounts/language-rate/Insert`, {
                        ...values,
                        userId: id,
                        fK_LanguageTo: [values.fK_LanguageTo],
                        days: values.days.map((item: any) => item.value),
                        fK_CommunicationType: values.fK_CommunicationType.map(
                          (item: any) => item.value
                        ),
                        fK_ServiceType: values.fK_ServiceType.map((item: any) => item.value),
                        rateCategory: rateCategory,
                        yearOfExp: values.yearOfExp,
                      })

                      if (response.data.status === 'S') {
                        toaster(
                          'success',
                          response.data.text ?? 'Successfully added languge & rates'
                        )
                        resetForm()
                        if (saveNewBtn === false) {
                          handleCancel()
                        } else {
                          queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}-ADMIN`])
                        }
                      } else if (response.data.status === 'E') {
                        toaster('error', response.data.text ?? 'Updated Error')
                      }
                    } catch (ex) {
                      toaster('error', 'Server Error')
                      console.error(ex)
                    } finally {
                      setSaveNewBtn(false)
                      setSubmitting(true)
                    }
                  } else {
                    try {
                      let response = await axios.post(`${API_URL}/rates/rate`, {
                        ...values,
                        userId: id,
                        fK_LanguageTo: [values.fK_LanguageTo],
                        days: values.days.map((item: any) => item.value),
                        fK_CommunicationType: values.fK_CommunicationType.map(
                          (item: any) => item.value
                        ),
                        fK_ServiceType: values.fK_ServiceType.map((item: any) => item.value),
                        rateCategory: rateCategory,
                      })

                      if (response.data.status === 'S') {
                        let approveResponse = await axios.post(
                          `${Approve_UERSBY_URL}/${adminModalOpen}`
                        )
                        if (approveResponse.data.status === 'S') {
                          toaster(
                            'success',
                            approveResponse.data.text ?? 'Successfully approved & rates added'
                          )
                          if (saveNewBtn === false) {
                            handleCancel()
                            resetForm()
                            const modal = document.getElementById('kt_add_acc_rate')
                            if (modal) {
                              const modalInstance = Modal.getInstance(modal)
                              if (modalInstance) modalInstance.hide()
                            }
                          } else {
                            setFieldValue('fK_CommunicationType', [])
                            setFieldValue('startTime', '')
                            setFieldValue('endTime', '')
                            setFieldValue('minimumBillableHours', 0)
                            setFieldValue('minimumBillableMinutes', 0)
                            setFieldValue('incrementsHours', 0)
                            setFieldValue('incrementsMinutes', 0)
                            setFieldValue('days', [])
                            setFieldValue('regularRate', '')
                            setFieldValue('rushRate', '')
                            setFieldValue('emergencyRate', '')
                            setFieldValue('conferenceCharges', '0')
                            setFieldValue('isActive', true)
                            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}-ADMIN`])
                          }
                        } else {
                          toaster('error', approveResponse.data.text)
                        }
                      } else if (response.data.status === 'E') {
                        toaster('error', response.data.text ?? 'Updated Error')
                      }
                    } catch (ex) {
                      toaster('error', 'Server Error')
                      console.error(ex)
                    } finally {
                      setSaveNewBtn(false)
                      setSubmitting(true)
                    }
                  }
                }}
              >
                {({
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  setFieldTouched,
                  setFieldValue,
                  handleSubmit,
                  resetForm,
                  values,
                  errors,
                  touched,
                  isValid,
                  dirty,
                  ...formik
                }) => (
                  <Form>
                    <div className='modal-content'>
                      <div className='modal-header py-2'>
                        <h4 className='modal-title'>
                          {adminModalOpen == null
                            ? 'Add Rate & Language'
                            : 'Rate & Language Approval'}
                        </h4>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                          onClick={() => {
                            resetForm()
                            handleCancel()
                          }}
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr061.svg'
                            className='svg-icon svg-icon-2x'
                          />
                        </div>
                      </div>

                      <div className='modal-body' style={{maxHeight: '80vh', overflowY: 'scroll'}}>
                        <div className='row g-4 g-xl-6'>
                          <div className='col-sm-12 col-md-12 col-lg-12'>
                            <div className=''>
                              <div className='row g-4 g-xl-6'>
                                <div className='col-sm-12 col-md-12 '>
                                  <div className='row g-8 mb-0'>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-12'>
                                          <label
                                            htmlFor='exampleFormControlInput1'
                                            className='required  form-label fs-7 mb-1'
                                          >
                                            From Language
                                          </label>
                                          <Select
                                            {...formik.getFieldProps('fK_LanguageFrom')}
                                            className='react-select-styled react-select-solid react-select-sm'
                                            classNamePrefix='react-select'
                                            options={languages?.map((item: any) => ({
                                              value: item.key,
                                              label: item.value,
                                            }))}
                                            placeholder='Select'
                                            isDisabled={adminModalOpen !== null}
                                            value={{
                                              value: values.fK_LanguageFrom,
                                              label:
                                                languages?.find(
                                                  (x: any) => x.key === values.fK_LanguageFrom
                                                )?.value || 'Select From Language',
                                            }}
                                            onChange={(selectedOption: any) => {
                                              setFieldValue(
                                                'fK_LanguageFrom',
                                                selectedOption?.value
                                              )
                                            }}
                                            onBlur={(e) => setFieldTouched('fK_LanguageFrom', true)}
                                            styles={{
                                              control: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                              }),
                                            }}
                                          />
                                          {errors.fK_LanguageFrom && touched?.fK_LanguageFrom && (
                                            <div className='fv-plugins-message-container'>
                                              <div className='fv-help-block'>
                                                <span role='alert'>
                                                  {String(errors.fK_LanguageFrom ?? '')}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-12'>
                                          <div className='d-flex justify-content-between'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required  form-label fs-7 mb-1'
                                            >
                                              To Language
                                            </label>
                                          </div>

                                          <Select
                                            {...formik.getFieldProps('fK_LanguageTo')}
                                            className='react-select-styled react-select-solid react-select-sm'
                                            classNamePrefix='react-select'
                                            options={languages.map((item: any) => ({
                                              value: item.key,
                                              label: item.value,
                                            }))}
                                            placeholder='Select To Languages'
                                            isDisabled={adminModalOpen !== null}
                                            value={{
                                              value: values.fK_LanguageTo,
                                              label:
                                                languages?.find(
                                                  (x: any) => x.key === values.fK_LanguageTo
                                                )?.value || 'Select From Language',
                                            }}
                                            onChange={(selectedOption: any) => {
                                              setFieldValue('fK_LanguageTo', selectedOption?.value)
                                            }}
                                            onBlur={(e) => setFieldTouched('fK_LanguageTo', true)}
                                            styles={{
                                              control: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                              }),
                                            }}
                                          />

                                          {errors.fK_LanguageTo && touched?.fK_LanguageTo && (
                                            <div className='fv-plugins-message-container'>
                                              <div className='fv-help-block'>
                                                <span role='alert'>
                                                  {typeof errors.fK_LanguageTo === 'string'
                                                    ? errors.fK_LanguageTo
                                                    : ''}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-12'>
                                          <div className='d-flex justify-content-between'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required  form-label fs-7 mb-1'
                                            >
                                              Service Type
                                            </label>
                                            <div className='col-md-2 d-flex justify-content-end align-items-end mb-1'>
                                              <div className='form-check form-check-custom form-check-solid form-check-sm '>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value=''
                                                  id='flexRadioLg2'
                                                  checked={
                                                    values.fK_ServiceType?.length ===
                                                    serviceTypes?.length
                                                      ? true
                                                      : false
                                                  }
                                                  disabled={adminModalOpen !== null}
                                                  onChange={() =>
                                                    setFieldValue(
                                                      'fK_ServiceType',
                                                      values.fK_ServiceType?.length !==
                                                        serviceTypes?.length
                                                        ? serviceTypes?.map((x: any) => ({
                                                            value: x.key,
                                                            label: x.value,
                                                          })) || []
                                                        : []
                                                    )
                                                  }
                                                />
                                                <label
                                                  className='form-check-label fs-8'
                                                  htmlFor='flexRadioLg2'
                                                >
                                                  All
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <Select
                                            {...formik.getFieldProps('fK_ServiceType')}
                                            className='react-select-styled react-select-solid react-select-sm'
                                            classNamePrefix='react-select'
                                            options={serviceTypes.map((item: any) => ({
                                              value: item.key,
                                              label: item.value,
                                            }))}
                                            isDisabled={adminModalOpen !== null}
                                            placeholder='Select Service Types'
                                            value={values.fK_ServiceType}
                                            onChange={(selectedOption: any) => {
                                              setFieldValue('fK_ServiceType', selectedOption)
                                            }}
                                            onBlur={(e) => setFieldTouched('fK_ServiceType', true)}
                                            isMulti
                                            styles={{
                                              control: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                              }),
                                            }}
                                          />
                                          {errors.fK_ServiceType && touched?.fK_ServiceType && (
                                            <div className='fv-plugins-message-container'>
                                              <div className='fv-help-block'>
                                                <span role='alert'>
                                                  {typeof errors.fK_ServiceType === 'string'
                                                    ? errors.fK_ServiceType
                                                    : ''}
                                                </span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-lg-12 fv-row'>
                                          <label className='required  form-label fs-7 mb-1'>
                                            Years of Exp
                                          </label>
                                          <div className='row'>
                                            <div className='col-lg-12 fv-row'>
                                              <input
                                                {...formik.getFieldProps('yearOfExp')}
                                                type='number'
                                                className='form-control form-control-white form-select-sm'
                                                disabled={adminModalOpen !== null}
                                                placeholder='Years of Exp'
                                              />
                                              {touched?.yearOfExp && errors.yearOfExp && (
                                                <div className='fv-plugins-message-container'>
                                                  <div className='fv-help-block'>
                                                    <span role='alert'>{errors.yearOfExp}</span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-12'>
                                          <div className='d-flex justify-content-between'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required  form-label fs-7 mb-1'
                                            >
                                              Communication Types
                                            </label>
                                            <div className='col-md-2 d-flex justify-content-end align-items-end  mb-1'>
                                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value=''
                                                  id='flexRadioLg1'
                                                  checked={
                                                    values.fK_CommunicationType?.length ===
                                                    communicationTypes?.length
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={() =>
                                                    setFieldValue(
                                                      'fK_CommunicationType',
                                                      values.fK_CommunicationType?.length !==
                                                        communicationTypes?.length
                                                        ? communicationTypes?.map((x: any) => ({
                                                            value: x.key,
                                                            label: x.value,
                                                          })) || []
                                                        : []
                                                    )
                                                  }
                                                  onBlur={(e) =>
                                                    setFieldTouched('fK_CommunicationType', true)
                                                  }
                                                />
                                                <label
                                                  className='form-check-label fs-8'
                                                  htmlFor='flexRadioLg1'
                                                >
                                                  All
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <Select
                                            {...formik.getFieldProps('fK_CommunicationType')}
                                            className='react-select-styled react-select-solid react-select-sm'
                                            classNamePrefix='react-select'
                                            placeholder='Select Communication Types'
                                            isMulti
                                            options={communicationTypes.map((item: any) => ({
                                              value: item.key,
                                              label: item.value,
                                            }))}
                                            value={values.fK_CommunicationType}
                                            onChange={(selectedOption: any) => {
                                              setFieldValue('fK_CommunicationType', selectedOption)
                                            }}
                                            onBlur={(e) =>
                                              setFieldTouched('fK_CommunicationType', true)
                                            }
                                            styles={{
                                              control: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                              }),
                                            }}
                                          />
                                          {errors.fK_CommunicationType &&
                                            touched?.fK_CommunicationType && (
                                              <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                  <span role='alert'>
                                                    {errors.fK_CommunicationType}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-6'>
                                          <div className='mb-3'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              Start Time
                                            </label>
                                            <input
                                              id='startTime'
                                              type='time'
                                              className='form-control form-control-white form-select-sm custom-input-height'
                                              placeholder='Start Time'
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              value={values.startTime}
                                            />
                                            {errors.startTime && touched?.startTime && (
                                              <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                  <span role='alert'>{errors.startTime}</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-6 '>
                                          <div className='mb-3'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              End Time
                                            </label>

                                            <input
                                              id='endTime'
                                              name='endTime'
                                              type='time'
                                              className='form-control form-control-white form-select-sm custom-input-height'
                                              placeholder='End Time'
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              value={values.endTime}
                                            />
                                            {errors.endTime && touched?.endTime && (
                                              <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                  <span role='alert'>{errors.endTime}</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-12 w-100'>
                                          <div className='mb-3'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              Minimum Billable
                                            </label>
                                            <div className='input-group input-group-sm'>
                                              <div className='d-flex w-auto' style={{flex: 1}}>
                                                <Select
                                                  {...formik.getFieldProps('minimumBillableHours')}
                                                  className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                                  classNamePrefix='react-select'
                                                  options={HoursOptions}
                                                  placeholder='Select Hours'
                                                  styles={{
                                                    control: (provided) => ({
                                                      ...provided,
                                                      width: '100%',
                                                      border: '1px solid #e4e6ef',
                                                      borderRadius: '4px 0 0 4px',
                                                    }),
                                                  }}
                                                  value={{
                                                    value: values.minimumBillableHours,
                                                    label:
                                                      HoursOptions.find(
                                                        (x: any) =>
                                                          x.value === values.minimumBillableHours
                                                      )?.label || 'Select Hours',
                                                  }}
                                                  onChange={(e: any) => {
                                                    setFieldValue('minimumBillableHours', e?.value)
                                                  }}
                                                  onBlur={(e) =>
                                                    setFieldTouched('minimumBillableHours', true)
                                                  }
                                                />
                                                {errors.minimumBillableHours &&
                                                  touched?.minimumBillableHours && (
                                                    <div className='fv-plugins-message-container'>
                                                      <div className='fv-help-block'>
                                                        <span role='alert'>
                                                          {errors.minimumBillableHours}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>

                                              <span className='input-group-text' style={{flex: 1}}>
                                                Hours
                                              </span>
                                              <div className='d-flex w-auto' style={{flex: 1}}>
                                                <Select
                                                  {...formik.getFieldProps(
                                                    'minimumBillableMinutes'
                                                  )}
                                                  className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                                  classNamePrefix='react-select'
                                                  options={MinutesOptions}
                                                  placeholder='Select Minutes'
                                                  styles={{
                                                    control: (provided) => ({
                                                      ...provided,
                                                      width: '100%',
                                                      border: '1px solid #e4e6ef',
                                                      borderRadius: '0',
                                                    }),
                                                  }}
                                                  value={{
                                                    value: values.minimumBillableMinutes,
                                                    label:
                                                      MinutesOptions.find(
                                                        (x: any) =>
                                                          x.value === values.minimumBillableMinutes
                                                      )?.label || 'Select Minutes',
                                                  }}
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      'minimumBillableMinutes',
                                                      e?.value
                                                    )
                                                  }}
                                                  onBlur={(e) =>
                                                    setFieldTouched('minimumBillableMinutes', true)
                                                  }
                                                />
                                                {errors.minimumBillableMinutes &&
                                                  touched?.minimumBillableMinutes && (
                                                    <div className='fv-plugins-message-container'>
                                                      <div className='fv-help-block'>
                                                        <span role='alert'>
                                                          {errors.minimumBillableMinutes}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>

                                              <span className='input-group-text' style={{flex: 1}}>
                                                Minutes
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='col-md-12 '>
                                          <div className=''>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              Increments
                                            </label>
                                            <div className='input-group input-group-sm'>
                                              <div className='d-flex w-auto' style={{flex: 1}}>
                                                <Select
                                                  className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                                  classNamePrefix='react-select'
                                                  options={HoursOptions}
                                                  placeholder='Select Hours'
                                                  styles={{
                                                    control: (provided) => ({
                                                      ...provided,
                                                      width: '100%',
                                                      border: '1px solid #e4e6ef',
                                                      borderRadius: '4px 0 0 4px',
                                                    }),
                                                  }}
                                                  value={{
                                                    value: values.incrementsHours,
                                                    label:
                                                      HoursOptions.find(
                                                        (x: any) =>
                                                          x.value === values.incrementsHours
                                                      )?.label || 'Select Hours',
                                                  }}
                                                  onChange={(e: any) => {
                                                    setFieldValue('incrementsHours', e?.value)
                                                  }}
                                                  onBlur={(e) =>
                                                    setFieldTouched('incrementsHours', true)
                                                  }
                                                />
                                                {errors.incrementsHours &&
                                                  touched?.incrementsHours && (
                                                    <div className='fv-plugins-message-container'>
                                                      <div className='fv-help-block'>
                                                        <span role='alert'>
                                                          {errors.incrementsHours}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>

                                              <span className='input-group-text' style={{flex: 1}}>
                                                Hours
                                              </span>
                                              <div className='d-flex w-auto' style={{flex: 1}}>
                                                <Select
                                                  className='react-select-styled react-select-solid react-select-sm flex-grow-1'
                                                  classNamePrefix='react-select'
                                                  options={MinutesOptions}
                                                  placeholder='Select Minutes'
                                                  styles={{
                                                    control: (provided) => ({
                                                      ...provided,
                                                      width: '100%',
                                                      border: '1px solid #e4e6ef',
                                                      borderRadius: '0',
                                                    }),
                                                  }}
                                                  value={{
                                                    value: values.incrementsMinutes,
                                                    label:
                                                      MinutesOptions.find(
                                                        (x: any) =>
                                                          x.value === values.incrementsMinutes
                                                      )?.label || 'Select Minutes',
                                                  }}
                                                  onChange={(e: any) => {
                                                    setFieldValue('incrementsMinutes', e?.value)
                                                  }}
                                                  onBlur={(e) =>
                                                    setFieldTouched('incrementsMinutes', true)
                                                  }
                                                />
                                                {errors.incrementsMinutes &&
                                                  touched?.incrementsMinutes && (
                                                    <div className='fv-plugins-message-container'>
                                                      <div className='fv-help-block'>
                                                        <span role='alert'>
                                                          {errors.incrementsMinutes}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>

                                              <span className='input-group-text' style={{flex: 1}}>
                                                Minutes
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-12'>
                                          <div className='d-flex justify-content-between'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required  form-label fs-7 mb-1 me-3'
                                            >
                                              Days
                                            </label>
                                            <div className='d-flex justify-content-end align-items-center mb-1'>
                                              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                                <input
                                                  className='form-check-input'
                                                  type='checkbox'
                                                  value=''
                                                  id='flexRadioLg3'
                                                  checked={
                                                    values.days.length === daysOptions.length
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e: any) => {
                                                    if (values.days.length === daysOptions.length) {
                                                      setFieldValue('days', [])
                                                    } else {
                                                      setFieldValue('days', daysOptions)
                                                    }
                                                  }}
                                                  onBlur={(e) => setFieldTouched('days', true)}
                                                />
                                                <label
                                                  className='form-check-label fs-8'
                                                  htmlFor='flexRadioLg3'
                                                >
                                                  All
                                                </label>
                                              </div>
                                            </div>
                                          </div>

                                          <Select
                                            className='react-select-styled react-select-solid react-select-sm'
                                            classNamePrefix='react-select'
                                            options={daysOptions}
                                            placeholder='Select'
                                            isMulti
                                            styles={{
                                              control: (provided) => ({
                                                ...provided,
                                                width: '100%',
                                                border: '1px solid #e4e6ef',
                                              }),
                                            }}
                                            value={values.days}
                                            onChange={(selectedOption: any) => {
                                              setFieldValue('days', selectedOption)
                                            }}
                                            onBlur={(e) => setFieldTouched('days', true)}
                                          />
                                          {errors.days && touched?.days && (
                                            <div className='fv-plugins-message-container'>
                                              <div className='fv-help-block'>
                                                <span role='alert'>{errors.days}</span>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className='row d-flex mb-3'>
                                        <div className='col-md-4 col-sm-12'>
                                          <div className='mb-3'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              Regular
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-white form-select-sm custom-input-height'
                                              placeholder='Enter Regular Rate'
                                              id='regularRate'
                                              name='regularRate'
                                              value={values.regularRate}
                                              onChange={handleChange}
                                              onBlur={() => setFieldTouched('regularRate', true)}
                                            />
                                            {errors.regularRate && touched?.regularRate && (
                                              <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                  <span role='alert'>{errors.regularRate}</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-4 col-sm-12'>
                                          <div className='mb-3'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              Rush
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-white form-select-sm custom-input-height'
                                              placeholder='Enter Rush Rate'
                                              id='rushRate'
                                              name='rushRate'
                                              value={values.rushRate}
                                              onChange={handleChange}
                                              onBlur={() => setFieldTouched('rushRate', true)}
                                            />
                                            {errors.rushRate && touched?.rushRate && (
                                              <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                  <span role='alert'>{errors.rushRate}</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className='col-md-4 col-sm-12 '>
                                          <div className='mb-3'>
                                            <label
                                              htmlFor='exampleFormControlInput1'
                                              className='required form-label fs-7 mb-1'
                                            >
                                              Emergency
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-white form-select-sm custom-input-height'
                                              placeholder='Enter Emergency Rate'
                                              id='emergencyRate'
                                              name='emergencyRate'
                                              value={values.emergencyRate}
                                              onChange={handleChange}
                                              onBlur={() => setFieldTouched('emergencyRate', true)}
                                            />
                                            {errors.emergencyRate && touched?.emergencyRate && (
                                              <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>
                                                  <span role='alert'>{errors.emergencyRate}</span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>

                                        {rateCategory === 'C' && (
                                          <div className='col-md-12 col-sm-12 '>
                                            <div className='mb-3'>
                                              <label
                                                htmlFor='exampleFormControlInput1'
                                                className='required form-label fs-7 mb-1'
                                              >
                                                Conference Charge
                                              </label>

                                              <input
                                                type='number'
                                                className='form-control form-control-white form-select-sm custom-input-height'
                                                placeholder='Enter Conference Charge'
                                                id='conferenceCharges'
                                                name='conferenceCharges'
                                                value={values.conferenceCharges}
                                                onChange={handleChange}
                                                onBlur={() =>
                                                  setFieldTouched('conferenceCharges', true)
                                                }
                                              />
                                              {errors.conferenceCharges &&
                                                touched?.conferenceCharges && (
                                                  <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>
                                                      <span role='alert'>
                                                        {errors.conferenceCharges}
                                                      </span>
                                                    </div>
                                                  </div>
                                                )}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className='separator my-3'></div>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='required form-label fs-7 mb-1'
                                        >
                                          Status
                                        </label>
                                        <div className='row g-4 g-xl-6'>
                                          <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <label className='d-flex flex-start mb-5 cursor-pointer'>
                                              <span className='form-check form-check-custom form-check-success form-check-light form-check-sm me-3'>
                                                <input
                                                  name='isDelete'
                                                  autoComplete='off'
                                                  className='form-check-input'
                                                  type='radio'
                                                  value='1'
                                                  checked={values.isActive}
                                                  onChange={() => setFieldValue('isActive', true)}
                                                />
                                              </span>
                                              <span className='d-flex align-items-center me-2'>
                                                <span className='d-flex flex-column'>
                                                  <span className='fw-semibold text-gray-800 text-hover-primary fs-6'>
                                                    Active
                                                  </span>
                                                </span>
                                              </span>
                                            </label>
                                          </div>

                                          <div className='col-lg-6 col-md-6 col-sm-12'>
                                            <label className='d-flex flex-start mb-5 cursor-pointer'>
                                              <span className='form-check form-check-custom form-check-danger form-check-light form-check-sm me-3'>
                                                <input
                                                  name='isDelete'
                                                  autoComplete='off'
                                                  className='form-check-input'
                                                  type='radio'
                                                  value='0'
                                                  checked={!values.isActive}
                                                  onChange={() => setFieldValue('isActive', false)}
                                                />
                                              </span>
                                              <span className='d-flex align-items-center me-2'>
                                                <span className='d-flex flex-column'>
                                                  <span className='fw-semibold text-gray-800 text-hover-primary fs-6'>
                                                    Inactive
                                                  </span>
                                                </span>
                                              </span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='modal-footer py-3'>
                        <button
                          type='reset'
                          className='btn btn-light btn-sm'
                          data-bs-dismiss='modal'
                          onClick={() => {
                            resetForm()
                            handleCancel()
                            //setSaveNew(false)
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary btn-sm'
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          Approve & Save
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary btn-sm'
                          disabled={isSubmitting || !isValid || !dirty}
                          onClick={() => {
                            setSaveNewBtn(true)
                          }}
                        >
                          Approve & Save & New
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* begin::Actions */}
              {/* <div className='text-center pt-15'>
                <button
                  onClick={handleCancel}
                  className='btn btn-light btn-sm me-3'
                  data-kt-users-modal-action='cancel'
                >
                  Cancel
                </button>
              </div> */}
              {/* end::Actions */}
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {AdminModal}

import React, {useState} from 'react'
import QuillInput from '../../../common/componenets/quill/quill'
// import ReactQuill from 'react-quill'

export function NotificationConfig() {
  const [isEditing, setIsEditing] = useState(false)
  const [isEditing2, setIsEditing2] = useState(false)
  const [isEditing3, setIsEditing3] = useState(false)
  const [title, setTitle] = useState('User Creations')
  const [title2, setTitle2] = useState('Password Reset ')
  const [title3, setTitle3] = useState('Language Adding')

  const handleEditClick = () => {
    setIsEditing(true)
  }
  const handleEditClick2 = () => {
    setIsEditing2(true)
  }
  const handleEditClick3 = () => {
    setIsEditing3(true)
  }

  const handleBlur = () => {
    setIsEditing(false)
  }
  const handleBlur2 = () => {
    setIsEditing2(false)
  }
  const handleBlur3 = () => {
    setIsEditing3(false)
  }

  const handleChange = (e: any) => {
    setTitle(e.target.value)
  }
  const handleChange2 = (e: any) => {
    setTitle2(e.target.value)
  }
  const handleChange3 = (e: any) => {
    setTitle3(e.target.value)
  }

  return (
    <>
      <div className='card mb-5'>
        <div className='accordion' id='kt_accordion_1'>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_1'>
              <button
                className='accordion-button fs-6 fw-semibold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_1'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_1'
              >
                <div className='editable-title-container'>
                  {isEditing ? (
                    <input
                      type='text'
                      value={title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoFocus
                      className='edit-input'
                    />
                  ) : (
                    <div className='d-flex align-items-center '>
                      <div className='d-flex'>
                        {' '}
                        <h1 className='fs-6 fw-semibold me-3 mb-0'>{title}</h1>
                      </div>
                      <div className='d-flex'>
                        <i className='d-flex bi bi-pencil-square' onClick={handleEditClick}></i>
                      </div>
                    </div>
                  )}
                </div>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_1'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_1'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='card border border-1'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>Email Template</p>
                      </div>
                      <div className='card-body'>
                        <div className='row g-6'>
                          <div className='col-sm-12'>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Subject
                              </label>
                              <div className='d-flex w-100 flex-1 align-items-center'>
                                <input
                                  type='text'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Enter Subject'
                                />
                              </div>
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Messege
                              </label>
                              <div className=''>
                                <div className='w-100 '>
                                  <QuillInput />
                                </div>
                                {/* <ReactQuill theme='snow' value={value} onChange={setValue} /> */}
                              </div>
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Receivers
                              </label>
                              <div className='d-flex justify-content-between flex-wrap'>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Admin
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Manager
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Interpreter
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Content Admin User
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      User
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='separator my-3'></div>
                            <div className='w-100 py-2 d-flex flex-end'>
                              <button
                                type='button'
                                className='btn btn-light btn-sm me-2'
                                data-bs-dismiss='modal'
                              >
                                Discard
                              </button>

                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                data-bs-dismiss='modal'
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='card bg-light'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>
                          Template Preview
                        </p>
                      </div>
                      <div className='card-body'>
                        <div className='Econtainer border border-2 border-dashed p-4 rounded border-gray-600'>
                          <div className='Eheader'>
                            <h6 className='fw-semibold'>Account Creation Confirmation</h6>
                          </div>
                          <div className='Econtent'>
                            <p>Dear [User's Name],</p>
                            <p>
                              We are pleased to inform you that your user account has been
                              successfully created.
                            </p>
                            <p>
                              <strong>Username:</strong> [Username]
                            </p>
                            <p>
                              <strong>Email:</strong> [User's Email]
                            </p>
                            <p>
                              If you did not initiate this request, please contact our support team
                              immediately.
                            </p>
                            <p>Thank you for registering with us!</p>
                          </div>
                          <div className='Efooter'>
                            <p>Best regards,</p>
                            <p>[Your Name/Your Team]</p>
                            <p>[Your Company]</p>
                            <p>[Contact Information]</p>
                            <p>
                              <a href='[Website]'>Visit our website</a>
                            </p>
                          </div>
                          <div>
                            <div className='modal-footer py-2 flex-column flex-end'>
                              {/* <button
                                type='button'
                                className='btn btn-light btn-sm me-2'
                                data-bs-dismiss='modal'
                              >
                                Discard
                              </button> */}

                              <button
                                type='button'
                                className='btn btn-secondary btn-sm'
                                data-bs-dismiss='modal'
                              >
                                Send Test Email To Me
                              </button>
                              <span className='fs-8 text-gray-400'>
                                Text will deliver to your default email account
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_2'>
              <button
                className='accordion-button fs-6 fw-semibold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_2'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_2'
              >
                <div className='editable-title-container'>
                  {isEditing2 ? (
                    <input
                      type='text'
                      value={title2}
                      onChange={handleChange2}
                      onBlur={handleBlur2}
                      autoFocus
                      className='edit-input'
                    />
                  ) : (
                    <div className='d-flex align-items-center'>
                      <h1 className='fs-6 fw-semibold me-3 mb-0'>{title2}</h1>
                      <i className='bi bi-pencil-square' onClick={handleEditClick2}></i>
                    </div>
                  )}
                </div>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_2'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_2'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='card border border-1 '>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>Email Template</p>
                      </div>
                      <div className='card-body'>
                        <div className='row g-6'>
                          <div className='col-sm-12'>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Subject
                              </label>
                              <div className='d-flex w-100 flex-1 align-items-center'>
                                <input
                                  type='text'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Enter Subject'
                                />
                              </div>
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Messege
                              </label>
                              <div className=''>
                                <div className='w-100 '>
                                  <QuillInput />
                                </div>
                                {/* <ReactQuill theme='snow' value={value} onChange={setValue} /> */}
                              </div>
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Receivers
                              </label>
                              <div className='d-flex justify-content-between flex-wrap'>
                                <div className='mb-3 px1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Admin
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Manager
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Interpreter
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Content Admin User
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      User
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='separator my-3'></div>
                            <div className=' w-100 py-2 d-flex flex-end'>
                              <button
                                type='button'
                                className='btn btn-light btn-sm me-2'
                                data-bs-dismiss='modal'
                              >
                                Discard
                              </button>

                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                data-bs-dismiss='modal'
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='card bg-light'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>
                          Template Preview
                        </p>
                      </div>
                      <div className='card-body'>
                        <div className='Econtainer border border-2 border-dashed p-4 rounded border-gray-600'>
                          <div className='Eheader'>
                            <h4 className='fw-semibold'>Account Password Reset</h4>
                          </div>
                          <div className='Econtent'>
                            <p>Dear [User's Name],</p>
                            <p>
                              We received a request to reset the password for your account. If you
                              did not request this, please ignore this email. Your password will not
                              be changed.
                            </p>
                            <p>To reset your password, please click the link below:</p>
                            <a href='[Reset Password Link]' className='button'>
                              Reset Password
                            </a>
                            <p>
                              This link will expire in [X hours/days], so please use it promptly. If
                              the link expires or you experience any issues, you can request a new
                              password reset email.
                            </p>
                            <p>
                              If you have any questions or need further assistance, please contact
                              our support team at [support email/phone number].
                            </p>
                            <p>Thank you for registering with us!</p>
                          </div>
                          <div className='Efooter'>
                            <p>Best regards,</p>
                            <p>[Your Name/Your Team]</p>
                            <p>[Your Company]</p>
                            <p>[Contact Information]</p>
                            <p>
                              <a href='[Website]'>Visit our website</a>
                            </p>
                          </div>
                          <div>
                            <div className='modal-footer py-2 flex-column flex-end'>
                              {/* <button
                                type='button'
                                className='btn btn-light btn-sm me-2'
                                data-bs-dismiss='modal'
                              >
                                Discard
                              </button> */}

                              <button
                                type='button'
                                className='btn btn-secondary btn-sm'
                                data-bs-dismiss='modal'
                              >
                                Send Test Email To Me
                              </button>
                              <span className='fs-8 text-gray-400'>
                                Text will deliver to your default email account
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='kt_accordion_1_header_3'>
              <button
                className='accordion-button fs-6 fw-semibold collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_1_body_3'
                aria-expanded='false'
                aria-controls='kt_accordion_1_body_3'
              >
                <div className='editable-title-container'>
                  {isEditing3 ? (
                    <input
                      type='text'
                      value={title3}
                      onChange={handleChange3}
                      onBlur={handleBlur3}
                      autoFocus
                      className='edit-input'
                    />
                  ) : (
                    <div className='d-flex align-items-center'>
                      <h1 className='fs-6 fw-semibold me-3 mb-0'>{title3}</h1>
                      <i className='bi bi-pencil-square' onClick={handleEditClick3}></i>
                    </div>
                  )}
                </div>
              </button>
            </h2>
            <div
              id='kt_accordion_1_body_3'
              className='accordion-collapse collapse'
              aria-labelledby='kt_accordion_1_header_3'
              data-bs-parent='#kt_accordion_1'
            >
              <div className='accordion-body'>
                <div className='row g-4 g-xl-6'>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='card border border-1 '>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>Email Template</p>
                      </div>
                      <div className='card-body'>
                        <div className='row g-6'>
                          <div className='col-sm-12'>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Subject
                              </label>
                              <div className='d-flex w-100 flex-1 align-items-center'>
                                <input
                                  type='text'
                                  className='form-control form-control-white form-select-sm custom-input-height'
                                  placeholder='Enter Subject'
                                />
                              </div>
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Messege
                              </label>
                              <div className=''>
                                <div className='w-100 '></div>
                                <QuillInput />
                              </div>
                            </div>
                            <div className='mb-3'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='form-label fs-7 mb-1'
                              >
                                Receivers
                              </label>
                              <div className='d-flex justify-content-between flex-wrap'>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Admin
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Manager
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Interpreter
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      Content Admin User
                                    </label>
                                  </div>
                                </div>
                                <div className='mb-3 px-1'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-2'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value=''
                                      id='flexRadioLg0'
                                    />
                                    <label className='form-check-label' htmlFor='flexRadioLg0'>
                                      User
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='separator my-3'></div>
                            <div className=' w-100 py-2 d-flex flex-end'>
                              <button
                                type='button'
                                className='btn btn-light btn-sm me-2'
                                data-bs-dismiss='modal'
                              >
                                Discard
                              </button>

                              <button
                                type='button'
                                className='btn btn-primary btn-sm'
                                data-bs-dismiss='modal'
                              >
                                Save changes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <div className='card bg-light'>
                      <div className='card-header'>
                        <p className='card-title text-gray-500 fw-semibold fs-6'>
                          Template Preview
                        </p>
                      </div>
                      <div className='card-body'>
                        <div className='Econtainer border border-2 border-dashed p-4 rounded border-gray-600'>
                          <div className='Eheader'>
                            <h4 className='fw-semibold'>New Language Added</h4>
                          </div>

                          <div className='Econtent'>
                            <p>Dear [Recipient's Name/Team],</p>
                            <p>I hope this message finds you well.</p>
                            <p>
                              We are thrilled to announce that <strong>[New Language]</strong> has
                              been added to our <strong>[product/service/platform]</strong>. This
                              enhancement is part of our ongoing commitment to providing a more
                              inclusive and accessible experience for all our users.
                            </p>
                            <h5>Key Details:</h5>
                            <ul>
                              <li>
                                <strong>Language Added:</strong> [New Language]
                              </li>
                              <li>
                                <strong>Effective Date:</strong> [Date]
                              </li>
                              <li>
                                <strong>Available In:</strong> [Relevant Areas, e.g., interface,
                                documentation, customer support, etc.]
                              </li>
                            </ul>
                            <h5>What This Means for You:</h5>
                            <ul>
                              <li>
                                <strong>Enhanced User Experience:</strong> [Briefly explain how this
                                addition will improve the user experience.]
                              </li>
                              <li>
                                <strong>Updated Resources:</strong> [Mention any updated resources,
                                such as user guides or FAQs, if applicable.]
                              </li>
                            </ul>
                            <p>
                              We believe this update will significantly benefit our{' '}
                              <strong>[users/customers/clients]</strong> and enhance the overall
                              usability of our <strong>[product/service/platform]</strong>.
                            </p>
                            <p>
                              If you have any questions or need further information, please do not
                              hesitate to reach out to <strong>[Contact Person/Team]</strong> at{' '}
                              <strong>[Contact Information]</strong>.
                            </p>
                            <p>Thank you for your continued support.</p>
                          </div>

                          <div className='Efooter'>
                            <p>Best regards,</p>
                            <p>[Your Name/Your Team]</p>
                            <p>[Your Company]</p>
                            <p>[Contact Information]</p>
                            <p>
                              <a href='[Website]'>Visit our website</a>
                            </p>
                          </div>
                          <div>
                            <div className='modal-footer py-2 flex-column flex-end'>
                              {/* <button
                                type='button'
                                className='btn btn-light btn-sm me-2'
                                data-bs-dismiss='modal'
                              >
                                Discard
                              </button> */}

                              <button
                                type='button'
                                className='btn btn-secondary btn-sm'
                                data-bs-dismiss='modal'
                              >
                                Send Test Email To Me
                              </button>
                              <span className='fs-8 text-gray-400'>
                                Text will deliver to your default email account
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

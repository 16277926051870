import {FC, useState, useEffect, useCallback} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {roleQUERIES, isNotEmpty, toAbsoluteUrl, KTSVG} from '../../../../../_metronic/helpers'
import {initialUser, User, CustomDictionaryModel} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {
  createUser,
  getUserroles,
  updateUser,
  getCountries,
  getMaster,
  getCustomerLocations,
  GetPinCode,
  CheckPinAvailability,
  checkUserEmail,
} from '../core/_requests'
import {
  getAllPreCallQuestionByCustomer,
  getCustomers,
  getMasterData,
  getNativeLanguage,
  getTimeZone,
} from '../../../Common/components/core/_requests'
import ImageComponent from '../../../configuration-management/components/ImageComponent'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useMutation, useQuery} from 'react-query'

import axios from 'axios'
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import toaster from '../../../../../Utils/toaster'
import debounce from 'lodash/debounce'
import './Style.css'
import Select from 'react-select'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  Suggestion,
} from 'react-places-autocomplete'

const center = {
  lat: -3.745,
  lng: -38.523,
}

interface RenderFuncProps {
  getInputProps: () => React.InputHTMLAttributes<HTMLInputElement>
  getSuggestionItemProps: (suggestion: Suggestion) => React.HTMLAttributes<HTMLDivElement>
  suggestions: any
  loading?: boolean // Optional loading indicator
  error: any
}

const styles = `

.phoneNumber input{
    border: none; 
    outline: none; 

  }
`
type Props = {
  isUserLoading: boolean
  user: User
  userType: string
  customerCode: number
}

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const editUserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string()
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email')
    .email('Please enter valid email address')
    .required('Required'),
  phoneNumber: Yup.string()
    .required('Required')
    .test('is-valid-phone-number', 'Invalid phone number', (value) => {
      return isValidPhoneNumber(value ? value : '')
    }),
  pinCode: Yup.string()
    .matches(/^\d{4,8}$/, 'PIN must be between 4 and 8 digits')
    .required('Required'),
  fK_Customer: Yup.string().required('Required'),
  fK_Location: Yup.string().required('Required'),
  fK_Gender: Yup.string(),
  fK_PreCallPolicy: Yup.string(),
  isNewPasswordGenerate: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  street1: Yup.string(),
  street2: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  postalCode: Yup.string().required('Required'),
  latitude: Yup.number(),
  longitude: Yup.number(),
  isActive: Yup.boolean(),
  role: Yup.string().required('Required'),
  fK_DefaultNativeLanguage: Yup.string().required('Required'),
  fK_ServiceType: Yup.string().required('Required'),
  fK_DefaultTimeZone: Yup.string().required('Required'),
})

const UserEditModalForm: FC<Props> = ({user, isUserLoading, userType, customerCode}) => {
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState('')
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [customerLocation, setCustomerLocation] = useState<CustomDictionaryModel[]>([])
  const [isGenPinCode, setIsGenPinCode] = useState(true)
  const [userForEdit, setUserForEdit] = useState<User>({
    ...user,
    avatar: user.avatar || initialUser.avatar,
    role: user.role || user.userType == 'INTERPRETER' ? user.role : initialUser.role,
    position: user.position || initialUser.position,
    firstName: user.firstName || initialUser.firstName,
    email: user.email || initialUser.email,
    lastName: user.lastName || initialUser.lastName,
    phoneNumber: user.phoneNumber || initialUser.phoneNumber,
    fK_Customer: user.fK_Customer || initialUser.fK_Customer,
    fK_Gender: user.fK_Gender || initialUser.fK_Gender,
    fK_PreCallPolicy: user.fK_PreCallPolicy || initialUser.fK_PreCallPolicy,
    fK_Location: user.fK_Location || initialUser.fK_Location,
    isNewPasswordGenerate:
      user.code == undefined || user.code == '' || user.code == '0' ? true : false,
    pinCode: user.pinCodeString,
    pinCodeString: user.pinCodeString,
    address: user.address || initialUser.address,
    city: user.city || initialUser.city,
    state: user.state || initialUser.state,
    country: user.country || initialUser.country,
    postalCode: user.postalCode || initialUser.postalCode,
    street1: user.street1 || initialUser.street1,
    street2: user.street2 || initialUser.street2,
    latitude: user.latitude || initialUser.latitude,
    longitude: user.longitude || initialUser.longitude,
    fK_DefaultNativeLanguage: user.fK_DefaultNativeLanguage || initialUser.fK_DefaultNativeLanguage,
    fK_ServiceType: user.fK_ServiceType || initialUser.fK_ServiceType,
    fK_DefaultTimeZone: user.fK_DefaultTimeZone || initialUser.fK_DefaultTimeZone,
    opI_ShdTelephonic: userType === 'SYSTEM' || user.opI_ShdTelephonic,
    opI_OndemandTelephonic: userType === 'SYSTEM' || user.opI_OndemandTelephonic,
    vrI_ShdVideoInteroreting: userType === 'SYSTEM' || user.vrI_ShdVideoInteroreting,
    vrI_OndemandVideoInteroreting: userType === 'SYSTEM' || user.vrI_OndemandVideoInteroreting,
    osI_OnsiteConsecutive: userType === 'SYSTEM' || user.osI_OnsiteConsecutive,
    osI_OnsiteSimultaneous: userType === 'SYSTEM' || user.osI_OnsiteSimultaneous,
    other_3rdPartyPlatform: userType === 'SYSTEM' || user.other_3rdPartyPlatform,
  })
  const [newPinCode, setNewPinCode] = useState('')

  useEffect(() => {
    if (user.fK_Customer) refetchPreCallQuestion(user.fK_Customer)
  }, [user])

  useEffect(() => {
    if (userType === 'INTERPRETER' || userType === 'SYSTEM') {
      formik.setFieldValue('pinCode', 99999999)
    }
  }, [userType])

  const [data, setData] = useState(userForEdit)
  const {
    mutate: refetchPreCallQuestion,
    data: allPreCallQuestion,
    isLoading: isPreCallQuestionLoading,
  } = useMutation(getAllPreCallQuestionByCustomer)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
    formik.resetForm()
  }

  const {
    isFetching: isPinCodeGenerated,
    data: pinDataLoad,
    refetch: pinrefetch,
  } = useQuery(
    `PINCODE_GENERATE`,
    () => {
      const result = GetPinCode()
      setIsGenPinCode(true)
      return result
    },
    {
      cacheTime: 0,
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        // Clear the availability status data when a new pin code is generated
        removeAvailabilityStatus()
      },
    }
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    () => {
      return getMasterData('SERVICE_TYPE')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    () => {
      return getNativeLanguage()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingTimeZone, data: timeZoneList} = useQuery(
    `Query.timeZoneList`,
    () => {
      return getTimeZone()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const serviceOptions = !isFetchingServiceType
    ? servicetypeList?.data?.map((d: any) => ({
        value: d.key,
        label: d.value,
      }))
    : []

  const languageOptions = !isFetchingNativeLanguage
    ? nativeLanguageList?.data?.map((d: any) => ({
        value: d.key,
        label: d.value,
      }))
    : []

  const TimezoneOptions = !isFetchingTimeZone
    ? timeZoneList?.data?.map((d: any) => ({
        value: d.code,
        label: d.name,
      }))
    : []

  const {
    isFetching: isCheckAvailabilty,
    data: availabilityStatus,
    refetch: fn_CheckAvailabilty,
    remove: removeAvailabilityStatus,
  } = useQuery(
    `CHECK_AVAILAIBILTY`,
    () => {
      const result = CheckPinAvailability(newPinCode ?? '')
      return result
    },
    {cacheTime: 0, enabled: false, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (newPinCode) {
      if (newPinCode && newPinCode.length >= 4 && newPinCode.length <= 8) {
        fn_CheckAvailabilty()
      } else {
        removeAvailabilityStatus()
      }
    }
  }, [newPinCode])

  useEffect(() => {
    if (pinDataLoad) {
      formik.setFieldValue('pinCode', pinDataLoad?.data?.toString() ?? 0)
      formik.setFieldValue('pinCodeString', pinDataLoad?.data?.toString() ?? 0)
    }
  }, [pinDataLoad])

  const GeneratePinCode = (withRefresh?: boolean) => {
    pinrefetch()
  }

  const updateData = (value: string) => {
    const getallShortlistedLocations = axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/customer/${value}/location/getall-shortlist`,
    })

    axios
      .all([getallShortlistedLocations])
      .then(
        axios.spread((...responses) => {
          const getnotifications = responses[0]
          setCustomerLocation(getnotifications?.data.data)
        })
      )
      .catch(function (error) {})
    setData({...data, fK_Customer: value})
    return value
  }

  const {isFetching: isFetchingCountry, data: responseCountry} = useQuery(
    `${roleQUERIES.Country_LIST}`,
    () => {
      return getCountries()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingGender, data: responseGenderMaster} = useQuery(
    `${roleQUERIES.Gender_LIST}`,
    () => {
      return getMaster('GENDER')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    () => {
      return getCustomers(userType, customerCode ?? 0)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching, data: response} = useQuery(
    `${roleQUERIES.Role_LIST}`,
    () => {
      return getUserroles(userType)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (response !== undefined) {
      if (response.data !== undefined) {
        if (response.data.length > 0) {
          if (itemIdForUpdate) {
            setUserForEdit({...userForEdit})
          } else {
            setUserForEdit({...userForEdit, role: response.data[0].name})
          }
        }
      }
    }
  }, [response])

  useEffect(() => {
    const getallShortlistedLocations = axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/customer/${data.fK_Customer}/location/getall-shortlist`,
    })

    axios
      .all([getallShortlistedLocations])
      .then(
        axios.spread((...responses) => {
          const getnotifications = responses[0]
          setCustomerLocation(getnotifications?.data.data)
        })
      )
      .catch(function (error) {})
  }, [])

  const handlePinCodeChange = (e: any) => {
    formik.setFieldValue('pinCode', e.target.value)
    formik.setFieldValue('pinCodeString', e.target.value.toString())
    setIsGenPinCode(false)
    getAvailability(e.target.value.toString())
  }

  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')
  const userAvatarImg = toAbsoluteUrl(`/media/${userForEdit.avatar}`)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: userForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      const formData = new FormData()
      formData.append('firstName', values?.firstName ?? '')
      formData.append('lastName', values?.lastName ?? '')
      formData.append('phoneNumber', values.phoneNumber ?? '')
      //formData.append('address', values.address ?? "");
      // formData.append('isdeleted', JSON.parse(values.isdeleted + '' ?? "false"));
      formData.append('email', values.email ?? '')
      formData.append('fK_Gender', values.fK_Gender ?? '')
      formData.append('fK_Customer', data.fK_Customer ?? '')
      //formData.append('fK_Country', values.fK_Country ?? '0')
      formData.append('fK_Location', values.fK_Location ?? '')
      formData.append('fK_ServiceType', values.fK_ServiceType ?? '')
      formData.append('fk_PreCallPolicy', values.fK_PreCallPolicy ?? '')
      formData.append('fK_DefaultNativeLanguage', values.fK_DefaultNativeLanguage ?? '')
      formData.append('fK_DefaultTimeZone', values.fK_DefaultTimeZone ?? '')
      formData.append('role', values.role ?? '')

      if (values.lockoutEnabled + '' === 'true' || values.lockoutEnabled + '' == 'false') {
        formData.append('lockoutEnabled', JSON.parse(values.lockoutEnabled + ''))
      }
      // formData.append('fK_DefaultTimeZone', values.fK_DefaultTimeZone ?? "");
      // formData.append('fK_DefaultNativeLanguage', values.fK_DefaultNativeLanguage ?? "");
      formData.append('city', values?.city ?? '')

      if (userType === 'INTERPRETER' || userType === 'SYSTEM') {
        formData.append('pinCode', '0')
      } else {
        if (values.pinCode == undefined || values.pinCode == null || values.pinCode + '' == '') {
          formData.append('pinCode', '0')
        } else {
          formData.append('pinCode', (values?.pinCode ?? 0).toString())
          formData.append('pinCodeString', (values?.pinCode ?? 0).toString())
        }
      }

      formData.append('address', values?.address ?? '')
      formData.append('street1', values?.street1 ?? '')
      formData.append('street2', values?.street2 ?? '')
      formData.append('state', values?.state ?? '')
      formData.append('country', values?.country ?? '')
      formData.append('postalCode', values?.postalCode ?? '')
      formData.append('latitude', values.latitude ?? '')
      formData.append('longitude', values.longitude ?? '')
      formData.append('profileImageFile', selectedFile ?? '')
      formData.append('ProfileImage', values?.profileImage ?? '')
      formData.append('code', values.code + '')
      formData.append('userType', userType)

      if (
        values.isNewPasswordGenerate + '' === 'true' ||
        values.isNewPasswordGenerate + '' == 'false'
      ) {
        formData.append('isNewPasswordGenerate', JSON.parse(values.isNewPasswordGenerate + ''))
      } else {
        formData.append('isNewPasswordGenerate', 'false')
      }

      if (values.opI_ShdTelephonic + '' === 'true' || values.opI_ShdTelephonic + '' == 'false') {
        formData.append('oPI_ShdTelephonic', JSON.parse(values.opI_ShdTelephonic + ''))
      } else {
        formData.append('oPI_ShdTelephonic', 'false')
      }

      if (
        values.opI_OndemandTelephonic + '' === 'true' ||
        values.opI_OndemandTelephonic + '' == 'false'
      ) {
        formData.append('oPI_OndemandTelephonic', JSON.parse(values.opI_OndemandTelephonic + ''))
      } else {
        formData.append('oPI_OndemandTelephonic', 'false')
      }

      if (
        values.vrI_ShdVideoInteroreting + '' === 'true' ||
        values.vrI_ShdVideoInteroreting + '' == 'false'
      ) {
        formData.append(
          'vRI_ShdVideoInteroreting',
          JSON.parse(values.vrI_ShdVideoInteroreting + '')
        )
      } else {
        formData.append('vRI_ShdVideoInteroreting', 'false')
      }

      if (
        values.vrI_OndemandVideoInteroreting + '' === 'true' ||
        values.vrI_OndemandVideoInteroreting + '' == 'false'
      ) {
        formData.append(
          'vRI_OndemandVideoInteroreting',
          JSON.parse(values.vrI_OndemandVideoInteroreting + '')
        )
      } else {
        formData.append('vRI_OndemandVideoInteroreting', 'false')
      }
      if (
        values.osI_OnsiteConsecutive + '' === 'true' ||
        values.osI_OnsiteConsecutive + '' == 'false'
      ) {
        formData.append('oSI_OnsiteConsecutive', JSON.parse(values.osI_OnsiteConsecutive + ''))
      } else {
        formData.append('oSI_OnsiteConsecutive', 'false')
      }

      if (
        values.osI_OnsiteSimultaneous + '' === 'true' ||
        values.osI_OnsiteSimultaneous + '' == 'false'
      ) {
        formData.append('oSI_OnsiteSimultaneous', JSON.parse(values.osI_OnsiteSimultaneous + ''))
      } else {
        formData.append('oSI_OnsiteSimultaneous', 'false')
      }

      if (values.osI_OnsiteWhisper + '' === 'true' || values.osI_OnsiteWhisper + '' == 'false') {
        formData.append('oSI_OnsiteWhisper', JSON.parse(values.osI_OnsiteWhisper + ''))
      } else {
        formData.append('oSI_OnsiteWhisper', 'false')
      }

      if (values.osI_Onsite + '' === 'true' || values.osI_Onsite + '' == 'false') {
        formData.append('oSI_Onsite', JSON.parse(values.osI_Onsite + ''))
      } else {
        formData.append('oSI_Onsite', 'false')
      }

      if (
        values.other_3rdPartyPlatform + '' === 'true' ||
        values.other_3rdPartyPlatform + '' == 'false'
      ) {
        formData.append('other_3rdPartyPlatform', JSON.parse(values.other_3rdPartyPlatform + ''))
      } else {
        formData.append('other_3rdPartyPlatform', 'false')
      }

      setSubmitting(true)
      try {
        if (isNotEmpty(values.code)) {
          var result = await updateUser(formData)
          if (result?.status == 'S') {
            toaster('success', result?.text ?? 'Record Updated')
          } else if (result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not Updated')
          }
        } else {
          var res = await createUser(formData)
          if (res?.status == 'S') {
            toaster('success', res?.text ?? 'Record Updated')
          } else if (res?.status == 'E') {
            toaster('error', res?.text ?? 'Record Not Updated')
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  // Debounce function to handle API call
  const debouncedApiCall = useCallback(
    debounce(async (value) => {
      try {
        if (value != null) {
          var result = await checkUserEmail(value, userType)
          if (result?.status == 'E') {
            formik.setFieldValue('email', '')
            toaster('error', `'${value}' already exsists`)
          }
        }
      } catch (error) {
        formik.setFieldError('email', 'Required') // Set Formik error based on API response
      }
    }, 2000), // 500ms debounce delay
    []
  )

  const handleChange = (e: any, isError: any) => {
    formik.handleChange(e)
    console.log(isError)
    if (isError === false) debouncedApiCall(e.target.value)
  }

  useEffect(() => {
    if (!selectedFile) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }

    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        formik.setFieldValue('profileImage', reader.result)
        formik.setFieldTouched('profileImage', true, true)
      }
      reader.readAsDataURL(file)
    }

    setSelectedFile(file)
  }

  const genderOptions =
    !isFetchingGender && responseGenderMaster?.data
      ? responseGenderMaster.data.map((d) => ({value: d.key, label: d.value}))
      : []
  const customerOptions =
    !isFetchingCustomer && responseCustomer?.data
      ? responseCustomer.data.map((d) => ({value: d.key, label: d.value}))
      : []

  const locationOptions = customerLocation
    ? customerLocation.map((d) => ({value: d.key, label: d.value}))
    : []
  const preCallPolicyOptions =
    !isPreCallQuestionLoading && allPreCallQuestion
      ? allPreCallQuestion.map((d) => ({value: d.value, label: d.label}))
      : []
  const isPinCodeError =
    typeof formik.errors.pinCode === 'string' && formik.errors.pinCode.length > 0

  const handleSelect = async (address: string) => {
    try {
      const results = await geocodeByAddress(address)
      const placeDetails = await getLatLng(results[0])

      // Extract detailed address components
      let street1 = ''
      let street2 = ''
      let city = ''
      let state = ''
      let country = ''
      let postalCode = ''

      results[0].address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
          street1 = component.long_name
        } else if (component.types.includes('route')) {
          street2 = component.long_name
        } else if (component.types.includes('locality')) {
          city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name
        } else if (component.types.includes('country')) {
          country = component.long_name
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name
        }
      })

      formik.setFieldValue('address', address)
      formik.setFieldValue('street1', street1)
      formik.setFieldValue('street2', street2)
      formik.setFieldValue('city', city)
      formik.setFieldValue('state', state)
      formik.setFieldValue('country', country)
      formik.setFieldValue('postalCode', postalCode)
      formik.setFieldValue('latitude', placeDetails.lat)
      formik.setFieldValue('longitude', placeDetails.lng)

      // Select the input element with id "city"
      const cityInputElement = document.querySelector<HTMLInputElement>('#city')

      // Ensure the cityInputElement exists before focusing on it
      if (cityInputElement) {
        cityInputElement.focus() // Focuses on the input field
      }
    } catch (error) {
      console.error('Error fetching place details', error)
    }
  }

  const renderFunc: React.FC<RenderFuncProps> = ({
    getInputProps,
    getSuggestionItemProps,
    suggestions,
    loading,
    error,
  }) => (
    <div className='autocomplete-root'>
      <input
        className={clsx('form-control form-control-sm form-control-light custom-input-height')}
        disabled={formik.isSubmitting || isUserLoading}
        placeholder='Address'
        {...getInputProps()}
      />
      {error && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{error}</span>
          </div>
        </div>
      )}
      <div className='autocomplete-dropdown-container'>
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion: any) => (
          <div
            {...getSuggestionItemProps(suggestion)}
            key={suggestion.placeId}
            className='bg-light p-2 border border-bottom overflow-auto'
            style={{cursor: 'pointer'}}
          >
            <option>{suggestion.description}</option>
          </div>
        ))}
      </div>
    </div>
  )

  function getAvailability(pinCodeString: any) {
    setNewPinCode(pinCodeString)
  }

  return (
    <>
      <form
        id='kt_modal_add_user_form'
        className='modal-body'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7 px-1'
          style={{maxHeight: '75vh'}}
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='400px'
        >
          <div className='row g-4 g-xl-6'>
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div className=''>
                <div className='row g-4 g-xl-6'>
                  <ImageComponent
                    selectedFile={selectedFile}
                    onSelectFile={onSelectFile}
                    preview={preview}
                    profileImage={formik.values.profileImage}
                    type='Profile'
                  />
                  <div className='col-sm-8 col-md-8 col-lg-8 '>
                    <div className='mb-3'>
                      <label
                        htmlFor='exampleFormControlInput1'
                        className='required form-label fs-7 mb-1 '
                      >
                        Name
                      </label>
                      <div className='d-flex align-items-center'>
                        <div className='row g-3 g-xl-4 flex-grow-1'>
                          <div className='col-sm-6 col-md-6 col-lg-6 '>
                            <input
                              placeholder='First Name'
                              {...formik.getFieldProps('firstName')}
                              type='text'
                              name='firstName'
                              className={clsx(
                                'form-control form-control-white form-select-sm custom-input-height'
                              )}
                              autoComplete='off'
                              disabled={formik.isSubmitting || isUserLoading}
                              onClick={() => {
                                if (userType === 'INTERPRETER' || userType === 'SYSTEM') {
                                  formik.setFieldValue('pinCode', 99999999)
                                }
                              }}
                            />
                            {formik.errors.firstName && formik.touched.firstName && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.firstName}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='col-sm-6 col-md-6 col-lg-6 '>
                            <input
                              placeholder='Last name'
                              {...formik.getFieldProps('lastName')}
                              type='text'
                              name='lastName'
                              className={clsx(
                                'form-control form-control-white form-select-sm custom-input-height'
                              )}
                              autoComplete='off'
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            {formik.errors.lastName && formik.touched.lastName && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{formik.errors.lastName}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='' hidden={!(userType === 'INTERPRETER' && true)}>
                            <div className='col-sm-6 col-md-6 col-lg-6 '>
                              <Select
                                {...formik.getFieldProps('fK_Gender')}
                                name='fK_Gender'
                                placeholder='Select a Gender'
                                value={genderOptions.find(
                                  (option) => option.value === formik.values.fK_Gender
                                )}
                                onChange={(option) =>
                                  formik.setFieldValue('fK_Gender', option?.value || '')
                                }
                                isDisabled={formik.isSubmitting || isUserLoading}
                                options={genderOptions}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    border: '1px solid #e4e6ef',
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: '#a4afc4',
                                  }),
                                }}
                              />
                              {formik.errors.fK_Gender && formik.touched.fK_Gender && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.fK_Gender}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mb-3 '>
                      <div className='row g-4 g-xl-6'>
                        <div className=''>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 '
                          >
                            Email
                          </label>
                          <input
                            placeholder='Email'
                            {...formik.getFieldProps('email')}
                            className={clsx(
                              'form-control form-control-white form-select-sm custom-input-height'
                            )}
                            type='email'
                            name='email'
                            autoComplete='off'
                            onChange={(e) =>
                              handleChange(
                                e,
                                formik.touched.email && !formik.errors.email ? true : false
                              )
                            }
                            disabled={formik.isSubmitting || isUserLoading}
                          />
                          {formik.errors.email && formik.touched.email && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.email}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <div className='row g-4 g-xl-6'>
                        <div className='col-sm-6 col-md-6 col-lg-6 mb-5 '>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className='required form-label fs-7 mb-1 '
                          >
                            Contact Number
                          </label>

                          <PhoneInput
                            countryCallingCodeEditable={false}
                            international
                            defaultCountry='US'
                            id='phoneNumber'
                            name='phoneNumber'
                            value={formik.values.phoneNumber}
                            onBlur={() => formik.setFieldTouched('phoneNumber', true)}
                            onChange={(value) => {
                              formik.setFieldTouched('phoneNumber', true)
                              formik.setFieldValue('phoneNumber', value ? value : '')
                            }}
                            className={clsx(
                              'phoneNumber form-control form-control-white form-select-sm mb-2 custom-input-height'
                            )}
                          />
                          {formik.errors.phoneNumber && formik.touched.phoneNumber && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{formik.errors.phoneNumber}</span>
                              </div>
                            </div>
                          )}
                        </div>
                        {/* <div className='col-sm-6 col-md-6 col-lg-6 d-flex align-items-center'>
                          <div className='form-check-custom form-check-white form-check-sm'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value=''
                              id='flexCheckDefault'
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              Allow SMS Notifications
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-3'>
                <label className='required form-label fs-7 mb-1 '>Address</label>
                {typeof window.google !== 'undefined' && (
                  <PlacesAutocomplete
                    onSelect={(address: any) => {
                      handleSelect(address)
                    }}
                    value={formik.values.address}
                    onChange={(e: any) => {
                      if (e === '') {
                        formik.setFieldValue('address', '')
                        formik.setFieldValue('street1', '')
                        formik.setFieldValue('street2', '')
                        formik.setFieldValue('city', '')
                        formik.setFieldValue('state', '')
                        formik.setFieldValue('country', '')
                        formik.setFieldValue('postalCode', '')
                        formik.setFieldValue('latitude', center.lat)
                        formik.setFieldValue('longitude', center.lng)
                      }
                      formik.setFieldValue('address', e)
                    }}
                  >
                    {({getInputProps, getSuggestionItemProps, suggestions, loading}) => (
                      <div>
                        {renderFunc({
                          getInputProps,
                          getSuggestionItemProps,
                          suggestions,
                          loading,
                          error: formik.errors.address,
                        })}
                      </div>
                    )}
                  </PlacesAutocomplete>
                )}
              </div>

              <div className='row g-4 mb-3'>
                <div className='col-lg-6 col-sm-12'>
                  <label className='required form-label fs-7 mb-1 '>City</label>
                  <div>
                    <input
                      placeholder='City'
                      {...formik.getFieldProps('city')}
                      type='text'
                      name='city'
                      id='city'
                      className={clsx(
                        'form-control form-control-sm form-control-light custom-input-height'
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.city}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                  <label className='required form-label fs-7 mb-1 '>State</label>
                  <div>
                    <input
                      placeholder='State'
                      {...formik.getFieldProps('state')}
                      type='text'
                      name='state'
                      id='state'
                      className={clsx(
                        'form-control form-control-sm form-control-light custom-input-height'
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.errors.state && formik.touched.state && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.state}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                  <label className='required form-label fs-7 mb-1 '>Country</label>
                  <div>
                    <input
                      placeholder='Country'
                      {...formik.getFieldProps('country')}
                      type='text'
                      name='country'
                      id='country'
                      className={clsx(
                        'form-control form-control-sm form-control-light custom-input-height'
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.errors.country && formik.touched.country && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.country}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-6 col-sm-12'>
                  <label className='required form-label fs-7 mb-1 '>Postal Code</label>
                  <div className=''>
                    <input
                      placeholder='Postal Code'
                      {...formik.getFieldProps('postalCode')}
                      type='text'
                      name='postalCode'
                      id='postalCode'
                      className={clsx(
                        'form-control form-control-sm form-control-light custom-input-height'
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    />
                    {formik.errors.postalCode && formik.touched.postalCode && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.postalCode}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row g-4 mb-3'>
                <div className='col-sm-12 col-md-6 col-lg-6'>
                  <label className='required form-label fs-7 mb-1 '>
                    Customer & Office Location
                  </label>
                  {
                    <Select
                      {...formik.getFieldProps('fK_Customer')}
                      name='fK_Customer'
                      placeholder='Select a customer'
                      value={customerOptions.find(
                        (option) => option.value === formik.values.fK_Customer
                      )}
                      onChange={(option) => {
                        formik.setFieldValue('fK_Customer', option?.value || '')
                        updateData(option?.value || '')
                        refetchPreCallQuestion(option?.value || '')
                      }}
                      isDisabled={formik.isSubmitting || isUserLoading}
                      options={customerOptions}
                      onBlur={() => formik.setFieldTouched('fK_Customer', true)}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#a4afc4',
                        }),
                      }}
                    />
                  }
                  {formik.errors.fK_Customer && formik.touched.fK_Customer && (
                    <div className='d-block fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.fK_Customer}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6 d-flex align-items-end'>
                  <div className='w-100'>
                    <label className='form-label fs-7'></label>
                    <Select
                      {...formik.getFieldProps('fK_Location')}
                      name='fK_Location'
                      className='mt-1'
                      placeholder='Select a location'
                      value={locationOptions.find(
                        (option) => option.value === formik.values.fK_Location
                      )}
                      onChange={(option) =>
                        formik.setFieldValue('fK_Location', option?.value || '')
                      }
                      isDisabled={formik.isSubmitting || isUserLoading}
                      options={locationOptions}
                      onBlur={() => formik.setFieldTouched('fK_Location', true)}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#a4afc4',
                        }),
                      }}
                    />
                    {formik.errors.fK_Location && formik.touched.fK_Location && (
                      <div className='fv-plugins-message-container '>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.fK_Location}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='row mb-3 g-4'>
                <div className='col-lg-6 col-sm-12'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1 '
                  >
                    Native Language
                  </label>
                  <div className=''>
                    <Select
                      {...formik.getFieldProps('fK_DefaultNativeLanguage')}
                      name='fK_DefaultNativeLanguage'
                      placeholder='Select a language'
                      options={languageOptions}
                      value={languageOptions?.find(
                        (option) => option.value === formik.values.fK_DefaultNativeLanguage
                      )}
                      onChange={(option) =>
                        formik.setFieldValue('fK_DefaultNativeLanguage', option?.value || '')
                      }
                      isDisabled={formik.isSubmitting || isUserLoading}
                      onBlur={() => formik.setFieldTouched('fK_DefaultNativeLanguage', true)}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: '100%',
                          border: '1px solid #e4e6ef',
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: '#a4afc4',
                        }),
                      }}
                    />
                    {formik.errors.fK_DefaultNativeLanguage &&
                      formik.touched.fK_DefaultNativeLanguage && (
                        <div className='fv-plugins-message-container '>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.fK_DefaultNativeLanguage}</span>
                          </div>
                        </div>
                      )}
                  </div>
                </div>

                <div className='col-md-6 col-sm-12'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1 '
                  >
                    Default Service Type
                  </label>
                  <div className='d-flex align-items-center'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Select
                        {...formik.getFieldProps('fK_ServiceType')}
                        name='fK_ServiceType'
                        placeholder='Select a service'
                        options={serviceOptions}
                        value={serviceOptions?.find(
                          (option) => option.value === formik.values.fK_ServiceType
                        )}
                        onChange={(option) =>
                          formik.setFieldValue('fK_ServiceType', option?.value || '')
                        }
                        isDisabled={formik.isSubmitting || isUserLoading}
                        onBlur={() => formik.setFieldTouched('fK_ServiceType', true)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                      />
                      {formik.errors.fK_ServiceType && formik.touched.fK_ServiceType && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.fK_ServiceType}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-3'>
                <div className='col-lg-6 col-sm-12'>
                  <label
                    htmlFor='exampleFormControlInput1'
                    className='required form-label fs-7 mb-1 '
                  >
                    Default Timezone
                  </label>
                  <div>
                    <div className='w-100'>
                      <Select
                        {...formik.getFieldProps('fK_DefaultTimeZone')}
                        name='fK_DefaultTimeZone'
                        placeholder='Select a timezone'
                        options={TimezoneOptions}
                        value={TimezoneOptions?.find(
                          (option) => option.value === formik.values.fK_DefaultTimeZone
                        )}
                        onChange={(option) =>
                          formik.setFieldValue('fK_DefaultTimeZone', option?.value || '')
                        }
                        isDisabled={formik.isSubmitting || isUserLoading}
                        onBlur={() => formik.setFieldTouched('fK_DefaultTimeZone', true)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                      />
                      {formik.errors.fK_DefaultTimeZone && formik.touched.fK_DefaultTimeZone && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.fK_DefaultTimeZone}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className='col-sm-12 col-md-6 col-lg-6'
                  hidden={!(userType === 'CONSUMER' && true)}
                >
                  <label className='form-label fs-7 mb-1 '>Pre Call Policy</label>
                  <div className='d-flex align-items-center'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <Select
                        {...formik.getFieldProps('fK_PreCallPolicy')}
                        name='fK_PreCallPolicy'
                        placeholder='Select a policy'
                        value={preCallPolicyOptions.find(
                          (option) => option.value === formik.values.fK_PreCallPolicy
                        )}
                        onChange={(option) =>
                          formik.setFieldValue('fK_PreCallPolicy', option?.value || '')
                        }
                        isDisabled={
                          formik.isSubmitting || isUserLoading || formik.values.fK_Customer === ''
                        }
                        options={preCallPolicyOptions}
                        onBlur={() => formik.setFieldTouched('fK_PreCallPolicy', true)}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            width: '100%',
                            border: '1px solid #e4e6ef',
                          }),
                          placeholder: (provided) => ({
                            ...provided,
                            color: '#a4afc4',
                          }),
                        }}
                      />
                      {formik.errors.fK_PreCallPolicy && (
                        <div className='d-block fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.fK_PreCallPolicy}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <br />

              <>
                <div
                  className='card mb-5 mb-xl-5 bg-light'
                  // style={{backgroundColor: '#FAF7F5'}}
                  hidden={(userType === 'INTERPRETER' || userType === 'SYSTEM') && true}
                >
                  <div className='card-body p-5'>
                    <div className='d-flex flex-wrap flex-sm-nowrap mb-3 w-100'>
                      <div className='w-100'>
                        <div className='d-flex mb-2 flex-column w-100'>
                          <h5 className='text-black fs-3 fw-bold'>
                            {' '}
                            Pre-Authenticated PIN Management
                          </h5>
                          <h5 className='text-gray-600 text-hover-primary fw-normal me-1'>
                            (HINT : 'Should have minimum 4 digits')
                          </h5>
                          <div className='mb-1'>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                              <div className='d-flex flex-grow-1'>
                                <input
                                  placeholder='PIN CODE'
                                  {...formik.getFieldProps('pinCode')}
                                  type='text'
                                  name='pinCode'
                                  style={{
                                    fontWeight: 700,
                                    fontSize: '20px',
                                    letterSpacing: '0.5em',
                                    height: '45px',
                                    textAlign: 'center',
                                  }}
                                  className={clsx(
                                    'form-control form-control-white  mb-2 p-3 border border-dashed min-w-100px mt-2 bg-white border-gray-300 me-3'
                                  )}
                                  autoComplete='off'
                                  onChange={handlePinCodeChange}
                                  disabled={formik.isSubmitting || isUserLoading}
                                />
                              </div>
                              <div className='d-flex ms-3'>
                                <button
                                  type='button'
                                  onClick={() => GeneratePinCode()}
                                  className='btn btn-primary '
                                  disabled={isUserLoading || isPinCodeGenerated}
                                >
                                  {(isPinCodeGenerated || isUserLoading) && (
                                    <span className=''>
                                      Please wait...{' '}
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  )}
                                  {!isPinCodeGenerated && (
                                    <span className='indicator-label'>
                                      <i className='bi bi-arrow-repeat fs-6'></i>
                                      Generate
                                    </span>
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className='mt-2 mb-3'>
                            <div className='row g-4 g-xl-6'>
                              {formik.errors.pinCode && formik.touched.pinCode && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.pinCode}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {!isCheckAvailabilty && availabilityStatus && (
                            <>
                              <div className='mb-3'>
                                <div className='row g-4 g-xl-6'>
                                  <div className='d-flex align-items-center'>
                                    {!availabilityStatus?.data && (
                                      <>
                                        <div className='w-100 p-2 border border-success bg-white text-success rounded text-center fw-bold'>
                                          {' '}
                                          AVAILABLE
                                        </div>
                                      </>
                                    )}

                                    {availabilityStatus?.data && (
                                      <>
                                        {' '}
                                        <div className='w-100 p-2 border border-danger bg-white text-danger rounded text-center fw-bold'>
                                          {' '}
                                          NOT AVAILABLE
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className='mb-2 mt-2 w-100'>
                            <div className='d-flex flex-start'>
                              <div className='d-flex align-items-center flex-end'>
                                {/*<button
                                  style={{width: '100R%'}}
                                  type='reset'
                                 // onClick={() => fn_CheckAvailabilty()}
                                  className='btn btn-success btn-sm'
                                  data-kt-users-modal-action='cancel'
                                  disabled={
                                    isCheckAvailabilty ||
                                    isUserLoading ||
                                    isPinCodeGenerated ||
                                    isPinCodeError ||
                                    isGenPinCode
                                  }
                                >
                                  {(isCheckAvailabilty || isUserLoading) && (
                                    <span className=''>
                                      Please wait...{' '}
                                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                  )}
                                  {!isCheckAvailabilty && (
                                    <span className='indicator-label'>CHECK AVAILABILTY</span>
                                  )}
                                </button>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

              {/* <div className='mb-3'>
                <div className='row g-4 g-xl-6'>
                  <div className=''>
                    <label
                      htmlFor='exampleFormControlInput1'
                      className='required form-label fs-7 mb-1 '
                    >
                      Native Language & Default Service Type
                    </label>
                    <div className='d-flex align-items-center'>
                      <select
                        {...formik.getFieldProps('fK_DefaultNativeLanguage')}
                        name='fK_DefaultNativeLanguage'
                        className={clsx('form-select form-select-sm form-select-white')}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isUserLoading}
                      >
                        <option value=''>Select a Language</option>

                      </select>
                      <select
                        {...formik.getFieldProps('fK_ServiceType')}
                        name='fK_ServiceType'
                        className={clsx(
                          'form-select form-select-sm form-select-white ms-3'
                        )}
                        autoComplete='off'
                        disabled={formik.isSubmitting || isUserLoading}
                      >
                        <option value=''>Select a Service</option>

                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor='exampleFormControlInput1' className='required form-label fs-7 mb-1 '>
                  Default Timezone
                </label>
                <div>
                  <div className='w-100'>
                    <select
                      {...formik.getFieldProps('fK_DefaultTimeZone')}
                      name='fK_DefaultTimeZone'
                      className={clsx(
                        'form-select form-select-sm form-select-white mb-2'
                      )}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isUserLoading}
                    >
                      <option value=''>Select a Time Zone</option>
                    </select>
                  </div>
                </div>
              </div> */}
              <div className='mb-3'>
                <div className='row g-4 g-xl-6'>
                  {/* <div className='col-sm-6 col-md-6 col-lg-6 mb-5 '>
                    <div className=''>
                      <label htmlFor='exampleFormControlInput1' className='required form-label fs-7 mb-1 '>
                        Password Policy
                      </label>
                      <div className='d-flex align-items-center'>
                        <select
                          {...formik.getFieldProps('isNewPasswordGenerate')}
                          name='isNewPasswordGenerate'
                          className={clsx('form-select form-select-sm form-select-white')}
                          autoComplete='off'
                          disabled={formik.isSubmitting || isUserLoading}
                          onChange={(e) => {
                            formik.setFieldValue('isNewPasswordGenerate', e.target.value)
                            updateData(e.target.value)
                          }}
                        >
                          <option value='false'>No</option>
                          <option value='true'>Auto Regenerate Password sent via Email</option>
                        </select>
                        {formik.values.isNewPasswordGenerate + '' == 'true' ||
                          formik.values.isNewPasswordGenerate == true}
                        {formik.errors.isNewPasswordGenerate && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.isNewPasswordGenerate}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  {itemIdForUpdate && (
                    <div className='col-sm-6 col-md-6 col-lg-6 d-flex align-items-center'>
                      <div className='form-check-custom form-check-white form-check-sm mt-3 mb-3'>
                        <input
                          id='flexCheckDefault2'
                          placeholder=''
                          {...formik.getFieldProps('lockoutEnabled')}
                          type='checkbox'
                          name='lockoutEnabled'
                          className={clsx(
                            'form-check-input',
                            {
                              'is-invalid':
                                formik.touched.lockoutEnabled && formik.errors.lockoutEnabled,
                            },
                            {
                              'is-valid':
                                formik.touched.lockoutEnabled && !formik.errors.lockoutEnabled,
                            }
                          )}
                          checked={formik.values.lockoutEnabled}
                          disabled={formik.isSubmitting || isUserLoading}
                        />
                        {formik.touched.lockoutEnabled && formik.errors.lockoutEnabled && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{formik.errors.lockoutEnabled}</span>
                            </div>
                          </div>
                        )}

                        <label
                          className='form-check-label form-check-sm'
                          htmlFor='flexCheckDefault2'
                        >
                          Deactivate
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div hidden={userType === 'SYSTEM' && true}>
                  <h5>Permissions</h5>
                  <hr />
                  <div className='mb-3'>
                    <div className='row g-4 g-xl-6'>
                      <div className='col-sm-6 col-md-6 col-lg-6 '>
                        <label className='form-label fs-7 mb-1 '>OPI</label>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('opI_ShdTelephonic')}
                              type='checkbox'
                              name='opI_ShdTelephonic'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.opI_ShdTelephonic &&
                                    formik.errors.opI_ShdTelephonic,
                                },
                                {
                                  'is-valid':
                                    formik.touched.opI_ShdTelephonic &&
                                    !formik.errors.opI_ShdTelephonic,
                                }
                              )}
                              checked={formik.values.opI_ShdTelephonic}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              Scheduled Telephonic
                            </label>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('opI_OndemandTelephonic')}
                              type='checkbox'
                              name='opI_OndemandTelephonic'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.opI_OndemandTelephonic &&
                                    formik.errors.opI_OndemandTelephonic,
                                },
                                {
                                  'is-valid':
                                    formik.touched.opI_OndemandTelephonic &&
                                    !formik.errors.opI_OndemandTelephonic,
                                }
                              )}
                              checked={formik.values.opI_OndemandTelephonic}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              On-Demand Telephonic
                            </label>
                          </div>
                        </div>
                        <br />
                        <label className='form-label fs-7 mb-1 '>VRI</label>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('vrI_ShdVideoInteroreting')}
                              type='checkbox'
                              name='vrI_ShdVideoInteroreting'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.vrI_ShdVideoInteroreting &&
                                    formik.errors.vrI_ShdVideoInteroreting,
                                },
                                {
                                  'is-valid':
                                    formik.touched.vrI_ShdVideoInteroreting &&
                                    !formik.errors.vrI_ShdVideoInteroreting,
                                }
                              )}
                              checked={formik.values.vrI_ShdVideoInteroreting}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              Scheduled Video Interpreting
                            </label>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('vrI_OndemandVideoInteroreting')}
                              type='checkbox'
                              name='vrI_OndemandVideoInteroreting'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.vrI_OndemandVideoInteroreting &&
                                    formik.errors.vrI_OndemandVideoInteroreting,
                                },
                                {
                                  'is-valid':
                                    formik.touched.vrI_OndemandVideoInteroreting &&
                                    !formik.errors.vrI_OndemandVideoInteroreting,
                                }
                              )}
                              checked={formik.values.vrI_OndemandVideoInteroreting}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              On-Demand Video Interpreting
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6 col-md-6 col-lg-6 '>
                        <label className='form-label fs-7 mb-1 '>OSI</label>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('osI_OnsiteConsecutive')}
                              type='checkbox'
                              name='osI_OnsiteConsecutive'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.osI_OnsiteConsecutive &&
                                    formik.errors.osI_OnsiteConsecutive,
                                },
                                {
                                  'is-valid':
                                    formik.touched.osI_OnsiteConsecutive &&
                                    !formik.errors.osI_OnsiteConsecutive,
                                }
                              )}
                              checked={formik.values.osI_OnsiteConsecutive}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              Onsite Consecutive
                            </label>
                          </div>
                        </div>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('osI_OnsiteSimultaneous')}
                              type='checkbox'
                              name='osI_OnsiteSimultaneous'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.osI_OnsiteSimultaneous &&
                                    formik.errors.osI_OnsiteSimultaneous,
                                },
                                {
                                  'is-valid':
                                    formik.touched.osI_OnsiteSimultaneous &&
                                    !formik.errors.osI_OnsiteSimultaneous,
                                }
                              )}
                              checked={formik.values.osI_OnsiteSimultaneous}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              Onsite Simultaneous
                            </label>
                          </div>
                        </div>
                        {/* <div className='d-flex align-items-center mb-2'>
                      <div className='form-check-custom form-check-white form-check-sm-12'>
                        <input
                          placeholder=''
                          {...formik.getFieldProps('osI_OnsiteWhisper')}
                          type='checkbox'
                          name='osI_OnsiteWhisper'
                          className={clsx(
                            'form-check-input',
                            {
                              'is-invalid':
                                formik.touched.osI_OnsiteWhisper && formik.errors.osI_OnsiteWhisper,
                            },
                            {
                              'is-valid':
                                formik.touched.osI_OnsiteWhisper &&
                                !formik.errors.osI_OnsiteWhisper,
                            }
                          )}
                          checked={formik.values.osI_OnsiteWhisper}
                          disabled={formik.isSubmitting || isUserLoading}
                        />
                        <label
                          className='form-check-label form-check-sm'
                          htmlFor='flexCheckDefault'
                        >
                          Onsite Whisper
                        </label>
                      </div>
                    </div>
                    <div className='d-flex align-items-center mb-2'>
                      <div className='form-check-custom form-check-white form-check-sm-12'>
                        <input
                          placeholder=''
                          {...formik.getFieldProps('osI_Onsite')}
                          type='checkbox'
                          name='osI_Onsite'
                          className={clsx(
                            'form-check-input',
                            {'is-invalid': formik.touched.osI_Onsite && formik.errors.osI_Onsite},
                            {
                              'is-valid': formik.touched.osI_Onsite && !formik.errors.osI_Onsite,
                            }
                          )}
                          checked={formik.values.osI_Onsite}
                          disabled={formik.isSubmitting || isUserLoading}
                        />
                        <label
                          className='form-check-label form-check-sm'
                          htmlFor='flexCheckDefault'
                        >
                          Onsite
                        </label>
                      </div>
                    </div> */}
                        <br />
                        <label className='form-label fs-7 mb-1 '>Other</label>
                        <div className='d-flex align-items-center mb-2'>
                          <div className='form-check-custom form-check-white form-check-sm-12'>
                            <input
                              placeholder=''
                              {...formik.getFieldProps('other_3rdPartyPlatform')}
                              type='checkbox'
                              name='other_3rdPartyPlatform'
                              className={clsx(
                                'form-check-input',
                                {
                                  'is-invalid':
                                    formik.touched.other_3rdPartyPlatform &&
                                    formik.errors.other_3rdPartyPlatform,
                                },
                                {
                                  'is-valid':
                                    formik.touched.other_3rdPartyPlatform &&
                                    !formik.errors.other_3rdPartyPlatform,
                                }
                              )}
                              checked={formik.values.other_3rdPartyPlatform}
                              disabled={formik.isSubmitting || isUserLoading}
                            />
                            <label
                              className='form-check-label form-check-sm'
                              htmlFor='flexCheckDefault'
                            >
                              Third Party Platform
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <h5>Roles</h5>
                <hr />
                <div className='mb-7'>
                  {response?.data?.map((value, i) => (
                    <>
                      <div className='d-flex fv-row'>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            {...formik.getFieldProps('role')}
                            name='role'
                            type='radio'
                            value={value.name}
                            id='kt_modal_update_role_option_0'
                            defaultChecked={i === 0 ? true : false}
                            disabled={formik.isSubmitting || isUserLoading}
                            checked={value.name === formik.values.role ? true : false}
                            onChange={() => {
                              formik.setFieldValue('role', value.name)
                            }}
                          />
                          <label
                            className='form-check-label form-check-sm'
                            htmlFor='kt_modal_update_role_option_0'
                          >
                            {value.name}
                            <div className='text-gray-600'>{value.status}</div>
                          </label>
                        </div>
                      </div>
                      <div className='separator separator-dashed my-5'></div>
                    </>
                  ))}
                  {formik.errors.role && formik.touched.role && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.role}</span>
                      </div>
                    </div>
                  )}
                  {response?.data?.length == 0 && <>No roles available to assign</>}
                </div>
              </div>
            </div>
          </div>
          <div className='modal-footer py-3 px-0'>
            <button
              type='reset'
              onClick={() => cancel()}
              className='btn btn-light me-3 btn-sm'
              data-kt-users-modal-action='cancel'
              disabled={formik.isSubmitting || isUserLoading}
            >
              Discard
            </button>
            <button
              className='btn btn-primary btn-sm'
              data-kt-users-modal-action='submit'
              disabled={
                isUserLoading || formik.isSubmitting || !!availabilityStatus?.data || !formik.dirty
              }
              onClick={() => {
                formik.submitForm()
              }}
            >
              <span className='indicator-label'>Save</span>
              {(formik.isSubmitting || isUserLoading) && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {UserEditModalForm}

// Insert the styles into the document head
const styleSheet = document.createElement('style')
styleSheet.innerText = styles
document.head.appendChild(styleSheet)

import React, {createContext, useMemo, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {KTSVG} from '../../../../../../_metronic/helpers/components/KTSVG'
import {useAuth} from '../../../../../modules/auth'
import {
  createResponseContext,
  initialQueryResponse,
  roleQUERIES,
} from '../../../../../../_metronic/helpers'
import axios, {AxiosResponse} from 'axios'
import {DropdownResponse, User} from '../../core/_models'
import {useQuery} from 'react-query'
import {usersColumns} from '../../table/columns/_columns'
import {QueryResponseProvider, useQueryResponse} from '../../core/QueryResponseProvider'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`

const status = [
  {value: 'ACTIVE', label: 'Active'},
  {value: 'DESABLE', label: 'Inactive'},
  {value: 'PENDING', label: 'Pending'},
]
const roles = [
  {value: '1', label: 'Admin'},
  {value: '2', label: 'Manager'},
  {value: '3', label: 'User'},
  {value: '4', label: 'Interpreter Permissions'},
]
type Props = {
  isUserLoading?: boolean
  userType: string
  customerCode?: number
}
export const mycontext = createContext({}); 
const UserListFilterDropdown: React.FC<Props> = ({userType, isUserLoading, customerCode}) => {
  const {currentUser} = useAuth()
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedRole, setSelectedRole] = useState<MultiValue<{value: string; label: string}>>([])
  const [selectedStatus, setSelectedStatus] = useState<MultiValue<{value: string; label: string}>>(
    []
  )
  const [joinDate, setJoinedDate] = useState('')
  const [lastLoginDate, setLoginDate] = useState('')
  const [contextValue, setContextValue] = useState({});
  const {refetch} = useQueryResponse()

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios.get(GET_ALL_CUSTOMER_NAME).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )
  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const columns = useMemo(() => usersColumns, [])

  const handleSubmit = async () => {
    const data = {
      customers: selectedCustomer.map((option) => option.value),
      status: selectedStatus.map((option) => option.value),
      roles: selectedRole.map((option) => option.value),
      joinDate: joinDate || undefined,
      lastLoginDate: lastLoginDate || undefined,
    }
    setContextValue(data) 
    setShowDropdownMenu(false)
  }

  const handleReset = async () => {
    setSelectedCustomer([])
    setSelectedStatus([])
    setSelectedRole([])
    setJoinedDate('')
    setLoginDate('')
    setShowDropdownMenu(false)
    setContextValue({}) 
    await refetch()
  }

  return (
    <>
      <mycontext.Provider value={contextValue}>
        <QueryResponseProvider userType={userType} />
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className='btn btn-icon btn-sm no-caret'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-muted' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 max-w-300px w-300px' style={{minHeight: '100%'}}>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>
            <div className='separator border-gray-200'></div>
            <div className='px-4 mt-4'>
              <div className='row flex-column'>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='status' className='form-label fs-7 mb-0'>
                      Status
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={status}
                            value={selectedStatus}
                            onChange={setSelectedStatus}
                            isMulti
                            placeholder='Select : All'
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-3'>
                  <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                    Customer Name
                  </label>
                  <div className='row g-4 g-xl-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={!isFetchingCustomer ? customerOptions : []}
                          placeholder='Select : All'
                          isMulti
                          value={selectedCustomer}
                          onChange={setSelectedCustomer}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-3'>
                  <label htmlFor='role' className='form-label fs-7 mb-1'>
                    Role
                  </label>
                  <div className='row g-4 g-xl-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12'>
                      <div className='w-100'>
                        <Select
                          className='react-select-styled react-select-solid react-select-sm'
                          classNamePrefix='react-select'
                          options={roles}
                          placeholder='Select : All'
                          isMulti
                          value={selectedRole}
                          onChange={setSelectedRole}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              width: '100%',
                              border: '1px solid #e4e6ef',
                            }),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mb-3'>
                  <label htmlFor='joinDate' className='form-label fs-7 mb-1'>
                    Joined Date
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    id='joinDate'
                    value={joinDate}
                    onChange={(e) => setJoinedDate(e.target.value)}
                  />
                </div>
                <div className='mb-3'>
                  <label htmlFor='lastLoginDate' className='form-label fs-7 mb-1'>
                    Login Date
                  </label>
                  <input
                    type='date'
                    className='form-control form-control-solid'
                    id='lastLoginDate'
                    value={lastLoginDate}
                    onChange={(e) => setLoginDate(e.target.value)}
                  />
                </div>
              </div>
              <div className='text-end mt-3 px-4'>
                <button type='button' className='btn btn-sm btn-light me-2' onClick={handleReset}>
                  Reset
                </button>
                <button type='button' className='btn btn-sm btn-primary' onClick={handleSubmit}>
                  Apply
                </button>
              </div>
            </div>
          </Dropdown.Menu>
        )}
        </Dropdown>
        </mycontext.Provider>
    </>
  )
}
export {UserListFilterDropdown}

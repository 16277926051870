import {KTSVG} from '../../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

const ListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddObjModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <OverlayTrigger
        placement='top'
        overlay={<Tooltip id='tooltip-filter'>Add Pre-Call Policy</Tooltip>}
      >
        <div>
          <button
            type='button'
            className='btn btn-sm btn-primary btn-icon'
            onClick={openAddObjModal}
          >
            <i className='bi bi-plus fs-2'></i>
          </button>
        </div>
      </OverlayTrigger>
      {/* <button type='button' className='btn btn-primary btn-sm' onClick={openAddObjModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
        Add Pre-Call Policy
      </button> */}
    </div>
  )
}

export {ListToolbar}

/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div
        className='d-flex justify-content-between align-items-center text-end'
        style={{width: '100%'}}
      >
        <div className='d-flex text-dark'>
          {/* <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span> */}
          <a
            href='https://overleap.lk/'
            target='_blank'
            className='text-gray-800 text-hover-primary'
          >
            Ad Astra Connect - Ad Astra, Inc © 2024
          </a>
        </div>
        <div className='d-flex justify-content-end text-end'>
          <a
            href='https://trello.com/en/login'
            target='_blank'
            className='menu-link px-2 text-primary'
          >
            <i className='bi bi-headset me-2 text-primary'></i>
            Help Support
          </a>
        </div>
      </div>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}

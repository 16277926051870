import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import {PreCallPolicy} from '../../core/_models'

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<PreCallPolicy>>
}
const CustomHeader: FC<Props> = ({className, title, tableProps}) => {
  const id = tableProps.column.id

  return (
    <th {...tableProps.column.getHeaderProps()} style={{cursor: 'pointer'}} className={className}>
      {title}
    </th>
  )
}

export {CustomHeader}

import {KTSVG} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ListFilter} from './ListFilter'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
 

const ListToolbar = () => {
  const {setItemIdForUpdate} = useListView()
  const openAddObjModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      {/* <ListFilter /> */}

      {/* begin::Export */}
      {/* <button type='button' className='btn btn-light-primary me-3'>
        <KTSVG path='/media/icons/duotune/arrows/arr078.svg' className='svg-icon-2' />
        Export
      </button> */}
      {/* end::Export */}

      {/* begin::Add obj */}
      <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Add Document</Tooltip>}>
        <div>
          <button
            type='button'
            className='btn btn-primary btn-sm btn-icon'
            onClick={openAddObjModal}
          >
            <i className='bi bi-plus fs-2'></i>
          </button>
        </div>
      </OverlayTrigger>
      {/* <button type='button' className='btn btn-primary btn-sm' onClick={openAddObjModal}>
        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
        Add Document
      </button> */}
      {/* end::Add obj*/}
    </div>
  )
}

export {ListToolbar}

import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {initial, PreCallPolicy, ResponseObject} from '../core/_models'
import {createDataRecord, updateDataRecord} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {ListLoading} from '../components/loading/ListLoading'
import toaster from '../../../../../../../Utils/toaster'
import {useParams} from 'react-router-dom'

type Props = {
  isObjLoading: boolean
  dbObj: PreCallPolicy | null
}

const editObjSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  // description: Yup.string().required('Required'),
})

const CreateModalForm: FC<Props> = ({dbObj, isObjLoading}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [result, setResult] = useState<ResponseObject>({})
  const {id: customerId} = useParams()

  const [dbObjForEdit] = useState<PreCallPolicy | null>(dbObj)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues:
      dbObjForEdit != null ? dbObjForEdit : ({...initial, customer: customerId} as PreCallPolicy),
    validationSchema: editObjSchema,
    onSubmit: async (values, {setSubmitting}) => {
      console.log('Submitting ....')
      setSubmitting(true)
      try {
        if (dbObjForEdit != null) {
          var result = await updateDataRecord(values)
          setResult({status: result?.status, text: result?.text})
          if (result?.status == 'S') {
            toaster('success', result?.text ?? 'Record Updated')
            setSubmitting(true)
            cancel(true)
          } else if (result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not Updated')
          }
        } else {
          var result = await createDataRecord(values)
          setResult({status: result?.status, text: result?.text})
          if (result?.status == 'S') {
            toaster('success', result?.text ?? 'Record Updated')
            setSubmitting(true)
            cancel(true)
          } else if (result?.status == 'E') {
            toaster('error', result?.text ?? 'Record Not Updated')
          }
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        //setSubmitting(true)
        //cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row'>
            <div className='row'>
              <input
                {...formik.getFieldProps('customer')}
                type='number'
                name='customer'
                value={customerId}
                hidden
              />
              <div className='col-lg-12 mb-3'>
                <label className='form-label required  fs-7'>Name</label>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <input
                      placeholder='Name'
                      {...formik.getFieldProps('name')}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-white form-select-sm mb-2 custom-input-height',
                        {'is-invalid': formik.touched.name && formik.errors.name},
                        {
                          'is-valid': formik.touched.name && !formik.errors.name,
                        }
                      )}
                      autoComplete='on'
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className='col-lg-12 mb-3'>
                <label className='form-label fs-7 mb-1'>Description</label>
                <div className='row'>
                  <div className='col-lg-12 fv-row'>
                    <textarea
                      placeholder='Description'
                      {...formik.getFieldProps('description')}
                      rows={3}
                      name='description'
                      className={clsx(
                        'form-control form-control-white form-control-sm mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.description && formik.errors.description},
                        {
                          'is-valid':
                            formik.touched.description &&
                            !formik.errors.description &&
                            formik.values.description,
                        }
                      )}
                      autoComplete='on'
                      disabled={formik.isSubmitting || isObjLoading}
                    />
                    {formik.touched.description && formik.errors.description && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.description}</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {result?.status == 'E' && (
          <>
            <div className='notice d-flex bg-light-danger rounded border-danger border border-dashed p-6'>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-bold'>
                  <h4 className='text-gray-800 fw-bolder'>Error!</h4>
                  <div className='fs-6 text-gray-600'>{result?.text}</div>
                </div>
              </div>
            </div>
          </>
        )}

        {/* begin::Actions */}
        <div className='text-end mt-5'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light btn-sm me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isObjLoading}
          >
            Discard
          </button>

          <button
            type='submit'
            className='btn btn-primary btn-sm'
            data-kt-users-modal-action='submit'
            disabled={isObjLoading || formik.isSubmitting || !formik.isValid}
          >
            <span className='indicator-label'>Submit</span>
            {(formik.isSubmitting || isObjLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isObjLoading) && <ListLoading />}
    </>
  )
}

export {CreateModalForm}

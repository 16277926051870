import moment from 'moment'

const statusMapping: Record<number, React.ReactNode> = {
  0: <span className='badge badge-light-info fw-bolder me-auto px-4 py-3'>Invited</span>,
  1: <span className='badge badge-light-success fw-bolder me-auto px-4 py-3'>Accepted</span>,
  2: <span className='badge badge-light-danger fw-bolder me-auto px-4 py-3'>Declined</span>,
  3: <span className='badge badge-light-primary fw-bolder me-auto px-4 py-3'>Confirmed</span>,
  4: (
    <span className='badge badge-light-secondary fw-bolder me-auto px-4 py-3 text-gray-600'>
      Rejected
    </span>
  ),
  5: <span className='badge badge-light-warning fw-bolder me-auto px-4 py-3'>Pending</span>,
  6: <span className='badge badge-light-muted fw-bolder me-auto px-4 py-3'>Cancel</span>,
}

const downloadFile = async (base64URL: string, downloadFileName: string) => {
  try {
    // Fetch the file content
    const response = await fetch(base64URL)
    const data = await response.blob()

    // Create a URL for the Blob object
    const url = window.URL.createObjectURL(new Blob([data]))

    // Create a hidden anchor element with the download attribute
    const link = document.createElement('a')
    link.href = url
    link.download = downloadFileName // Set desired filename here

    // Programmatically click the anchor element to trigger the download
    link.click()

    // Revoke the URL to free up memory
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

function getFileType(url: string): string | null {
  const extension = url.split('.').pop()?.toLowerCase() // Get the file extension and convert to lowercase

  if (!extension) {
    return null // If there's no extension, return null
  }

  return extension
}

function formatDateString(originalDateString: string) {
  // Create a Date object from the input time string
  let date = new Date(originalDateString)

  // Extract components of the date
  let year = date.getFullYear()
  let month = ('0' + (date.getMonth() + 1)).slice(-2) // Months are zero-based
  let day = ('0' + date.getDate()).slice(-2)
  let hours = ('0' + date.getHours()).slice(-2)
  let minutes = ('0' + date.getMinutes()).slice(-2)

  // Construct the desired format
  let outputTime = `${year}-${month}-${day}T${hours}:${minutes}`

  return outputTime
}

function formatDuration(allSeconds: number) {
  const duration = moment.duration(allSeconds, 'seconds')

  // Extract the number of days, hours, and minutes
  const days = Math.floor(duration.asDays())
  const hours = duration.hours()
  const minutes = duration.minutes()
  const seconds = duration.seconds()

  // Create a formatted string
  let fromateDate = '0 Day'
  let formattedDuration = ''

  if (days > 0) {
    fromateDate = `${days} Day${days > 1 ? 's' : ''} `
  }
  if (hours > 0 || days > 0) {
    // Include hours if there's a day or if hours > 0
    formattedDuration += `${hours} hour${hours > 1 ? 's' : ''} `
  }
  if (minutes > 0 || hours > 0 || days > 0) {
    // Include minutes if there's an hour or day or if minutes > 0
    formattedDuration += ` ${minutes} minute${minutes > 1 ? 's' : ''}`
  }
  if(seconds > 0 || minutes > 0 || hours > 0 || days > 0) {
    // Include minutes if there's an hour or day or if minutes > 0
    formattedDuration += ` ${seconds} second${seconds > 1 ? 's' : ''}`
  }

  return {fromateDate: fromateDate, formattedDuration: formattedDuration}
}

function getTotalAppointments(userType: string | undefined, appointmentStatusCount: any) {
  let total = 0
  if (userType === 'SYSTEM') {
    total =
      parseInt(appointmentStatusCount?.open) +
      parseInt(appointmentStatusCount?.readyToAssign) +
      parseInt(appointmentStatusCount?.confirmed) +
      parseInt(appointmentStatusCount?.noInterpreter) +
      parseInt(appointmentStatusCount?.abandoned) +
      parseInt(appointmentStatusCount?.cancelled) +
      parseInt(appointmentStatusCount?.completed)
  }
  if (userType === 'CONSUMER') {
    total =
      parseInt(appointmentStatusCount?.open) +
      parseInt(appointmentStatusCount?.readyToAssign) +
      parseInt(appointmentStatusCount?.confirmed) +
      parseInt(appointmentStatusCount?.noInterpreter) +
      parseInt(appointmentStatusCount?.abandoned) +
      parseInt(appointmentStatusCount?.cancelled) +
      parseInt(appointmentStatusCount?.completed)
  }
  if (userType === 'INTERPRETER') {
    total =
      parseInt(appointmentStatusCount?.open) +
      parseInt(appointmentStatusCount?.readyToAssign) +
      parseInt(appointmentStatusCount?.confirmed) +
      parseInt(appointmentStatusCount?.abandoned) +
      parseInt(appointmentStatusCount?.cancelled) +
      parseInt(appointmentStatusCount?.completed)
  }
  return total
}

export {
  statusMapping,
  downloadFile,
  getFileType,
  formatDateString,
  formatDuration,
  getTotalAppointments,
}

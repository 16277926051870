/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {CommonLoading} from '../../../../Utils/commonLoading'

type Props = {
  className: string
  isLoading: boolean
  call_log: any
  tableRef: any
}

const LogsOndemandViewTable: React.FC<Props> = ({className, isLoading, call_log, tableRef}) => {
  const [activeModalTab, setActiveModalTab] = useState(1)

  const handleModalTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveModalTab(tabNumber)
  }
  return (
    <div className={`card ${className}`}>
      <div className='modal fade' tabIndex={-1} id='kt_click_total'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header py-2'>
              <h4 className='modal-title'>Details</h4>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div className='d-flex align-items-center justify-content-start'>
                <h5 className='d-flex text-gray-600 fw-normal fs-2 mb-0'>
                  Call ID : 70610776623026
                </h5>{' '}
                <span className='d-flex ms-3'>
                  <KTSVG
                    path='media/icons/duotune/general/gen054.svg'
                    className='svg-icon-muted svg-icon-2'
                  />
                </span>
              </div>
              <div className='row g-4'>
                <div className='mb-5'>
                  <div
                    className='py-3'
                    style={{minWidth: '300px', overflowX: 'auto', minHeight: '50px'}}
                  >
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-semibold flex-nowrap'>
                      <li className='nav-item'>
                        <a
                          className={`nav-link fs-7 text-gray-400   ${
                            activeModalTab === 1 ? 'text-active-dark fw-bold active show' : ''
                          }`}
                          data-bs-toggle='tab'
                          href='#kt_modaltab_pane_1'
                          onClick={() => handleModalTabClick(1)}
                        >
                          Call Details
                        </a>
                      </li>
                      <li className='nav-item'>
                        <a
                          className={`nav-link fs-7 text-gray-400 ${
                            activeModalTab === 2 ? 'text-active-dark fw-bold active show' : ''
                          }`}
                          data-bs-toggle='tab'
                          href='#kt_modaltab_pane_2'
                          onClick={() => handleModalTabClick(2)}
                        >
                          Routing Details
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className=''>
                    <div className='my-3'>
                      <div className='tab-content' id='myTabContent'>
                        {activeModalTab === 1 && (
                          <div className=''>
                            <div
                              className='tab-pane fade show active'
                              id='kt_modaltab_pane_1'
                              role='tabpanel'
                            >
                              <>
                                <div className=''>
                                  <div className='d-flex justify-content-between align-items-center py-5 flex-wrap'>
                                    <div className='d-flex flex-wrap'>
                                      <div className='d-flex align-items-center '>
                                        <div className='symbol-hover me-2'>
                                          <div className='symbol symbol-circle symbol-50px'>
                                            <img
                                              src={toAbsoluteUrl('/media/avatars/300-4.jpg')}
                                              alt=''
                                            />
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column flex-1 w-auto'>
                                          <div className='d-flex align-items-center fw-light'>
                                            <span className='fw-semibold d-flex align-items-center fs-6 me-1'>
                                              Faren Jam Sector called on the following device
                                            </span>
                                          </div>
                                          <span className='fs-6 fw-normal'>
                                            <i className='bi bi-telephone-forward-fill me-2'></i>
                                            GSM/+17603522407
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className='d-flex flex-wrap'>
                                      <div className='d-flex align-items-center '>
                                        <div className='symbol-hover me-2'>
                                          <div className='symbol symbol-circle symbol-50px'>
                                            <img
                                              src={toAbsoluteUrl('/media/avatars/300-7.jpg')}
                                              alt=''
                                            />
                                          </div>
                                        </div>

                                        <div className='d-flex flex-column flex-1 w-auto'>
                                          <div className='d-flex align-items-center fw-light'>
                                            <span className='fw-semibold d-flex align-items-center fs-6 me-1'>
                                              Navom ser answered on the following device
                                            </span>
                                          </div>
                                          <span className='fs-6 fw-normal'>
                                            <i className='bi bi-phone-fill me-2'></i>iOS/17.5.1
                                            Adastra for Interpreters
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='separator my-3'></div>
                                  <div className='row'>
                                    <h6 className='fs-6 fw-normal py-5'>
                                      You can edit any field that was answered on this call. New
                                      fields added to a pre-call policy will not be included below.
                                    </h6>
                                    <div className='col-sm-12 col-md-4'>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='form-label mb-1 fs-7 required'
                                        >
                                          CBP - Are you a member of US Border patrol
                                        </label>

                                        <input
                                          type='text'
                                          className='form-control form-control-white form-select-sm custom-input-height'
                                          placeholder='Enter CBP'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='form-label mb-1 fs-7 mb-1 fs-7 required'
                                        >
                                          Caller's Name
                                        </label>

                                        <input
                                          type='text'
                                          className='form-control form-control-white form-select-sm custom-input-height'
                                          placeholder='Enter Caller Name'
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-12 col-md-4'>
                                      <div className='mb-3'>
                                        <label
                                          htmlFor='exampleFormControlInput1'
                                          className='form-label mb-1 fs-7 mb-1 fs-7 required'
                                        >
                                          Title
                                        </label>

                                        <input
                                          type='text'
                                          className='form-control form-control-white form-select-sm custom-input-height'
                                          placeholder='Enter Title'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='text-end pt-3'>
                                  <button
                                    type='reset'
                                    className='btn btn-sm btn-light me-2'
                                    data-kt-menu-dismiss='true'
                                  >
                                    Cancel
                                  </button>

                                  <button
                                    type='submit'
                                    className='btn btn-sm btn-primary'
                                    data-kt-menu-dismiss='true'
                                  >
                                    Save
                                  </button>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                        {activeModalTab === 2 && (
                          <div className=''>
                            <div
                              className='tab-pane fade show active'
                              id='kt_modaltab_pane_2'
                              role='tabpanel'
                            >
                              <>
                                <div className='separator my-3'></div>
                                <div className='row'>
                                  <h6 className='fs-6 fw-normal py-5'>
                                    The list below shows all interpreters in your roster that were
                                    dialed before this call was picked up or abandoned.
                                  </h6>
                                </div>
                                <div className='d-flex flex-wrap flex-end'>
                                  <div className='d-flex align-items-center me-3 flex-nowrap'>
                                    <input
                                      type='text'
                                      data-kt-user-table-filter='search'
                                      className='form-control form-control-white form-control-sm max-w-250px custom-search-radius'
                                      placeholder='Search'
                                    />

                                    <button
                                      type='button'
                                      className='btn btn-primary btn-icon btn-sm custom-search-btn-radius px-3'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen021.svg'
                                        className=''
                                      />
                                    </button>
                                  </div>
                                </div>
                                <div className='table-responsive'>
                                  {/* begin::Table */}
                                  <table className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'>
                                    {/* begin::Table head */}
                                    <thead>
                                      <tr className='fw-semibold text-muted text-uppercase'>
                                        <th className='min-w-100px'>Interpreter Name</th>
                                        <th className='min-w-150px'>ID</th>
                                        <th className='min-w-150px'>Time Dialed</th>
                                        <th className='min-w-150px'>Ring Duration</th>
                                        <th className='min-w-150px'>Device</th>
                                        <th className='min-w-150px text-end'>Refused</th>
                                      </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                      <tr>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            Amila silva
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            3453454
                                          </a>
                                        </td>

                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            7/13/24 1:06:54 am
                                          </a>
                                        </td>
                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            12 sec
                                          </a>
                                        </td>

                                        <td>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'>
                                            Web
                                          </a>
                                        </td>

                                        <td className='text-end'>
                                          <a className='text-gray-800 text-hover-primary d-block fs-6'></a>
                                        </td>
                                      </tr>
                                    </tbody>
                                    {/* end::Table body */}
                                  </table>
                                  {/* end::Table */}
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-sm-12 '></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className='py-0'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-3 gy-3'
            ref={tableRef}
          >
            {/* begin::Table head */}
            <thead>
              <tr className='fw-semibold text-muted text-uppercase'>
                <th className='min-w-200px '>Call ID</th>
                <th className='min-w-150px'>
                  <span className='d-flex align-items-center'>
                    Call Status{' '}
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Connected - call includes an interpretation duration. Abandoned - call does not include an operator or interpretation duration. Unfulfilled - call answered by an operator, but does not have an interpretation duration. Rollover - requestor placed VRI call, but chose to use OPI instead while waiting to be connected.'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>

                <th className='min-w-150px '>Interpreter</th>
                <th className='min-w-200px '>Date Time</th>
                <th className='min-w-150px '>Requester</th>
                <th className='min-w-200px'>
                  <span className='d-flex align-items-center'>
                    Interpreter Rating{' '}
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Interpreter rating / call quality rating submitted by requestor'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>

                <th className='min-w-200px '>
                  <span className='d-flex align-items-center'>
                    Requester Rating
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Requestor rating / call quality rating submitted by interpreter'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>
                <th className='min-w-150px '>Language</th>
                <th className='min-w-150px '>Type</th>
                <th className='min-w-200px '>
                  <span className='d-flex align-items-center'>
                    Queue / Hold Time
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Call Center Queue/Hold Time is added in parenthesis next and represents the time a requestor waiting on hold with a BPIN Call Center. This column is listed separately in CSV exports as Call Center Queue/Hold Time'
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>
                <th className='min-w-150px '>Duration</th>
                <th className='min-w-200px '>Other Participants</th>
                <th className='min-w-200px '>Conference Duration</th>
                <th className='min-w-150px '>Account Charge</th>
                <th className='min-w-150px text-end'>
                  <span className=''>
                    Interpreter Pay
                    <a
                      type='button'
                      className=''
                      data-toggle='tooltip'
                      data-placement='top'
                      title="N/A amounts indicate that call was flagged as a test call and won't be included in the invoice."
                    >
                      <KTSVG
                        path='/media/icons/duotune/general/gen045.svg'
                        className='svg-icon-muted text-primary'
                      />
                    </a>
                  </span>
                </th>
                {/* <th className='min-w-150px text-end'>Action</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {!isLoading && call_log?.length > 0 ? (
                call_log?.map((item: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <a
                        href='#'
                        className='text-gray-800 text-hover-primary d-block fs-6'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_click_total'
                      >
                        {item.code ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>Connected</a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.interpreter ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.initiationTimestamp ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.customer ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.languages ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.channel ?? '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.agentPauseDurationInSeconds
                          ? Math.ceil(item.agentPauseDurationInSeconds) + 's'
                          : '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.totalCallDuration ? Math.ceil(item.totalCallDuration) + 's' : '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>
                        {item.agentCallDuration ? Math.ceil(item.agentCallDuration) + 's' : '-'}
                      </a>
                    </td>
                    <td>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                    </td>
                    <td className='text-end'>
                      <a className='text-gray-800 text-hover-primary d-block fs-6'>-</a>
                    </td>
                    {/* <td>
                      <div className='d-flex flex-end'>
                        <a
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_click_total'
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </a>
                      </div>
                    </td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={14}>
                    <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                      <div className='text-center'>
                        <div className='symbol symbol-200px '>
                          <img src='/media/other/nodata.png' alt='' />
                        </div>
                      </div>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                        No matching records found
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
      {isLoading && <CommonLoading />}
    </div>
  )
}

export {LogsOndemandViewTable}

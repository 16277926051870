import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'

type Props = {
  userType: string
}

const UserEditModalHeader: FC<Props> = ({userType}) => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return (
    <div className='modal-header py-2'>
      {/* begin::Modal title */}
      <h2 className='modal-title fs-4'>
        {userType == 'ADMIN'
          ? itemIdForUpdate
            ? 'Edit Language'
            : 'Add Language & Rates'
          : itemIdForUpdate
          ? 'Edit Language'
          : 'Add Language'}
      </h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        onClick={() => setItemIdForUpdate(undefined)}
        style={{cursor: 'pointer'}}
      >
        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {UserEditModalHeader}

import React, {FC, useState} from 'react'
import {Dropdown} from 'react-bootstrap'
import Select, {MultiValue} from 'react-select'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import axios, {AxiosResponse} from 'axios'
import {roleQUERIES} from '../../../../_metronic/helpers'
import {useQuery} from 'react-query'
import {DropdownResponse} from '../../members-management/users-list/core/_models'
import {useAuth} from '../../../modules/auth'

const API_URL = process.env.REACT_APP_API_URL
const GET_ALL_CUSTOMER_NAME = `${API_URL}/customer/getall-shortlist/Consumer/0`
const GET_ALL_SERVICETYPES = `${API_URL}/master/getall/SERVICE_TYPE`
const GET_ALL_COMMUNICATIONTYPES = `${API_URL}/master/getall/COMMUNICATION_TYPE`
const GET_ALL_Languages = `${API_URL}/master/languages/active-shortlist`

type Props = {
  setAllAppointments: any
  setCurrentPage: any
  setFilterData: any
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
}
export function AppointmentViewTableFilterDropdown({
  setAllAppointments,
  setCurrentPage,
  setFilterData,
  setTotalPages,
}: Props) {
  const {currentUser} = useAuth()
  const [selectedCustomer, setSelectedCustomer] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedStatus, setSelectedStatus] = useState<MultiValue<{value: string; label: string}>>(
    []
  )
  const [selectedLanguages, setSelectedLanguages] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedServiceTypes, setSelectedServiceTypes] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [selectedCommunicationTypes, setSelectedCommunicationTypes] = useState<
    MultiValue<{value: string; label: string}>
  >([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [showDropdownMenu, setShowDropdownMenu] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchQuery, setSearchQuery] = useState<any>({
    search: '',
    id: 0,
  })
  const [currentPage, setpage] = useState(1)

  const getCustomers = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_CUSTOMER_NAME}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getservicetypes = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_SERVICETYPES}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getcommunicationtypes = (): Promise<DropdownResponse> => {
    return axios
      .get(`${GET_ALL_COMMUNICATIONTYPES}`)
      .then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const getNativeLanguage = (): Promise<DropdownResponse> => {
    return axios.get(`${GET_ALL_Languages}`).then((d: AxiosResponse<DropdownResponse>) => d.data)
  }

  const {isFetching: isFetchingCustomer, data: responseCustomer} = useQuery(
    `${roleQUERIES.Customer_LIST}`,
    getCustomers,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingNativeLanguage, data: nativeLanguageList} = useQuery(
    `Query.nativeLanguageList`,
    getNativeLanguage,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingServiceType, data: servicetypeList} = useQuery(
    `Query.serviceTypeList`,
    getservicetypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const {isFetching: isFetchingcommunication, data: communicationTypeList} = useQuery(
    `Query.communicationTypeList`,
    getcommunicationtypes,
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const languageOptions =
    nativeLanguageList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const serviceTypeOptions =
    servicetypeList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const communicationTypeOptions =
    communicationTypeList?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const customerOptions =
    responseCustomer?.data?.map((d) => ({
      value: d.key ?? '',
      label: d.value ?? '',
    })) ?? []

  const handleSubmit = () => {
    const SUBMIT_FILTERS_URL = `${API_URL}/Appoinment/filter/${currentUser?.result.userType}/${currentUser?.result.code}`
    const data = {
      accounts: selectedCustomer.map((option) => option.value),
      langs: selectedLanguages.map((option) => option.value),
      serviceTypes: selectedServiceTypes.map((option) => option.value),
      communicationTypes: selectedCommunicationTypes.map((option) => option.value),
      startDate: startDate || undefined,
      endDate: endDate || undefined,
      status: selectedStatus.map((option) => option.value),
    }
    setFilterData(data)
    setCurrentPage(1)

    axios.post(SUBMIT_FILTERS_URL, data).then((response) => {
      const {data: appointments, payload} = response.data
      setAllAppointments(appointments)
      setTotalPages(payload.pagination.last_page)
      setShowDropdownMenu(false)
    })
  }
  
  const status = [
    {value: '0', label: 'Open'},
    {value: '1', label: 'Ready to Assign'},
    {value: '2', label: 'Confirmed' },
    {value: '7', label: 'Accepted'},
    {value: '3', label: 'No interpreter'},
    {value: '4', label: 'Abandoned'},
    {value: '5', label: 'Complete'},
    {value: '6', label: 'Cancelled' },
    
  ]
  const getFilteredStatusOptions = () => {
    if (currentUser?.result.userType === 'INTERPRETER') {
      return status.filter(option =>
        ['0','2', '4', '5', '6','7'].includes(option.value)
      );
    }
    if (currentUser?.result.userType === 'SYSTEM') {
      return status.filter(option =>
        ['0','1','2', '3','4', '5', '6'].includes(option.value)
      );
    }
    if (currentUser?.result.userType === 'CONSUMER') {
      return status.filter(option =>
        ['0','2','3', '4', '5', '6'].includes(option.value)
      );
    }
    
    return status;
  };
  
 

  const fetchAllAppointments = async () => {
    setIsLoading(true)
    try {
      let response = await axios.get(
        `${API_URL}/Appoinment/${currentUser?.result?.userType}/${currentUser?.result?.code}`,
        {
          params: {
            page: currentPage,
            items_per_page: 10,
            ...searchQuery,
          },
        }
      )
      const {data, payload} = response.data
      setCurrentPage(1)
      setAllAppointments(data)
      setTotalPages(payload.pagination.last_page)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleReset = async () => {
    setCurrentPage(1)
    setSelectedCustomer([])
    setSelectedLanguages([])
    setSelectedServiceTypes([])
    setSelectedCommunicationTypes([])
    setStartDate('')
    setEndDate('')
    setShowDropdownMenu(false)
    await fetchAllAppointments()
    setFilterData({})
  }
  return (
    <>
      <Dropdown onToggle={(nextShow) => setShowDropdownMenu(nextShow)}>
        <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-filter'>Filter</Tooltip>}>
          <Dropdown.Toggle
            variant='primary'
            id='dropdown-basic'
            className='btn btn-icon btn-sm no-caret'
          >
            <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-primary' />
          </Dropdown.Toggle>
        </OverlayTrigger>
        {showDropdownMenu && (
          <Dropdown.Menu className='p-4 w-400px'>
            <div className='px-4 pb-4'>
              <div className='fs-4 text-dark fw-semibolder'>Filter</div>
            </div>

            <div className='separator border-gray-200'></div>
            <div className='card-scroll px-4 mt-4'>
              <div className='row flex-column'>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='customerName' className='form-label fs-7 mb-1'>
                      Customer Name
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingCustomer ? customerOptions : []}
                            placeholder='Select : All'
                            isMulti
                            value={selectedCustomer}
                            onChange={setSelectedCustomer}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='languages' className='form-label fs-7 mb-1'>
                      Languages
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingNativeLanguage ? languageOptions : []}
                            placeholder='Select : All'
                            isMulti
                            value={selectedLanguages}
                            onChange={setSelectedLanguages}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='serviceType' className='form-label fs-7 mb-1'>
                      Service Type
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingServiceType ? serviceTypeOptions : []}
                            placeholder='Select : All'
                            isMulti
                            value={selectedServiceTypes}
                            onChange={setSelectedServiceTypes}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='communicationType' className='form-label fs-7 mb-1'>
                      Communication Type
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            options={!isFetchingcommunication ? communicationTypeOptions : []}
                            placeholder='Select : All'
                            isMulti
                            value={selectedCommunicationTypes}
                            onChange={setSelectedCommunicationTypes}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12'>
                  <div className='mb-3'>
                    <label htmlFor='status' className='form-label fs-7 mb-1'>
                      Status
                    </label>
                    <div className='row g-4 g-xl-4'>
                      <div className='col-sm-12 col-md-12 col-lg-12'>
                        <div className='w-100'>
                          
                          <Select
                            className='react-select-styled react-select-solid react-select-sm'
                            classNamePrefix='react-select'
                            value={selectedStatus}
                            onChange={setSelectedStatus}
                            options={getFilteredStatusOptions()}
                            isMulti
                            placeholder='Select'
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                border: '1px solid #e4e6ef',
                              }),
                            }}
                          />
                          
                      
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='fv-row'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor='startDate' className='form-label fs-7 mb-1'>
                        Start Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder='Enter Subject'
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor='endDate' className='form-label fs-7 mb-1'>
                        End Date
                      </label>
                      <input
                        type='date'
                        className='form-control form-control-white form-select-sm custom-input-height'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='text-end pt-5 px-4'>
              <button
                type='reset'
                className='btn btn-sm btn-light me-2'
                data-kt-menu-dismiss='true'
                onClick={handleReset}
              >
                Reset
              </button>

              <button
                type='submit'
                className='btn btn-sm btn-primary'
                data-kt-menu-dismiss='true'
                onClick={handleSubmit}
              >
                Apply
              </button>
            </div>
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  )
}

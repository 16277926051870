import {FC, useEffect} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteCustomer, reinvite} from '../../core/_requests'
import {Deletemodel} from '../../core/_models'
import {Link} from 'react-router-dom'

type Props = {
  code: string
}

const UserActionsCell: FC<Props> = ({code}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(code)
  }

  // const deleteData: Deletemodel = {
  //   email: email,
  //   code: code,
  // };

  const deleteItem = useMutation(() => deleteCustomer(code), {
    // 💡 response of the mutation is passed to onSuccess
    onSuccess: () => {
      // ✅ update detail view directly
      queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
    },
  })

  const func_reinvite = (param: string) => {
    reinvite(code)
  }

  return (
    <>
      <Link to={'/customers/' + code}>
        <div className='d-flex justify-content-end flex-shrink-0'>
          <a
            href='#'
            type='button'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          >
            <i className='bi bi-eye-fill'></i>
          </a>
        </div>
      </Link>
    </>
  )

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Edit
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => await deleteItem.mutateAsync()}
          >
            Deactivate
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}

        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export {UserActionsCell}

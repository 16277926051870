import {ColumnInstance, Row, useTable} from 'react-table'
import {KTCardBody} from '../../../../../../../_metronic/helpers/components/KTCardBody'
import {PreCallPolicy} from '../core/_models'
import {objsColumns} from './columns/_columns'
import {useMemo} from 'react'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
} from '../core/QueryResponseProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {ListPagination} from '../components/pagination/ListPagination'

const Table = () => {
  const columns = useMemo(() => objsColumns, [])

  const dbObjs = useQueryResponseData()
  const isLoading = useQueryResponseLoading()

  const data = useMemo(() => dbObjs, [dbObjs])

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: data !== undefined ? data : [],
  })

  return (
    <>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-2 table-hover'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-semibold fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<PreCallPolicy>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='table-hover' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<PreCallPolicy>, i) => {
                  prepareRow(row)
                  return <CustomRow row={row} key={row.id} />
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='py-5 d-flex flex-column align-content-center justify-content-center'>
                      <div className='text-center'>
                        <div className='symbol symbol-200px '>
                          <img src='/media/other/nodata.png' alt='' />
                        </div>
                      </div>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center fw-semibold fs-3 text-gray-400'>
                        No matching records found
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <ListPagination />
        {isLoading && <ListLoading />}
      </KTCardBody>
    </>
  )
}

export {Table}
